<template>
  <div class="create-user">
      <b-row>
        <b-col cols="12" class="header">
          <h1>Create user</h1>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-card header="Create user">
        <b-form-group>
        <b-form @submit.prevent="createUser()">
          {{ profile }}
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="First name *" label-for="firstname">
                <b-input name="firstname" v-model="name"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Last name *" label-for="lastname">
                <b-input name="lastname" v-model="last_name" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="E-mail *" label-for="email">
                <b-input name="email" v-model="email" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Role *" label-for="user-type">
                <b-form-select name="user-type" v-model="user_type" :options="type_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Image *" label-for="imageupload">
              <b-form-file 
                v-model="file"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
          
            <b-col md="6">
              <b-form-group v-if="user_type == 1" label-size="sm" label="Level" label-for="user-level">
                <b-form-select name="user-level" v-model="user_level" :options="level_list_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Phone" label-for="phone">
                <b-input name="phone" v-model="phone" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Address" label-for="street">
                <b-input v-model="address_street" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Zip Code" label-for="zip">
                <b-input name="zip" v-model="address_zip" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="City" label-for="town">
                <b-input name="town" v-model="address_town" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Country *" label-for="country">
                <b-form-select name="country" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="btn btn-success pull-right" v-if="loading == false">
            Create user
          </b-button>
          <b-button disabled class="btn btn-success pull-right" v-else-if="loading == true">
            <b-spinner small ></b-spinner> Loading
          </b-button>
        </b-form>
        </b-form-group>
      </b-card>
  </div>
</template>

<script>

let level_list = [];

export default {
  name: "createuser",
  components: {
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          href: '/dashboard'
        },
        {
          text: 'Settings',
          href: '/settings'
        },
        {
          text: 'Create User',
          href: '#',
          active: true
        },
      ],
      success: false,
      error: false,
      loading: false,
      errorMessage: '',
      name: '',
      last_name: '',
      email: '',
      user_level: 0,
      password: 'a',
      c_password: 'a',
      phone: '',
      address_street: '',
      address_town: '',
      address_zip: '',
      country: null,
      file: '',
      user_type: null,
      level_list_options: level_list,
      type_options: [
        { value: null, text: 'Please select a user role' },
        { value: 1, text: 'CUBE' },
        { value: 2, text: 'Profile' },
        { value: 3, text: 'Brand' },
      ],
      country_options: [],
    }
  },
  created () {
    this.getLevelList(),
    this.addCountryList()
  },
  methods: {
    addCountryList() {
      this.$global.getCountryList().forEach(item => {
        this.country_options.push(item)
      })
    },
    getLevelList() {
      this.$http.bamse.get('user/listlevels')
        .then(response => this.getLevelListSuccess(response))
        .catch(() => this.getLevelListFailed())
    },
    getLevelListSuccess(response) {

      if(response.data.success) {
        level_list = []
        response.data.success.forEach(function (result, key) {
          level_list.push({value: result.id, text: result.user_level_name})
        });
        
        this.level_list_options = level_list
      }
    },
    getLevelListFailed() {
      this.error = 'no fetch sorry'
    },
    createUser() {

      if (this.name == '' || this.last_name == '' || this.email == '' || this.user_type == '' || this.file == '' || this.country == '' ){
        this.$global.makeToast(this, 'Oh oh!', 'Required information missing.', 'danger')
      } else {

        this.loading = true
        
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('last_name', this.last_name);
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('c_password', this.c_password);
        formData.append('user_type', this.user_type);
        formData.append('user_level', this.user_level);
        formData.append('file', this.file);
        formData.append('phone', this.phone);
        formData.append('address_street', this.address_street);
        formData.append('address_town', this.address_town);
        formData.append('address_town', this.address_town);
        formData.append('address_zip', this.address_zip);
        formData.append('country', this.country);

        this.$http.bamse.post('user/register', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => this.createUserSuccess(response))
        .catch(error => this.createUserFailed(error))
      }
    },
    createUserSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'User created', 'success')
        
         setTimeout( () => this.$router.push({ path: '/list-users?type=active' }), 1500);
      }
    },
    createUserFailed(error) {
      loading = false
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
  }
}
</script>
