<template>
  <div class="resetpassword">
    <b-row>
      <b-col cols="12" class="background" v-bind:style="{ 'background-image': 'url(' + selectedImage + ')' }">
        <b-col cols="4" class="box">
          <h1>RevRelations</h1>
          <b-form class="loginform" @submit.prevent="resetPasswordLast()">
            <b-input
              id="email"
              placeholder="Email"
              name="email"
              v-model="email"
            ></b-input>
            <b-input
              type="password"
              id="password"
              placeholder="New Password"
              name="password"
              v-model="password"
            ></b-input>
            <b-button type="submit" variant="primary">Reset password</b-button>
          </b-form>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "resetpassword",
  components: {
  },
  data: function () {
    return {
      email: this.$route.query.email,
      password: '',
      token: this.$route.query.token,
      error: false,
      success: false,
      images: [
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/1.png',
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/2.png',
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/3.png'
      ],
      selectedImage: null,
    }
  },
  mounted() {
    window.setInterval(() => {
        this.selectedImage = this.randomItem(this.images)
    },7000);
  },
  created() {
    this.selectedImage = this.randomItem(this.images)
  },
  methods: {
    randomItem (items) {
      return items[Math.floor(Math.random()*items.length)];
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    resetPasswordLast() {
      if(this.validateEmail(this.email) && this.password.length > 0) {
        this.$http.bamse.post('password/reset', {
            email: this.email, 
            password: this.password,
            token: this.token
        }).then(response => this.resetPasswordLastSuccessful(response))
          .catch(response => this.resetPasswordLastFailed(response))
      } else {
        this.$global.makeToast(this, 'Oh oh!', 'Enter a valid email address and a safe password.')
      }
    },
    resetPasswordLastSuccessful(response) {
      if (response.data) {
        this.$global.makeToast(this, 'Success!', response.data.message)
      }
      this.$router.replace(this.$route.query.redirect || '/')
    },
    resetPasswordLastFailed(response) {
      this.$global.makeToast(this, 'Oh oh!', 'This password reset token is expired or already used.')
    }
  }
};
</script>
