<template>
  <div>
    <b-navbar toggleable="lg" >
      <b-navbar-brand>
        <router-link to="/dashboard">
          <svg v-if="dev === false" class="logo" viewBox="0 0 101 84">
            <path d="M74.4,3.4C67,3.4,59,7.3,58,12.9V3.7H43v38.7c0,10.6-6.2,18.4-15.9,18.4c-2.4,0-5.1-0.9-6-1.5l-2.8,10.9
              c1.9,1.1,5.3,2,8.9,2c7.4,0,14.8-3.9,15.8-9.5v8.9h15V33.2c1-10.6,6.9-18.4,16.6-18.4c2.4,0,5.5,0.9,6.4,1.5l2.9-10.9
              C82,4.3,78.1,3.4,74.4,3.4z"/>
          </svg>
          <svg v-if="dev === true" class="logo-dev" viewBox="0 0 101 84">
            <path d="M74.4,3.4C67,3.4,59,7.3,58,12.9V3.7H43v38.7c0,10.6-6.2,18.4-15.9,18.4c-2.4,0-5.1-0.9-6-1.5l-2.8,10.9
              c1.9,1.1,5.3,2,8.9,2c7.4,0,14.8-3.9,15.8-9.5v8.9h15V33.2c1-10.6,6.9-18.4,16.6-18.4c2.4,0,5.5,0.9,6.4,1.5l2.9-10.9
              C82,4.3,78.1,3.4,74.4,3.4z"/>
          </svg>
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="this.$store.state.user && this.$store.state.profile_id || this.$store.state.user && this.$store.state.brand_id">
          <b-nav-item link-classes="trythis">
            <router-link v-bind:to="'dashboard'">
              Dashboard
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="showAdlinks === true && type === 2">
            <router-link v-bind:to="'available-adlinkcampaigns'">
              Adlinks
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link v-bind:to="'campaigns'">
              Campaigns
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link v-bind:to="'reports'">
              Reports
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="type === 1 && level === 1 || type === 1 && level === 3">
            <router-link v-bind:to="'finances'">
              <span>Financials</span>
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="type === 2">
            <router-link v-bind:to="'finances'">
              <span>Payments</span>
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div v-if="this.$store.state.user">
            <b-nav-item-dropdown right>
              <template slot="button-content">
                <b-img
                fluid
                class="profile_pic_nav"
                :src="getProfileImg(current_id)"
                @error="replaceByDefault"
                ></b-img>
                {{ name }}
                <span v-if="dev === true">
                  ({{ getAccountType() }})
                </span>
              </template>
              <b-dropdown-item to="settings" v-if="type === 1">
                <font-awesome-icon icon="cogs" /> Settings
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'manageuser' }" v-else-if="type === 2">
                <font-awesome-icon icon="cogs" /> Settings
              </b-dropdown-item>
              <b-dropdown-item @click="switchUser()"  v-if="type === 2">
                <font-awesome-icon icon="user" /> Switch profile
              </b-dropdown-item>
              <b-dropdown-item @click="logOut()">
                <font-awesome-icon icon="sign-out" /> Sign Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
          <!-- <div v-else>
            <router-link to="login">
              Login
            </router-link>
          </div> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: "Navigation",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'brand_id']),
    nav: {
      get () { return this.$store.state.profile_assets[0].tune_id},
      set (value) { this.addNav(value) }
    }
  },
  created() {
    this.addNav()
    this.getDev()
  },
  data() {
    return {
      navLinks: [
        { id: 1, title: 'Dashboard', link: 'dashboard' },
        { id: 2, title: 'Campaigns', link: 'campaigns' },
        { id: 3, title: 'Reports', link: 'reports' }

      ],
      dev: false,
      showAdlinks: false
    }
  },
  methods: {
    ...mapMutations({
      'logoutStore': 'LOGOUT',
      'setProfile': 'SETPROFILE'
    }),
    switchUser(){
      this.setProfile(null)
      this.$router.push({ path: '/' })
    },
    getDev(){
      if(process.env.VUE_APP_MODE == 'local') {
        this.dev = true
      }
    },
    getProfileImg(id){
      return this.$global.spacesURL()+'users/user_' + id + '/profile_image/user_' + id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    addNav(tune_id) {
      if(tune_id != 0) {
        //this.navLinks.splice(1, 0, {id: 5, title: 'Adlinks', link: 'available-adlinkcampaigns'})
        this.showAdlinks = true
      }
    },
    getAccountType() {
      let AccountName
      if(this.type === 1){
        AccountName = "Cube"
      } else if (this.type === 2) {
        AccountName = "Profile"
      } else if (this.type === 3) {
        AccountName = "Brand"
      } else {
        AccountName = "N/A"
      }
      return AccountName
    },
    logOut() {
      this.logoutStore();
    }
  }
}
</script>
