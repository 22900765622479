<template>
  <div class="dashboard cube-dashboard">
	  <b-row>
      <b-card-group columns>
        <b-card>
          <b-card-text>
            <div class="pull-left">
              <b-img 
                fluid
                class="profile_pic"
                :src="this.$global.spacesURL()+'users/user_' + current_id + '/profile_image/user_' + current_id + '.png'" 
                @error="replaceByDefault"
              ></b-img>
            </div>
            <div class="pull-left name">
              <h4>{{ name }}</h4>
            </div>
            <div class="clearfix"></div>
          </b-card-text>
          <router-link :to="{ name: 'manageuser', query: { id: current_id }}">
            <b-button block variant="outline-primary">Edit your settings</b-button>
          </router-link>
        </b-card>
      </b-card-group>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FileUpload from "@/components/general/FileUpload.vue";

export default {
  name: "salesdashboard",
  components: {
    FileUpload
  },
  computed: {
    ...mapState(['name', 'current_id', 'profile_assets', 'brand_assets']),
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsBrands() {
        // Create an options list from our fields
        return this.fields_brands
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsCampaignPm() {
        // Create an options list from our fields
        return this.fields_campaign_pm
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsCampaignSales() {
        // Create an options list from our fields
        return this.fields_campaigns_sales
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
  data: function () {
    return {
      calendaritems: [],
      refresh: false,
      file: null,
      image_name: '',
      image: '',
      filter: null,
      filter_brands: null,
      filter_campaigns_pm: null,
      items_campaigns_pm: [],
      filter_campaigns_sales: null,
      items_campaigns_sales: [],
      fields_campaigns_pm: [
        {
          key: 'campaign_name',
        },
        {
          key: 'action',
        }
      ],
      fields_campaigns_sales: [
        {
          key: 'campaign_name',
        },
        {
          key: 'campaign_budget',
        }
      ],
      fields: [
        {
          key: 'name',
        },
        {
          key: 'action',
        }
      ],
      fields_brands: [
        {
          key: 'company_name',
        },
        {
          key: 'action',
        }
      ],
      attrs: [
      {
        key: 'today',
        highlight: true,
        dates: [],
        customData: {
          names: []
        },
        popover: { 
          label: true,
          isInteractive: true,
        }
      }
    ]
    }
  },
  created () {
    this.getCalendarPosts(),
    this.listMicroCampaignsPm(),
    this.listMicroCampaignsSales()
  },
  methods: {
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    getProfileImg(profile_id){
      return this.$global.spacesURL()+'profiles/profile_' + profile_id + '/profile_picture/profile_' + profile_id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredBrands(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredCampaignsPm(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredCampaignsSales(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCalendarPosts() {
      this.$http.bamse.get('campaigns/fixed/cube/listalldates')
        .then(response => this.getCalendarPostsSuccess(response))
        .catch(() => this.getCalendarPostsFailed())
    },
    getCalendarPostsSuccess(response) {
      if(response.data.success) {

        //Events
        response.data.success.events.forEach((item, index) => {

          this.attrs[0].customData.names.push({
              date: item.end_date, 
              title: item.campaign_name+': '+item.title+' ('+item.type+')',
              campaign_id: item.campaign_id,
              type: item.type
          });

          this.attrs[0].dates.push(item.end_date);

        })

        //Profile activities
        response.data.success.profile_activities.forEach((item, index) => {
          console.log(item)
          this.attrs[0].customData.names.push({
              date: item.draft_date, 
              title: item.campaign_name+': '+item.activity+' (Draft)',
              campaign_id: item.campaign_id,
              type: 'Draft'
          });

          this.attrs[0].customData.names.push({
              date: item.publish_date, 
              title: item.campaign_name+': '+item.activity+' (Publish)',
              campaign_id: item.campaign_id,
              type: 'Publish'
          });

          this.attrs[0].dates.push(item.draft_date);
          this.attrs[0].dates.push(item.publish_date);

        })
        
        this.refresh = true

      }
    
    },
    getCalendarPostsFailed() {
      this.error = 'no fetch sorry'
    },
    listMicroCampaignsPm() {
      this.$http.bamse.get('user/campaigns/projectmanager')
        .then(response => this.listMicroCampaignsPmSuccess(response))
        .catch(() => this.listMicroCampaignsPmFailed())
    },
    listMicroCampaignsPmSuccess(response) {
      if(response.data.success) {
        this.items_campaigns_pm = response.data.success
      }
    },
    listMicroCampaignsPmFailed() {
      this.error = 'no fetch sorry'
    },
    listMicroCampaignsSales() {
      this.$http.bamse.get('user/campaigns/salesperson')
        .then(response => this.listMicroCampaignsSalesSuccess(response))
        .catch(() => this.listMicroCampaignsSalesFailed())
    },
    listMicroCampaignsSalesSuccess(response) {
      if(response.data.success) {
        this.items_campaigns_sales = response.data.success
      }
    },
    listMicroCampaignsSalesFailed() {
      this.error = 'no fetch sorry'
    },
    getTitleForDate(datum) {
      var title_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          title_array.push({
            title: item.title, 
            campaign_id: item.campaign_id,
            type: item.type
          })
        }
      })

      return title_array
    },
    getCampaingIdForDate(datum) {
      var campaignID_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          campaignID_array.push(item.campaign_id)
        }
      })

      return campaignID_array
    }
  }
};
</script>