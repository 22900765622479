<template>
    <b-card>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          hover 
          responsive
          :items="items" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
          foot-clone
        >
          <template v-slot:cell(profile_name)="row">
            <b-img 
              class="table-img"
              fluid 
              :src="getProfileImg(row.item.profile_id)" 
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}
          </template>
          <template v-slot:cell(amount)="row">
            {{ $global.convertCurrency(country, row.item.amount)}}
          </template>
          
          <template v-slot:cell(id)="row">
            <b-button 
              :id="row.item.withdrawal_id" 
              size="sm" 
              variant="outline-warning" 
              @click="markAsPaid(row.item.withdrawal_id)"
            >
              Retract payment
            </b-button>
          </template>

            <template #foot(id)="data">
                <span></span>
              </template>
              <template #foot(withdrawal_approved)="data">
                <span></span>
              </template>
              <template #foot(profile_name)="data">
                <span></span>
              </template>
          <template #foot(amount)="data">
            {{ $global.convertCurrency(country, total)}}
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import $ from 'jquery'

import { mapState } from 'vuex'
export default {
  name: "pendingwithdrawals",
  props:['items_from_approved', 'items_from_pending'],
  components: {
  
  },
  created () {
    this.listApprovedWithdrawals()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        total: 0,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'profile_name',
            sortable: true
          },
          {
            key: 'amount',
            sortable: true
          },
          {
            key: 'withdrawal_approved',
            sortable: true,
            label: 'Invoice appproved',
          },
          {
            key: 'id',
            label: 'Manage'
          }
        ],
        items: [],
      }
    },
    mounted: function () { 
      this.$root.$on('addNewItemFromPending', (newItem) => { // here you need to use the arrow function
      // this.items.push(newItem);
      this.listApprovedWithdrawals()
      })
    },
    computed: {
      ...mapState(['country']),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
        getProfileImg(id){
          return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
        },
        replaceByDefault(e){
          var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
          e.target.src = url
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        listApprovedWithdrawals() {
          this.$http.bamse.get('finance/withdrawals/approved')
            .then(response => this.listApprovedWithdrawalsSuccess(response))
            .catch(() => this.listApprovedWithdrawalsFailed())
        },
        listApprovedWithdrawalsSuccess(response) {
          if(response.data.success) {
            this.items = response.data.success;
            this.total = 0;
            this.items.forEach((item) => {
              this.total = this.total + Number(item.amount);
            })
          }
        },
        listApprovedWithdrawalsFailed() {
          this.error = 'no fetch sorry'
        },
        markAsPaid(id) {
          this.$http.bamse.post('finance/withdrawals/status', { 
            withdrawal_id: id,
            status: 0
          })
          .then(response => this.markAsPaidSuccess(response, id, status))
          .catch(() => this.markAsPaidFailed())
        },
        markAsPaidSuccess(response, id, knapp) {
          if(response.data.success) {
            this.success = true

            const index = this.items.findIndex(item => item.withdrawal_id === id) // find the post index 
            if (~index){ // if the post exists in array
              this.$root.$emit('addNewItemFromApproved', this.items[index]);
              this.items.splice(index, 1) //delete the post
            }
            this.listApprovedWithdrawals();

          }
        },
        markAsPaidFailed() {
          this.error = true
        },
    },
};
</script>
