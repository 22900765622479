<template>
    <div v-if="type == 1">
      <CubeCalendar />
    </div>

    <div v-else-if="type == 2">
      <ProfileCalendar />
    </div>

    <div v-else-if="type == 3">
      <BrandCalendar />
    </div>

    <div v-else>
      Loading...
    </div>

</template>

<script>

import { mapState } from 'vuex'
import CubeCalendar from "@/components/cube/CubeCalendar.vue";
import ProfileCalendar from "@/components/profile/ProfileCalendar.vue";
import BrandCalendar from "@/components/brand/BrandCalendar.vue";

export default {
  name: "calendar",
  components: {
    CubeCalendar,
    ProfileCalendar,
    BrandCalendar
  },
  computed: {
    ...mapState(['type', 'current_user_id']),
  },
  data: function () {
    return {
    }
  },
};
</script>
