import axios from 'axios'

const API_URL = process.env.VUE_APP_API_TUNE

export default axios.create({
  baseURL: API_URL,
  /**
   * Do not set authorization header here, this is what caused the login problem
   * when changing users. This instance gets created once and only gets detroyed when page
   * refreshes.
   */
  headers: {
    'Accept': 'text/plain, */*; q=0.01',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },

  transformRequest: [(data, headers) => { delete headers.common.Authorization 
    return data }]

})