<template>
  <div>
    <b-row>
    <b-col cols="12" class="header">
      <h1>Manage {{camp_name}}</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-tabs active content-class="mt-3">
      <b-tab title="General">
        <InformationAdlinkCampaign />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Connected profiles">
        <ConnectedProfilesForAdlinkCampaign />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Other profiles">
        <UnConnectedProfilesForAdlinkCampaign />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Edit campaign" >
        <div class="manage-campaign">
          <ManageAdlinkCampaign />
        </div>
      </b-tab>
      <b-tab title="Reports" >
          <ReportsAdlinkCampaign />
      </b-tab>
    </b-tabs>
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import InformationAdlinkCampaign from "@/components/campaigns/adlink/InformationAdlinkCampaign.vue";
import ManageAdlinkCampaign from "@/components/campaigns/adlink/ManageAdlinkCampaign.vue";
import ConnectedProfilesForAdlinkCampaign from "@/components/campaigns/adlink/ConnectedProfilesForAdlinkCampaign.vue";
import UnConnectedProfilesForAdlinkCampaign from "@/components/campaigns/adlink/UnConnectedProfilesForAdlinkCampaign.vue";
import ReportsAdlinkCampaign from "@/components/campaigns/adlink/ReportsAdlinkCampaign.vue";

export default {
  name: "managecampaign",
  components: {
    InformationAdlinkCampaign,
    ManageAdlinkCampaign,
    ConnectedProfilesForAdlinkCampaign,
    UnConnectedProfilesForAdlinkCampaign,
    ReportsAdlinkCampaign
  },
  computed: {
    ...mapState(['level', 'type', 'current_user_id']),
  },
  created () {
    this.getCampaignDetails()
  },
  data: function () {
    return {
      id: this.$route.query.id,
      camp_name: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Adlink Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Manage',
          href: '#',
          active: true
        }
      ]
    }
  },
  methods: {
    getCampaignDetails() {
      this.$http.bamse.get('campaigns/adlink/details?campaign_id='+this.id)
      .then(response => this.getCampaignDetailsSuccess(response))
      .catch(() => this.getCampaignDetailsFailed())
    },
    getCampaignDetailsSuccess(response) {
      if(response.data.success) {
        this.camp_name = response.data.success[0].campaign_name
      }
    },
    getCampaignDetailsFailed() {

    }
  },
};
</script>
