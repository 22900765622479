import axios from 'axios'

const LOCAL = process.env.VUE_APP_MODE === "local"

export default axios.create({

  baseURL: LOCAL ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL,
  /**
   * Do not set authorization header here, this is what caused the login problem
   * when changing users. This instance gets created once and only gets detroyed when page
   * refreshes.
   */
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
 
})