<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
            <b-input name="campaign_name" v-model="campaign_name"></b-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-size="sm" label="Brand" label-for="brand_id">
            <b-form-select name="brand_id" v-model="brand_id" :options="options_brand"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-size="sm" label="Sales person" label-for="sales_person">
            <b-form-select name="sales_person" v-model="sales_person" :options="options_sales_person"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-size="sm" label="Delivery" label-for="project_manager">
            <b-form-select name="project_manager" v-model="project_manager" :options="options_project_manager"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label-size="sm" label="Description" label-for="description">
          <b-form-textarea
            id="description"
            v-model="description"
            placeholder="Describe the campaign (for internal use)"
            rows="8"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label-size="sm" label="Start date" label-for="start_date">
          <datepicker
            wrapper-class="form-control"
            :monday-first="true"
            format="dd-MM-yyyy"
            v-model="start_date"
            name="start_date"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-size="sm" label="End date" label-for="end_date">
          <datepicker
            wrapper-class="form-control"
            :monday-first="true"
             format="dd-MM-yyyy"
            v-model="end_date"
            name="end_date"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-size="sm" label="Cover Image" label-for="imageupload">
          <b-form-file
            v-model="cover_image"
            placeholder="Upload a new cover image..."
            drop-placeholder="Drop cover image here..."
            accept=".jpg, .png"
            name="imageupload"
          ></b-form-file>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-button class="pull-right" @click="updateCampaign()" v-if="loading == false" variant="outline-success">Update campaign</b-button>
        <b-button class="pull-right" v-if="loading == true" variant="outline-success"><b-spinner small ></b-spinner> Loading</b-button>
      </b-col>
    </b-row>
    <b-row class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>Profile activities</h5>
        <b-table
          striped
          responsive
          :items="activities"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:row-details="row">
            <b-row>
              <b-col md="3">
                <span class="label">Draft Date</span>
                <datepicker
                  wrapper-class="form-control"
                  :monday-first="true"
                  format="dd-MM-yyyy"
                  v-model="activities[row.index].draft_date"
                  name="date"
                ></datepicker>
              </b-col>
              <b-col md="3">
                <span class="label">Publish Date</span>
                <datepicker
                  wrapper-class="form-control"
                  :monday-first="true"
                  format="dd-MM-yyyy"
                  v-model="activities[row.index].publish_date"
                  name="date"
                ></datepicker>
              </b-col>
              <b-col md="3">
                <span class="label">Revenue</span>
                <b-input
                  name="revenue"
                  v-model="activities[row.index].revenue"
                ></b-input>
              </b-col>
              <b-col md="3">
                <span class="label">Cost</span>
                <b-input
                  name="cost"
                  v-model="activities[row.index].cost"
                ></b-input>
              </b-col>

              <b-col cols="12">
                <br>
                <b-form-group label-size="sm" label="Description" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="activities[row.index].description"
                    placeholder="Description for the profile"
                    rows="5"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button class="pull-right margin-left" @click="updateActivity(row.index)" v-if="loading_activity == false" variant="outline-success">Update activity</b-button>
                <b-button class="pull-right margin-left" @click="deleteActivity(row.index)" v-if="loading_activity == false" variant="outline-danger">Delete activity</b-button>
                <b-button class="pull-right margin-left" v-if="loading_activity == true" variant="outline-success"><b-spinner small ></b-spinner> Loading</b-button>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.name }}
          </template>
          <template v-slot:cell(manage)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} details
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  name: "ManageFixedCampaign",
  components: {
    Datepicker,
    Multiselect
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: '',
      description: '',
      start_date: '',
      end_date: '',
      brand_id: '',
      options_project_manager: [],
      options_sales_person: [],
      cover_image: null,
      sales_person: '',
      project_manager: '',
      options_brand: [
        { value: null, text: 'Please select an option' },
      ],
      options_channels: ['Blogg', 'Instagram', 'Instagram Stories', 'Youtube', 'TikTok', 'Ambassadorship', 'Content Rights', 'Participation', 'Podcast', 'Snapchat'],
      loading: false,
      loading_activity: false,
      activities: null,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'activity',
          label: 'Activity',
          sortable: true
        },
        {
          key: 'manage',
          label: 'Manage',
          sortable: true
        },
      ],
      filter: null,
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets'])
  },
  created () {
    this.getBrandList()
    this.getcubeEmployees()
    this.getCampaign()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {
        this.campaign_name = response.data.success[0][0].campaign_name
        this.description = response.data.success[0][0].description
        this.start_date = response.data.success[0][0].start_date
        this.end_date = response.data.success[0][0].end_date
        this.brand_id = response.data.success[0][0].brand_id
        this.approved = response.data.success[0][0].approved_by_profile
        this.status = response.data.success[0][0].status
        this.sales_person = response.data.success[0][0].sales_person
        this.project_manager = response.data.success[0][0].project_manager

        //Activities in [1]
        this.activities = response.data.success[1]
        this.activities = _.uniqBy(this.activities, 'id');

      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {
      if(response.data.success) {
        var brand_options_list = this.options_brand
        response.data.success.forEach(function (result, key) {
          brand_options_list.push({value: result.id, text: result.company_name})
        });
      }
    },
    getBrandListFailed() {
      this.error = 'no fetch sorry'
    },
    getcubeEmployees() {
      this.$http.bamse.get('/user/listcube')
        .then(response => this.getcubeEmployeesSuccess(response))
        .catch(() => this.getcubeEmployeesFailed())
    },
    getcubeEmployeesSuccess(response) {
      if(response.data.success) {
        var employee_list = []
        response.data.success.forEach(function (result, key) {
          var name = result.name + " " + result.last_name
          employee_list.push({value: result.id, text: name})
        });

        this.options_sales_person = employee_list
        this.options_project_manager = employee_list
      }
    },
    getcubeEmployeesFailed() {
      this.error = 'no fetch sorry'
    },
    updateCampaign() {
      this.loading = true

      this.start_date = JSON.stringify(this.start_date)
      this.end_date = JSON.stringify(this.end_date)

      let formData = new FormData();

      formData.append('campaign_name', this.campaign_name);
      formData.append('brand_id', this.brand_id);
      formData.append('project_manager', this.project_manager);
      formData.append('sales_person', this.sales_person);
      formData.append('description', this.description);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      //formData.append('file', this.cover_image);

      this.$http.bamse.post('campaigns/fixed/edit?campaign_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateCampaignSuccess(response))
      .catch(error => this.updateCampaignFailed(error))
    },
    updateCampaignSuccess(response) {
      if(response.data.success) {
        this.getCampaign()
        this.loading = false
        this.$global.makeToast(this, 'Success', 'Campaign updated', 'success')
      }
    },
    updateCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    updateActivity(id) {
      this.loading_activity = true

      this.draft_date = JSON.stringify(this.activities[id].draft_date)
      this.publish_date = JSON.stringify(this.activities[id].publish_date)

      let formData = new FormData();

      formData.append('activity_id', this.activities[id].id);
      formData.append('revenue', this.activities[id].revenue);
      formData.append('cost', this.activities[id].cost);
      formData.append('description', this.activities[id].description)
      formData.append('draft_date', this.draft_date);
      formData.append('publish_date', this.publish_date);
      //formData.append('file', this.cover_image);

      this.$http.bamse.post('campaigns/fixed/editprofileactivity', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateActivitySuccess(response))
      .catch(error => this.updateActivityFailed(error))
    },
    updateActivitySuccess(response) {
      if(response.data.success) {
        this.getCampaign()
        this.loading_activity = false
        this.$global.makeToast(this, 'Success', 'Activity updated', 'success')
      }
    },
    updateActivityFailed() {
      this.error = 'no fetch sorry'
    },
    deleteActivity(id) {
      this.loading_activity = true

      let formData = new FormData();

      formData.append('activity_id', this.activities[id].id);

      this.$http.bamse.post('campaigns/fixed/deleteprofileactivity', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.deleteActivitySuccess(response))
      .catch(error => this.deleteActivityFailed(error))
    },
    deleteActivitySuccess(response) {
      if(response.data.success) {
        this.getCampaign()
        this.loading_activity = false
        this.$global.makeToast(this, 'Success', 'Activity deleted', 'success')
      }
    },
    deleteActivityFailed() {
      this.error = 'no fetch sorry'
    },
  }
};
</script>