<template>
  <div>
    
    <b-row>
      <b-col cols="12" class="header">
        <h1>Reports</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>

    <div v-if="type == 1">
      <CubeReports />
    </div>

    <div v-else-if="type == 2">
      <ProfileReports />
    </div>

    <div v-else-if="type == 3">
      <BrandReports />
    </div>

    <div v-else>
    
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import CubeReports from "@/components/reports/general/CubeReports.vue";
import ProfileReports from "@/components/reports/general/ProfileReports.vue";
import BrandReports from "@/components/reports/general/BrandReports.vue";

export default {
  name: "reports",
  components: {
    CubeReports,
    ProfileReports,
    BrandReports
  },
  computed: {
    ...mapState(['type', 'current_user_id']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Reports',
          active: true
        },
      ]
    }
  },
};
</script>