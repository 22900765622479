<template>
  <b-row class="login">
    <b-col sm="12">
      <p class="alert alert-danger" v-if="error">{{ error }}</p>
      <p class="alert alert-success" v-if="success">{{ success }}</p>
    </b-col>
    <b-col sm="12" v-if="resetpass === false">
      <h2 class="form-signin-heading">Sign in</h2>
      <b-form @submit.prevent="login()">
        <b-input
          id="email"
          placeholder="Email"
          name="email"
          v-model="email"
          required
        ></b-input>
        <b-input
          type="password"
          id="password"
          placeholder="Password"
          name="password"
          v-model="password"
          required
        ></b-input>
        <b-row>
          <b-col sm="1">
            <b-button type="submit" variant="primary">Login</b-button>
          </b-col>
          <b-col sm="11">
            <p @click="toggleResetPass()" class="forgottext">Forgot password?</p>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col sm="12" v-if="resetpass === true">
      <h2 class="form-signin-heading">Reset your password</h2>
      <b-form @submit.prevent="resetPassword()">
        <b-input
          id="email"
          placeholder="Email"
          name="email"
          v-model="email"
        ></b-input>
        <b-row>
          <b-col sm="1">
            <b-button disabled variant="primary" v-if="loading == true">
              <b-spinner small ></b-spinner>
            </b-button>
            <b-button v-if="loading == false" type="submit" variant="primary">Reset</b-button>
          </b-col>
          <b-col sm="11">
            <p @click="toggleResetPass()" class="forgottext">Sign in</p>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "login",
  components: {
  },
  data: function () {
    return {
      email: '',
      password: '',
      error: false,
      success: false,
      resetpass: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  created () {
    this.checkCurrentLogin()
  },
  updated () {
    this.checkCurrentLogin()
  },
  methods: {
    ...mapMutations({
      'loginStore': 'LOGIN',
      'logoutStore': 'LOGOUT'
    }),
    checkCurrentLogin () {
      if (this.currentUser) {
        this.$router.replace(this.$route.query.redirect || '/dashboard')
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    login() {
      if(this.validateEmail(this.email) && this.password.length > 0) {

        this.$http.bamse.post('user/login', { email: this.email, password: this.password })
        .then(response => this.loginSuccessful(response))
        .catch(() => this.loginFailed())

      } else {
        this.$global.makeToast(this, 'Oh oh!', 'Enter a valid email address.', 'danger')
      }

    },
    loginSuccessful(response) {
      if (!response.data.success.token) {
        this.loginFailed()
        return
      }
      this.loginStore(response.data.success);
    },
    loginFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Email address or password is incorrect. Please try again.', 'danger')
      //this.logoutStore();
      //delete localStorage.token
    },
    toggleResetPass() {
      this.resetpass = !this.resetpass
    },
    resetPassword() {
      if(this.validateEmail(this.email)) {
        this.loading = true
        this.$http.bamse.post('password/create', { email: this.email })
          .then(response => this.resetPasswordSuccessful(response))
          .catch(response => this.resetPasswordFailed(response))
      } else {
        this.$global.makeToast(this, 'Oh oh!', 'Enter a valid email address.', 'danger')
      }
    },
    resetPasswordSuccessful(response) {
      if (response.data) {
        this.success = response.data.message
      }
      this.loading = false
    },
    resetPasswordFailed(response) {
      this.$global.makeToast(this, 'Oh oh!', 'Couldnt find that email address in our system. Maybe you use another one?', 'danger')
      this.loading = false
    }
  }
};
</script>
