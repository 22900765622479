<template>
  <div class="create-user">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Manage 
          <span v-if="asset_type == 1">{{name}}</span>
          <span v-else>{{company_name}}</span>
        </h1>
        <b-breadcrumb v-if="asset_type == 1" :items="breadcrumbs"></b-breadcrumb>
        <b-breadcrumb v-else :items="breadcrumbs_company"></b-breadcrumb>
      </b-col>
    </b-row>
      <b-card>
        <b-form @submit.prevent="updateAsset()">
          <b-row v-if="asset_type == 1">
            <b-col cols="6">
              <b-form-group label-size="sm" label="Name" label-for="name">
                <b-input name="name" :disabled="edit === false" v-model="name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Type" label-for="company">
                <b-form-select name="company" :disabled="edit === false" v-model="company" :options="type_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="Company Name" label-for="company_name">
                <b-input name="company_name" :disabled="edit === false" v-model="company_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="Registration Number" label-for="reg_number">
                <b-input name="reg_number" :disabled="edit === false" v-model="reg_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="VAT Number" label-for="vat_number">
                <b-input name="vat_number" :disabled="edit === false" v-model="vat_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Gender" label-for="gender">
                 <b-form-select name="gender" :disabled="edit === false" v-model="gender" :options="gender_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Social Security Number" label-for="ssn">
                <b-input name="ssn" :disabled="edit === false" v-model="ssn" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Birthday" label-for="birthday">
                <datepicker :disabled="edit === false" v-model="birthday" name="birthday"
                  :bootstrap-styling=true
                  :calendar-button=true
                  :monday-first=true
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Entry Code" label-for="entry_code">
                <b-input name="entry_code" :disabled="edit === false" v-model="entry_code" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Country" label-for="country">
                <b-form-select name="country" :disabled="edit === false" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Clothes" label-for="size_clothes">
                <b-input name="size_clothes" :disabled="edit === false" v-model="size_clothes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Shoes" label-for="size_shoes">
                <b-input name="size_shoes" :disabled="edit === false" v-model="size_shoes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Jeans" label-for="size_jeans">
                <b-input name="size_jeans" :disabled="edit === false" v-model="size_jeans" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Clearing" label-for="bank_clearing">
                <b-input name="bank_clearing" :disabled="edit === false" v-model="bank_clearing" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Account" label-for="bank_account">
                <b-input name="bank_account" :disabled="edit === false" v-model="bank_account" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Name" label-for="bank_name">
                <b-input name="bank_name" :disabled="edit === false" v-model="bank_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank IBAN" label-for="bank_iban">
                <b-input name="bank_iban" :disabled="edit === false" v-model="bank_iban" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank BIC" label-for="bank_bic">
                <b-input name="bank_bic" :disabled="edit === false" v-model="bank_bic" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Instagram" label-for="url_instagram">
                <b-input name="url_instagram" :disabled="edit === false" v-model="url_instagram" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Facebook" label-for="url_facebook">
                <b-input name="url_facebook" :disabled="edit === false" v-model="url_facebook" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Blog" label-for="url_blog">
                <b-input name="url_blog" :disabled="edit === false" v-model="url_blog" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Youtube" label-for="url_youtube">
                <b-input name="url_youtube" :disabled="edit === false" v-model="url_youtube" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Agency" label-for="agency">
                <b-input name="agency" :disabled="edit === false" v-model="agency" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Agent" label-for="agent">
                <b-input name="agent" :disabled="edit === false" v-model="agent" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Exclusive Brands" label-for="exclusive_brands">
                <b-input name="exclusive_brands" :disabled="edit === false" v-model="exclusive_brands" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Denied Campaigns" label-for="denied_campaigns">
                <b-input name="denied_campaigns" :disabled="edit === false" v-model="denied_campaigns" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Image" label-for="imageupload">
                <b-form-file 
                  v-model="file"
                  placeholder="Choose a picture..."
                  drop-placeholder="Drop picture here..."
                  accept=".jpg, .png"
                  name="imageupload"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
              <b-form-file 
                v-model="file2"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="AdLinks" label-for="adlinks">
                <b-button v-if="tune_id == 0 && loading_tune == false" @click="this.connectAdlink" variant="outline-success">
                  Connect to AdLinks
                </b-button>
                <b-button v-if="tune_id == 0 && loading_tune == true" @click="this.connectAdlink" disabled variant="outline-success">
                  <b-spinner small ></b-spinner> Loading
                </b-button>
                <b-button  v-if="tune_id != 0 && loading_tune == false" variant="outline-success" disabled>
                  Tune ID: {{ tune_id }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="asset_type == 2">
            <b-col cols="6">
              <b-form-group label-size="sm" label="Brand name" label-for="company_name">
                <b-input name="company_name" :disabled="edit === false" v-model="company_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Registration number" label-for="reg_number">
                <b-input name="reg_number" :disabled="edit === false" v-model="reg_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="VAT number" label-for="vat_number">
                <b-input name="vat_number" :disabled="edit === false" v-model="vat_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Address" label-for="address_street">
                <b-input name="address_street" :disabled="edit === false" v-model="address_street" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="City" label-for="address_town">
                <b-input name="address_town" :disabled="edit === false" v-model="address_town" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Zip" label-for="address_zip">
                <b-input name="address_zip" :disabled="edit === false" v-model="address_zip" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Country" label-for="country">
                <b-form-select name="country" :disabled="edit === false" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Brand Image" label-for="imageupload">
                <b-form-file 
                  v-model="file"
                  placeholder="Choose a picture..."
                  drop-placeholder="Drop picture here..."
                  accept=".jpg, .png"
                  name="imageupload"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
              <b-form-file 
                v-model="file2"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="AdLinks" label-for="adlinks">
                <b-button v-if="tune_id == 0 && loading_tune == false" @click="this.connectAdlink" variant="outline-success">
                  Connect to AdLinks
                </b-button>
                <b-button v-if="tune_id == 0 && loading_tune == true" @click="this.connectAdlink" disabled variant="outline-success">
                  <b-spinner small ></b-spinner> Loading
                </b-button>
                <b-button  v-if="tune_id != 0 && loading_tune == false" variant="outline-success" disabled>
                  Tune ID: {{ tune_id }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button v-if="edit == true && loading == false" type="submit" class="pull-right" variant="outline-success">Save</b-button>
        </b-form>
        <b-button v-if="edit == true && loading == false" @click="this.doEdit" class="pull-right cancel-btn" variant="outline-danger">Cancel</b-button>

        <b-button disabled class="btn btn-success pull-right" v-if="edit == true && loading == true">
          <b-spinner small ></b-spinner> Loading
        </b-button>

        <b-button v-if="edit == false && loading == false" @click="this.doEdit" class="pull-right" variant="outline-primary">Edit</b-button>
      
        

        

      </b-card>
  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';

export default {
  name: "manageasset",
  components: {
    Datepicker
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Profiles',
          to: '/list-assets?type=profiles',
        },
        {
          text: 'Manage asset',
          href: '#',
          active: true
        }
      ],
      breadcrumbs_company: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Brands',
          to: '/list-assets?type=brands',
        },
        {
          text: 'Manage asset',
          href: '#',
          active: true
        }
      ],
      id: this.$route.query.id,
      ref_id: null,
      success: false,
      error: false,
      edit: false,
      loading: false,
      loading_tune: false,
      errorMessage: '',
      asset_type: this.$route.query.type,
      name: null,
      company: 0,
      company_name: null,
      reg_number: null,
      vat_number: null,
      gender: null,
      ssn: null,
      birthday: '31 July 1980',
      entry_code: null,
      size_clothes: null,
      size_shoes: null,
      size_jeans: null,
      bank_clearing: null,
      bank_account: null,
      bank_name: null,
      bank_iban: null,
      bank_bic: null,
      url_instagram: null,
      url_facebook: null,
      url_blog: null,
      tune_id: null,
      url_youtube: null,
      agency: null,
      agent: null,
      address_street: null,
      address_town: null,
      address_zip: null,
      country: null,
      file: null,
      file2: null,
      exclusive_brands: null,
      denied_campaigns: null,
      main_type_options: [
        { value: 1, text: 'Profile' },
        { value: 2, text: 'Brand' },
      ],
      type_options: [
        { value: 0, text: 'Company' },
        { value: 1, text: 'Private' },
      ],
      gender_options: [
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
        { value: 'Other', text: 'Other' },
      ],
      country_selected: null,
      country_options: []
    }
  },
  created() {
    this.getAssetInfo()
    this.addCountryList()
  },
  methods: {
    addCountryList() {
      this.$global.getCountryList().forEach(item => {
        this.country_options.push(item)
      })
    },
    doEdit() {
      this.edit = !this.edit
    },
    checkTypeOfString(s) {
      if(s == '' || s == null || s == 'null'){
        return ''
      } else {
        return s
      }
    },
    getAssetInfo() {
      if(this.$route.query.type === 1) {
        this.getProfileInfo()
      }else if(this.$route.query.type === 2) {
        this.getBrandInfo()
      } else {

      }
    },
    getProfileInfo() {
      console.log("HEEE");
      this.$http.bamse.get('assets/profile/details?profile_id='+this.id)
        .then(response => this.getProfileInfoSuccess(response))
        .catch(() => this.getProfileInfoFailed())
    },
    getProfileInfoSuccess(response) {
      if(response.data.success) {
        this.name = this.checkTypeOfString(response.data.success[0].name)
        this.company = response.data.success[0].company
        this.company_name = this.checkTypeOfString(response.data.success[0].company_name)
        this.reg_number = this.checkTypeOfString(response.data.success[0].reg_number)
        this.vat_number = this.checkTypeOfString(response.data.success[0].vat_number)
        this.gender = this.checkTypeOfString(response.data.success[0].gender)
        this.ssn = this.checkTypeOfString(response.data.success[0].ssn)
        this.birthday = this.checkTypeOfString(response.data.success[0].birthday)
        this.entry_code = this.checkTypeOfString(response.data.success[0].entry_code)
        this.size_clothes = this.checkTypeOfString(response.data.success[0].size_clothes)
        this.size_shoes = this.checkTypeOfString(response.data.success[0].size_shoes)
        this.size_jeans = this.checkTypeOfString(response.data.success[0].size_jeans)
        this.bank_clearing = this.checkTypeOfString(response.data.success[0].bank_clearing)
        this.bank_account = this.checkTypeOfString(response.data.success[0].bank_account)
        this.bank_name = this.checkTypeOfString(response.data.success[0].bank_name)
        this.bank_iban = this.checkTypeOfString(response.data.success[0].bank_iban)
        this.bank_bic = this.checkTypeOfString(response.data.success[0].bank_bic)
        this.url_instagram = this.checkTypeOfString(response.data.success[0].url_instagram)
        this.url_facebook = this.checkTypeOfString(response.data.success[0].url_facebook)
        this.url_blog = this.checkTypeOfString(response.data.success[0].url_blog)
        this.url_youtube = this.checkTypeOfString(response.data.success[0].url_youtube)
        this.agency = this.checkTypeOfString(response.data.success[0].agency)
        this.tune_id = this.checkTypeOfString(response.data.success[0].tune_id)
        this.agent = this.checkTypeOfString(response.data.success[0].agent)
        this.country = this.checkTypeOfString(response.data.success[0].country)
        this.exclusive_brands = this.checkTypeOfString(response.data.success[0].exclusive_brands)
        this.denied_campaigns = this.checkTypeOfString(response.data.success[0].denied_campaigns)
      }
    },
    getProfileInfoFailed() {
      this.error = 'no fetch sorry'
    },
    updateProfileInfo() {

      this.loading = true

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('company', this.company);
      formData.append('company_name', this.company_name);
      formData.append('reg_number', this.reg_number);
      formData.append('vat_number', this.vat_number);
      formData.append('gender', this.gender);
      formData.append('ssn', this.ssn);
      formData.append('birthday', this.birthday);
      formData.append('entry_code', this.entry_code);
      formData.append('size_clothes', this.size_clothes);
      formData.append('size_shoes', this.size_shoes);
      formData.append('size_jeans', this.size_jeans);
      formData.append('bank_clearing', this.bank_clearing);
      formData.append('bank_account', this.bank_account);
      formData.append('bank_name', this.bank_name);
      formData.append('bank_iban', this.bank_iban);
      formData.append('bank_bic', this.bank_bic);
      formData.append('url_instagram', this.url_instagram);
      formData.append('url_facebook', this.url_facebook);
      formData.append('url_blog', this.url_blog);
      formData.append('url_youtube', this.url_youtube);
      formData.append('agency', this.agency);
      formData.append('agent', this.agent);
      formData.append('country', this.country);
      formData.append('exclusive_brands', this.exclusive_brands);
      formData.append('denied_campaigns', this.denied_campaigns);
      formData.append('file', this.file);
      formData.append('file2', this.file2);

      this.$http.bamse.post('assets/profile/edit?profile_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateProfileInfoSuccess(response))
        .catch(error => this.updateProfileInfoFailed(error))
    },
    updateProfileInfoSuccess(response) {
     if(response.data.success) {
       this.$global.makeToast(this, 'Success', 'Profile updated', 'success')

       setTimeout( () => this.$router.push({ path: '/list-assets?type=profiles' }), 1500);
     }
    },
    updateProfileInfoFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
      this.edit = false
    },
    getBrandInfo() {
      this.$http.bamse.get('assets/brand/details?brand_id='+this.id)
        .then(response => this.getBrandInfoSuccess(response))
        .catch(() => this.getBrandInfoFailed())
    },
    getBrandInfoSuccess(response) {
     if(response.data.success) {

        this.company_name = response.data.success[0].company_name
        this.reg_number = response.data.success[0].reg_number
        this.vat_number = response.data.success[0].vat_number
        this.address_street = response.data.success[0].address_street
        this.address_town = response.data.success[0].address_town
        this.address_zip = response.data.success[0].address_zip
        this.country = response.data.success[0].country
        this.tune_id = response.data.success[0].tune_id

     }
    },
    getBrandInfoFailed() {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    updateBrandInfo() {

      this.loading = true

      let formData = new FormData();
      formData.append('company_name', this.company_name);
      formData.append('reg_number', this.reg_number);
      formData.append('vat_number', this.vat_number);
      formData.append('address_street', this.address_street);
      formData.append('address_town', this.address_town);
      formData.append('address_zip', this.address_zip);
      formData.append('country', this.country);
      formData.append('file', this.file);
      formData.append('file2', this.file2);

      this.$http.bamse.post('assets/brand/edit?brand_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateBrandInfoSuccess(response))
        .catch(error => this.updateBrandInfoFailed(error))
    },
    updateBrandInfoSuccess(response) {
     if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Brand updated')

        setTimeout( () => this.$router.push({ path: '/list-assets?type=brands' }), 1500);

     }
    },
    updateBrandInfoFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
      this.edit = false
    },
    updateAsset() {
      if(this.$route.query.type === 1) {
        this.updateProfileInfo()
      }else if(this.$route.query.type === 2) {
        this.updateBrandInfo()
      } else {

      }
    },
    connectAdlink() {

      this.loading_tune = true

      let formData = new FormData();


      if (this.asset_type == 2) {


        formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
        formData.append('Target', 'Advertiser');
        formData.append('Method', 'create');
        formData.append('data[company]', this.company_name);
        formData.append('data[zipcode]', '11160');
        formData.append('data[city]', 'Stockholm');
        formData.append('data[address1]', 'Kammakargatan 48');
        formData.append('data[status]', 'active');
        formData.append('return_object', '1');

      } else {

        formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
        formData.append('Target', 'Affiliate');
        formData.append('Method', 'create');
        formData.append('data[company]', this.name);
        formData.append('data[zipcode]', '11160');
        formData.append('data[city]', 'Stockholm');
        formData.append('data[address1]', 'Kammakargatan 48');
        formData.append('data[status]', 'active');
        formData.append('return_object', '1');

      }

      this.$http.tune.post('', formData)
      .then(response => this.connectAdlinkSuccess(response))
        .catch(error => this.connectAdlinkFailed(error))
      
    },
    connectAdlinkSuccess(response) {

     if(response.data.response.status == 1) {

       if (this.asset_type == 2) {
        console.log(response.data.response.data.Advertiser.id)


        let formData = new FormData();

        formData.append('brand_id', this.id);
        formData.append('tune_id', response.data.response.data.Advertiser.id);

        this.ref_id = response.data.response.data.Advertiser.id;

          this.$http.bamse.post('assets/brand/connectTune', formData)
        .then(response => this.connectTuneSuccess(response))
          .catch(error => this.connectTuneFail(error))
       } else {
          console.log(response.data.response.data.Affiliate.id)

          let formData = new FormData();

          formData.append('profile_id', this.id);
          formData.append('tune_id', response.data.response.data.Affiliate.id);

          this.ref_id = response.data.response.data.Affiliate.id;

          this.$http.bamse.post('assets/profile/connectTune', formData)
        .then(response => this.connectTuneSuccess(response))
          .catch(error => this.connectTuneFail(error))
       }

     }
    },
    connectAdlinkFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    connectTuneSuccess(response) {

      this.updateRefId()

    },
    connectTuneFail() {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    updateRefId() {


      if (this.asset_type == 2) {
        let formData = new FormData();

          formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
          formData.append('Target', 'Advertiser');
          formData.append('Method', 'update');
          formData.append('id', this.ref_id);
          formData.append('data[ref_id]', this.ref_id);

            this.$http.tune.post('', formData)
            .then(response => this.updateRefIdSuccess(response))
              .catch(error => this.updateRefIdFailed(error))
       } else {
          let formData = new FormData();

          formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
          formData.append('Target', 'Affiliate');
          formData.append('Method', 'update');
          formData.append('id', this.ref_id);
          formData.append('data[ref_id]', this.ref_id);

            this.$http.tune.post('', formData)
            .then(response => this.updateRefIdSuccess(response))
              .catch(error => this.updateRefIdFailed(error))
       }

      
    },
    updateRefIdFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    updateRefIdSuccess(response) {
      this.loading_tune = false
      this.$global.makeToast(this, 'Success', 'Connected to AdLinks')
      this.tune_id = this.ref_id
    },
  }
}
</script>

