<template>
<div class="activemicrocampaigns">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Active Micro Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-row class="margin-bottom">
      <b-col cols="12" class="margin-bottom sortable">
          <b-button @click="toggleSortables('1')" id="sort-1" class="active-sort" variant="outline-primary">Show all</b-button>
          <b-button @click="toggleSortables('2')" id="sort-2" variant="outline-primary">Upload Draft</b-button>
          <b-button @click="toggleSortables('3')" id="sort-3" variant="outline-primary">Draft in review</b-button>
          <b-button @click="toggleSortables('4')" id="sort-4" variant="outline-primary">Mark as done</b-button>
          <b-button @click="toggleSortables('5')" id="sort-5" variant="outline-primary">In review</b-button>
      </b-col>
    </b-row>
    <b-row>

    <b-col xl="4" lg="4" md="6" v-for="item in orderedItems(items)" v-if="item.status !== null && item.status !== 5" v-bind:key="item.id">
        <b-modal :id="'modal_brief_'+item.id" title="View brief" hide-footer>
          <p>
            {{ item.description }}
          </p>
        </b-modal>

        <b-modal :id="'modal_upload_'+item.id" :title="'Upload draft for '+item.campaign_name" hide-footer>

          <b-form-file
            v-model="file1"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
          ></b-form-file>

          <b-form-file
            v-if="file1 != null"
            v-model="file2"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
          ></b-form-file>

          <b-form-file
            v-if="file2 != null"
            v-model="file3"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
          ></b-form-file>

          <b-form-file
            v-if="file3 != null"
            v-model="file4"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
          ></b-form-file>

          <b-form-file
            v-if="file4 != null"
            v-model="file5"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
          ></b-form-file>

          <b-button block variant="success" class="uploaddraftbtn" v-on:click="uploadDraft(item.id, item.my_profile_id, item.campaign_name)">Upload</b-button>
        </b-modal>



        <b-card
            class="card-active-micro"
            overlay
            :img-src="getCampaignImg(item.id)"
            @error="replaceByDefault"
            text-variant="white"
        >

        <b-row>
            <b-col cols="12" class="text-center">
                <h3>{{ item.campaign_name }}</h3>
                <b-img
                    class="campaign-img"
                    :src="getBrandImg(item.brand_id)"
                    fluid
                ></b-img>
                <h5>{{ item.company_name }}</h5>

                <h1 class="payout-type" v-if="item.payout_type == 'cpc'">{{ item.profile_cpc }} {{ item.currency }}</h1>
                <h1 class="payout-type" v-if="item.payout_type == 'cpa_percentage'">{{ item.profile_payout_percent }}%</h1>
            </b-col>
        </b-row>
        <b-row class="foot mt-2">

            <b-col cols="4">
              <span class="label">Draft</span>
              <span v-if="item.draft_required == 1">Yes</span>
              <span v-else>No</span>
            </b-col>
            <b-col cols="4" class="text-center">
              <span class="label_center">End date</span>
              {{ item.end_date }}
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="label_right">Commission</span>
              {{ item.revenue_profile_per_post }} SEK
            </b-col>

            <div class="clearfix"></div>

             <b-col cols="12" class="text-center mt-2 mb-2">
              <span v-if="item.channels.includes(1)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/>
              </span>

              <span v-if="item.channels.includes(2)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook'}"/>
              </span>

              <span v-if="item.channels.includes(3)" class="icon micro-font-awesome">
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'home' }"/>
              </span>

              <span v-if="item.channels.includes(4)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/>
              </span>
            </b-col>

            <div class="clearfix"></div>


           <b-col class="mt-1">
              <b-button :id="item.id" v-if="item.status == 1 && item.draft_required == 1" block variant="outline-light" @click="$bvModal.show('modal_upload_'+item.id+'')">Upload draft</b-button>
              <b-button v-if="item.status == 1 && item.draft_required == 0" :id="item.id" block variant="outline-success" @click="markAsDone(item.id, item.my_profile_id)">Mark as done</b-button>
              <b-button v-if="item.status == 3" :id="item.id" block variant="outline-success" @click="markAsDone(item.id, item.my_profile_id)">Mark as done</b-button>
              <b-button v-if="item.status == 2" :id="'thankyou-'+item.id" block variant="outline-light" disabled>Draft in review</b-button>
              <b-button v-if="item.status == 4" :id="'thankyou-'+item.id" block variant="outline-light" disabled>In review</b-button>
              <b-button :id="'thankyou-'+item.id" variant="success" block disabled class="hide">Thank you</b-button>
              <b-button :id="'viewbrief_'+item.id" class="viewbrief" block variant="outline-light" @click="$bvModal.show('modal_brief_'+item.id+'')">View brief</b-button>
           </b-col>

        </b-row>

        </b-card>
      </b-col>


    </b-row>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'

  import FileUpload from '@/components/general/FileUpload.vue'

  export default {
    name: "activemicrocampaigns",
    components: {
      FileUpload
    },
    computed: {
      ...mapState(['profile_id', 'profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
    },
    data: function () {
      return {
        items: [],
        file1: null,
        file2: null,
        file3: null,
        file4: null,
        file5: null,
        currentOrder: 'show_all',
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Active Micro Campaigns',
          href: '#',
          active: true
        },
      ],
      }
    },
    created () {
      this.listMicroCampaigns()
    },
    methods: {
      getBrandImg(brand_id){
        return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
      },
      getCampaignImg(id){
        return this.$global.spacesURL()+'campaigns/micro/campaign_' + id + '/cover_image/campaign_' + id + '.png'
      },
      toggleSortables(id){
        if(id == 1) {
          this.currentOrder = 'show_all'
          $("#sort-1").addClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
          $("#sort-5").removeClass('active-sort')
        } else if (id == 2) {
          this.currentOrder = 'status_draft'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").addClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
          $("#sort-5").removeClass('active-sort')
        } else if (id == 3) {
          this.currentOrder = 'status_draftreview'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").addClass('active-sort')
          $("#sort-4").removeClass('active-sort')
          $("#sort-5").removeClass('active-sort')
        } else if (id == 4) {
          this.currentOrder = 'status_markdone'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").addClass('active-sort')
          $("#sort-5").removeClass('active-sort')
        } else if (id == 5) {
          this.currentOrder = 'status_review'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
          $("#sort-5").addClass('active-sort')
        }
      },
      replaceByDefault(e){
        var url = "https://bamse-in-space.fra1.digitaloceanspaces.com/brands/brand_" + e.target.id + "/profile_picture/brand_" + e.target.id + ".png"

        e.target.src = url
      },
      orderedItems() {

        // Try and get the this.items arrays to sort with the chosen status first
        // like 3,1,2,4 or 4,1,2,3 the function _.orderBy is from loDash
        // https://lodash.com/

        if(this.currentOrder == "show_all"){
          return this.items
        }else if(this.currentOrder == "status_draft"){
          return _.filter(this.items, { 'status': 1 })
        }else if(this.currentOrder == "status_draftreview"){
          return _.filter(this.items, { 'status': 2 })
        }else if(this.currentOrder == "status_markdone"){
          return _.filter(this.items, { 'status': 3 })
        }else if(this.currentOrder == "status_review"){
          return _.filter(this.items, { 'status': 4 })
        }
      },
      listMicroCampaigns() {
        this.$http.bamse.post('campaigns/micro/listallactive/profile/self', {
          profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
        })
          .then(response => this.listMicroCampaignsSuccess(response))
          .catch(() => this.listMicroCampaignsFailed())
      },
      listMicroCampaignsSuccess(response) {
        if(response.data.success) {
          this.items = response.data.success
        }
      },
      markAsDone(campaign_id, profile_id) {
        this.$http.bamse.post('campaigns/micro/status/update/self', {
          campaign_id: campaign_id,
          profile_id: profile_id,
          status: 4
        })
        .then(response => this.markAsDoneSuccess(response, campaign_id))
        .catch(() => this.markAsDoneFailed())

      },
      markAsDoneSuccess(response, campaign_id) {
      if(response.data.success) {
          this.success = true
          $('#'+campaign_id).toggleClass("hide")
          $('#thankyou-'+campaign_id).toggleClass("hide")
        }
      },
      markAsDoneFailed() {
        this.error = true
      },
      uploadDraft(campaign_id, profile_id) {

        let formData = new FormData();
        formData.append('file', this.file1);
        formData.append('file2', this.file2);
        formData.append('file3', this.file3);
        formData.append('file4', this.file4);
        formData.append('file5', this.file5);
        formData.append('campaign_id', campaign_id);
        formData.append('profile_id', profile_id);
        formData.append('status', 2);

        this.$http.bamse.post( '/campaigns/micro/status/update/self',
        formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then(response => this.uploadDraftSuccess(response, campaign_id))
            .catch(() => this.uploadDraftFailed());
      },
      uploadDraftSuccess(response, campaign_id) {
      if(response.data.success) {
          this.success = true
          $('#'+campaign_id).toggleClass("hide")
          $('#thankyou-'+campaign_id).toggleClass("hide")
          var modalname = "modal_upload_"+campaign_id
          this.$bvModal.hide(modalname)
        }
      },
      uploadDraftFailed() {
        this.error = true
      },

    }
  };
</script>