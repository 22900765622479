<template>
    <b-card>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          hover
          responsive
          sortBy='id'
          :items="items" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(campaign_id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(campaign_name)="row">
            <b-img 
              class="table-img"
              fluid 
              :src="getCampaignImg(row.item.brand_id)" 
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.campaign_name }}
          </template>
          <template v-slot:cell(payout_type)="row">
            <span v-if="row.item.payout_type == 'cpc'">
              CPC
            </span>
            <span v-else>
              CPA
            </span>
          </template>
          <template v-slot:cell(id)="row">
            <router-link :to="{ name: 'manageadlinkcampaign', query: { id: row.value }}">
              <b-button size="sm" variant="outline-primary">Edit</b-button>
            </router-link>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>

<script>

export default {
  name: "activeadlinkcampaigns",
  components: {
  },
  created () {
    this.listAdlinkCampaigns()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            label: 'ID',
            key: 'campaign_id',
            sortable: true,
            thStyle: {'min-width': '15px !important'}
          },
          {
            label: 'Campaign',
            key: 'campaign_name',
            sortable: true
          },
          {
            label: 'Brand',
            key: 'brand_name',
            sortable: true
          },
          {
            label: 'Type',
            key: 'payout_type',
            sortable: true,
            thStyle: {'min-width': '25px !important'}
          },
          {
            label: 'Start date',
            key: 'start_date',
            sortable: true
          },
          {
            label: 'End date',
            key: 'end_date',
            sortable: true
          },
          {
            key: 'id',
            label: 'Edit',
            sortable: true,
            thStyle: {'min-width': '15px !important'}
          }
        ],
        items: [],
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      getCampaignImg(id){
        return this.$global.spacesURL()+'brands/brand_' + id + '/profile_picture/brand_' + id + '.png'
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      listAdlinkCampaigns() {
        this.$http.bamse.get('campaigns/adlink/listallactive')
          .then(response => this.listAdlinkCampaignsSuccess(response))
          .catch(() => this.listAdlinkCampaignsFailed())
      },
      listAdlinkCampaignsSuccess(response) {
      if(response.data.success) {
        this.items = response.data.success        
      }
      },
      listAdlinkCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
  },
};
</script>