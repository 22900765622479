<template>
<div class="campaigns">
    <b-row>
      <b-col cols="4">
        <b-card title="Cube Campaigns">
          <hr>
          <router-link :to="{ name: 'listfixedcampaigns', query: { type: 'active' }}">
            <b-button variant="outline-primary">Active</b-button>
          </router-link>
        <!--  <router-link :to="{ name: 'archivedmicrocampaigns' }">
            <b-button variant="outline-primary">Archived</b-button>
          </router-link> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    name: "profilecampaigns",
    components: {
    },
    computed: {
      ...mapState(['current_user_id', 'profile_assets']),
    },
  };
</script>