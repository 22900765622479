<template>
  <div class="list-users">

    <div v-if="list_type == 'active'">
       <b-row>
          <b-col cols="12" class="header">
              <h1>Active users</h1>
              <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
            </b-col>
        </b-row>
      <ListActiveUsers />
    </div>
    <div v-else-if="list_type == 'suspended'">
      <b-row>
          <b-col cols="12" class="header">
              <h1>Suspended users</h1>
              <b-breadcrumb :items="breadcrumbssuspended"></b-breadcrumb>
            </b-col>
        </b-row>
      
      <ListSuspendedUsers />
    </div>
    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>
  import ListActiveUsers from "@/components/cube/ListActiveUsers.vue";
  import ListSuspendedUsers from "@/components/cube/ListSuspendedUsers.vue";

export default {
  name: "activeusers",
  query: ['type'],
  components: {
    ListActiveUsers,
    ListSuspendedUsers
  },
  data() {
    return {
      list_type: this.$route.query.type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Active Users',
          href: '#',
          active: true
        }
      ],
      breadcrumbssuspended: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Suspended Users',
          href: '#',
          active: true
        }
      ],
    }
  },
};
</script>