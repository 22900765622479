<template>
    <b-card>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          hover 
          responsive
          :items="items" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
            <template v-slot:cell(profile_name)="row">
              <b-img 
                class="table-img"
                fluid 
                :src="getProfileImg(row.item.profile_id)" 
                @error="replaceByDefault"
              ></b-img>
              {{ row.item.name }}
            </template>
            <template v-slot:cell(cube_revenue)="row">
                {{ $global.convertCurrency(country, row.item.cube_revenue)}}
            </template>
            <template v-slot:cell(profile_revenue)="row">
                {{ $global.convertCurrency(country, row.item.profile_revenue)}}
            </template>
            
            <template v-slot:cell(id)="row">
              <b-button 
                :id="row.item.id" 
                size="sm" 
                variant="outline-success" 
                @click="markAsPaid(row.item.id)"
              >
                Approve transaction
              </b-button>
              <b-button 
                :id="row.item.id" 
                size="sm" 
                class="margin-top"
                variant="outline-danger" 
                @click="deleteTransaction(row.item.id)"
              >
                Reject transaction
              </b-button>
            </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import $ from 'jquery'

import { mapState } from 'vuex'
export default {
  name: "pendingtransactions",
  props:['items_from_pending'],
  components: {
  
  },
  created () {
    this.listPendingTransactions()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'profile_name',
            sortable: true
          },
          {
            key: 'cube_revenue',
            sortable: true
          },
          {
            key: 'profile_revenue',
            sortable: true
          },
          {
            key: 'activity',
            sortable: true
          },
          {
            key: 'transaction_date',
            sortable: true
          },
          {
            key: 'id',
            label: 'Manage'
          }
        ],
        items: [],
      }
    },
    mounted: function () { 
      this.$root.$on('addNewItemFromApproved', (newItem) => { // here you need to use the arrow function
       this.items.push(newItem);
      })
    },
    computed: {
      ...mapState(['country']),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
        getProfileImg(id){
          return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
        },
        replaceByDefault(e){
          var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
          e.target.src = url
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        listPendingTransactions() {
        this.$http.bamse.get('finance/transactions/pending')
            .then(response => this.listPendingTransactionsSuccess(response))
            .catch(() => this.listPendingTransactionsFailed())
        },
        listPendingTransactionsSuccess(response) {
          console.log(response.data.success)
          if(response.data.success) {
            this.items = response.data.success        
          }
        },
        listPendingTransactionsFailed() {
          this.error = 'no fetch sorry'
        },
        markAsPaid(id) {
          this.$http.bamse.post('finance/transactions/changegeneralstatus', { 
            withdrawal_id: id,
            status: 2
          })
          .then(response => this.markAsPaidSuccess(response, id, status))
          .catch(() => this.markAsPaidFailed())
        },
        markAsPaidSuccess(response, id, knapp) {
          if(response.data.success) {
            this.success = true
            console.log(id)
            
            const index = this.items.findIndex(item => item.id === id) // find the post index 
            if (~index){ // if the post exists in array
              this.$root.$emit('addNewItemFromPending', this.items[index]);
              this.items.splice(index, 1) //delete the post
            }

          }
        },
        markAsPaidFailed() {
          this.error = true
        },
        deleteTransaction(id) {
          this.$http.bamse.post('finance/transactions/deletetransaction', { 
            withdrawal_id: id,
          })
          .then(response => this.deleteTransactionSuccess(response, id, status))
          .catch(() => this.deleteTransactionFailed())
        },
        deleteTransactionSuccess(response, id, knapp) {
          if(response.data.success) {
            this.success = true
            console.log(id)
            
            const index = this.items.findIndex(item => item.id === id) // find the post index 
            if (~index){ // if the post exists in array
              this.items.splice(index, 1) //delete the post
            }

          }
        },
        deleteTransactionFailed() {
          this.error = true
        },
    },
};
</script>
