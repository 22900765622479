<template>
  <div class="dashboard">
	 <b-row>
      <b-col cols="6" offset="3">
        <b-card title="Your Brands">
          <b-list-group flush>
            <b-list-group-item v-for="item in brand_assets" v-bind:key="item.id">
              <b-row>
                <b-col cols="2">
                  <div class="profile-pic-div">
                    <b-img
                      fluid
                      class="profile_pic_multiple"
                      :src="getProfileImg(item.brand_id)"
                      @error="replaceByDefault"
                    ></b-img>
                  </div>
                </b-col>
                <b-col cols="8" class="dashboard-padding">
                  <p>{{ item.company_name }}</p>
                </b-col>
                <b-col cols="2">
                    <b-button @click="setBrandID(item.brand_id)" size="sm" variant="outline-primary">Use</b-button>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'

export default {
  name: "branddashboardmultiple",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'profile_id', 'brand_id']),
  },
  data: function () {
    return {
    }
  },
  methods: {
    ...mapMutations({
      'setBrand': 'SETBRAND'
    }),
    getProfileImg(id){
      return this.$global.spacesURL()+'brands/brand_' + id + '/profile_picture/brand_' + id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    setBrandID(id) {
      this.setBrand(id)
      this.$router.push({ path: '/' })
    }
  }
};
</script>
