<template>
<div class="conversionreport">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Conversion Report</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-card>
    <div class="all-users">
      <b-row v-if="showdates">
        <b-col lg="2" md="6">
          <b-form-group label-size="sm" label="Start date" label-for="start_date">
            <datepicker 
              wrapper-class="form-control" 
              :monday-first="true"
              format="yyyy-MM-dd" 
              v-model="start_date" 
              name="start_date"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="6">
          <b-form-group label-size="sm" label="End date" label-for="end_date">
            <datepicker 
              wrapper-class="form-control" 
              :monday-first="true"
              format="yyyy-MM-dd"  
              v-model="end_date" 
              name="end_date"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="12">
          <b-button @click="getStats()" class="pull-left active-sort getdatesbtn" variant="outline-primary">Submit</b-button>
          <b-button @click="showdates = false" class="pull-left active-sort getdatesbtn" variant="outline-primary">Cancel</b-button>
        </b-col>
        <b-col xl="5" lg="12" class="datefilter">
            <b-button @click="pickDates(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 90 days</b-button>
        
            <b-button @click="pickDates(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 30 days</b-button>

          <b-button @click="pickDates(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 7 days</b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col lg="3" class="margin-bottom-sort">
          <span class="label">Current dates</span>
          <b-button @click="showdates = true" v-if="loading == false" class="active-sort datebtn" variant="outline-primary">{{ start_date}} - {{ end_date }}</b-button>
          <b-button class="active-sort datebtn" variant="outline-primary" v-else>
          <b-spinner small ></b-spinner> Loading
        </b-button>  
        </b-col>
      </b-row>
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table 
            striped
            hover
            responsive
            :items="items" 
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(order_id)="row">
                {{ row.item.order_id.substring(0,17) }}
            </template>
            <template v-slot:cell(profile)="row">
                {{ row.item.profile }}
            </template>
            <template v-slot:cell(status)="row">
                {{ row.item.status }}
            </template>
            <template v-slot:cell(sale_amount)="row">
                {{ row.item.sale_amount }} SEK
            </template>
            <template v-slot:cell(net_sale_amount)="row">
                {{ row.item.net_sale_amount }} SEK
            </template>
            <template v-slot:cell(revenue)="row">
                {{ row.item.revenue }} SEK
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" v-if="noDataFound">
          <center><p>No data found.</p></center>
        </b-col>
      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';

export default {
  name: "conversionreport",
  components: {
    Datepicker
  },
  created () {
    this.setDate()
    this.getStats()
  },
  data() {
    return {
      breadcrumbs: [
      {
        text: 'Reports',
        to: '/reports'
      },
      {
        text: 'Revenue Status Report',
        active: true
      },
      ],
      showdates: false,
      loading: false,
      tune_id: this.$store.state.brand_assets[0].tune_id,
      error: false,
      success: false,
      users: null,
      filter: null,
      noDataFound: false,
      start_date: '',
      end_date: '',
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'datetime',
          label: 'Conversion Time',
          sortable: true
        },
        {
          key: 'order_id',
          label: 'Order ID',
          sortable: true
        },
        {
          key: 'profile',
          label: 'Profile',
          sortable: true
        },
        {
          key: 'status',
          label: 'Order status',
          sortable: true
        },
        {
          key: 'sale_amount',
          label: 'Sale Amount',
          sortable: true
        },
        {
          key: 'net_sale_amount',
          label: 'Net Sale Amount',
          sortable: true
        },
        {
          key: 'revenue',
          label: 'Revenue',
          sortable: true
        }
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setDate(){
      this.end_date = moment().format('YYYY-MM-DD')
      this.start_date = moment().subtract(120, 'days').format('YYYY-MM-DD');
    },
    pickDates(id){
      if(id == 1){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.getStats()
      } else if(id == 2){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.getStats()
      } else if(id == 3){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(90, 'days').format('YYYY-MM-DD');
        this.getStats()

      } 
    },
    getStats() {
      this.items = []
      this.loading = true
      this.showdates = false
      let formData = new FormData();
      
      this.start_date = moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      
      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Report');
      formData.append('Method', 'getConversions');
      
      formData.append('fields[]', 'Stat.status');
      formData.append('fields[]', 'Stat.datetime');
      formData.append('fields[]', 'Stat.revenue');
      formData.append('fields[]', 'Stat.sale_amount');
      formData.append('fields[]', 'Stat.net_sale_amount');
      formData.append('fields[]', 'Stat.advertiser_info');
      formData.append('fields[]', 'Affiliate.company');
      formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.status][values][]', 'rejected');
      formData.append('filters[Stat.status][values][]', 'pending');
      formData.append('filters[Stat.status][values][]', 'approved');
      formData.append('limit', '0');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);
      formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
      formData.append('filters[Advertiser.ref_id][values]', this.tune_id);
      formData.append('sort[Stat.datetime]', 'desc');

      this.$http.tune.post('', formData)
      .then(response => this.getStatsSuccess(response))
        .catch(error => this.getStatsFailed(error))
    },
    getStatsSuccess(response) {
      if(response.data.response.data.count == null) {
        this.noDataFound = true
      } else {
        this.noDataFound = false
      }
      if(response.data.response.status == 1) {
        
        var old_ref_id = 0;
        
        response.data.response.data.data.forEach((item) => {
          this.items.push({
            order_id: item.Stat.advertiser_info,
            datetime: item.Stat.datetime,
            profile: item.Affiliate.company,
            status: item.Stat.status,
            sale_amount: Number(item.Stat.sale_amount).toFixed(2),
            net_sale_amount: Number(item.Stat.net_sale_amount).toFixed(2),
            revenue: Number(item.Stat.revenue).toFixed(2)
          })

        })
        
        this.loading = false
      } else {
        this.$global.makeToast(this, 'Oh oh!', response.data.response.errorMessage, 'danger')
      }
    },
    getStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    }
  },
};
</script>
