<template>
  <div>
    <b-row>
    <b-col cols="12" class="header">
      <h1>Manage {{camp_name}}</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-tabs active content-class="mt-3">
      <b-tab title="Active profiles">
        <ListProfilesForMicroCampaign />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Edit campaign" >
        <div class="manage-campaign">
          <ManageMicroCampaign />
        </div>
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Connected profiles">
        <ConnectedProfiles />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Other profiles">
        <UnConnectedProfiles />
      </b-tab>
    </b-tabs>
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import ManageMicroCampaign from "@/components/campaigns/micro/ManageMicroCampaign.vue";
import ListProfilesForMicroCampaign from "@/components/campaigns/micro/ListProfilesForMicroCampaign.vue";
import ConnectedProfiles from "@/components/campaigns/micro/ConnectedProfiles.vue";
import UnConnectedProfiles from "@/components/campaigns/micro/UnConnectedProfiles.vue";
export default {
  name: "managecampaign",
  components: {
    ManageMicroCampaign,
    ListProfilesForMicroCampaign,
    ConnectedProfiles,
    UnConnectedProfiles
  },
  computed: {
    ...mapState(['level', 'type', 'current_user_id']),
  },
  created () {
    this.getCampaignDetails()
  },
  data: function () {
    return {
      id: this.$route.query.id,
      camp_name: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Micro Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Manage',
          href: '#',
          active: true
        }
      ]
    }
  },
  methods: {
    getCampaignDetails() {
      this.$http.bamse.get('campaigns/micro/details?campaign_id='+this.id)
      .then(response => this.getCampaignDetailsSuccess(response))
      .catch(() => this.getCampaignDetailsFailed())
    },
    getCampaignDetailsSuccess(response) {
      if(response.data.success) {
        this.camp_name = response.data.success[0].campaign_name
      }
    },
    getCampaignDetailsFailed() {

    }
  },
};
</script>
