<template>
<div class="shortlinkreport">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Shortlink Report</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-card>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(short_prefix)="row">
              {{ getTrackerLink() }}{{ row.item.short_prefix }}
            </template>
            <template v-slot:cell(full_url)="row">
                <a :href="row.item.full_url" target="_blank">
                  Link
                  <font-awesome-icon
                    :icon="{ prefix: 'fal', iconName: 'link' }"
                  />
                </a>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" v-if="noDataFound">
          <center><p>No data found.</p></center>
        </b-col>
      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: "shortlinkreport",
  components: {
    Datepicker
  },
  computed: {
    ...mapState(['current_id', 'profile_assets', 'brand_assets', 'type', 'profile_id']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  created () {
    this.getShortlinkReport()
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Reports',
          to: '/reports'
        },
        {
          text: 'Shortlink Report',
          active: true
        }
      ],
      loading: false,
      error: false,
      success: false,
      users: null,
      filter: null,
      noDataFound: false,
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'short_prefix',
          label: 'Shortlink',
          sortable: true
        },
        {
          key: 'full_url',
          label: 'Redirect URL',
          sortable: true
        },
        {
          key: 'clicks',
          label: 'Clicks',
          sortable: true
        },
        {
          key: 'profile',
          label: 'Profile',
          sortable: true
        },
        {
          key: 'brand',
          label: 'Brand',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true
        }
      ],
      items: [],
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getShortlinkReport() {
      this.loading = true

      this.$http.bamse.post('campaigns/adlink/shortlink/report', {
        profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
      })
      .then(response => this.getShortlinkReportSuccess(response))
        .catch(error => this.getShortlinkReportFailed(error))
    },
    getShortlinkReportSuccess(response) {

      this.noDataFound = false

      if(response.data.success) {

        this.items = response.data.success
        this.loading = false

      }
    },
    getShortlinkReportFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    getTrackerLink() {
      let trackerURL = null;
      if(process.env.VUE_APP_MODE == 'Local') {
        trackerURL = process.env.VUE_APP_TRACKER_URL_DEV
      } else {
        trackerURL = process.env.VUE_APP_TRACKER_URL
      }

      return trackerURL
    }
  },
};
</script>
