<template>
  <div class="profile-dashboard brand-dashboard">
	<b-row>
        <b-card
          class="header-card"
          overlay
          :img-src="getCoverImg(brand_id)"
           @error="replaceByDefault"
           text-variant="white"
          >
          
          <div class="pull-left profile-pic-div">
            <b-img 
              fluid
              class="profile_pic"
              :src="getBrandImg(brand_id)" 
              @error="replaceByDefault"
            ></b-img>
          </div>
          <div class="name">
            <h4>{{ brand_assets[this.$global.getBrandInfo(brand_id, this.brand_assets)].company_name }}</h4>
          </div>
            
        </b-card>
        <div class="infobar">
          <b-row class="infobar-row">
          </b-row>
        </div>
        </b-row>
      	<b-row class="profile-dashboard-row">
          <b-col cols="5">
            
            <b-card class="calendar">
              <div>
                <h4 class="header-h">Calendar</h4>
                <span class="header-icon">
                  <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'calendar-alt' }"/>
                </span>
              </div>
              <div class="clearfix"></div>
              <hr>
              <v-calendar 
                :attributes='attrs'
                is-expanded 
                is-inline
                :key="refresh"
                popoverExpanded=true
                color="gray"
                title-position="right"
                :min-date='new Date()'
                locale="en"
                :first-day-of-week="2"
              >
                <div slot="day-popover" slot-scope="{ day, format, masks, dayTitle, attributes }">
                    <div v-for="value in getTitleForDate(format(day.date, 'YYYY-MM-DD'))">
                    <span v-if="value.type === 'Draft'" class="draft"></span>
                    <span v-if="value.type === 'Publish'" class="publish"></span>
                    <span v-if="value.type === 'Event' || value.type === 'Production'" class="event"></span>
                    <router-link :to="{ name: 'managefixedcampaign', query: { id: value.campaign_id }}">
                      {{ value.title }}
                    </router-link>
                  </div>
                </div>
              </v-calendar>
              <router-link :to="{ name: 'calendar' }">
                <b-button block variant="outline-primary">Go to calendar</b-button>
              </router-link>
            </b-card>
          </b-col>
          <b-col cols="7">
            <b-card v-if="showAdlinks === true">
              <div>
                <span class="second-h">last 7 days</span>
                <h4 class="header-h">Adlinks</h4>
                
                <span class="header-icon">
                  <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'mouse-pointer' }"/>
                </span>
              </div>
              <div class="clearfix"></div>
              <hr>
              <b-row>
                <b-col cols="3">
                  <span class="label">Clicks</span>
                  <h2>{{ clicks }}</h2>
                </b-col>
                <b-col cols="3">
                  <span class="label">Unique</span>
                  <h2>{{ clicks }}</h2>
                </b-col>
                <b-col cols="3">
                  <span class="label">Conversions</span>
                  <h2>{{ conversions }}</h2>
                </b-col>
                <b-col cols="3">
                  <span class="label">Money</span>
                  <h2>{{ profit }} {{ currency }}</h2>
                </b-col>
              </b-row>
              <hr>
              <canvas id="adlinkstats"></canvas>
              <hr>
              <router-link :to="{ name: 'reports' }">
                <b-button block variant="outline-primary">Go to reports</b-button>
              </router-link>
            </b-card>
          </b-col>
      
    </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Chart from 'chart.js';
import moment from 'moment'

export default {
  name: "dashboard",
  components: {
      Chart
  },
  computed: {
    ...mapState(['brand_id', 'profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
  },
  data: function () {
    return {
      showAdlinks: true,
      calendaritems: [],
      refresh: false,
      clicks: '',
      conversions: '',
      profit: '',
      unique: '',
      currency: '',
      chartData: null,
      run_this: false,
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      revenueChartData: {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          legend: {
            position: 'bottom'
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: false,
                labelString: 'Date',
              },
            }],
            yAxes: [{
              scaleLabel: {
                display: false,
                labelString: 'Amount',
              },
              ticks: {
                beginAtZero: true,
                precision:0,
                display: false,
              },
            }]
          }
        }
      },
      attrs: [
      {
        key: 'today',
        highlight: true,
        dates: [],
        customData: {
          names: []
        },
        popover: { 
          label: true,
          isInteractive: true,
        }
      }
    ],
    fields: [
        {
          key: 'campaign_name',
        },
        {
          key: 'status_text',
          label: 'Status'
        }
      ],
      items: [],
    fields_available: [
        {
          key: 'campaign_name',
        },
        {
          key: 'company_name',
          label: 'Brand'
        }
      ],
      items_available: []
    }
  },
  mounted() {
  },
  created () {
    this.listFixedCampaigns()
    this.getAdlinkStats()
  },
  methods: {
    getCoverImg(id){
      return this.$global.spacesURL()+'brands/brand_' + id + '/cover_image/brand_' + id + '.png'
    },
    getBrandImg(id){
      return this.$global.spacesURL()+'brands/brand_' + id + '/profile_picture/brand_' + id + '.png'
    },
    createChart(chartId, chartData) {
        const ctx = document.getElementById(chartId);
        const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
        });
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    listFixedCampaigns() {
      this.$http.bamse.post('campaigns/fixed/brand/listalldates?brand_id='+this.brand_id)
        .then(response => this.listFixedCampaignsSuccess(response))
        .catch(() => this.listFixedCampaignsFailed())
    },
    listFixedCampaignsSuccess(response) {
      if(response.data.success) {

        //Events
        response.data.success.events.forEach((item, index) => {

          this.attrs[0].customData.names.push({
              date: item.end_date, 
              title: item.campaign_name+': '+item.title+' ('+item.type+')',
              campaign_id: item.campaign_id,
              type: item.type
          });

          this.attrs[0].dates.push(item.end_date);

        })

        //Profile activities
        response.data.success.profile_activities.forEach((item, index) => {
          console.log(item)
          this.attrs[0].customData.names.push({
              date: item.draft_date, 
              title: item.campaign_name+': '+item.activity+' (Draft)',
              campaign_id: item.campaign_id,
              type: 'Draft'
          });

          this.attrs[0].customData.names.push({
              date: item.publish_date, 
              title: item.campaign_name+': '+item.activity+' (Publish)',
              campaign_id: item.campaign_id,
              type: 'Publish'
          });

          this.attrs[0].dates.push(item.draft_date);
          this.attrs[0].dates.push(item.publish_date);

        })
        
        this.refresh = true
        
        /* response.data.success.forEach((item, index) => {
          
          this.attrs[0].customData.names.push({
              date: item.draft_date, 
              title: item.campaign_name+': '+item.activity+' (Draft)',
              campaign_id: item.campaign_id
          });

          this.attrs[0].customData.names.push({
              date: item.publish_date, 
              title: item.campaign_name+': '+item.activity+' (Publish)',
              campaign_id: item.campaign_id
          });

          this.attrs[0].dates.push(item.draft_date);
          this.attrs[0].dates.push(item.publish_date);

        })

        this.refresh = true

        response.data.success.forEach((item, index) => {
          this.items_available.push(item)
          this.items.push(item)
        }) */

      }
    
    },
    listFixedCampaignsFailed() {
      this.error = 'no fetch sorry'
    },
    getTitleForDate(datum) {
      var title_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          title_array.push({
            title: item.title, 
            campaign_id: item.campaign_id,
            type: item.type
          })
        }
      })

      return title_array
    },
    getCampaingIdForDate(datum) {
      var campaignID_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          campaignID_array.push(item.campaign_id)
        }
      })

      return campaignID_array
    },
    getAdlinkStats() {

      let formData = new FormData();

      let date_format = moment().format('YYYY-MM-DD')

      let date_end = moment().subtract(7, 'days');
      let date_format_end = moment(date_end).format('YYYY-MM-DD')

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Report');
      formData.append('Method', 'getStats');
      formData.append('fields[]', 'Stat.unique_clicks');
      formData.append('fields[]', 'Stat.conversions');
      formData.append('fields[]', 'Stat.clicks');
      formData.append('fields[]', 'Stat.payout');
      formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
      formData.append('filters[Advertiser.ref_id][values]', this.brand_assets[this.$global.getProfileInfo(this.brand_id, this.brand_assets)].tune_id);
      formData.append('data_start', date_format_end);
      formData.append('data_end', date_format)
      formData.append('sort[Stat.date]=', 'ASC');
      formData.append('totals', '1');

      this.$http.tune.post('', formData)
      .then(response => this.getAdlinkStatsSuccess(response))
        .catch(error => this.getAdlinkStatsFailed(error))
    },
    getAdlinkStatsSuccess(response) {

      if(response.data.response.data.count != null) {

        this.clicks = response.data.response.data.totals.Stat.clicks
        this.unique = response.data.response.data.totals.Stat.unique_clicks
        this.conversions = response.data.response.data.totals.Stat.conversions
        this.profit = response.data.response.data.totals.Stat.payout
        this.profit = this.profit.split('.')[0]

        var responseArray = response.data.response.data.data
        var dates = []
        var clicks = []
        var unique_clicks = []
        var conversions = []

        responseArray.forEach(function (item) {
            dates.push(item.Stat.date)
            clicks.push(item.Stat.clicks)
            unique_clicks.push(item.Stat.unique_clicks)
            conversions.push(item.Stat.conversions)
        });

        this.revenueChartData.data.labels = dates
        
        this.revenueChartData.data.datasets.push({
            label: 'Clicks',
            data: clicks,
            fill: false,
            hidden: false,
            borderColor: [
                '#DF744A',
            ],
            backgroundColor: 'rgba(223, 116, 74, 0.3)',
            borderWidth: 1
        })

        this.revenueChartData.data.datasets.push({
            label: 'Unique clicks',
            data: unique_clicks,
            fill: false,
            hidden: false,
            borderColor: [
                '#DCB239',
            ],
            backgroundColor: 'rgba(220, 178, 57, 0.3)',
            borderWidth: 1
        })
        
        this.revenueChartData.data.datasets.push({
              label: 'Conversions',
              data: conversions,
              fill: false,
              hidden: false,
              borderColor: [
                  '#DF744A',
              ],
              backgroundColor: 'rgba(223, 116, 74, 0.3)',
              borderWidth: 1
        })
        
        this.createChart('adlinkstats', this.revenueChartData);
        this.showAdlinks = true
      } else {
        this.showAdlinks = false
      }
    },
    getAdlinkStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    compareValues(key, order='asc') {
        return function(a, b) {
            if(!a.hasOwnProperty(key) || 
            !b.hasOwnProperty(key)) {
            return 0; 
            }
            
            const varA = (typeof a[key] === 'string') ? 
            a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ? 
            b[key].toUpperCase() : b[key];
            
            let comparison = 0;
            if (varA > varB) {
            comparison = 1;
            } else if (varA < varB) {
            comparison = -1;
            }
            return (
            (order == 'desc') ? 
            (comparison * -1) : comparison
            );
        };
    }
  }
};
</script>