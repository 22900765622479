<template>
  <div class="settings">
    <b-row>
      <b-col cols="12" class="header">
        <h1>My settings</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
      <b-card header="Social settings">
        <b-row>
          <b-col cols="6">
            <v-facebook-login
              app-id="511378276181679"
              @sdk-init="handleSdkInit"
              @connect="getToken"
              :login-options="this.facebook.loginOptions"
            >
            </v-facebook-login>

            <b-button v-if="instagram == false" variant="outline-success" >Connect to Instagram</b-button>
              <b-button v-else variant="outline-success" disabled>You're connected to Instagram</b-button>
          </b-col>
          <b-col cols="6">
            <b-button v-if="youtube == false" variant="outline-success">Connect to Youtube</b-button>
            <b-button v-else variant="outline-success" disabled="">You're connected to Youtube</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card header="User settings">
        <b-form @submit.prevent="updateUserInfo()">
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="First name" label-for="firstname">
                <b-input :disabled="edit === false" name="firstname" v-model="name"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Last name" label-for="lastname">
                <b-input :disabled="edit === false" name="lastname" v-model="last_name" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="E-mail" label-for="email">
                <b-input :disabled="edit === false" name="email" v-model="email" ></b-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-size="sm" label="Phone" label-for="phone">
                <b-input :disabled="edit === false" name="phone" v-model="phone" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Address" label-for="street">
                <b-input :disabled="edit === false" v-model="address_street" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="City" label-for="town">
                <b-input :disabled="edit === false" name="town" v-model="address_town" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Zip Code" label-for="zip">
                <b-input :disabled="edit === false" name="zip" v-model="address_zip" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Country" label-for="country">
                <b-form-select :disabled="edit === false" name="country" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
         <!-- <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="User image" label-for="imageupload">
                <b-form-file
                  :disabled="edit === false"
                  v-model="file"
                  placeholder="Choose a picture..."
                  drop-placeholder="Drop picture here..."
                  accept=".jpg, .png"
                  name="imageupload"
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>-->
          <b-button v-if="edit == true && loading == true" disabled variant="outline-success" class="pull-right"><b-spinner small ></b-spinner> Loading</b-button>
          <b-button v-if="edit == true && loading == false" type="submit" variant="outline-success" class="pull-right">Save</b-button>
        </b-form>
        <b-button v-if="edit == true && loading == false" @click="this.doEdit" class="pull-right cancel-btn" variant="outline-danger">Cancel</b-button>
        <b-button v-if="edit == false && loading == false" @click="this.doEdit" class="pull-right" variant="outline-primary">Edit</b-button>
      </b-card>

      <b-card header="Profile settings">
        <b-form @submit.prevent="updateProfileInfo()">
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Name" label-for="asset_name">
                <b-input :disabled="editasset === false" name="asset_name" v-model="asset_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Type" label-for="company">
                <b-form-select :disabled="editasset === false" name="company" v-model="company" :options="type_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="company == 1">
              <b-form-group label-size="sm" label="Company Name" label-for="company_name">
                <b-input :disabled="editasset === false" name="company_name" v-model="company_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="company == 1">
              <b-form-group label-size="sm" label="Registration Number" label-for="reg_number">
                <b-input :disabled="editasset === false" name="reg_number" v-model="reg_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="company == 1">
              <b-form-group label-size="sm" label="VAT Number" label-for="vat_number">
                <b-input :disabled="editasset === false" name="vat_number" v-model="vat_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Gender" label-for="gender">
                 <b-form-select :disabled="editasset === false" name="gender" v-model="gender" :options="gender_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Social Security Number" label-for="ssn">
                <b-input :disabled="editasset === false" name="ssn" v-model="ssn" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Birthday" label-for="birthday">
                <datepicker :disabled="editasset === false" v-model="birthday" name="birthday"
                  :bootstrap-styling=true
                  :calendar-button=true
                  :monday-first=true
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Entry Code" label-for="entry_code">
                <b-input :disabled="editasset === false" name="entry_code" v-model="entry_code" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Size Clothes" label-for="size_clothes">
                <b-input :disabled="editasset === false" name="size_clothes" v-model="size_clothes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Size Shoes" label-for="size_shoes">
                <b-input :disabled="editasset === false" name="size_shoes" v-model="size_shoes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Size Jeans" label-for="size_jeans">
                <b-input :disabled="editasset === false" name="size_jeans" v-model="size_jeans" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Bank Clearing" label-for="bank_clearing">
                <b-input :disabled="editasset === false" name="bank_clearing" v-model="bank_clearing" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Bank Account" label-for="bank_account">
                <b-input :disabled="editasset === false" name="bank_account" v-model="bank_account" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Bank Name" label-for="bank_name">
                <b-input :disabled="editasset === false" name="bank_name" v-model="bank_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Bank IBAN" label-for="bank_iban">
                <b-input :disabled="editasset === false" name="bank_iban" v-model="bank_iban" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Bank BIC" label-for="bank_bic">
                <b-input :disabled="editasset === false" name="bank_bic" v-model="bank_bic" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Instagram" label-for="url_instagram">
                <b-input :disabled="editasset === false" name="url_instagram" v-model="url_instagram" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Facebook" label-for="url_facebook">
                <b-input :disabled="editasset === false" name="url_facebook" v-model="url_facebook" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Blog" label-for="url_blog">
                <b-input :disabled="editasset === false" name="url_blog" v-model="url_blog" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Youtube" label-for="url_youtube">
                <b-input :disabled="editasset === false" name="url_youtube" v-model="url_youtube" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Country" label-for="country_profile">
                <b-form-select :disabled="editasset === false" name="country_profile" v-model="country_profile" :options="country_profile_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button v-if="editasset == true && loadingasset == true" disabled variant="outline-success" class="pull-right"><b-spinner small ></b-spinner> Loading</b-button>
          <b-button v-if="editasset == true && loadingasset == false" type="submit" variant="outline-success" class="pull-right">Save</b-button>
        </b-form>
        <b-button v-if="editasset == true && loadingasset == false" @click="this.doEditAsset" class="pull-right cancel-btn" variant="outline-danger">Cancel</b-button>
        <b-button v-if="editasset === false" @click="this.doEditAsset" class="pull-right" variant="outline-primary">Edit</b-button>
      </b-card>

      <b-card header="Account settings">
        <b-row>
          <b-col md="6">
            <b-button v-if="reset == false" @click="resetPassword()" variant="outline-primary">
              Reset my password
            </b-button>
            <b-button v-else variant="outline-success" disabled>
              Check your inbox
            </b-button>
          </b-col>
          <b-col md="6">
             If you want to remove your account send an email to admin@revrelations.com
          </b-col>
        </b-row>
      </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex'
import Alerts from "@/components/general/Alerts.vue";
import ListProfiles from "@/components/cube/ListProfiles.vue";
import ListBrands from "@/components/cube/ListBrands.vue";
import Datepicker from 'vuejs-datepicker';
import VFacebookLogin from 'vue-facebook-login-component'

export default {
  name: "manageuser",
  components: {
    Alerts,
    ListProfiles,
    ListBrands,
    Datepicker,
    VFacebookLogin
  },
  data: function () {
    return {
      facebook: {
        FB: {},
        scope: {},
        loginOptions: { scope: 'instagram_basic,instagram_manage_insights,pages_show_list'}
      },
      access_token: null,
      long_lived_token: null,
      page_id: null,
      instagram_id: null,
      reset: false,
      instagram: false,
      youtube: false,
      edit: false,
      editasset: false,
      loading: false,
      loadingasset: false,
      error: false,
      success: false,
      name: null,
      last_name: null,
      email: null,
      user_type: null,
      user_type_name: null,
      phone: null,
      address_street: null,
      address_town: null,
      address_zip: null,
      country: null,
      status: null,
      country_selected: null,
      country_options: [],
      country_profile: null,
      country_profile_selected: null,
      country_profile_options: [],
      asset_name: null,
      company: 0,
      company_name: null,
      reg_number: null,
      vat_number: null,
      gender: null,
      ssn: null,
      birthday: '31 July 1980',
      entry_code: null,
      size_clothes: null,
      size_shoes: null,
      size_jeans: null,
      bank_clearing: null,
      bank_account: null,
      bank_name: null,
      bank_iban: null,
      bank_bic: null,
      url_instagram: null,
      url_facebook: null,
      url_blog: null,
      url_youtube: null,
      agency: null,
      agent: null,
      address_street: null,
      address_town: null,
      address_zip: null,
      country: null,
      file: null,
      exclusive_brands: null,
      denied_campaigns: null,
      main_type_options: [
        { value: 1, text: 'Profile' },
        { value: 2, text: 'Brand' },
      ],
      type_options: [
        { value: 1, text: 'Company' },
        { value: 2, text: 'Private' },
      ],
      gender_options: [
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
        { value: 'Other', text: 'Other' },
      ],
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'User',
          href: '#',
        },
        {
          text: 'My settings',
          href: '#',
          active: true
        }
      ],
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser', type: 'type' }),
    ...mapState(['ext_brand_assets', 'ext_profile_assets', 'profile_assets', 'profile_id']),
  },
  created () {
    this.getUserInfo()
    this.addCountryList()
  },
  methods: {
    handleSdkInit({ FB, scope }) {
      this.facebook.scope = scope
      this.facebook.FB = FB
    },
    getToken(){

      const out = this

      this.facebook.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          out.access_token = response.authResponse.accessToken
        }
      })
      if(this.access_token != 'null') {
        this.$http.fb.get('oauth/access_token?grant_type=fb_exchange_token&client_id=511378276181679&client_secret=4416db1f5c1a25f0f64f331549823436&fb_exchange_token='+this.access_token)
        .then(response => this.getTokenSuccess(response))
        .catch(() => this.getTokenFailed())
      }
    },
    getTokenSuccess(response){
      this.long_lived_token = response.data.access_token
      console.log("LONG LIVE THE TOKEN:"+this.long_lived_token)

      this.$http.fb.get('me/accounts?access_token='+this.long_lived_token)
        .then(response => this.getPagesSuccess(response))
        .catch(() => this.getPagesFailed())

    },
    getTokenFailed(){
      console.log("fail")
    },
    getPagesSuccess(response){
      this.page_id = response.data.data[0].id
      this.$http.fb.get(this.page_id+'?fields=instagram_business_account&access_token='+this.long_lived_token)
        .then(response => this.getInstagramSuccess(response))
        .catch(() => this.getInstagramFailed())

    },
    getPagesFailed(){
      console.log("fail")
    },
    getInstagramSuccess(response){
      this.instagram_id = response.data.instagram_business_account.id
      this.$http.fb.get(this.instagram_id+'/media?fields=caption&access_token='+this.long_lived_token)
        .then(response => this.getCaptionsSuccess(response))
        .catch(() => this.getCaptionsFailed())
    },
    getInstagramFailed(){
      console.log("fail")
    },
    getCaptionsSuccess(response){
      console.log(response)
    },
    getCaptionsFailed(){
      console.log("fail")
    },
    addCountryList() {
      this.$global.getCountryList().forEach(item => {
        this.country_options.push(item)
        this.country_profile_options.push(item)
      })
    },
    checkTypeOfString(s) {
      if(s == '' || s == null || s == 'null'){
        return ''
      } else {
        return s
      }
    },
    getUserInfo() {
      this.$http.bamse.get('user/details/self')
        .then(response => this.getUserInfoSuccess(response))
        .catch(() => this.getUserInfoFailed())
    },
    getUserInfoSuccess(response) {
     if(response.data.success) {
       this.name = this.checkTypeOfString(response.data.success[0].name)
       this.last_name = this.checkTypeOfString(response.data.success[0].last_name)
       this.email = this.checkTypeOfString(response.data.success[0].email)
       this.user_type = this.checkTypeOfString(response.data.success[0].user_type)
       this.user_type_name = this.checkTypeOfString(response.data.success[0].user_type_name)
       this.phone = this.checkTypeOfString(response.data.success[0].phone)
       this.address_street = this.checkTypeOfString(response.data.success[0].address_street)
       this.address_town = this.checkTypeOfString(response.data.success[0].address_town)
       this.address_zip = this.checkTypeOfString(response.data.success[0].address_zip)
       this.country = this.checkTypeOfString(response.data.success[0].country)
       this.status = this.checkTypeOfString(response.data.success[0].status)
       this.user_level = this.checkTypeOfString(response.data.success[0].user_level)
       this.$store.state.ext_profile_assets = this.checkTypeOfString(response.data.success.profile_assets)
       this.$store.state.ext_brand_assets = this.checkTypeOfString(response.data.success.brand_assets)

       this.getProfileInfo()
     }
    },
    getUserInfoFailed() {
      this.error = 'no fetch sorry'
    },
    updateUserInfo() {

      this.loading = true

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('last_name', this.last_name);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('user_type', this.user_type);
      formData.append('address_street', this.address_street);
      formData.append('address_town', this.address_town);
      formData.append('address_zip', this.address_zip);
      formData.append('country', this.country);
      formData.append('user_level', this.user_level);
      formData.append('file', this.file);

      this.$http.bamse.post('user/edit/self', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateUserInfoSuccess(response))
        .catch(() => this.updateUserInfoFailed())
    },
    updateUserInfoSuccess(response) {
      if(response.data.success) {
        this.edit = false
        this.loading = false
        localStorage.name = this.name+' '+this.last_name
        this.$global.makeToast(this, 'Success', 'User updated', 'success')
      }
    },
    updateUserInfoFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Something went wrong!', 'danger')
      this.loading = false
    },
    getProfileInfo() {
      this.$http.bamse.get('assets/profile/details?profile_id='+this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id)
        .then(response => this.getProfileInfoSuccess(response))
        .catch(() => this.getProfileInfoFailed())
    },
    getProfileInfoSuccess(response) {
     if(response.data.success) {

        this.asset_name = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].name
        this.company = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].company
        this.country_profile = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].country
        this.company_name = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].company_name
        this.reg_number = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].reg_number
        this.vat_number = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].vat_number
        this.gender = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].gender
        this.ssn = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].ssn
        this.birthday = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].birthday
        this.entry_code = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].entry_code
        this.size_clothes = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].size_clothes
        this.size_shoes = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].size_shoes
        this.size_jeans = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].size_jeans
        this.bank_clearing = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].bank_clearing
        this.bank_account = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].bank_account
        this.bank_name = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].bank_name
        this.bank_iban = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].bank_iban
        this.bank_bic = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].bank_bic
        this.url_instagram = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].url_instagram
        this.url_facebook = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].url_facebook
        this.url_blog = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].url_blog
        this.url_youtube = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].url_youtube
        this.agency = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].agency
        this.agent = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].agent
        this.exclusive_brands = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].exclusive_brands
        this.denied_campaigns = response.data.success[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].denied_campaigns
     }
    },
    getProfileInfoFailed() {
      this.error = 'no fetch sorry'
    },
    updateProfileInfo() {

      this.loadingasset = true
      this.$http.bamse.post('assets/profile/edit?profile_id='+this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id, {
        name: this.asset_name,
        company: this.company,
        country: this.country_profile,
        company_name: this.company_name,
        reg_number: this.reg_number,
        vat_number: this.vat_number,
        gender: this.gender,
        ssn: this.ssn,
        birthday: this.birthday,
        entry_code: this.entry_code,
        size_clothes: this.size_clothes,
        size_shoes: this.size_shoes,
        size_jeans: this.size_jeans,
        bank_clearing: this.bank_clearing,
        bank_account: this.bank_account,
        bank_name: this.bank_name,
        bank_iban: this.bank_iban,
        bank_bic: this.bank_bic,
        url_instagram: this.url_instagram,
        url_facebook: this.url_facebook,
        url_blog: this.url_blog,
        url_youtube: this.url_youtube,
        agency: this.agency,
        agent: this.agent,
        exclusive_brands: this.exclusive_brands,
        denied_campaigns: this.denied_campaigns,
      })
        .then(response => this.updateProfileInfoSuccess(response))
        .catch(() => this.updateProfileInfoFailed())
    },
    updateProfileInfoSuccess(response) {
     if(response.data.success) {
      this.editasset = false
      this.loadingasset = false
      this.$global.makeToast(this, 'Success', 'Asset updated', 'success')
     }
    },
    updateProfileInfoFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Something went wrong!', 'danger')
      this.loadingasset = false
    },
    doEdit() {
      this.edit = !this.edit
    },
    doEditAsset() {
      this.editasset = !this.editasset
    },
    resetPassword() {
      this.$http.bamse.post('password/create', { email: this.email })
        .then(response => this.resetPasswordSuccessful(response))
        .catch(response => this.resetPasswordFailed(response))
    },
    resetPasswordSuccessful(response) {
      if (response.data) {
        this.$global.makeToast(this, 'Check your inbox', response.data.message)
        this.reset = true
      }
    },
    resetPasswordFailed(response) {
      this.$global.makeToast(this, 'Oh oh!', 'Couldnt find that email address in our system. Maybe you use another one?', 'danger')
      this.loading = false
    },
  }

};

</script>