<template>
  <div class="campaign-info fixed-campaign">
    <b-card
        overlay
        :img-src="getCampaignImg(this.id)"
        img-alt="Card Image"
        text-variant="white"
    >
      <b-row>
        <b-col cols="8" class="description margin-top">
          <h1>{{campaign_name}}</h1>
        </b-col>
        <b-col cols="4" class="fixed-campaign-overview">
          <img :src="getBrandImg(this.brand_id)" class="profile_pic">
        </b-col>
        <b-col cols="4" class="fixed-campaign-overview-details">
          <span class="label">Start</span>
          {{ this.start_date }}
        </b-col>
        <b-col cols="4" class="fixed-campaign-overview-details">
          <span class="label">End</span>
          {{ this.end_date }}
        </b-col>
        <b-col cols="4" class="fixed-campaign-overview-details">
          <span class="label">Cost</span>
          {{ this.total_cost }} SEK
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="reject-brief" hide-footer>
      <template v-slot:modal-title>
        Reject brief
      </template>
      <h6>Reason for rejection</h6>
      <b-form-textarea
        id="comment"
        v-model="comment"
        rows="6"
        max-rows="22"
      ></b-form-textarea>
      <b-button class="margin-top" block variant="outline-primary" @click="rejectBrief(profile_id, comment)">
        Reject brief
      </b-button>
    </b-modal>
    <b-row v-if="briefs.length > 0" class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>Briefs to approve</h5>
        <b-table
          class="margin-top" 
          striped
          hover
          :items="briefs" 
          :fields="fields_briefs"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(name)="row">
            <b-img 
              class="table-img"
              fluid 
              :src="getProfileImg(row.item.id)" 
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.name }}
          </template>
          <template v-slot:cell(brief)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} brief
            </b-button>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="12" class="pdf margin-top">
                <object :data="getBrandBrief(id, row.item.id)" type="application/pdf" width="100%" height="100%" hspace="0">
                  This browser does not support PDFs.
                </object>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(action)="row">
            <b-button size="sm" variant="outline-primary" @click="approveBrief(row.item.id)">
              Approve brief
            </b-button>
            <b-button class="margin-left" size="sm" variant="outline-primary" @click="rejectBriefModal(row.item.id)">
              Reject brief
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h5>Profile activities</h5>
        <b-table 
          striped
          :items="activities" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(draft_date)="row">
            <span v-if="row.item.draft_date != null">
              {{row.item.draft_date}}
            </span>
            <span v-else>
              No draft required
            </span>
          </template>
          <template v-slot:cell(status)="row">
            <span v-if="row.item.approved_by_brand == 0">
              Waiting for brand
            </span>
            <span v-else-if="row.item.approved_by_brand == 2">
              Waiting for new brief
            </span>
            <span v-else-if="row.item.approved_by_profile == 0">
              Waiting for profile
            </span>
            <span v-else-if="row.item.approved_by_profile == 2">
              Waiting for profile
            </span>
            <span v-else>
              <span v-if="row.item.status == 0">
                Waiting for draft
              </span>
              <span v-else-if="row.item.status == 1">
                Waiting for draft
              </span>
              <span v-else-if="row.item.status == 2">
                <b-button @click="row.toggleDetails" size="sm" variant="outline-primary">
                  {{ row.detailsShowing ? 'Hide' : 'Show' }} drafts
                </b-button>
              </span>
              <span v-else-if="row.item.status == 3">
                Waiting for delivery
              </span>
              <span v-else-if="row.item.status == 4">
                Delivery approved
              </span>
              <span v-else-if="row.item.status == 5">
                Waiting for draft
              </span>
              <span v-else-if="row.item.status == 6">
                Rejected by brand
              </span>
              <span v-else-if="row.item.status == 7">
                Delivered
              </span>
            </span>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="6" class="margin-top">
                <h5>Caption</h5>
                <hr>
                {{row.item.caption}}
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5>URL</h5>
                <hr>
                {{row.item.url}}
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5 class="margin-top">Attachments</h5>
                <hr class="margin-top">
                <span v-for="(item, index) in drafts" v-if="item.post_id == row.item.id" class="block">
                  <a :href="$global.spacesURL()+item.url" target="_blank">
                    <b-button class="draft-button margin-top" size="sm" variant="outline-primary">
                      <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'paperclip' }"/> {{ getAttachmentName(item.url) }}
                    </b-button>
                  </a>
                </span>
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5 class="margin-top">Comment</h5>
                <hr class="margin-top">
                   <b-form-textarea
                    id="textarea"
                    v-model="row.item.brand_comment"
                    placeholder="Add a comment about the draft"
                    rows="6"
                  ></b-form-textarea>
                </span>
              </b-col>
            </b-row>
            <b-row class="draft">
                <b-col cols="6">
                  <hr>
                  <b-button class="margin-top" variant="outline-success" block @click="approveDraft(row.item.id)">
                    Approve draft
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <hr>
                  <b-button class="margin-top" variant="outline-danger" block @click="rejectDraft(row.item.id, row.item.brand_comment)">
                    Reject draft
                  </b-button>
                </b-col>
            </b-row>
          </template>
          <template v-slot:cell(name)="row">
            <b-img 
              class="table-img"
              fluid 
              :src="getProfileImg(row.item.profile_id)" 
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.name }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>General activities</h5>
        <b-table
          class="margin-top" 
          striped
          hover
          :items="generalactivities" 
          :fields="fields_general"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(description)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} description
            </b-button>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="12" class="margin-top">
                {{ row.item.description }}
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(status)="row">
            <span v-if="row.item.status == 0">
              In progress
            </span>
            <span v-if="row.item.status == 1">
              Delivered
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

import $ from 'jquery'

export default {
  name: "InformationFixedCampaign",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: '',
      description: '',
      start_date: '',
      end_date: '',
      brand_id: '',
      campaign_id: '',
      profile_id: '',
      approved: '',
      total_cost: 0,
      total_cost_count: 0,
      general_cost_count: 0,
      modalShow: false,
      activities: null,
      filter: null,
      drafts: [],
      comment: null,
      generalactivities: [],
      briefs: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'activity',
          label: 'Activity',
          sortable: true
        },
        {
          key: 'draft_date',
          label: 'Draft Date',
          sortable: true
        },
        {
          key: 'publish_date',
          label: 'Publish Date',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        /*{
          key: 'description',
          Label: 'Description'
        },*/
      ],
      fields_briefs: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'brief',
          label: 'Brief',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true
        },
        /*{
          key: 'description',
          Label: 'Description'
        },*/
      ],
      fields_general: [
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'title',
          label: 'Title',
          sortable: true
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'end_date',
          label: 'End Date',
          sortable: true
        },
        {
          key: 'description',
          Label: 'Description'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
      ],
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets'])
  },
  created () {
    this.getCampaign()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCampaignImg(id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/cover_image/campaign_' + id + '.png'
    },
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    getBrandBrief(id, profile_id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/briefs/brand_' + profile_id + '.pdf'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {

        this.campaign_name = response.data.success[0][0].campaign_name
        this.start_date = response.data.success[0][0].start_date
        this.end_date = response.data.success[0][0].end_date
        this.brand_id = response.data.success[0][0].brand_id
        this.campaign_id = response.data.success[0][0].campaign_id

        //Activities in [1]
        this.activities = response.data.success[1]
        this.activities = _.uniqBy(this.activities, 'id');
        

        this.activities.forEach(activity => {
          if(this.total_cost_count == 0) {
            this.total_cost = this.total_cost + activity.revenue
          }
          
          if(activity.approved_by_brand == 0) {
            this.briefs.push({name: activity.name, id: activity.profile_id})
          }
          if(activity.status == 2){
            this.getDrafts(activity.profile_id, activity.id)
          }
        });

        this.briefs = _.uniqBy(this.briefs, 'id');
        this.getGeneralActivities()
        this.total_cost_count = 1
      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    getGeneralActivities() {
        this.$http.bamse.get('campaigns/fixed/listallgeneralactivities?campaign_id='+this.id)
        .then(response => this.getGeneralActivitiesSuccess(response))
        .catch(() => this.getGeneralActivitiesFailed())
    },
    getGeneralActivitiesSuccess(response) {
      if(response.data.success) {

        this.generalactivities = response.data.success

        response.data.success.forEach(activity => {
    
          if(this.general_cost_count == 0) {
            this.total_cost = this.total_cost + activity.revenue
            
          }

          if(activity.status == 2){
            this.getDrafts(activity.profile_id, activity.id)
          }
          
        });
        this.general_cost_count = 1
      }
    },
    getGeneralActivitiesFailed() {
      this.error = 'no fetch sorry'
    },
    approveBrief(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approvebriefbrand?campaign_id='+this.id+'&profile_id='+profile_id)
      .then(response => this.approveBriefSuccess(response, profile_id))
      .catch(() => this.approveBriefFailed())
    },
    approveBriefSuccess(response, profile_id) {
      if(response.data.success) {
        var index = _.findIndex(this.briefs, { 'id': profile_id})
        this.briefs.splice(index, 1)
        this.getCampaign()
      }
    },
    approveBriefFailed() {
      this.error = 'no fetch sorry'
    },
    rejectBriefModal(profile_id){
      this.profile_id = profile_id
      this.comment = ''
      this.$bvModal.show('reject-brief')
    },
    rejectBrief(profile_id, comment) {
      this.$http.bamse.post('campaigns/fixed/rejectbriefbrand?campaign_id='+this.id+'&profile_id='+profile_id+'&comment='+comment)
      .then(response => this.rejectBriefSuccess(response, profile_id))
      .catch(() => this.rejectBriefFailed())
    },
    rejectBriefSuccess(response, profile_id) {
      if(response.data.success) {
        var index = _.findIndex(this.briefs, { 'id': profile_id})
        this.briefs.splice(index, 1)
        this.$bvModal.hide('reject-brief')
        this.getCampaign()
      }
    },
    rejectBriefFailed() {
      this.error = 'no fetch sorry'
    },
    getDrafts(profile_id, post_id) {
        this.$http.bamse.get('campaigns/fixed/getdraft?campaign_id='+this.id+'&profile_id='+profile_id+'&post_id='+post_id)
        .then(response => this.getDraftsSuccess(response, post_id))
        .catch(() => this.getDraftsFailed())
    },
    getDraftsSuccess(response, post_id) {
      if(response.data.success) {
        response.data.success.forEach((item) => {
          var url = item.replace("dev/", "")
          var url = url.replace("live/", "")
          this.drafts.push({post_id: post_id, url: url})
        })
        
      }
    },
    approveDraft(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approvedraft?post_id='+profile_id)
      .then(response => this.approveDraftSuccess(response))
      .catch(() => this.approveDraftFailed())
    },
    approveDraftSuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    approveDraftFailed() {
      this.error = 'no fetch sorry'
    },
    rejectDraft(post_id, comment) {
      this.$http.bamse.post('campaigns/fixed/rejectdraft?post_id='+post_id+'&comment='+comment)
      .then(response => this.rejectDraftSuccess(response))
      .catch(() => this.rejectDraftFailed())
    },
    rejectDraftSuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    rejectDraftFailed() {
      this.error = 'no fetch sorry'
    },
    getAttachmentName(attachment) {
     return attachment.substring(attachment.lastIndexOf("/") + 1);
    },
  },
};
</script>