<template>
  <div class="list-microcampaigns">
    <div v-if="list_type == 'active'">
    <b-row>
      <b-col cols="12" class="header">
      <h1>Active adlink campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
   </b-row>
      <ListActiveAdlinkCampaigns />
    </div>
    <div v-else-if="list_type == 'archived'">
      <b-row>
      <b-col cols="12" class="header">
      <h1>Archived adlink campaigns</h1>
      <b-breadcrumb :items="breadcrumbsarchived"></b-breadcrumb>
    </b-col>
   </b-row>
      <ListInactiveAdlinkCampaigns />
    </div>
    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>
  import ListActiveAdlinkCampaigns from "@/components/campaigns/adlink/ListActiveAdlinkCampaigns.vue";
  import ListInactiveAdlinkCampaigns from "@/components/campaigns/adlink/ListInactiveAdlinkCampaigns.vue";

export default {
  name: "listadlinkcampaigns",
  query: ['type'],
  components: {
    ListActiveAdlinkCampaigns,
    ListInactiveAdlinkCampaigns
  },
  data() {
    return {
      list_type: this.$route.query.type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Adlink Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Active Adlink Campaigns',
          href: '#',
          active: true
        },
      ],
      breadcrumbsarchived: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Adlink Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Archived Adlink Campaigns',
          href: '#',
          active: true
        },
      ]
    }
  },
};
</script>