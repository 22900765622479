<template>
  <div class="userbank">
	<b-row>
      <b-col cols="12" class="header">
        <h1>Withdrawal history koko</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-table 
          striped
          responsive
          :items="items" 
          :fields="fields"
          sortBy='id'
          :sort-desc='sortDesc'
          >
          <template v-slot:cell(amount)="row">
            {{ $global.convertCurrency(country, row.item.amount)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button size="sm" target="_blank"
            :href="getInvoicePdf(row.item.url, row.item.profile_id)" variant="outline-primary">Invoice</b-button>
          </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: "dashboard",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type',
    'current_id', 'country']),
  },
  created () {
    this.listWithdrawals()
  },
  data: function () {
    return {
      pending_in: '',
      pending_out: '',
      sortDesc: true,
      balance: '',
      profile_withdraw_id: '',
      withdraw_amount: 5,
      fields: [
        {
          key: 'withdrawal_requested',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'amount',
          sortable: true
        },
        {
          key: 'id',
          label: 'Manage'
        }
      ],
      items: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances'
        },
        {
          text: 'Withdrawal History',
          active: true
        }
      ],
    }
  },
  methods: {
    getInvoicePdf(url, id){
      return this.$global.spacesURL()+'profiles/profile_'+id+'/invoices/'+url
    },
    listWithdrawals() {
        this.$http.bamse.get('user/withdrawals')
          .then(response => this.listWithdrawalsSuccess(response))
          .catch(() => this.listWithdrawalsFailed())
    },
    listWithdrawalsSuccess(response) {
    if(response.data.success) {
      console.log(response.data.success)

      this.items = response.data.success[0]
      
    }
    },
    listWithdrawalsFailed() {
      this.error = 'no fetch sorry'
    },
  }
};
</script>
