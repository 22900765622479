<template>
	<div>
        <b-row>
      <b-col cols="12" class="header">
        <h1>Your sold campaigns</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-card>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          hover
          responsive
          sortBy='id'
          sortDesc=false
          :items="items" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
        <span slot="campaign_budget" slot-scope="row">
          {{row.item.campaign_budget}} SEK
       	</span>
        </b-table>
      </b-row>
    </div>
  </b-card>
</div>
</template>

<script>

import Alerts from "@/components/general/Alerts.vue";

export default {
  name: "activemicrocampaigns",
  components: {
  },
  created () {
    this.listMicroCampaigns()
  },
  data() {
      return {
        error: false,
        success: false,
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Micro Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Your campaigns',
          href: '#',
          active: true
        },
      ],
        users: null,
        filter: null,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'campaign_name',
            sortable: true
          },
          {
            key: 'campaign_budget',
            sortable: true
          }
        ],
        items: []
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      listMicroCampaigns() {
        this.$http.bamse.get('user/campaigns/salesperson')
          .then(response => this.listMicroCampaignsSuccess(response))
          .catch(() => this.listMicroCampaignsFailed())
      },
      listMicroCampaignsSuccess(response) {
      if(response.data.success) {
        console.log(response.data.success)

        this.items = response.data.success        
      }
      },
      listMicroCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
  },
};
</script>