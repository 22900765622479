<template>
  <div class="campaign-info fixed-campaign">
    <b-card
      overlay
      :img-src="getCampaignImg(this.id)"
      img-alt="Card Image"
      text-variant="white"
    >
      <b-row>
        <b-col md="8" class="description margin-top">
          <h1>{{campaign_name}}</h1>
          {{ this.description }}
        </b-col>
        <b-col md="4" class="fixed-campaign-overview">
          <img :src="getBrandImg(this.brand_id)" class="profile_pic">
        </b-col>
        <b-col md="3" sm="6" class="fixed-campaign-overview-details">
          <span class="label">Start</span>
          {{ this.start_date }}
        </b-col>
        <b-col md="3" sm="6" class="fixed-campaign-overview-details">
          <span class="label">End</span>
          {{ this.end_date }}
        </b-col>
        <b-col md="3" sm="6" class="fixed-campaign-overview-details">
          <span class="label">Revenue</span>
          {{ $global.convertCurrency(country, this.total_revenue)}}
        </b-col>
        <b-col md="3" sm="6" class="fixed-campaign-overview-details">
          <span class="label">Profit</span>
          {{ $global.convertCurrency(country, this.profit)}}
        </b-col>
        <b-col cols="6">
          <hr>
          <b-button size="sm" class="pull-left margin-top" variant="outline-light" disabled>Campaign ID: {{id}}</b-button>
        </b-col>
        <b-col cols="6">
          <hr>
          <b-button size="sm" v-if="status==0" class="pull-right margin-top" variant="outline-light" @click="closeCampaign(id)">Close campaign</b-button>
          <b-button size="sm" v-else class="pull-right margin-top" variant="outline-light" disabled>Campaign closed</b-button>
        </b-col>
      </b-row>
    </b-card>
<b-modal id="review-brief" hide-footer>
      <template v-slot:modal-title>
        Reason for rejection
      </template>
      <span v-if="show_brand_brief_comment == true">
        {{brand_brief_comment}}
      </span>
      <span v-else-if="show_profile_brief_comment == true">
        {{profile_brief_comment}}
      </span>
      <hr>
      <div v-if="sendnewbrief == false">
        <span v-if="show_brand_brief_comment == true">
          <b-button class="margin-top" block variant="outline-primary" @click="updateBrief(profile_id, 'brand', null)">
            Send back brief as is
          </b-button>
        </span>
        <span v-else-if="show_profile_brief_comment == true">
          <b-button class="margin-top" block variant="outline-primary" @click="updateBrief(profile_id, 'profile', null)">
            Send back brief as is
          </b-button>
        </span>
        <b-button class="margin-top" block variant="outline-primary" @click="sendnewbrief = true">
          Submit new brief
        </b-button>
        <hr>
      </div>
      <div v-if="sendnewbrief">
        <b-form-file
          v-model="new_brief"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>
        <span v-if="show_brand_brief_comment == true">
          <b-button class="margin-top" block variant="outline-primary" @click="updateBrief(profile_id, 'brand', true)">
            Submit new brief
          </b-button>
        </span>
        <span v-else-if="show_profile_brief_comment == true">
          <b-button class="margin-top" block variant="outline-primary" @click="updateBrief(profile_id, 'profile', true)">
            Submit new brief
          </b-button>
        </span>
      </div>
    </b-modal>
    <b-row v-if="briefs.length > 0" class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>Briefs to review</h5>
        <b-table
          class="margin-top"
          striped
          hover
          responsive
          :items="briefs"
          :fields="fields_briefs"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.name }}
          </template>
          <template v-slot:cell(brief)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} brief
            </b-button>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="12" class="pdf margin-top">
                <object :data="getBrandBrief(id, row.item.id)" type="application/pdf" width="100%" height="100%" hspace="0">
                  This browser does not support PDFs.
                </object>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(action)="row">
            <span v-if="row.item.type == 'brand'">
              Brand rejected brief
            </span>
            <span v-else-if="row.item.type == 'profile'">
              Profile rejected brief
            </span>
            <b-button class="table-button" size="sm" variant="outline-primary" @click="reviewBriefModal(row.item.id, row.item.brand_brief_comment, row.item.profile_brief_comment, row.item.type)">
              Review brief
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>Profile activities</h5>
        <b-table
          striped
          responsive
          :items="activities"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(draft_date)="row">
            <span v-if="row.item.draft_date != null">
              {{row.item.draft_date}}
            </span>
            <span v-else>
              No draft required
            </span>
          </template>
          <template v-slot:cell(status)="row">
            <span v-if="row.item.approved_by_brand == 0">
              Waiting for brand
            </span>
            <span v-else-if="row.item.approved_by_brand == 2">
              Rejected by brand
            </span>
            <span v-else-if="row.item.approved_by_profile == 0">
              Waiting for profile
            </span>
            <span v-else-if="row.item.approved_by_profile == 2">
              Rejected by profile
            </span>
            <span v-else>
              <span v-if="row.item.status == 0">
                Waiting for draft
              </span>
              <span v-else-if="row.item.status == 1">
                <b-button @click="row.toggleDetails()" size="sm" variant="outline-primary">
                  {{ row.detailsShowing ? 'Hide' : 'Show'}} drafts
                </b-button>
              </span>
              <span v-else-if="row.item.status == 2">
                Waiting for brand
              </span>
              <span v-else-if="row.item.status == 3">
                 <b-button size="sm" variant="outline-primary" @click="approveDelivery(row.item.id)">
                    Approve delivery
                  </b-button>
              </span>
              <span v-else-if="row.item.status == 4">
                Delivered
                 <b-button class="table-button" size="sm" variant="outline-primary" @click="payActivity(row.item.id)">
                    Pay
                  </b-button>
              </span>
              <span v-else-if="row.item.status == 7">
                Delivered and paid
              </span>
              <span v-else-if="row.item.status == 5">
                Draft rejected by Cube
              </span>
              <span v-else-if="row.item.status == 6">
                <b-button @click="row.toggleDetails()" size="sm" variant="outline-primary">
                  Review draft
                </b-button>
              </span>
            </span>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="6" class="margin-top">
                <h5>Caption</h5>
                <hr>
                {{row.item.caption}}
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5>URL</h5>
                <hr>
                <a :href="row.item.url" target="_blank">{{row.item.url}}</a>
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5 class="margin-top">Attachments</h5>
                <hr class="margin-top">
                <span v-for="(item, index) in drafts" v-if="item.post_id == row.item.id" class="block">
                  <a :href="$global.spacesURL()+item.url" target="_blank">
                    <b-button class="draft-button margin-top" size="sm" variant="outline-primary">
                      <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'paperclip' }"/> {{ getAttachmentName(item.url) }}
                    </b-button>
                  </a>
                  <b-button class="draft-button margin-top" size="sm" variant="outline-danger" @click="deleteAttachment(item.url)">
                    Delete
                  </b-button>
                </span>
              </b-col>
              <b-col cols="6" class="margin-top">
                <div v-if="row.item.brand_comment != null">
                  <h5 class="margin-top">Brand comment</h5>
                  <hr class="margin-top">
                  {{ row.item.brand_comment }}
                  <hr class="margin-top">
                </div>
                <div v-if="row.item.status == 5 || row.item.status == 1  || row.item.status == 6">
                  <h5 class="margin-top">Comment</h5>
                  <hr class="margin-top">
                    <b-form-textarea
                      id="textarea"
                      v-model="row.item.comment"
                      placeholder="Add a comment about the draft"
                      rows="6"
                    ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <b-row class="draft">
                <b-col cols="6">
                  <hr>
                  <b-button class="margin-top" variant="outline-success" block @click="approveDraft(row.item.id)">
                    Approve draft and send to brand
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <hr>
                  <b-button class="margin-top" variant="outline-danger" block @click="rejectDraft(row.item.id, row.item.comment)">
                    Reject draft
                  </b-button>
                </b-col>
            </b-row>
          </template>
          <template v-slot:cell(name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.name }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="margin-top">
      <b-col cols="12" class="margin-top">
        <h5>General activities</h5>
        <b-table
          class="margin-top"
          striped
          responsive
          :items="generalactivities"
          :fields="fields_general"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(description)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} description
            </b-button>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="12" class="margin-top">
                {{ row.item.description }}
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(status)="row">
            <span v-if="row.item.status == 0">
              <b-button size="sm" variant="outline-primary" @click="approveActivity(row.item.id)">
                Approve delivery
              </b-button>
            </span>
            <span v-if="row.item.status == 1">
              Delivered
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

import $ from 'jquery'

export default {
  name: "InformationFixedCampaign",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: '',
      description: '',
      start_date: '',
      end_date: '',
      brand_id: '',
      profile_id: '',
      new_brief: null,
      brand_brief_comment: null,
      sendnewbrief: false,
      show_profile_brief_comment: false,
      show_brand_brief_comment: false,
      drafts: [],
      briefs: [],
      status: 0,
      total_revenue: 0,
      total_cost: 0,
      total_cost_count: 0,
      general_cost_count: 0,
      profit: 0,
      activities: null,
      filter: null,
      generalactivities: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'activity',
          label: 'Activity',
          sortable: true
        },
        {
          key: 'draft_date',
          label: 'Draft Date',
          sortable: true
        },
        {
          key: 'publish_date',
          label: 'Publish Date',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
      ],
      fields_briefs: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'brief',
          label: 'Brief',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true
        },
        /*{
          key: 'description',
          Label: 'Description'
        },*/
      ],
      fields_general: [
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'title',
          label: 'Title',
          sortable: true
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'end_date',
          label: 'End Date',
          sortable: true
        },
        {
          key: 'description',
          Label: 'Description'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
      ],
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets', 'country'])
  },
  created () {
    this.getCampaign()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCampaignImg(id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/cover_image/campaign_' + id + '.png'
    },
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    closeCampaign(campaign_id){
      this.$http.bamse.post('campaigns/fixed/closecampaign?campaign_id='+campaign_id)
        .then(response => this.closeCampaignSuccess(response))
        .catch(() => this.closeCampaignFailed())
    },
    closeCampaignSuccess(response) {
      if(response.data.success) {
        this.status = 1
        this.$global.makeToast(this, 'Yay!', 'Campaign closed!')
        this.getCampaign()
      }
    },
    closeCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    payActivity(post_id){
      this.$http.bamse.post('campaigns/fixed/payactivity?post_id='+post_id)
        .then(response => this.payActivitySuccess(response))
        .catch(() => this.payActivityFailed())
    },
    payActivitySuccess(response) {
      if(response.data.success) {

        this.getCampaign()
      }
    },
    payActivityFailed() {
      this.error = 'no fetch sorry'
    },
    reviewBriefModal(profile_id, brand_comment, profile_comment, type){
      this.profile_id = profile_id
      this.brand_brief_comment = brand_comment
      this.profile_brief_comment = profile_comment
      this.sendnewbrief = false
      if(type == "brand"){
        this.show_brand_brief_comment = true
      }else if(type == "profile"){
        this.show_profile_brief_comment = true
      }
      this.$bvModal.show('review-brief')
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {

        this.campaign_name = response.data.success[0][0].campaign_name
        this.description = response.data.success[0][0].description
        this.start_date = response.data.success[0][0].start_date
        this.end_date = response.data.success[0][0].end_date
        this.brand_id = response.data.success[0][0].brand_id
        this.status = response.data.success[0][0].status

        //Activities in [1]
        this.activities = response.data.success[1]
        this.activities = _.uniqBy(this.activities, 'id');

        this.activities.forEach(activity => {
          if(this.total_cost_count == 0) {
            this.total_revenue = this.total_revenue + activity.revenue
            this.total_cost = this.total_cost + activity.cost
          }
          if(activity.status == 1 || activity.status == 6){
            this.getDrafts(activity.profile_id, activity.id)
          }
          if(activity.approved_by_brand == 2) {
            this.briefs.push({name: activity.name, id: activity.profile_id, brand_brief_comment: activity.brand_brief_comment, profile_brief_comment: activity.profile_brief_comment, type: 'brand'})
          } else if(activity.approved_by_profile == 2) {
            this.briefs.push({name: activity.name, id: activity.profile_id, brand_brief_comment: activity.brand_brief_comment, profile_brief_comment: activity.profile_brief_comment, type: 'profile'})
          }
        });

        this.briefs = _.uniqBy(this.briefs, 'id');
        this.total_cost_count = 1
        this.getGeneralActivities()
      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    getBrandBrief(id, profile_id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/briefs/brand_' + profile_id + '.pdf'
    },
    getGeneralActivities() {
        this.$http.bamse.get('campaigns/fixed/listallgeneralactivities?campaign_id='+this.id)
        .then(response => this.getGeneralActivitiesSuccess(response))
        .catch(() => this.getGeneralActivitiesFailed())
    },
    getGeneralActivitiesSuccess(response) {
      if(response.data.success) {
        this.generalactivities = response.data.success
        response.data.success.forEach(activity => {

          if(this.general_cost_count == 1){
            this.total_revenue = this.total_revenue + activity.revenue
            this.total_cost = this.total_cost + activity.cost
          }

        });
        this.profit = this.total_revenue - this.total_cost
        this.general_cost_count = 1
      }
    },
    getGeneralActivitiesFailed() {
      this.error = 'no fetch sorry'
    },
    approveDelivery(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approvedelivery?post_id='+profile_id)
      .then(response => this.approveDeliverySuccess(response))
      .catch(() => this.approveDeliveryFailed())
    },
    approveDeliverySuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    approveDeliveryFailed() {
      this.error = 'no fetch sorry'
    },
    approveActivity(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approveactivity?post_id='+profile_id)
      .then(response => this.approveActivitySuccess(response))
      .catch(() => this.approveActivityFailed())
    },
    approveActivitySuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    approveActivityFailed() {
      this.error = 'no fetch sorry'
    },
    approveBrief(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approvebrief?campaign_id='+this.id+'&profile_id='+profile_id)
      .then(response => this.approveBriefSuccess(response, profile_id))
      .catch(() => this.approveBriefFailed())
    },
    approveBriefSuccess(response, profile_id) {
      if(response.data.success) {
        this.$bvModal.hide('review-brief')
        var index = _.findIndex(this.briefs, { 'id': profile_id})
        this.briefs.splice(index, 1)
        this.show_profile_brief_comment = false
        this.show_brand_brief_comment = false
        this.getCampaign()
      }
    },
    approveBriefFailed() {
      this.error = 'no fetch sorry'
    },
    updateBrief(profile_id, type, newbrief) {
      let formData = new FormData();
      formData.append('campaign_id', this.id)
      formData.append('profile_id', this.profile_id)
      formData.append('type', type);
      if(type == "brand" && newbrief != null){
        formData.append('brand_brief', this.new_brief)
      } else if(type == "profile" && newbrief != null) {
        formData.append('profile_brief', this.new_brief)
      }
      this.$http.bamse.post('campaigns/fixed/updatebrief', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateBriefSuccess(response, this.profile_id))
      .catch(error => this.updateBriefFailed(error))
    },
    updateBriefSuccess(response, profile_id) {
      if(response.data.success) {
        this.$bvModal.hide('review-brief')
        var index = _.findIndex(this.briefs, { 'id': profile_id})
        this.briefs.splice(index, 1)
        this.show_profile_brief_comment = false
        this.show_brand_brief_comment = false
        this.getCampaign()
      }
    },
    updateBriefFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    getDrafts(profile_id, post_id) {
        this.$http.bamse.get('campaigns/fixed/getdraft?campaign_id='+this.id+'&profile_id='+profile_id+'&post_id='+post_id)
        .then(response => this.getDraftsSuccess(response, post_id))
        .catch(() => this.getDraftsFailed())
    },
    getDraftsSuccess(response, post_id) {
      if(response.data.success) {
        response.data.success.forEach((item) => {
          var url = item.replace("dev/", "")
          var url = url.replace("live/", "")
          this.drafts.push({post_id: post_id, url: url})
        })
      }
    },
    getDraftsFailed() {
      this.error = 'no fetch sorry'
    },
    getAttachmentName(attachment) {
     return attachment.substring(attachment.lastIndexOf("/") + 1);
    },
    deleteAttachment(attachment){
      this.$http.bamse.post('campaigns/fixed/deleteattachment?attachment='+attachment)
      .then(response => this.deleteAttachmentSuccess(response, attachment))
      .catch(() => this.deleteAttachmentFailed())
    },
    deleteAttachmentSuccess(response, attachment) {
      if(response.data.success) {
        var index = _.findIndex(this.drafts, { 'url': attachment})
        this.drafts.splice(index, 1)
      }
    },
    deleteAttachmentFailed() {
      this.error = 'no fetch sorry'
    },
    approveDraft(profile_id) {
      this.$http.bamse.post('campaigns/fixed/approvedraft?post_id='+profile_id)
      .then(response => this.approveDraftSuccess(response))
      .catch(() => this.approveDraftFailed())
    },
    approveDraftSuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    approveDraftFailed() {
      this.error = 'no fetch sorry'
    },
    rejectDraft(profile_id, comment) {
      this.$http.bamse.post('campaigns/fixed/rejectdraft?post_id='+profile_id+'&comment='+comment)
      .then(response => this.rejectDraftSuccess(response))
      .catch(() => this.rejectDraftFailed())
    },
    rejectDraftSuccess(response, profile_id) {
      if(response.data.success) {
        this.getCampaign()
      }
    },
    rejectDraftFailed() {
      this.error = 'no fetch sorry'
    },
  },
};
</script>
