<template>
  <div class="home">
    <b-row>
      <b-col cols="12" class="background" v-bind:style="{ 'background-image': 'url(' + selectedImage + ')' }">
        <b-col cols="4" class="box">
          <h1>RevRelations</h1>
          <b-col cols="12" class="loginform" v-if="resetpass === false && apply === false">
            <b-form @submit.prevent="login()">
              <b-input
                id="email"
                placeholder="Email"
                name="email"
                v-model="email"
                required
                ></b-input>
              <b-input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                v-model="password"
                required
                ></b-input>
              <b-row>
                  <b-button type="submit" variant="primary">Login</b-button>
                  <p @click="toggleResetPass()" class="forgottext">
                    Forgot password?
                  </p>
              </b-row>
            </b-form>
          </b-col>
          <b-col cols="12" class="resetform" v-if="resetpass === true && apply === false">
            <b-form @submit.prevent="resetPassword()">
              <b-input
                id="email"
                placeholder="Email"
                name="email"
                v-model="email"
              ></b-input>
              <b-row>
                <b-button disabled variant="primary" v-if="loading == true">
                  <b-spinner small ></b-spinner>
                </b-button>
                <b-button v-if="loading == false" type="submit" variant="primary">Reset</b-button>
                <p @click="toggleResetPass()" class="forgottext">Sign in</p>
              </b-row>
            </b-form>
          </b-col>
          <b-col cols="12" class="applyform" v-if="resetpass === false && apply === true">            
            <b-form-select 
              v-model="iAm" 
              :options="options">
            </b-form-select>
            <b-form v-if="iAm === 'creator'" @submit.prevent="applyForm()">
              <b-input
                id="name"
                placeholder="Name"
                name="name"
                v-model="name"
                required
                ></b-input>
              <b-input
                id="email"
                placeholder="Email"
                name="email"
                v-model="email"
                required
                ></b-input>
              <b-form-textarea
                id="links"
                placeholder="Add links to your social channels"
                name="links"
                v-model="links"
                rows="3"
                max-rows="3"
                required
                ></b-form-textarea>
              <b-row>
                <b-button type="submit" variant="primary">Apply</b-button>
              </b-row>
            </b-form>
            
            <b-form v-if="iAm === 'brand'" @submit.prevent="applyForm()">
              <b-input
                id="company"
                placeholder="Company"
                name="company"
                v-model="company"
                required
                ></b-input>
              <b-input
                id="name"
                placeholder="Name"
                name="name"
                v-model="name"
                required
                ></b-input>
              <b-input
                id="email"
                placeholder="Email"
                name="email"
                v-model="email"
                required
                ></b-input>
              <b-row>
                <b-button type="submit" variant="primary">Apply</b-button>
              </b-row>
            </b-form>
          </b-col>
          <p v-if="apply === false" class="join">
            Want to join us? 
            <b @click="toggleApply()">
              Apply here
            </b>
          </p>
          <p v-else class="join">
            Want to login instead? 
            <b @click="toggleApply()">
              Login here
            </b>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "home",
  components: {
  },
  data: function () {
    return {
      images: [
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/1.png',
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/2.png',
        'https://bamse-in-space.fra1.digitaloceanspaces.com/front_page/3.png'
      ],
      selectedImage: null,
      email: '',
      password: '',
      error: false,
      success: false,
      resetpass: false,
      loading: false,
      apply: false,
      iAm: null,
      options: [
        { value: null, text: "I'm a..." },
        { value: 'creator', text: 'Creator' },
        { value: 'brand', text: 'Brand' }
      ],
      name: '',
      company: '',
      links: ''
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  mounted() {
    window.setInterval(() => {
        this.selectedImage = this.randomItem(this.images)
    },7000);
  },
  created() {
    this.checkCurrentLogin(),
    this.selectedImage = this.randomItem(this.images),
    this.checkForResetQuery()
  },
  updated () {
    this.checkCurrentLogin()
  },
  methods: {
    ...mapMutations({
      'loginStore': 'LOGIN',
      'logoutStore': 'LOGOUT'
    }),
    randomItem (items) {
      return items[Math.floor(Math.random()*items.length)];
    },
    checkCurrentLogin () {
      if (this.currentUser) {
        this.$router.replace(this.$route.query.redirect || '/dashboard')
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    login() {
      if(this.validateEmail(this.email) && this.password.length > 0) {

        this.$http.bamse.post('user/login', { email: this.email, password: this.password })
        .then(response => this.loginSuccessful(response))
        .catch(() => this.loginFailed())

      } else {
        this.$global.makeToast(this, 'Oh oh!', 'Enter a valid email address.', 'danger')
      }
      
    },
    loginSuccessful(response) {
      if (!response.data.success.token) {
        this.loginFailed()
        return
      }
      this.loginStore(response.data.success);
    },
    loginFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Email address or password is incorrect. Please try again.', 'danger')
      //this.logoutStore();
      //delete localStorage.token
    },
    toggleResetPass() {
      this.resetpass = !this.resetpass
    },
    resetPassword() {
      if(this.validateEmail(this.email)) {
        this.loading = true
        this.$http.bamse.post('password/create', { email: this.email })
          .then(response => this.resetPasswordSuccessful(response))
          .catch(response => this.resetPasswordFailed(response))
      } else {
        this.email = '';
        this.$global.makeToast(this, 'Oh oh!', 'Enter a valid email address.', 'danger')
      }
    },
    resetPasswordSuccessful(response) {
      if (response.data) {
        this.$global.makeToast(this, 'Check your inbox', response.data.message)
        
      }
      this.loading = false
    },
    resetPasswordFailed(response) {
      this.$global.makeToast(this, 'Oh oh!', 'Couldnt find that email address in our system. Maybe you use another one?', 'danger')
      this.loading = false
    },
    toggleApply() {
      this.resetpass = false
      this.apply = !this.apply
    },
    applyForm() {
      if(this.validateEmail(this.email)) {
        this.$http.bamse.post('user/apply', {
        type: this.iAm,
        name: this.name,
        email: this.email, 
        links: this.links,
        company: this.company,

      }).then(response => this.applyFormSuccessful(response))
        .catch(() => this.loginFailed())
      } else {
        this.$global.makeToast(this, 'Oh oh!', 'Fill out the whole form with a valid email please :)', 'danger')
      }
    },
    applyFormSuccessful(response) {
      this.$global.makeToast(this, "Woho!", "Thank you "+this.name+" for applying to RevRelations. We'll keep in touch!", "success")
      this.iAm = null
      this.name = null
      this.email = null
      this.links = null
      this.company = null
      this.toggleApply()
      
    },
    applyFormFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Something went wrong. Please try again later!', 'danger')
    },
    checkForResetQuery() {
      if(this.$route.query.reset) {
        this.toggleResetPass()
      }
    }
  }
};
</script>