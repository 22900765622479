import 'babel-polyfill'

import Vue from "vue";
import App from "./App.vue";
import axios from './backend/vue-axios/index.js';
import $ from 'jquery';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import moment from 'moment';
import BootstrapVue from 'bootstrap-vue';
import VueYouTubeEmbed from 'vue-youtube-embed';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import router from "./router";
import store from "./store";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/sass/index.sass';
import './assets/sass/responsive.sass';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import FullCalendar from 'vue-full-calendar';
import VCalendar from 'v-calendar';
import VueHtml2Canvas from 'vue-html2canvas';
import GlobalFunctions from './plugins/GlobalFunctions';

library.add(far);
library.add(fas);
library.add(fal);
library.add(fab);

Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);

Vue.use(GlobalFunctions);
Vue.use(BootstrapVue);
Vue.use(VCalendar);
Vue.use(FullCalendar);
Vue.use(VueHtml2Canvas);
Vue.use(VueLodash);
Vue.use(lodash);
Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount("#app");