<template>
  <div class="list-microcampaigns">
    <div v-if="list_type == 'active'">
    <b-row>
      <b-col cols="12" class="header">
      <h1>Active micro campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
   </b-row>
      <ListActiveMicroCampaigns />
    </div>
    <div v-else-if="list_type == 'archived'">
      <b-row>
      <b-col cols="12" class="header">
      <h1>Archived micro campaigns</h1>
      <b-breadcrumb :items="breadcrumbsarchived"></b-breadcrumb>
    </b-col>
   </b-row>
      <ListInactiveMicroCampaigns />
    </div>
    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>
  import ListActiveMicroCampaigns from "@/components/campaigns/micro/ListActiveMicroCampaigns.vue";
  import ListInactiveMicroCampaigns from "@/components/campaigns/micro/ListInactiveMicroCampaigns.vue";

export default {
  name: "activeusers",
  query: ['type'],
  components: {
    ListActiveMicroCampaigns,
    ListInactiveMicroCampaigns
  },
  data() {
    return {
      list_type: this.$route.query.type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Micro Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Active Micro Campaigns',
          href: '#',
          active: true
        },
      ],
      breadcrumbsarchived: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Micro Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Archived Micro Campaigns',
          href: '#',
          active: true
        },
      ]
    }
  },
};
</script>