<template>
<div class="campaigns">
    <b-row>
      <b-col cols="4" v-if="this.$store.state.brand_assets[0].tune_id != 0">
        <b-card title="Adlink reports">
          <hr>
          <router-link :to=" { name: 'adlinkgeneralreport' }">
            <b-button variant="outline-primary">Graph</b-button>
          </router-link>
          <router-link :to=" { name: 'conversionreport' }">
            <b-button variant="outline-primary">Revenue</b-button>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    name: "brandreports",
    components: {
    },
    computed: {
      ...mapState(['current_user_id', 'profile_assets', 'brand_assets']),
    },
  };
</script>
