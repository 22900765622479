<template>
  <div>
    <div v-if="type == 'micro'" class="create-campaign">
      <CreateMicroCampaign />
    </div>
    <div v-if="type == 'adlink'" class="create-campaign">
      <CreateAdlinkCampaign />
    </div>
    <div v-if="type == 'fixed'" class="create-campaign">
      <CreateFixedCampaign />
    </div>
  </div>
</template>

<script>
import CreateMicroCampaign from "@/components/campaigns/micro/CreateMicroCampaign.vue";
import CreateAdlinkCampaign from "@/components/campaigns/adlink/CreateAdlinkCampaign.vue";
import CreateFixedCampaign from "@/components/campaigns/fixed/CreateFixedCampaign.vue";
export default {
  name: "createcampaign",
  components: {
    CreateMicroCampaign,
    CreateAdlinkCampaign,
    CreateFixedCampaign
  },
  data: function () {
    return {
      type: this.$route.query.type,
    }
  },
  methods: {
    },
};
</script>
