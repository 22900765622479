<template>
<div>
  <b-row v-if="getCampaignInsights(id) != '404'">
    <b-col cols="12" class="pdf margin-top">
      <object :data="getCampaignInsights(id)" type="application/pdf" width="100%" height="100%" hspace="0">
        This browser does not support PDFs.
      </object>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col cols="12">
      No Insights uploaded yet.
    </b-col>
  </b-row>
  <b-row>
    <b-col v-if="level === 1 || level === 2" cols="12">
      <span class="margin-top" v-if="getCampaignInsights(id) != '404'">
        <h5 class="margin-top">Update insights</h5>
        <hr>
      </span>
      <b-form-file
        v-model="file"
        placeholder="Choose your file or drop it here..."
        drop-placeholder="Drop your file here..."
      ></b-form-file>
      <b-button block variant="outline-primary" @click="uploadInsights">
        Upload Insights
      </b-button>
    </b-col>
  </b-row>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'

export default {
  name: "Insights",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      id: this.$route.query.id,
      insights: false,
      file: null,
    }
  },
  computed: {
    ...mapState(['level', 'current_id', 'profile_assets'])
  },
  created () {
  },
  methods: {
    uploadInsights() {
      let formData = new FormData();

      formData.append('file', this.file);
      formData.append('campaign_id', this.id);

      this.$http.bamse.post('campaigns/fixed/uploadinsights', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.uploadInsightsSuccess(response))
      .catch(error => this.uploadInsightsFailed(error)) 
    },
    uploadInsightsSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Insights uploaded!', 'success')
        setTimeout( () => this.$router.go(), 1500);
      }
    },
    uploadInsightsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    getCampaignInsights(id){

      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/insights/insights_' + id + '.pdf', false);
      xhr.send();
     
      if (xhr.status == "404") {
          return '404'
      } else {
          return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/insights/insights_' + id + '.pdf'
      }

      
    },
  }
};
</script>