<template>
  <div>
  <b-row>
    <b-col cols="12" class="header">
      <h1>Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <div v-if="type == 1">
      <CubeCampaigns />
    </div>

    <div v-else-if="type == 2">
      <ProfileCampaigns />
    </div>

    <div v-else-if="type == 3">
      <BrandCampaigns />
    </div>

    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import CubeCampaigns from "@/components/campaigns/general/CubeCampaigns.vue";
import ProfileCampaigns from "@/components/campaigns/general/ProfileCampaigns.vue";
import BrandCampaigns from "@/components/campaigns/general/BrandCampaigns.vue";

export default {
  name: "campaigns",
  components: {
    CubeCampaigns,
    ProfileCampaigns,
    BrandCampaigns
  },
  computed: {
    ...mapState(['level', 'type', 'current_user_id']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          active: true
        },
      ]
    }
  },
};
</script>
