<template>
  <div class="create-user">
      <b-row>
        <b-col cols="12" class="header">
          <h1>Create asset</h1>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-card header="Create asset">
        <b-form-group>
        <b-form @submit.prevent="formSubmit()">
        
          <b-row>
            <b-col cols="12">
              <b-form-group label-size="sm" label="Type of asset" label-for="asset-type">
                <b-form-select name="asset-type" v-model="asset_type" :options="main_type_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="asset_type == 1">
            <b-col cols="12">
              <h2>General information</h2>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Name" label-for="name">
                <b-input name="name" v-model="name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Type" label-for="company">
                <b-form-select name="company" v-model="company" :options="type_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="Company Name" label-for="company_name">
                <b-input name="company_name" v-model="company_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="Registration Number" label-for="reg_number">
                <b-input name="reg_number" v-model="reg_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="company == 0">
              <b-form-group label-size="sm" label="VAT Number" label-for="vat_number">
                <b-input name="vat_number" v-model="vat_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Gender" label-for="gender">
                 <b-form-select name="gender" v-model="gender" :options="gender_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Social Security Number" label-for="ssn">
                <b-input name="ssn" v-model="ssn" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Birthday" label-for="birthday">
                <datepicker v-model="birthday" name="birthday"
                  :bootstrap-styling=true
                  :calendar-button=true
                  :monday-first=true
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Entry Code" label-for="entry_code">
                <b-input name="entry_code" v-model="entry_code" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Country" label-for="country">
                <b-form-select name="country" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <h2>Clothing sizes</h2>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Clothes" label-for="size_clothes">
                <b-input name="size_clothes" v-model="size_clothes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Shoes" label-for="size_shoes">
                <b-input name="size_shoes" v-model="size_shoes" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Size Jeans" label-for="size_jeans">
                <b-input name="size_jeans" v-model="size_jeans" ></b-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <h2>Bank details</h2>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Clearing" label-for="bank_clearing">
                <b-input name="bank_clearing" v-model="bank_clearing" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Account" label-for="bank_account">
                <b-input name="bank_account" v-model="bank_account" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank Name" label-for="bank_name">
                <b-input name="bank_name" v-model="bank_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank IBAN" label-for="bank_iban">
                <b-input name="bank_iban" v-model="bank_iban" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Bank BIC" label-for="bank_bic">
                <b-input name="bank_bic" v-model="bank_bic" ></b-input>
              </b-form-group>
            </b-col>

            <!--<b-col cols="12">
              <h2>Social Channels</h2>
            </b-col>

            <b-col cols="3">
              <b-form-group label-size="sm" label="Instagram URL" label-for="url_instagram">
                <b-input name="url_instagram" v-model="url_instagram" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Instagram Reach" label-for="reach_instagram">
                <b-input name="reach_instagram" v-model="reach_instagram" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Instagram Cube Price" label-for="cube_price_instagram">
                <b-input name="cube_price_instagram" v-model="cube_price_instagram" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Instagram Profile Price" label-for="profile_price_instagram">
                <b-input name="profile_price_instagram" v-model="profile_price_instagram" ></b-input>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group label-size="sm" label="Facebook URL" label-for="url_facebook">
                <b-input name="url_facebook" v-model="url_facebook" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Facebook Reach" label-for="reach_facebook">
                <b-input name="reach_facebook" v-model="reach_facebook" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Facebook Cube Price" label-for="cube_price_facebook">
                <b-input name="cube_price_facebook" v-model="cube_price_facebook" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Facebook Profile Price" label-for="profile_price_facebook">
                <b-input name="profile_price_facebook" v-model="profile_price_facebook" ></b-input>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group label-size="sm" label="Blog URL" label-for="url_blog">
                <b-input name="url_blog" v-model="url_blog" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Blog Reach" label-for="reach_blog">
                <b-input name="reach_blog" v-model="reach_blog" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Blog Cube Price" label-for="cube_price_blog">
                <b-input name="cube_price_blog" v-model="cube_price_blog" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Blog Profile Price" label-for="profile_price_blog">
                <b-input name="profile_price_blog" v-model="profile_price_blog" ></b-input>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group label-size="sm" label="Youtube URL" label-for="url_youtube">
                <b-input name="url_youtube" v-model="url_youtube" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Youtube Reach" label-for="reach_youtube">
                <b-input name="reach_youtube" v-model="reach_youtube" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Youtube Cube Price" label-for="cube_price_youtube">
                <b-input name="cube_price_youtube" v-model="cube_price_youtube" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-size="sm" label="Youtube Profile Price" label-for="profile_price_youtube">
                <b-input name="profile_price_youtube" v-model="profile_price_youtube" ></b-input>
              </b-form-group>
            </b-col>-->

            <b-col cols="12">
              <h2>Other details</h2>
            </b-col>
            
            <b-col cols="6">
              <b-form-group label-size="sm" label="Agency" label-for="agency">
                <b-input name="agency" v-model="agency" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Agent" label-for="agent">
                <b-input name="agent" v-model="agent" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Exclusive Brands" label-for="exclusive_brands">
                <b-input name="exclusive_brands" v-model="exclusive_brands" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Denied Campaigns" label-for="denied_campaigns">
                <b-input name="denied_campaigns" v-model="denied_campaigns" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Profile image" label-for="imageupload">
              <b-form-file 
                v-model="file"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
              <b-form-file 
                v-model="file2"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="asset_type == 2">
            <b-col cols="6">
              <b-form-group label-size="sm" label="Brand name *" label-for="company_name">
                <b-input name="company_name" v-model="company_name" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Registration number" label-for="reg_number">
                <b-input name="reg_number" v-model="reg_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="VAT number" label-for="vat_number">
                <b-input name="vat_number" v-model="vat_number" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Address" label-for="address_street">
                <b-input name="address_street" v-model="address_street" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="City" label-for="address_town">
                <b-input name="address_town" v-model="address_town" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Zip" label-for="address_zip">
                <b-input name="address_zip" v-model="address_zip" ></b-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Country *" label-for="country">
                <b-form-select name="country" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">

            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Brand image *" label-for="imageupload">
              <b-form-file 
                v-model="file"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-size="sm" label="Cover image *" label-for="imageupload">
              <b-form-file 
                v-model="file2"
                placeholder="Choose a picture..."
                drop-placeholder="Drop picture here..."
                accept=".jpg, .png"
                name="imageupload"
              ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="btn btn-success pull-right" v-if="loading == false && asset_type != null">
            Create Asset
          </b-button>
          <b-button disabled class="btn btn-success pull-right" v-else-if="loading == true && asset_type != null">
            <b-spinner small ></b-spinner> Loading
          </b-button>
        </b-form>
        </b-form-group>
      </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Alerts from "@/components/general/Alerts.vue";
import Datepicker from 'vuejs-datepicker';
import FileUpload from '@/components/general/FileUpload.vue'

let channel_list = [];

export default {
  name: "createasset",
  components: {
    Alerts,
    Datepicker,
    FileUpload
  },
  computed: {
    ...mapState(['current_id']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Create Asset',
          href: '#',
          active: true
        }
      ],
      success: false,
      error: false,
      loading: false,
      errorMessage: '',
      asset_type: null,
      name: '',
      company: 0,
      company_name: '',
      reg_number: '',
      vat_number: '',
      gender: '',
      ssn: '',
      birthday: '31 July 1980',
      entry_code: '',
      size_clothes: '',
      size_shoes: '',
      size_jeans: '',
      bank_clearing: '',
      bank_account: '',
      bank_name: '',
      bank_iban: '',
      bank_bic: '',
      url_instagram: null,
      reach_instagram: null,
      cube_price_instagram: null,
      profile_price_instagram: null,
      url_facebook: null,
      reach_facebook: null,
      cube_price_facebook: null,
      profile_price_facebook: null,
      url_blog: null,
      reach_blog: null,
      cube_price_blog: null,
      profile_price_blog: null,
      url_youtube: null,
      reach_youtube: null,
      cube_price_youtube: null,
      profile_price_youtube: null,
      agency: '',
      agent: '',
      address_street: '',
      address_town: '',
      address_zip: '',
      country: '',
      exclusive_brands: '',
      denied_campaigns: '',
      file: null,
      file2: null,
      country: null,
      main_type_options: [
        { value: null, text: 'Choose type of asset' },
        { value: 1, text: 'Profile' },
        { value: 2, text: 'Brand' },
      ],
      type_options: [
        { value: 0, text: 'Company' },
        { value: 1, text: 'Private' },
      ],
      gender_options: [
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
        { value: 'Other', text: 'Other' },
      ],
      country_selected: null,
      country_options: []
    }
  },
  created() {
    this.addCountryList()
  },
  methods: {
    addCountryList() {
      this.$global.getCountryList().forEach(item => {
        this.country_options.push(item)
      })
    },
    formSubmit() {
      if(this.asset_type === 1){
        this.createProfile()
      }else if(this.asset_type === 2) {
        this.createBrand()
      } else {
        this.error = true
      }
    },
    createProfile() {

      this.loading = true

      channel_list = []

      if(this.url_instagram !== null) {
        channel_list.push({
          channel_type: 1, 
          channel_type_text: "Instagram", 
          channel_url: this.url_instagram, 
          channel_reach: this.reach_instagram, 
          channel_cube_price: this.cube_price_instagram, 
          channel_profile_price: this.profile_price_instagram
        })
      }

      if(this.url_facebook !== null) {
        channel_list.push({
          channel_type: 2, 
          channel_type_text: "Facebook", 
          channel_url: this.url_facebook, 
          channel_reach: this.reach_facebook, 
          channel_cube_price: this.cube_price_facebook, 
          channel_profile_price: this.profile_price_facebook        
        })
      }

      if(this.url_blog !== null) {
        channel_list.push({
          channel_type: 3, 
          channel_type_text: "Blog", 
          channel_url: this.url_blog, 
          channel_reach: this.reach_blog, 
          channel_cube_price: this.cube_price_blog, 
          channel_profile_price: this.profile_price_blog       
        })
      }

      if(this.url_youtube !== null) {
        channel_list.push({
          channel_type: 4, 
          channel_type_text: "Youtube", 
          channel_url: this.url_youtube, 
          channel_reach: this.reach_youtube, 
          channel_cube_price: this.cube_price_youtube, 
          channel_profile_price: this.profile_price_youtube       
        })
      }

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('company', this.company);
      formData.append('company_name', this.company_name);
      formData.append('reg_number', this.reg_number);
      formData.append('vat_number', this.vat_number);
      formData.append('gender', this.gender);
      formData.append('ssn', this.ssn);
      formData.append('birthday', this.birthday);
      formData.append('entry_code', this.entry_code);
      formData.append('size_clothes', this.size_clothes);
      formData.append('size_shoes', this.size_shoes);
      formData.append('size_jeans', this.size_jeans);
      formData.append('bank_clearing', this.bank_clearing);
      formData.append('bank_account', this.bank_account);
      formData.append('bank_name', this.bank_name);
      formData.append('bank_iban', this.bank_iban);
      formData.append('bank_bic', this.bank_bic);
      formData.append('channels', this.channel_list);
      formData.append('agency', this.agency);
      formData.append('agent', this.agent);
      formData.append('country', this.country);
      formData.append('exclusive_brands', this.exclusive_brands);
      formData.append('denied_campaigns', this.denied_campaigns);
      formData.append('file', this.file);
      formData.append('file2', this.file2);
    
      this.$http.bamse.post('assets/profile/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.createProfileSuccess(response))
      .catch(error => this.createProfileFailed(error))
    },
    createProfileSuccess(response) {
      if(response.data.profile_id) {
        let profile_id = response.data.profile_id
        this.addProfileToUser(profile_id)
      }
    },
    createProfileFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    createBrand() {

     if (this.company_name == '' || this.country == '' || this.file == '' ){
        this.$global.makeToast(this, 'Oh oh!', 'Required information missing.', 'danger')
      } else {

        this.loading = true

        let formData = new FormData();
        formData.append('company_name', this.company_name);
        formData.append('reg_number', this.reg_number);
        formData.append('vat_number', this.vat_number);
        formData.append('address_street', this.address_street);
        formData.append('address_town', this.address_town);
        formData.append('address_zip', this.address_zip);
        formData.append('country', this.country);
        formData.append('file', this.file);
        formData.append('file2', this.file2);

        this.$http.bamse.post('assets/brand/create', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => this.createBrandSuccess(response))
          .catch(error => this.createBrandFailed(error))
      }
      
      
    },
    createBrandSuccess(response) {
      if(response.data.brand_id) {
        let brand_id = response.data.brand_id
        this.addBrandToUser(brand_id)
      }
    },
    createBrandFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
    },
    addBrandToUser(brand_id) {

        var brand_array = []
      
        brand_array.push({id: brand_id})

        this.$http.bamse.post('assets/brand/connect', { 
          user_id: this.current_id, 
          brand_id: brand_array
        })
          .then(response => this.addBrandToUserSuccess(response))
          .catch(() => this.addBrandToUserFailed())
      
    },
    addBrandToUserSuccess(response) {
      
         this.$global.makeToast(this, 'Success', 'Brand created', 'success')
         setTimeout( () => this.$router.push({ path: 'list-assets', query: { type: 'brands' }}), 1500);

    },
    addBrandToUserFailed() {
      this.error = true
      this.loading = false
    },
    addProfileToUser(profile_id) {

        var profile_array = []
      
        profile_array.push({id: profile_id})

        this.$http.bamse.post('assets/profile/connect', { 
          user_id: this.current_id, 
          profile_id: profile_array
        })
          .then(response => this.addProfileToUserSuccess(response))
          .catch(() => this.addProfileToUserFailed())
      
    },
    addProfileToUserSuccess(response) {
      
         this.$global.makeToast(this, 'Success', 'Profile created', 'success')
         setTimeout( () => this.$router.push({ path: 'list-assets', query: { type: 'profiles' }}), 1500);

    },
    addProfileToUserFailed() {
      this.error = true
      this.loading = false
    },
  }
}

</script>
