<template>
  <div class="userbank">
      <b-row>
      <b-col cols="12" class="header">
        <h1>Withdrawal</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
	<b-row>
      <b-col cols="12">
        <b-card :header="'Withdrawal '+ reference" ref="capture">
          <div>
            <div v-if="this.profile_assets[0].is_company == 1">
              <b-table 
                thead-class="hidden_header"
                :items="items"
                :fields="fields"
                >
              </b-table>
            </div>
            <div class="payslip-margin" v-if="this.profile_assets[0].is_company == 0">
              <b-col cols="12" class="border-div-payslip">
                <h5>
                  Payment specification created {{ date}}
                </h5>
              </b-col>
              <b-col cols="12" class="border-div">
                <b-row>
                  <b-col cols="5">
                    <b><p>Name</p></b>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ name }}</p>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ payslip_companyname }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">
                    <b><p>Adress</p></b>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ address }}</p>
                    <p>{{ zip }}</p>
                    <p>{{ city }}, {{ country }}</p>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ payslip_companyadress }}</p>
                    <p>{{ payslip_companyzip }}</p>
                    <p>{{ payslip_companycity }}, {{ payslip_companycountry }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">
                    <b><p>Social Security Number / Corporate identity number</p></b>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ ssn }}</p>
                  </b-col>
                  <b-col cols="3">
                    <p>{{ payslip_companycin }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">
                    <b><p>VAT Registration Number</p></b>
                  </b-col>
                  <b-col cols="3">
                  </b-col>
                  <b-col cols="3">
                    <p>{{ payslip_companyvat }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="border-div">
                <b-row class="header-payslip">
                  <b-col cols="3">
                    Gross payment
                  </b-col>
                  <b-col cols="3">
                    Payroll tax
                  </b-col>
                  <b-col cols="3">
                    Tax
                  </b-col>
                  <b-col cols="3">
                    Net payment
                  </b-col>
                </b-row>
                <b-row class="center-payslip">
                  <b-col cols="3">
                    {{ grosspayment }} {{ currency }}
                  </b-col>
                  <b-col cols="3">
                    {{ payrolltax }} {{ currency }}
                  </b-col>
                  <b-col cols="3">
                    {{ tax }} {{ currency }}
                  </b-col>
                  <b-col cols="3">
                    {{ netpayment }} {{ currency }}
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
          <b-button @click="downloadPDF()" variant="outline-primary" class="pull-right margin-right">Download PDF</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import jsPDF from 'jspdf'

var img;

export default {
  name: "userwithdrawals",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
  },
  created () {
    this.setUp()
    this.getProfileInfo()
    this.getUserInfo()
  },
  mounted() {
    this.withdraw()
  },
  data: function () {
    return {
      amount: this.$route.query.amount,
      profile_withdraw_id: '',
      ssn: '',
      city: '',
      address: '',
      zip: '',
      grosspayment: '',
      reference: '',
      payrolltax: '',
      tax: '',
      netpayment: '',
      date: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances',
        },
        {
          text: 'Withdrawal',
          active: true
        }
      ],
      fields: [
          { key: 'description' },
          { key: 'value', colType: "math" },
        ],
      items: [
        ],
        currency: '',
    }
  },
  methods: {
    downloadPDF() {
      const doc = new jsPDF({compress: true});
       /** WITH CSS */
       var canvasElement = document.createElement('canvas');
        this.$html2canvas(this.$refs.capture, { canvas: canvasElement 
          }).then(function (canvas) {
        const img = canvas.toDataURL("image/png");
        doc.addImage(img,'JPEG',5, 5, 200, 140);
        doc.save("Payment.pdf");
       });
    },
    setUp(){

      if(this.country == "Sweden" && this.profile_assets[0].is_company !== 1) {
        this.payslip_companyname = "WeAreCube AB"
        this.payslip_companyadress = "Kammakargatan 48"
        this.payslip_companyzip = "111 60"
        this.payslip_companycity = "Stockholm"
        this.payslip_companycountry = "Sweden"
        this.payslip_companycin = "000000-0000"
        this.payslip_companyvat = "0000000000SE"
        this.currency = "SEK"
      } else if (this.country == "Denmark" && this.profile_assets[0].is_company !== 1) {
        this.payslip_companyname = "WeAreCube AB"
        this.payslip_companyadress = "Kammakargatan 48"
        this.payslip_companyzip = "111 60"
        this.payslip_companycity = "Stockholm"
        this.payslip_companycountry = "Sweden"
        this.payslip_companycin = "000000-0000"
        this.payslip_companyvat = "0000000000SE"
        this.currency = "DK"
      }

      this.profile_withdraw_id = this.profile_assets[0].profile_id


      var dt = new Date();

      
      this.date = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();

      this.reference = this.profile_withdraw_id + "-" + dt.getFullYear() + "" + (dt.getMonth() + 1) + "" + dt.getDate() + "-" + dt.getHours() + "" + dt.getMinutes() + "" + dt.getSeconds();




      var amountFormat = this.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      var vat = this.amount * 0.25
      var vatFormat = vat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      var invoiceAmount = +this.amount + +vat
      var invoiceAmountFormat = invoiceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");


      var payrolltaxes = this.amount / 1.3142 * 0.3142
      var payrolltaxRound = Math.round(payrolltaxes)
      var payrolltaxFormat = payrolltaxRound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      var taxes = this.amount / 1.3142 * 0.3
      var taxRound = Math.round(taxes)
      var taxFormat = taxRound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      var netpay = this.amount / 1.3142 * 0.7
      var netpayRound = Math.round(netpay)
      var netpayFormat = netpayRound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");



      this.grosspayment = amountFormat
      this.payrolltax = payrolltaxFormat
      this.tax = taxFormat
      this.netpayment = netpayFormat

      if(this.country == "Sweden" && this.profile_assets[0].is_company == 1) {
        this.currency = "SEK"
        this.items.push(
        { description: 'Company', value: 'WeAreCube AB' },
        { description: 'Adress', value: 'Kammakargatan 46' },
        { description: 'ZIP', value: '111 60' },
        { description: 'City', value: 'Stockholm' },
        { description: 'Country', value: 'Sweden' },
        { description: 'Our Reference', value: 'Olof Lindblom' },
        { description: 'E-mail', value: 'invoice@wearecube.se' },
        { description: 'Corporate identity number', value: '556840-9543' },
        { description: 'VAT Registration number', value: 'SE556840954301' },
        { description: 'Payment terms', value: '30 days' },
        { description: 'Reference number', value: this.reference },
        { description: 'Amount without VAT', value: amountFormat+'.00 ' + this.currency },
        { description: 'VAT (25%)', value: vatFormat+' '+this.currency },
        { description: 'Amount to invoice', value: invoiceAmountFormat+' '+ this.currency },
      )
      } else if(this.country == "Denmark" && this.profile_assets[0].is_company == 1) {
        this.currency = "DK"
        this.items.push(
        { description: 'Companyq', value: 'WeAreCube AB' },
        { description: 'Adress', value: 'Kammakargatan 46' },
        { description: 'ZIP', value: '111 60' },
        { description: 'City', value: 'Stockholm' },
        { description: 'Country', value: 'Sweden' },
        { description: 'Our Reference', value: 'Olof Lindblom' },
        { description: 'E-mail', value: 'invoice@wearecube.se' },
        { description: 'Corporate identity number', value: '556840-9543' },
        { description: 'VAT Registration number', value: 'SE556840954301' },
        { description: 'Payment terms', value: '30 days' },
        { description: 'Reference number', value: '1' },
        { description: 'Amount without VAT', value: amountFormat+'.00 ' + this.currency },
        { description: 'VAT (25%)', value: vatFormat+' '+this.currency },
        { description: 'Amount to invoice', value: invoiceAmountFormat+' '+ this.currency },
      )
      } else {
        console.log("nah")
      }
    },
    withdraw() {
      var _this = this;
       const doc = new jsPDF({compress: true});
       var canvasElement = document.createElement('canvas');
        this.$html2canvas(this.$refs.capture, { 
          canvas: canvasElement 
          }).then(function (canvas) {
            const img = canvas.toDataURL("image/png");
            doc.addImage(img,'JPEG',5, 5, 200, 130);
            var pdf = doc.output('blob')
            //doc.save(pdf)
            let formData = new FormData();
          formData.append('file', pdf);
          formData.append('profile_id', _this.profile_withdraw_id);
          formData.append('amount', _this.amount);
          formData.append('currency', _this.currency);
          formData.append('status', 0); 
          _this.$http.bamse.post( 'assets/profile/withdrawal/make',
          formData,
              {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
              ).then(response => _this.withdrawSuccess(response))
              .catch(() => _this.withdrawFailed());
        })
        
      
    },
    withdrawSuccess(response) {
      if(response.data.success) {
        this.success = true
        console.log("yay")
      }
    },
    withdrawFailed() {
      this.error = true
    },
    getProfileInfo() {
      this.$http.bamse.get('assets/profile/details?profile_id='+this.profile_withdraw_id)
        .then(response => this.getProfileInfoSuccess(response))
        .catch(() => this.getProfileInfoFailed())
    },
    getProfileInfoSuccess(response) {
     if(response.data.success) {
        this.ssn = response.data.success[0].ssn
     }
    },
    getProfileInfoFailed() {
      this.error = 'no fetch sorry'
    },
    getUserInfo() {
      this.$http.bamse.get('user/details/self')
        .then(response => this.getUserInfoSuccess(response))
        .catch(() => this.getUserInfoFailed())
    },
    getUserInfoSuccess(response) {
      console.log("details/self")
      console.log(response)
     if(response.data.success) {
        this.address = response.data.success[0].address_street
        this.city = response.data.success[0].address_town
        this.zip = response.data.success[0].address_zip
     }
    },
    getUserInfoFailed() {
      this.error = 'no fetch sorry'
    },
  }
};
</script>