<template>
  <div>
    <b-row>
      <b-col cols="12" class="header">
        <h1>Manage {{campaign_name}}</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>

    <!-- Cube view -->
    <b-tabs active content-class="mt-3" v-if="type == 1">
      <b-tab title="General">
        <InformationFixedCampaign />
      </b-tab>
      <b-tab v-if="level === 1 || level === 2" title="Edit campaign" >
        <div class="manage-campaign">
          <ManageFixedCampaign />
        </div>
      </b-tab>
      <b-tab title="Reports" >
          <ReportsFixedCampaign />
      </b-tab>
      <b-tab title="Insights" >
          <InsightsFixedCampaign />
      </b-tab>
    </b-tabs>

    <!-- Brand view -->
    <b-tabs active content-class="mt-3" v-if="type == 3">
      <b-tab title="General">
        <InformationFixedCampaignBrand />
      </b-tab>
      <b-tab title="Reports" >
        <ReportsFixedCampaignBrand />
      </b-tab>
      <b-tab title="Insights" >
        <InsightsFixedCampaignBrand />
      </b-tab>
    </b-tabs>
  </div>
  
</template>

<script>
import { mapState } from 'vuex'

import InformationFixedCampaign from "@/components/campaigns/fixed/InformationFixedCampaign.vue";
import ManageFixedCampaign from "@/components/campaigns/fixed/ManageFixedCampaign.vue";
import ReportsFixedCampaign from "@/components/campaigns/fixed/ReportsFixedCampaign.vue";
import InsightsFixedCampaign from "@/components/campaigns/fixed/InsightsFixedCampaign.vue";

import InformationFixedCampaignBrand from "@/components/campaigns/fixed/brand/InformationFixedCampaign.vue";
import ReportsFixedCampaignBrand from "@/components/campaigns/fixed/brand/ReportsFixedCampaign.vue";
import InsightsFixedCampaignBrand from "@/components/campaigns/fixed/brand/InsightsFixedCampaign.vue";

export default {
  name: "managecampaign",
  components: {
    InformationFixedCampaign,
    ManageFixedCampaign,
    ReportsFixedCampaign,
    InformationFixedCampaignBrand,
    ReportsFixedCampaignBrand,
    InsightsFixedCampaign,
    InsightsFixedCampaignBrand
  },
  computed: {
    ...mapState(['level', 'type', 'current_user_id']),
  },
  created () {
    this.getCampaignDetails()
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Cube Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Manage',
          href: '#',
          active: true
        }
      ]
    }
  },
  methods: {
    getCampaignDetails() {
      this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id)
      .then(response => this.getCampaignDetailsSuccess(response))
      .catch(() => this.getCampaignDetailsFailed())
    },
    getCampaignDetailsSuccess(response) {
      if(response.data.success) {
        this.campaign_name = response.data.success[0][0].campaign_name
      }
    },
    getCampaignDetailsFailed() {

    }
  },
};
</script>
