<template>
  <div class="campaign-info adlink">
    <b-card
      overlay
      :img-src="getCampaignImg(this.id)"
      img-alt="Card Image"
      height="200"
      text-variant="white"
      :title="this.campaign_name"
    >
      <b-row>
        <b-col lg="8" class="description">
          {{ this.description }}
        </b-col>
        <b-col lg="4" class="campaign-overview">
          <b-col lg="12" md="4" sm="4">
            <span class="label">Start</span>
            {{ this.start_date }}
          </b-col>
          <b-col lg="12" md="4" sm="4">
            <span class="label">End</span>
            {{ this.end_date }}
          </b-col>
          <b-col lg="12" md="4" sm="4">
            <span class="label">Type</span>
            <span v-if="this.type == 'cpc'">
              CPC
            </span>
            <span v-else>
              CPA
            </span>
          </b-col>
          <b-col lg="12" md="4" sm="4">
            <span class="label">Cube</span>
            {{ this.cube_cut }}
          </b-col>
          <b-col lg="12" md="4" sm="4">
            <span class="label">Profile</span>
            {{ this.profile_cut }}
          </b-col>
        </b-col>
        <b-col cols="12">
          <hr>
          <b-button size="sm" class="pull-left margin-top" variant="outline-light" disabled>Campaign ID: {{id}}</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card-group deck>
      <b-card class="campaign-info-box">
        <div>
            <h4 class="header-h">Profiles</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'user' }"/>
            </span>
        </div>
        <div class="clearfix"></div>
        <hr>
        {{ this.number_of_profiles }}
      </b-card>
      <b-card class="campaign-info-box">
        <div>
            <h4 class="header-h">Clicks</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'mouse-pointer' }"/>
            </span>
        </div>
        <div class="clearfix"></div>
        <hr>
        {{ this.clicks }}
      </b-card>
      <b-card class="campaign-info-box">
        <div>
            <h4 class="header-h">Conversions</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'shopping-cart' }"/>
            </span>
        </div>
        <div class="clearfix"></div>
        <hr>
        {{ this.conversions }}
      </b-card>
      <b-card class="campaign-info-box">
        <div>
            <h4 class="header-h">Profit</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
            </span>
        </div>
        <div class="clearfix"></div>
        <hr>
        {{ $global.convertCurrency(country, this.profit)}}
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

import $ from 'jquery'

export default {
  name: "InformationAdlinkCampaign",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: '',
      description: '',
      start_date: '',
      end_date: '',
      type: '',
      currency: '',
      cube_cut: '',
      profile_cut: '',
      number_of_profiles: '',
      tune_id: '',
      clicks: '',
      conversions: '',
      profit: '',
    }
  },
  computed: {
    ...mapState(['country']),
  },
  created () {
    this.getCampaign()
    this.listConnectedProfiles()
  },
  methods: {
    getCampaignImg(id){
      return this.$global.spacesURL()+'campaigns/adlink/campaign_' + id + '/cover_image/campaign_' + id + '.png'
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/adlink/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {

        this.campaign_name = response.data.success[0].campaign_name
        this.description = response.data.success[0].description
        this.start_date = response.data.success[0].start_date
        this.end_date = response.data.success[0].end_date
        this.currency = response.data.success[0].currency
        this.type = response.data.success[0].payout_type
        this.tune_id = response.data.success[0].tune_id

        if(this.type == "cpc") {
            this.cube_cut = response.data.success[0].cube_cpc + " " + this.currency
            this.profile_cut = response.data.success[0].profile_cpc + " " + this.currency
        } else {
            this.cube_cut = response.data.success[0].cube_payout_percent + "%"
            this.profile_cut = response.data.success[0].profile_payout_percent + "%"
        }

        this.getCampaignStats()

      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    listConnectedProfiles() {
      this.$http.bamse.get('campaigns/adlink/details/profiles/connected?campaign_id='+this.id)
        .then(response => this.listConnectedProfilesSuccess(response))
        .catch(() => this.listConnectedProfilesFailed())
    },
    listConnectedProfilesSuccess(response) {
      if(response.data.success) {
       this.number_of_profiles =  response.data.success.length
      }
    },
    listConnectedProfilesFailed() {
      this.error = 'no fetch sorry'
    },
    getCampaignStats() {

      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Report');
      formData.append('Method', 'getStats');
      formData.append('fields[]', 'Stat.profit');
      formData.append('fields[]', 'Stat.conversions');
      formData.append('fields[]', 'Stat.clicks');
      formData.append('filters[Stat.offer_id][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.offer_id][values]', this.tune_id);

      this.$http.tune.post('', formData)
      .then(response => this.getCampaignStatsSuccess(response))
        .catch(error => this.getCampaignStatsFailed(error))
    },
    getCampaignStatsSuccess(response) {
      if(response.data.response.status == 1) {
          this.clicks = response.data.response.data.data[0].Stat.clicks
          this.conversions = response.data.response.data.data[0].Stat.conversions
          this.profit = response.data.response.data.data[0].Stat.profit

          if(this.clicks == '' || this.clicks == null) {
            this.clicks = "N/A"
            this.conversions = "N/A"
            this.profit = "N/A"
            this.currency = ""
          } else {
            this.profit = this.profit.split('.')[0]
          }

          
      }
    },
    getCampaignStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
  },
};
</script>
