<template>
  <div class="campaign-info campaign-reports">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Finance Report</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="campaign-reports-box">
          <b-row>
            <b-col cols="12">
              <b-form @submit.prevent="createAdlinkCampaign()">  
                <b-row v-if="showdates">
                  <b-col lg="2">
                    <b-form-group label-size="sm" label="Start date" label-for="start_date">
                      <datepicker 
                        wrapper-class="form-control" 
                        :monday-first="true"
                        format="dd-MM-yyyy" 
                        v-model="start_date" 
                        name="start_date"
                      ></datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col lg="2">
                    <b-form-group label-size="sm" label="End date" label-for="end_date">
                      <datepicker 
                        wrapper-class="form-control" 
                        :monday-first="true"
                        format="dd-MM-yyyy" 
                        v-model="end_date" 
                        name="end_date"
                      ></datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-button @click="getStats()" class="pull-left active-sort getdatesbtn" variant="outline-primary">Submit</b-button>
                    <b-button @click="showdates = false" class="pull-left active-sort getdatesbtn" variant="outline-primary">Cancel</b-button>
                  </b-col>
                  <b-col xl="5" class="datefilter">
                      <b-button @click="pickDates(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 90 days</b-button>
                  
                      <b-button @click="pickDates(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 30 days</b-button>

                    <b-button @click="pickDates(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 7 days</b-button>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col lg="3" class="margin-bottom-sort">
                    <span class="label">Current dates</span>
                    <b-button @click="showdates = true" v-if="loading == false" class="active-sort datebtn" variant="outline-primary">{{ start_date}} - {{ end_date }}</b-button>
                    <b-button class="active-sort datebtn" variant="outline-primary" v-else>
                    <b-spinner small ></b-spinner> Loading
                  </b-button>  
                  </b-col>
                  <b-col lg="3" v-if="this.$route.query.campaign == null">
                      <span class="label">Brands</span>
                      <multiselect 
                      :showLabels="false" 
                      placeholder="Pick brands" 
                      :close-on-select="false" 
                      multiple 
                      :clearOnSelect="false" 
                      v-model="brand_array" 
                      :options="brand_options"
                      label="text"
                      track-by="text"
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} brands selected</span></template>
                      </multiselect>
                  </b-col>
                  <b-col lg="3">
                      <span class="label">Profiles</span>
                      <multiselect 
                      :showLabels="false" 
                      placeholder="Pick profiles" 
                      :close-on-select="false" 
                      multiple 
                      :clearOnSelect="false" 
                      v-model="profiles_array" 
                      :options="profiles_options"
                      label="text"
                      track-by="text"
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} profiles selected</span></template>
                      </multiselect>
                  </b-col>
                  <b-col lg="3" v-if="this.$route.query.campaign == null">
                      <span class="label">Campaigns</span>
                      <multiselect 
                      :showLabels="false" 
                      placeholder="Pick campaigns" 
                      :close-on-select="false" 
                      multiple 
                      :clearOnSelect="false" 
                      v-model="campaign_array" 
                      :options="campaign_options"
                      label="text"
                      track-by="text"
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} campaigns selected</span></template>
                      </multiselect>
                  </b-col>
                  <b-col cols="12" class="sortfilter">
                     <!-- <b-button @click="pickFilter(1)" id="filter1" class="pull-left active-sort getdatesbtn" variant="outline-primary">Instagram</b-button>
                  
                      <b-button @click="pickFilter(2)" id="filter2" class="pull-left active-sort getdatesbtn" variant="outline-primary">Instagram Stories</b-button>

                    <b-button @click="pickFilter(3)" id="filter3" class="pull-left active-sort getdatesbtn" variant="outline-primary">Youtube</b-button> -->

                    <b-button @click="getStats()" class="pull-right active-sort getdatesbtn updategraph" variant="outline-primary">Update</b-button>
                  </b-col>
                </b-row>
              
              </b-form>
            </b-col>
          </b-row>
          <b-row class="margin-bottom">
            <b-col cols="12" class="margin-bottom sortable">
                <b-button @click="filterChart('all')" id="show-all" class="active-sort" variant="outline-primary">Show all</b-button>

                <b-button :id="item.id" @click="filterChart(item.label)" v-for="item in chartData['line'].datasets" :key="item.id" variant="outline-primary" class="active-sort" :style="'background-color:'+item.backgroundColor">{{ item.label }}</b-button>

            </b-col>
          </b-row>

          <line-chart :updated="updated" :options="options" :chart-data="chartData['line']"></line-chart>
        </b-card>
        <b-card v-if="showBarChart">
          <bar-chart :updated="updated" :options="options" :chart-data="chartData['bar']"></bar-chart>
        </b-card>
        <b-card class="campaign-reports-table">
          <span class="label">Sort by</span>
          <b-form-select
            v-model="pivot_selected" 
            :options="pivot_options"
            @change="getPivot"
          >
          </b-form-select>
          <hr>
          <div class="pivot-responsive">
            <b-table 
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              ref="pivotTable"
            >
            <template v-slot:cell(profile)="row">
              <span v-if="row.item.profile > 0">
                {{getProfileName(row.item.profile)}}
              </span>
            </template>
            <template v-slot:cell(brand)="row">
              <span v-if="row.item.brand > 0">
                {{getBrandName(row.item.brand)}}
              </span>
            </template>
            <template v-slot:cell(campaign)="row">
              <span v-if="row.item.campaign > 0">
                {{getCampaignName(row.item.campaign)}}
              </span>
            </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'
import $ from 'jquery'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'

export default {
  name: "reports",
  components: {
    LineChart,
    BarChart,
    Datepicker,
    Multiselect,
  },
  computed: {
    ...mapState(['type', 'current_user_id', 'country']),
  },
  created() {
    this.getCurrencyName()
    this.setDate()
    this.getBrandList()
    this.getProfileList()
    this.getCampaignList()
    this.getStats()
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Reports',
          to: '/reports' 
        },
        {
          text: 'Finance Report',
          active: true
        },
      ],
      chartData: {},
      updated: 0,
      loading: true,
      showBarChart: false,
      currentfilter: 0,
      pivot_selected: 'profile',
      pivot_offset: 3,
      profiles_options: [],
      brand_options: [],
      campaign_options: [],
      profiles_array: [],
      brand_array: [],
      campaign_array: [],
      brands: [],
      profiles: [],
      campaigns: [],
      showdates: false,
      start_date: null,
      end_date: null,
      items: [],
      pivot_options: [
        { value: "date", text: "Date" },
        { value: "campaign", text: "Campaign" },
        { value: "brand", text: "Brand" },
        { value: "profile", text: "Profile" },
      ],
      fields: [
          {
            label: 'Campaign',
            key: 'campaign',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Date',
            key: 'date',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Brand',
            key: 'brand',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Profile',
            key: 'profile',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned Profile Revenue',
            key: 'planned_revenue',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned Profile Cost',
            key: 'planned_cost',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned Profile Profit',
            key: 'planned_profit',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved Profile Revenue',
            key: 'approved_revenue',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved Profile Cost',
            key: 'approved_cost',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved Profile Profit',
            key: 'approved_profit',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned General Revenue',
            key: 'planned_general_revenue',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned General Cost',
            key: 'planned_general_cost',
            sortable: true,
            class: null,
          },
          {
            label: 'Planned General Profit',
            key: 'planned_general_profit',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved General Revenue',
            key: 'approved_general_revenue',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved General Cost',
            key: 'approved_general_cost',
            sortable: true,
            class: null,
          },
          {
            label: 'Approved General Profit',
            key: 'approved_general_profit',
            sortable: true,
            class: null,
          },

        ],
      options: null,
    }
  },
  methods: {
    getCurrencyName() {
      var currencyName = this.$global.getCurrencyName(this.country)
      this.options = {
        //Chart.js options
        scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            },
            gridLines: {
                display: true
            },
            
        }],
        xAxes: [ {
            gridLines: {
                display: true
            }
        }]
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label;
              var id = data.datasets[tooltipItem.datasetIndex].id;
              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              label += " "+currencyName;
              return label;
            }
          }
        }
      }
    },
    getProfileName(id) {
      var profileIndex = _.findIndex(this.profiles_options, {value: {profile_id: id}});
      var profileName = this.profiles_options[profileIndex].text
      return profileName
    },
    getBrandName(id) {
      var brandIndex = _.findIndex(this.brand_options, {value: {brand_id: id}});
      var brandName = this.brand_options[brandIndex].text
      return brandName
    },
    getCampaignName(id) {
      var campaignIndex = _.findIndex(this.campaign_options, {value: {campaign_id: id}});
      var campaignName = this.campaign_options[campaignIndex].text
      campaignName = campaignName.substring(0, campaignName.indexOf('('));
      return campaignName
    },
    setDate(){
      this.end_date = moment().format('YYYY-MM-DD')
      this.start_date = moment().subtract(50, 'days').format('YYYY-MM-DD');
      if(this.$route.query.campaign) {
        var campaignID = parseInt(this.$route.query.campaign)
        this.campaigns.push(campaignID)
      }
    },
    pickDates(id) {
      if(id == 1) {
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
      } else if(id == 2) {
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
      } else if(id == 3) {
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(90, 'days').format('YYYY-MM-DD');
      }
      this.showdates = false
      this.getStats()
    },
    getStats() {
      this.chartData['line'] = [];

      this.showdates = false

      let formData = new FormData();


      if(this.brand_array.length > 0) {
        this.brand_array.forEach( item  => {
          this.brands.push(item.value.brand_id)
        });
        this.brands = JSON.stringify(this.brands)
        formData.append('brands', this.brands);
      }
      if(this.profiles_array.length > 0) {
        this.showBarChart = true
        this.profiles_array.forEach( item  => {
          this.profiles.push(item.value.profile_id)
        });
        this.profiles = JSON.stringify(this.profiles)
        formData.append('profiles', this.profiles);
      } else {
        this.showBarChart = false
      }
      if(this.campaign_array.length > 0) {
        this.campaign_array.forEach( item  => {
          this.campaigns.push(item.value.campaign_id)
        });
        this.campaigns = JSON.stringify(this.campaigns)
        formData.append('campaigns', this.campaigns)
      }

      this.brands = []
      this.campaigns = []
      this.profiles = []

      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.end_date = moment(this.end_date).format('YYYY-MM-DD');

      formData.append('start_date', this.start_date)
      formData.append('end_date', this.end_date)
      
      this.$http.bamse.post('/finance/getfinancestats', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => this.getStatsSuccess(response))
        .catch(error => this.getStatsFailed(error))
    },
    getStatsSuccess(response) {
      if(response.data.success) {
        
        // Empty filters
        this.brands = []
        this.profiles = []
        this.campaigns = []

        var newLabels = []
        var newBarLabels = []
        var newData = []
        var newBarData = []

        newLabels = response.data.success.dates
        newBarLabels = response.data.success.profiles
        //console.log(this.$global.convertCurrency(this.country, 25000))
        var plannedProfileRevenue = [],
            plannedProfileCosts = [],
            plannedProfileProfit = [],
            approvedProfileRevenue = [],
            approvedProfileCosts = [],
            approvedProfileProfit = [],
            plannedGeneralRevenue = [],
            plannedGeneralCosts = [],
            plannedGeneralProfit = [],
            approvedGeneralRevenue = [],
            approvedGeneralCosts = [],
            approvedGeneralProfit = [];
            
        response.data.success.dates.forEach((item, index) => {
          
          plannedProfileRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_pending_in[index]
            ))
          );

          plannedProfileCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_pending_out[index]
            ))
          );

          plannedProfileProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_pending_profit[index]
            ))
          );

          approvedProfileRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_approved_in[index]
            ))
          );

          approvedProfileCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_approved_out[index]
            ))
          );

          approvedProfileProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_approved_profit[index]
            ))
          );

          plannedGeneralRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_pending_in[index]
            ))
          );

          plannedGeneralCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_pending_out[index]
            ))
          );

          plannedGeneralProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_pending_profit[index]
            ))
          );

          approvedGeneralRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_pending_in[index]
            ))
          );

          approvedGeneralCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_approved_out[index]
            ))
          );

          approvedGeneralProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              response.data.success.stats.fixed_general_approved_profit[index]
            ))
          );

        })
        
        newData.push({
          id: 1,
          label: 'Planned Profile Revenue',
          data: plannedProfileRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(223, 116, 74, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 2,
          label: 'Planned Profile Costs',
          data: plannedProfileCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#dcb2394d',
          ],
          backgroundColor: 'rgba(220, 178, 57, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 3,
          label: 'Planned Profile Profit',
          data: plannedProfileProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(223, 116, 74, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 4,
          label: 'Approved Profile Revenue',
          data: approvedProfileRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#dcb2394d',
          ],
          backgroundColor: 'rgba(220, 178, 57, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 5,
          label: 'Approved Profile Costs',
          data: approvedProfileCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#e24e424d',
          ],
          backgroundColor: 'rgba(226, 78, 66, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 6,
          label: 'Approved Profile Profit',
          data: approvedProfileProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#eb6e804d',
          ],
          backgroundColor: 'rgba(235, 110, 128, 0.3)',
          borderWidth: 1
        })


        newData.push({
          id: 7,
          label: 'Planned General Revenue',
          data: plannedGeneralRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#008f954d',
          ],
          backgroundColor: 'rgba(0, 143, 149, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 8,
          label: 'Planned General Costs',
          data: plannedGeneralCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#94618e4d',
          ],
          backgroundColor: 'rgba(148, 97, 142, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 9,
          label: 'Planned General Profit',
          data: plannedGeneralProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#4484ce4d',
          ],
          backgroundColor: 'rgba(68, 132, 206, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 10,
          label: 'Approved General Revenue',
          data: approvedGeneralRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#6b7a8f4d',
          ],
          backgroundColor: 'rgba(107, 122, 143, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 11,
          label: 'Approved General Costs',
          data: approvedGeneralCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#66ab8c4d',
          ],
          backgroundColor: 'rgba(102, 171, 140, 0.3)',
          borderWidth: 1
        })

        newData.push({
          id: 12,
          label: 'Approved General Profit',
          data: approvedGeneralProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#636b464d',
          ],
          backgroundColor: 'rgba(99, 107, 70, 0.3)',
          borderWidth: 1
        })

        this.chartData['line'] = {
          //Data to be represented on x-axis
          labels: newLabels,
          datasets: newData
        }


        var plannedRevenue = [],
            plannedCosts = [],
            plannedProfit = [],
            approvedRevenue = [],
            approvedCosts = [],
            approvedProfit = [];

        response.data.success.barstats.fixed_pending_in.forEach((item, index) => {
          plannedRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        response.data.success.barstats.fixed_pending_out.forEach((item, index) => {
          plannedCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        response.data.success.barstats.fixed_pending_profit.forEach((item, index) => {
          plannedProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        response.data.success.barstats.fixed_approved_in.forEach((item, index) => {
          approvedRevenue.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        response.data.success.barstats.fixed_approved_out.forEach((item, index) => {
          approvedCosts.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        response.data.success.barstats.fixed_approved_profit.forEach((item, index) => {
          approvedProfit.push(
            parseFloat(this.$global.convertCurrency(
              this.country, 
              item
            ))
          );
        })

        newBarData.push({
          id: 1,
          label: 'Planned Revenue',
          data: plannedRevenue,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(223, 116, 74, 0.3)',
        })

        newBarData.push({
          id: 2,
          label: 'Planned Costs',
          data: plannedCosts,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(223, 116, 74, 0.3)',
        })

        newBarData.push({
          id: 3,
          label: 'Planned Profit',
          data: plannedProfit,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(223, 116, 74, 0.3)',
        })

        newBarData.push({
          id: 4,
          label: 'Approved Revenue',
          data: approvedRevenue,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(220, 178, 57, 0.3)',
        })

        newBarData.push({
          id: 5,
          label: 'Approved Costs',
          data: approvedCosts,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(226, 78, 66, 0.3)',
        })

        newBarData.push({
          id: 6,
          label: 'Approved Profit',
          data: approvedProfit,
          fill: false,
          hidden: false,
          backgroundColor: 'rgba(235, 110, 128, 0.3)',
        })

        newBarData.push({
          id: 7,
          label: 'Planned General Revenue',
          data: plannedGeneralRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(0, 143, 149, 0.3)',
          borderWidth: 1
        })

        newBarData.push({
          id: 8,
          label: 'Planned General Costs',
          data: plannedGeneralCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(148, 97, 142, 0.3)',
          borderWidth: 1
        })

        newBarData.push({
          id: 9,
          label: 'Planned General Profit',
          data: plannedGeneralProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(68, 132, 206, 0.3)',
          borderWidth: 1
        })

        newBarData.push({
          id: 10,
          label: 'Approved General Revenue',
          data: approvedGeneralRevenue,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(107, 122, 143, 0.3)',
          borderWidth: 1
        })

        newBarData.push({
          id: 11,
          label: 'Approved General Costs',
          data: approvedGeneralCosts,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(102, 171, 140, 0.3)',
          borderWidth: 1
        })

        newBarData.push({
          id: 12,
          label: 'Approved General Profit',
          data: approvedGeneralProfit,
          fill: false,
          hidden: false,
          borderColor: [
              '#DF744A',
          ],
          backgroundColor: 'rgba(99, 107, 70, 0.3)',
          borderWidth: 1
        })


        this.chartData['bar'] = {
        //Data to be represented on x-axis
          labels: newBarLabels,
          datasets: newBarData
        }
  
       this.updated = this.updated+1
       this.loading = false

      var self = this
      setTimeout(function() {
        if(self.currentfilter != 0){
         self.pickFilter(self.currentfilter)
        }
       }, 1000);
      }
      this.getPivot()
    },
    getStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    getPivot() {

      let formData = new FormData();

      if(this.brand_array.length > 0) {
        this.brand_array.forEach( item  => {
          this.brands.push(item.value.brand_id)
        });
        this.brands = JSON.stringify(this.brands)
        formData.append('brands', this.brands);
      }
      if(this.profiles_array.length > 0) {
        this.showBarChart = true
        this.profiles_array.forEach( item  => {
          this.profiles.push(item.value.profile_id)
        });
        this.profiles = JSON.stringify(this.profiles)
        formData.append('profiles', this.profiles);
      } else {
        this.showBarChart = false
      }
      if(this.campaign_array.length > 0) {
        this.campaign_array.forEach( item  => {
          this.campaigns.push(item.value.campaign_id)
        });
        this.campaigns = JSON.stringify(this.campaigns)
        formData.append('campaigns', this.campaigns)
      }

      this.brands = []
      this.campaigns = []
      this.profiles = []

      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.end_date = moment(this.end_date).format('YYYY-MM-DD');

      formData.append('start_date', this.start_date)
      formData.append('end_date', this.end_date)
      formData.append('type', this.pivot_selected)

      this.$http.bamse.post('/finance/getfinancestatspivot', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => this.getPivotSuccess(response))
        .catch(error => this.getPivotFailed(error))
    },
    getPivotSuccess(response) {
      if(response.data.success) {

        var pivot_items = [];

        if(this.pivot_selected == 'date'){
          //Hide/Show correct columns in pivot table
          this.pivot_offset = 1
          var index = _.findIndex(this.fields, {key: "date"});
          this.fields[index].class = null
          var index2 = _.findIndex(this.fields, {key: "campaign"});
          this.fields[index2].class = 'hidden_header'
          var index3 = _.findIndex(this.fields, {key: "brand"});
          this.fields[index3].class = 'hidden_header'
          var index4 = _.findIndex(this.fields, {key: "profile"});
          this.fields[index4].class = 'hidden_header'

          response.data.success.forEach((item, index) => {

            pivot_items.push({
              approved_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_cost
              ),
              approved_general_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_general_cost
              ),
              approved_general_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_general_profit
              ),
              approved_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_general_revenue
              ),
              approved_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_profit
              ),
              approved_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_revenue
              ),
              date: item.date,
              planned_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_cost
              ),
              planned_general_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_general_cost
              ),
              planned_general_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_general_profit
              ),
              planned_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_general_revenue
              ),
              planned_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_profit
              ),
              planned_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_revenue
              )
            });

          })

          this.items = pivot_items

        } else if(this.pivot_selected == 'campaign'){
          this.pivot_offset = 3
          //Hide/Show correct columns in pivot table
          var index = _.findIndex(this.fields, {key: "date"});
          this.fields[index].class = 'hidden_header'
          var index2 = _.findIndex(this.fields, {key: "campaign"});
          this.fields[index2].class = null
          var index3 = _.findIndex(this.fields, {key: "brand"});
          this.fields[index3].class = 'hidden_header'
          var index4 = _.findIndex(this.fields, {key: "profile"});
          this.fields[index4].class = 'hidden_header'

          response.data.success.forEach((item, index) => {

            pivot_items.push({
              approved_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_cost
              ),
              approved_general_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_general_cost
              ),
              approved_general_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_general_profit
              ),
              approved_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_general_revenue
              ),
              approved_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_profit
              ),
              approved_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_revenue
              ),
              campaign: item.campaign,
              planned_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_cost
              ),
              planned_general_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_general_cost
              ),
              planned_general_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_general_profit
              ),
              planned_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_general_revenue
              ),
              planned_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_profit
              ),
              planned_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_revenue
              )
            });

          })

          this.items = pivot_items

        } else if(this.pivot_selected == 'brand'){
          this.pivot_offset = 2
          //Hide/Show correct columns in pivot table
          var index = _.findIndex(this.fields, {key: "date"});
          this.fields[index].class = 'hidden_header'
          var index2 = _.findIndex(this.fields, {key: "campaign"});
          this.fields[index2].class = 'hidden_header'
          var index3 = _.findIndex(this.fields, {key: "brand"});
          this.fields[index3].class = null
          var index4 = _.findIndex(this.fields, {key: "profile"});
          this.fields[index4].class = 'hidden_header'

          response.data.success.forEach((item, index) => {

            pivot_items.push({
              approved_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_cost
              ),
              approved_general_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_general_cost
              ),
              approved_general_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_general_profit
              ),
              approved_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_general_revenue
              ),
              approved_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_profit
              ),
              approved_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_revenue
              ),
              brand: item.brand,
              planned_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_cost
              ),
              planned_general_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_general_cost
              ),
              planned_general_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_general_profit
              ),
              planned_general_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_general_revenue
              ),
              planned_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_profit
              ),
              planned_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_revenue
              )
            });

          })
          this.items = pivot_items

        } else if(this.pivot_selected == 'profile'){
          this.pivot_offset = 0
          //Hide/Show correct columns in pivot table
          var index = _.findIndex(this.fields, {key: "date"});
          this.fields[index].class = 'hidden_header'
          var index2 = _.findIndex(this.fields, {key: "campaign"});
          this.fields[index2].class = 'hidden_header'
          var index3 = _.findIndex(this.fields, {key: "brand"});
          this.fields[index3].class = 'hidden_header'
          var index4 = _.findIndex(this.fields, {key: "profile"});
          this.fields[index4].class = null

          response.data.success.forEach((item, index) => {
           
            pivot_items.push({
              approved_cost: this.$global.convertCurrency(
                this.country, 
                item.approved_cost
              ),
              approved_profit: this.$global.convertCurrency(
                this.country, 
                item.approved_profit
              ),
              approved_revenue: this.$global.convertCurrency(
                this.country, 
                item.approved_revenue
              ),
              planned_cost: this.$global.convertCurrency(
                this.country, 
                item.planned_cost
              ),
              planned_profit: this.$global.convertCurrency(
                this.country, 
                item.planned_profit
              ),
              planned_revenue: this.$global.convertCurrency(
                this.country, 
                item.planned_revenue
              ),
              profile: item.profile
            });

          })
          this.items = pivot_items
        }
        
        //this.items = response.data.success
      }
    },
    getPivotFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error)
    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {
      if(response.data.success) {
        response.data.success.forEach((result, key) => {
            this.profiles_options.push({value: {profile_id: result.id}, text: result.name})
        });
        this.profiles_options = _.orderBy(this.profiles_options, ['text'],['asc']);
      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {
      if(response.data.success) {
        response.data.success.forEach((result, key) => {
            this.brand_options.push({value: {brand_id: result.id}, text: result.company_name})
        });
        this.brand_options = _.orderBy(this.brand_options, ['text'],['asc']);
      }
    },
    getCampaignList() {
      // byt till ny endpoint campaigns/adlink/listall när den är skapad
      this.$http.bamse.get('campaigns/fixed/listallactive')
        .then(response => this.getCampaignListSuccess(response))
        .catch(() => this.getCampaignListFailed())
    },
    getCampaignListSuccess(response) {

      if(response.data.success) {
        response.data.success.forEach((result, key) => {
            this.campaign_options.push({value: {campaign_id: result.id}, text: result.campaign_name+' ('+result.brand_name+')'})
        });
        this.campaign_options = _.orderBy(this.campaign_options, ['text'],['asc']);
      }
    },
    getCampaignListFailed() {
      this.error = 'no fetch sorry'
    },
    filterChart(label) {
 
      if(label == "all"){

        this.currentfilter = 0
        $('#filter1').removeClass('active-filter')
        $('#filter2').removeClass('active-filter')
        $('#filter3').removeClass('active-filter')

        var number = this.chartData['line'].datasets.length
        var numberActive = $(".sortable .active-sort").length
        
        if(numberActive>=number) {
    
          //If all buttons are active, UNMARK all on "Show all" click
          this.fields.forEach(item => {
            if(item.key != this.pivot_selected) {
              item.class = 'hidden_header'
            }
          });

          this.chartData['line'].datasets.forEach((dataset) => {
            
            count = count+1
            dataset.hidden = true
            $(".sortable .active-sort").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
            $(".sortable .active-sort").removeClass('active-sort')

          });

          this.chartData['bar'].datasets.forEach((dataset) => {
            
            count = count+1
            dataset.hidden = true
            $(".sortable .active-sort").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
            $(".sortable .active-sort").removeClass('active-sort')

          });

        } else {


          this.fields.forEach(item => {
            if(this.pivot_selected == 'profile') {
              if(item.key == 'brand' || item.key == 'campaign' || item.key == 'date') {
                item.class = 'hidden_header'
              } else {
                item.class = null
              }
            } else if (this.pivot_selected == 'brand') {
              if(item.key == 'profile' || item.key == 'campaign' || item.key == 'date') {
                item.class = 'hidden_header'
              } else {
                item.class = null
              }
            } else if (this.pivot_selected == 'campaign') {
              if(item.key == 'brand' || item.key == 'profile' || item.key == 'date') {
                item.class = 'hidden_header'
              } else {
                item.class = null
              }
            } else if (this.pivot_selected == 'date') {
              if(item.key == 'brand' || item.key == 'campaign' || item.key == 'profile') {
                item.class = 'hidden_header'
              } else {
                item.class = null
              }
            }
          });         

          var count = 0

          this.chartData['line'].datasets.forEach((dataset) => {
            
            count = count+1
            dataset.hidden = false
            $("#"+count).addClass('active-sort')
            $("#"+dataset.id).attr('style', 'background-color:'+dataset.backgroundColor+'; border: 1px solid '+dataset.backgroundColor)

          });

          this.chartData['bar'].datasets.forEach((dataset) => {
            
            count = count+1
            dataset.hidden = false
            $("#"+count).addClass('active-sort')
            $("#"+dataset.id).attr('style', 'background-color:'+dataset.backgroundColor+'; border: 1px solid '+dataset.backgroundColor)

          });

        }
          this.updated = this.updated+1
      } else {
        var index = _.findIndex(this.chartData['line'].datasets, {label: label});
        this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
        this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden

        this.updated = this.updated+1
        
        if(this.chartData['line'].datasets[index].hidden == true){
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
        } else {
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)
        }
        
        var index2 = _.findIndex(this.fields, {label: label});
        if(this.fields[index2].class == null){
          this.fields[index2].class = 'hidden_header'
        } else {
          this.fields[index2].class = null
        }

      }

      var number = this.chartData['line'].datasets.length
      var count = 0
      this.chartData['line'].datasets.forEach(function(dataset) {
        if(dataset.hidden == true){
          $("#show-all").removeClass('active-sort')
          $("#show-all").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
        } else {
          count = count+1
        }
      });

      if(count == number){
        
        $("#show-all").addClass('active-sort')
        $("#show-all").attr('style', 'background-color: #000; border: 1px solid #000; color: #fff;')
      }
    },
    pickFilter(id){


      this.chartData['line'].datasets.forEach((item) => {
          var id = item.id
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = false
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)
       
      })

      if(id == 1){

        this.currentfilter = 1
        $('#filter1').addClass('active-filter')
        $('#filter2').removeClass('active-filter')
        $('#filter3').removeClass('active-filter')

        var filterList = [9,10,11,12,13,14,15,16,17,18,19]
        
        for (let index = 0; index <= this.chartData['line'].datasets.length; index++) {
          this.fields[index+this.pivot_offset].class = null
        }

        filterList.forEach((item) => {
          this.fields[item+3].class = 'hidden_header'
        });

        this.hidePivotHeaders()

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')       
        });
        
        this.updated = this.updated+1

      } else if(id == 2){

        this.currentfilter = 2
        $('#filter1').removeClass('active-filter')
        $('#filter2').addClass('active-filter')
        $('#filter3').removeClass('active-filter')

        var filterList = [3,4,7,8,13,14,15,16,17,18,19]

        for (let index = 0; index <= this.chartData['line'].datasets.length; index++) {
          this.fields[index+this.pivot_offset].class = null
        }

        filterList.forEach((item) => {
          this.fields[item+3].class = 'hidden_header'
        });

        this.hidePivotHeaders()

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

        });

        this.updated = this.updated+1
        
      } else if(id == 3){

        this.currentfilter = 3
        $('#filter1').removeClass('active-filter')
        $('#filter2').removeClass('active-filter')
        $('#filter3').addClass('active-filter')

        var filterList = [1,2,3,8,9,10,12]

        for (let index = 0; index <= this.chartData['line'].datasets.length; index++) {
          this.fields[index+this.pivot_offset].class = null
        }

        filterList.forEach((item) => {
          this.fields[item+3].class = 'hidden_header'
        });

        this.hidePivotHeaders()

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          
        });

        this.updated = this.updated+1

      } 
    },
    hidePivotHeaders(){
    this.fields.forEach(item => {
      if(this.pivot_selected == 'profile') {
        if(item.key == 'brand' || item.key == 'campaign' || item.key == 'date') {
          item.class = 'hidden_header'
        }
      } else if (this.pivot_selected == 'brand') {
        if(item.key == 'profile' || item.key == 'campaign' || item.key == 'date') {
          item.class = 'hidden_header'
        }
      } else if (this.pivot_selected == 'campaign') {
        if(item.key == 'brand' || item.key == 'profile' || item.key == 'date') {
          item.class = 'hidden_header'
        }
      } else if (this.pivot_selected == 'date') {
        if(item.key == 'brand' || item.key == 'campaign' || item.key == 'profile') {
          item.class = 'hidden_header'
        }
      }
    });
  },
  },
};
</script>
