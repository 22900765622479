<template>
  <div>
    <b-row>
      <b-col cols="12" class="header">
        <h1>Create micro campaign</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <ValidationObserver v-slot="{ handleSubmit, failed }">
    <b-form @submit.prevent="handleSubmit(createMicroCampaign)">
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="outline-secondary">Campaign information</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-input name="campaign_name" v-model="campaign_name"></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Brand" label-for="brand_id">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="brand_id" v-model="brand_id" :options="options_brand"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Sales person" label-for="sales_person">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="sales_person" v-model="sales_person" :options="options_sales_person"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Project Manager" label-for="project_manager">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="project_manager" v-model="project_manager" :options="options_project_manager"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Description" label-for="description">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-textarea
                      id="description"
                      v-model="description"
                      placeholder="Describe the campaign"
                      rows="8"
                    ></b-form-textarea>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="start_date"
                      name="start_date"
                    ></datepicker>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                       format="dd-MM-yyyy"
                      v-model="end_date"
                      name="end_date"
                    ></datepicker>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-file
                    v-model="file"
                    placeholder="Choose a picture..."
                    drop-placeholder="Drop picture here..."
                    accept=".jpg, .png"
                    name="imageupload"
                  ></b-form-file>
                  <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="draft_req_form">
                  <b-form-checkbox
                    id="draft_required"
                    v-model="draft_required"
                    name="draft_required"
                    value="1"
                    unchecked-value="0"
                  >
                    Is a draft required?
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Channels" label-for="channels">
                    <div @click="addChannel(1)" class="channel-disabled" v-bind:class="{ channelactive: instagram }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/>
                    </div>
                    <div @click="addChannel(2)" class="channel-disabled" v-bind:class="{ channelactive: facebook }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/>
                    </div>
                    <div @click="addChannel(3)" class="channel-disabled" v-bind:class="{ channelactive: blog }">
                      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'home' }"/>
                    </div>
                    <div @click="addChannel(4)" class="channel-disabled" v-bind:class="{ channelactive: youtube }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-1.accordion-2 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-2 variant="outline-secondary">Budget</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Campaign Budget" label-for="price">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <b-input
                      type="number"
                      name="price"
                      v-model="price"
                      @input="getCubePercentage"
                    ></b-input>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Cube" label-for="revenue_cube">
                    <validation-provider rules="required" v-slot="{ errors }"><b-input-group :append="cube_percentage" class="mb-2 mr-sm-2 mb-sm-0">

                      <b-input
                        type="number"
                        name="revenue_cube"
                        v-model="revenue_cube"
                        @input="getCubePercentage"
                      ></b-input>
                      </b-input-group>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Profile" label-for="revenue_profile">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <b-input-group :append="profile_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        type="number"
                        name="revenue_profile"
                        v-model="revenue_profile"
                        @input="getProfilePercentage"
                      ></b-input>
                    </b-input-group>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Price per post" label-for="price_per_post">
                    <b-input name="price_per_post" v-model="price_per_post"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-2.accordion-3 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-3 variant="outline-secondary">Profiles/Creators</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row class="listprofilesbig">

                <b-col cols="12" class="margin-btn">
                   <b-button
                    v-if="showButton"
                    @click="addProfileToCampaign()"
                    size="sm"
                    variant="success"
                    class="card-header-btns"
                  >
                    Add to campaign
                  </b-button>

                  <b-button
                    v-if="showButton"
                    @click="removeProfileFromCampaign()"
                    size="sm"
                    variant="danger"
                    class="card-header-btns"
                  >
                    Remove from campaign
                  </b-button>
                </b-col>

                  <b-col class="table_filter" cols="12">
                    <b-input-group>
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col cols="12">
                  <b-table
                    striped
                    selectable
                    hover
                    responsive
                    :items="items"
                    :fields="fields"
                    :filter="filter"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                    select-mode="range"
                    selectedVariant="success"
                    ref="profiles"
                  >
                    <template v-slot:cell(type)="row">
                      Profile
                    </template>
                    <template v-slot:cell(activated)="row">
                        {{row.selected}}
                        <font-awesome-icon icon="badge-check" v-if="checkIfAdded(row.item.id)" />
                      </template>
                    </b-table>

                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-3 @click="showSubmit = true" variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-button class="btn-margin" type="submit" v-if="failed == true && loading == false" block disabled variant="outline-danger">Please fill out all fields to create a campaign</b-button>
      <b-button class="btn-margin" type="submit" v-if="loading == false && failed != true" block variant="outline-success">Create campaign</b-button>
      <b-button class="btn-margin" type="submit" v-if="loading == true" block variant="outline-success"><b-spinner small ></b-spinner> Loading</b-button>
    </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import FileUpload from '@/components/general/FileUpload.vue'
import ListProfiles from "@/components/cube/ListProfiles.vue";
import $ from 'jquery'

import { ValidationProvider, extend } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

let brand_list = [];
let profile_list = [];
let employee_list = [];

export default {
  name: "CreateMicroCampaign",
  components: {
    Datepicker,
    ListProfiles,
    FileUpload,
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      filter: null,
      showButton: false,
      loading: false,
      items: [],
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'activated',
          label: false,
          sortable: true
        }
      ],
      selected_profiles: [],
      activated_profiles: [],
      showSubmit: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Create Micro Campaign',
          href: '#',
          active: true
        },
      ],
      channels_array: [],
      cube_percentage: '0%',
      profile_percentage: '0%',
      sales_person: '',
      project_manager: '',
      campaign_name: '',
      micro_profiles_from_listview: '',
      brand_id: null,
      description: '',
      draft_required: 0,
      profiles: [],
      profiles_array: [],
      channels: [],
      revenue_cube: 0,
      revenue_profile: 0,
      price_per_post: 0,
      start_date: null,
      end_date: null,
      price: '',
      file: null,
      options_project_manager: [],
      options_sales_person: [],
      options_brand: [
        { value: null, text: 'Please select an option' },
      ],
      options_profiles: profile_list,
      options_channels: [
        { value: 1, text: 'Instagram' },
        { value: 2, text: 'Facebook' },
        { value: 3, text: 'Blog' },
        { value: 4, text: 'Youtube'}
      ],
      instagram: false,
      facebook: false,
      blog: false,
      youtube: false,
      options_payment_conditions: [
        { value: null, text: 'Please select an option' },
        { value: 10, text: '10 days' },
        { value: 15, text: '15 days' },
        { value: 20, text: '20 days' },
        { value: 30, text: '30 days'}
      ],
      selected_profiles_micro: [],
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  created () {
    this.getBrandList()
    this.getProfileList()
    this.getcubeEmployees()
  },
  methods: {
    addProfileToCampaign() {

      for(var i = 1; i <= this.selected_profiles.length; i++){
        this.activated_profiles.push(this.selected_profiles[i-1].id);
      }

      var uniq = [...new Set(this.activated_profiles)];

      this.activated_profiles = uniq;

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles.length; i++){
        id_array.push({id: this.activated_profiles[i-1]});
      }

      this.profiles_array = id_array

      this.$refs.profiles.clearSelected()

    },
    removeProfileFromCampaign() {

      var active = this.activated_profiles;

      this.selected_profiles.forEach(function(element) {
        var index = active.indexOf(element.id);
        if (index > -1) {
          active.splice(index, 1);
        }
      });

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles.length; i++){
        id_array.push({id: this.activated_profiles[i-1]});
      }

      this.profiles_array = id_array

      this.$refs.profiles.clearSelected()
    },
    checkIfAdded(id) {
      var check = this.activated_profiles.includes(id);
      if(check == true){
        return true
      } else {
        return false
      }
    },
    rowSelected(items) {
      this.selected_profiles = items
      if (this.selected_profiles && this.selected_profiles.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addChannel(id) {
      if(id == 1) {
        this.instagram = !this.instagram
      }
      if(id == 2) {
        this.facebook = !this.facebook
      }
      if(id == 3) {
        this.blog = !this.blog
      }
      if(id == 4) {
        this.youtube = !this.youtube
      }
      this.channels_array = _.uniqBy(this.channels_array, 'value')
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {

      if(response.data.success) {
        brand_list = []
        var brand_options_list = this.options_brand
        response.data.success.forEach(function (result, key) {
          brand_options_list.push({value: result.id, text: result.company_name})
        });
      }
    },
    getBrandListFailed() {
      this.error = 'no fetch sorry'
    },
    getcubeEmployees() {
      this.$http.bamse.get('/user/listcube')
        .then(response => this.getcubeEmployeesSuccess(response))
        .catch(() => this.getcubeEmployeesFailed())
    },
    getcubeEmployeesSuccess(response) {

      if(response.data.success) {
        employee_list = []
        response.data.success.forEach(function (result, key) {
          var name = result.name + " " + result.last_name
          employee_list.push({value: result.id, text: name})
        });

        this.options_sales_person = employee_list
        this.options_project_manager = employee_list
      }
    },
    getcubeEmployeesFailed() {
      this.error = 'no fetch sorry'
    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {

      if(response.data.success) {
        this.items = response.data.success

        var i;
        var myProfiles = [];

        for(i = 1; i <= response.data.success.length; i++){
          var check = this.profile_assets.find(x => x.profile_id === response.data.success[i-1].id);
          if(check != undefined){
            myProfiles.push(response.data.success[i-1]);
          } else {
          }
        }

        this.items = myProfiles
      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    createMicroCampaign() {

      this.loading = true

      var profiles = JSON.stringify(this.activated_profiles)

      let formData = new FormData();

      if(this.instagram == true) {
        this.channels_array.push({value: 1, text: "Instagram"});
      }
      if(this.facebook == true) {
        this.channels_array.push({value: 2, text: "Facebook"});
      }
      if(this.blog == true) {
        this.channels_array.push({value: 3, text: "Blog"});
      }
      if(this.youtube == true) {
        this.channels_array.push({value: 4, text: "Youtube"});
      }
      this.channels_array = _.uniqBy(this.channels_array, 'value')
      this.channels_array = JSON.stringify(this.channels_array)

      this.start_date = JSON.stringify(this.start_date)
      this.end_date = JSON.stringify(this.end_date)

      formData.append('campaign_name', this.campaign_name);
      formData.append('profiles', profiles);
      formData.append('brand_id', this.brand_id);
      formData.append('project_manager', this.project_manager);
      formData.append('draft_required', this.draft_required);
      formData.append('sales_person', this.sales_person);
      formData.append('description', this.description);
      formData.append('price_per_post', this.price_per_post);
      formData.append('channels', this.channels_array);
      formData.append('campaign_budget', this.price);
      formData.append('revenue_cube', this.revenue_cube);
      formData.append('revenue_profiles', this.revenue_profile);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      formData.append('created_by', this.current_id);
      formData.append('file', this.file);

      this.$http.bamse.post('campaigns/micro/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.createMicroCampaignSuccess(response))
      .catch(error => this.createMicroCampaignFailed(error))
    },
    createMicroCampaignSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Micro campaign created', 'success')
        setTimeout( () => this.$router.push({ path: '/list-microcampaigns?type=active' }), 1500);
      }
    },
    createMicroCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
    },
    getCubePercentage() {
      this.cube_percentage = ""+Math.round(this.revenue_cube / this.price * 100)+"%"
    },
    getProfilePercentage() {
      this.profile_percentage = ""+Math.round(this.revenue_profile / this.price * 100)+"%"
    }
  },
};
</script>