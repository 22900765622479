<template>
  <div class="list-users">



    <div v-if="list_type == 'profiles'">
      <b-row>
        <b-col cols="12" class="header">
          <h1>Your profiles</h1>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <ListProfiles view="listprofiles" />
    </div>

    <div v-else-if="list_type == 'brands'">
      <b-row>
      <b-col cols="12" class="header">
        <h1>Your brands</h1>
        <b-breadcrumb :items="breadcrumbsbrand"></b-breadcrumb>
      </b-col>
    </b-row>
      <ListBrands view="listbrands" />
    </div>
    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>
import ListProfiles from "@/components/cube/ListProfiles.vue";
import ListBrands from "@/components/cube/ListBrands.vue";

export default {
  name: "listassets",
  query: ['type'],
  components: {
    ListProfiles,
    ListBrands
  },
  data() {
    return {
      list_type: this.$route.query.type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Profiles',
          href: '#',
          active: true
        }
      ],
      breadcrumbsbrand: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Brand',
          href: '#',
          active: true
        }
      ],
    }
  },
};
</script>