<template>
  <div class="all-users">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Adlinks Overview</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
      <b-col class="totaltotal">
          <span class="total_amount"><b>Total Pending:</b> {{
          this.$global.convertCurrency(country, this.totalnew)}}</span>
          <span class="total_amount"><b>Total OK:</b> {{
          this.$global.convertCurrency(country, this.totalok)}}</span>
          <span class="total_amount"><b>Total Rejected:</b> {{
          this.$global.convertCurrency(country, this.totalfail)}}</span>
          <span class="total_amount"><b>Total Approved:</b> {{
          this.$global.convertCurrency(country, this.totalapproved)}}</span>
        </b-col>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        
        <b-table
          striped
          hover
          responsive
          :items="items"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(profile_name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}
          </template>
          <template v-slot:cell(new)="row">
            {{ $global.convertCurrency(country, row.item.new)}}
          </template>
          <template v-slot:cell(ok)="row">
            {{ $global.convertCurrency(country, row.item.ok)}}
          </template>
          <template v-slot:cell(fail)="row">
            {{ $global.convertCurrency(country, row.item.fail)}}
          </template>
          <template v-slot:cell(approved)="row">
            {{ $global.convertCurrency(country, row.item.approved)}}
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import $ from 'jquery'

import { mapState } from 'vuex'
export default {
  name: "adlinkoverview",
  components: {

  },
  created () {
    this.getAdlinkOverview()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        total: 0,
        approved: 0,
        totalnew: 0,
        totalok: 0,
        totalfail: 0,
        totalapproved: 0,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'profile_name',
            sortable: true
          },
          {
            label: 'Total Pending',
            key: 'new',
            sortable: true
          },
          {
            label: 'Total OK',
            key: 'ok',
            sortable: true
          },
          {
            label: 'Total Rejected',
            key: 'fail',
            sortable: true
          },
          {
            label: 'Total Approved',
            key: 'approved',
            sortable: true
          }
        ],
        items: [],
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances',
        },
        {
          text: 'Adlinks Overview',
          href: '#',
          active: true
        }
      ]
      }
    },
    mounted:{
    },
    computed: {
      ...mapState(['country']),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      getProfileImg(id){
        return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      },
      getAdlinkOverview() {
        this.$http.bamse.get('finance/getadlinkoverview')
          .then(response => this.getAdlinkOverviewSuccess(response))
          .catch(() => this.getAdlinkOverviewFailed())
      },
      getAdlinkOverviewSuccess(response) {
        if(response.data.success) {
          //this.items = response.data.success

          response.data.success.forEach(item => {
            if(item.total != 0){
              this.items.push(item)
            }
          });

          this.items.forEach(item => {
            this.totalnew = this.totalnew + Number(item.new)
            this.totalok = this.totalok + Number(item.ok)
            this.totalfail = this.totalfail + Number(item.fail)
            this.totalapproved = this.totalapproved + Number(item.approved)
          });
          
        }
      },
      getAdlinkOverviewFailed() {
        this.error = 'no fetch sorry'
      },
    },
};
</script>
