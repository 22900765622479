<template>
  <div>
    <div v-if="!$route.meta.noMargin" class="margin"></div>
    <div class="footer">
        <b-col cols="12" class="footer-inner">
          <b-row>
            <b-col cols="4" class="text-left">
              <ul>
                <li>
                  <router-link to="/terms">
                    Terms and Conditions
                  </router-link>
                </li>
                <li>
                  <a href="./assets/pdf/privacy_policy.pdf">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="./assets/pdf/cookie_policy.pdf">
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </b-col>
            <b-col cols="4">
              <router-link to="/dashboard">
                <img class="logo" src="@/assets/img/rev-footer.png">
              </router-link>
              <p class="copy">Copyright © {{ getYear() }} - All rights reserved - Revrelations</p>
            </b-col>
            <b-col cols="4" class="text-right">
              <ul>
                <li>
                  Revrelations
                </li>
                <li>
                  Kammakargatan 48
                </li>
                <li>
                  111 60 STOCKHOLM
                </li>
                <li>
                  T: +46 (0)73 500 11 44
                </li>
                <li>
                  contact@revrelations.com
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",
  components: {
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    
  },
  methods: {
   getYear() {
     const date = new Date();
     const year = date.getFullYear();
     return year;
   }
  }
};
</script>
