<template>
<div class="campaigns">
    <b-row>
      <b-col cols="4" v-if="profile_assets[0].tune_id != 0">
        <b-card title="Adlink reports">
          <hr>
          <router-link :to="{ name: 'adlinkgeneralreport' }">
            <b-button variant="outline-primary">General</b-button>
          </router-link>
          <router-link :to="{ name: 'adlinkgeneralreportpivot' }">
            <b-button variant="outline-primary">Table</b-button>
          </router-link>
          <router-link :to="{ name: 'revenuestatusreport' }">
            <b-button variant="outline-primary">Revenue</b-button>
          </router-link>
          <router-link :to="{ name: 'shortlinkreport' }">
            <b-button variant="outline-primary">Shortlink Report</b-button>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    name: "profilecampaigns",
    components: {
    },
    computed: {
      ...mapState(['current_user_id', 'profile_assets']),
    },
  };
</script>