<template>
  <div class="create-fixed">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Create Cube campaign</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>

    <ValidationObserver v-slot="{ handleSubmit, failed }">
    <b-form @submit.prevent="handleSubmit(createFixedCampaign)">
      <div role="tablist">
        <!-- Campaign information -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="outline-secondary">Campaign information</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-input name="campaign_name" v-model="campaign_name"></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Brand" label-for="brand_id">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="brand_id" v-model="brand_id" :options="options_brand"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Sales person" label-for="sales_person">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="sales_person" v-model="sales_person" :options="options_sales_person"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Delivery" label-for="project_manager">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="project_manager" v-model="project_manager" :options="options_project_manager"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Description" label-for="description">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      placeholder="Describe the campaign (for internal use)"
                      rows="8"
                    ></b-form-textarea>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="start_date"
                      name="start_date"
                    ></datepicker>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                       format="dd-MM-yyyy"
                      v-model="end_date"
                      name="end_date"
                    ></datepicker>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cover Image" label-for="imageupload">
                    <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-file
                      v-model="cover_image"
                      placeholder="Upload cover image..."
                      drop-placeholder="Drop cover image here..."
                      accept=".jpg, .png"
                      name="imageupload"
                    ></b-form-file>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Skip briefs" label-for="skip_briefs">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <b-form-select name="skip_briefs" v-model="skip_briefs" :options="options_skip_briefs"></b-form-select>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-1.accordion-2 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Activities -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-2 variant="outline-secondary">Activations</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <!-- Add profiles and types -->
              <b-row>
                <b-col xl="8">
                  <span class="label">Profiles</span>
                  <multiselect
                      :showLabels="false"
                      class="profile-select"
                      placeholder="Pick profiles for this campaign"
                      :clearOnSelect="false"
                      :close-on-select="true"
                      v-model="profiles_picked"
                      :options="options_profiles"
                      label="name"
                      multiple
                      @select="addProfile"
                  >
                  </multiselect>
                </b-col>
                <b-col xl="4" class="addthis">
                  <span class="label">Add this</span>
                  <b-button @click="addProduction" variant="outline-primary">Production</b-button>&nbsp;
                  <b-button @click="addRetainer" variant="outline-primary">Retainer</b-button>&nbsp;
                  <b-button @click="addEvent" variant="outline-primary">Event</b-button>&nbsp;
                  <b-button @click="addFee" variant="outline-primary">Fee</b-button>
                </b-col>
              </b-row>
              <br>
              <div v-for="item in profiles_picked" class="fixed-campaign-post">
                <b-row>
                  <b-col md="6">
                    <img
                      v-if="item.name == 'Production'"
                      fluid
                      class="profile_pic"
                      src="@/assets/img/production.png"
                    ></img>
                    <img
                      v-else-if="item.name == 'Event'"
                      fluid
                      class="profile_pic"
                      src="@/assets/img/event.png"
                    ></img>
                    <img
                      v-else-if="item.name == 'Retainer'"
                      fluid
                      class="profile_pic"
                      src="@/assets/img/retainer.png"
                    ></img>
                    <b-img
                      v-else
                      fluid
                      class="profile_pic"
                      :src="getProfileImg(item.id)"
                      @error="replaceByDefault"
                    ></b-img>
                    <h2>{{ item.name }}</h2>
                  </b-col>
                  <b-col lg="3" class="profilebrief" v-if="item.id > 0">
                      <b-form-group label-size="sm" label="Profile Brief" label-for="imageupload">
                        <b-form-file
                          v-model="posts[getIndex(item.id)].profile_brief"
                          placeholder="Upload profile brief..."
                          drop-placeholder="Drop brief here..."
                          accept=".jpg, .png"
                          name="imageupload"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col lg="3" v-if="item.id > 0">
                      <b-form-group label-size="sm" label="Brand Brief" label-for="imageupload">
                        <b-form-file
                          v-model="posts[getIndex(item.id)].brand_brief"
                          placeholder="Upload brand brief..."
                          drop-placeholder="Drop brief here..."
                          accept=".jpg, .png"
                          name="imageupload"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="item.name == 'Retainer'" v-for="items in posts[getIndex(item.id)].posts" class="fixed-post">
                  <b-row>
                    <b-col cols="12">
                      <font-awesome-icon @click="removePost(item.id, items.post_id)" class="remove-post" :icon="{ prefix: 'fa', iconName: 'times-circle' }"/>
                    </b-col>
                     <b-col md="6">
                      <span class="label">Title</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <b-input
                        name="cost"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].title"
                      ></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Start Date</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                        wrapper-class="form-control"
                        :monday-first="true"
                        format="dd-MM-yyyy"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].start_date"
                        name="date"
                      ></datepicker>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <span class="label">End Date</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                        wrapper-class="form-control"
                        :monday-first="true"
                        format="dd-MM-yyyy"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].end_date"
                        name="date"
                      ></datepicker>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Revenue</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <b-input
                        name="revenue"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].revenue"
                        @change="getTotalRevenue(item.id)"
                      ></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <br>
                      <b-form-group label-size="sm" label="Description" label-for="description">
                        <validation-provider rules="required" v-slot="{ errors }">
                        <b-form-textarea
                          id="description"
                          v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].description"
                          placeholder="Describe this activity"
                          rows="5"
                        ></b-form-textarea>
                        <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>


                <div v-else-if="item.name == 'Production' || item.name == 'Event'" v-for="items in posts[getIndex(item.id)].posts" class="fixed-post">
                  <b-row>
                    <b-col cols="12">
                      <font-awesome-icon @click="removePost(item.id, items.post_id)" class="remove-post" :icon="{ prefix: 'fa', iconName: 'times-circle' }"/>
                    </b-col>
                        <b-col md="6">
                          <span class="label">Title</span>
                          <validation-provider rules="required" v-slot="{ errors }">
                          <b-input
                            name="cost"
                            v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].title"
                          ></b-input>
                          <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                        </b-col>
                        <b-col md="3">
                          <span class="label">Start Date</span>
                          <validation-provider rules="required" v-slot="{ errors }">
                          <datepicker
                            wrapper-class="form-control"
                            :monday-first="true"
                            format="dd-MM-yyyy"
                            v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].start_date"
                            name="date"
                          ></datepicker>
                          <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                        </b-col>
                        <b-col md="3">
                          <span class="label">End Date</span>
                          <validation-provider rules="required" v-slot="{ errors }">
                          <datepicker
                            wrapper-class="form-control"
                            :monday-first="true"
                            format="dd-MM-yyyy"
                            v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].end_date"
                            name="date"
                          ></datepicker>
                          <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <br>
                          <b-form-group label-size="sm" label="Description" label-for="description">
                            <validation-provider rules="required" v-slot="{ errors }">
                            <b-form-textarea
                              id="description"
                              v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].description"
                              placeholder="Describe this activity"
                              rows="5"
                            ></b-form-textarea>
                            <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                          </b-form-group>
                        </b-col>
                  </b-row>
                </div>

                <div v-else-if="item.name == 'Fee'" v-for="items in posts[getIndex(item.id)].posts" class="fixed-post">
                  <b-row>
                    <b-col cols="12">
                      <font-awesome-icon @click="removePost(item.id, items.post_id)" class="remove-post" :icon="{ prefix: 'fa', iconName: 'times-circle' }"/>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Fee</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <b-input
                        name="fee"
                        v-model="posts[getIndex(item.id)].total_revenue"
                      ></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <span class="label">Date</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                        wrapper-class="form-control"
                        :monday-first="true"
                        format="dd-MM-yyyy"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].start_date"
                        name="date"
                      ></datepicker>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                  </b-row>
                </div>

                <div v-if="item.id > 0" v-for="items in posts[getIndex(item.id)].posts" class="fixed-post">
                  <b-row>
                    <b-col cols="12">
                      <font-awesome-icon @click="removePost(item.id, items.post_id)" class="remove-post" :icon="{ prefix: 'fa', iconName: 'times-circle' }"/>
                    </b-col>
                    <b-col md="4">
                      <span class="label">Product</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <multiselect
                        :showLabels="false"
                        placeholder="Pick product"
                        :clearOnSelect="false"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].channel"
                        :options="options_channels"
                      >
                      </multiselect>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col v-if="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].channel == 'Participation'">
                    </b-col>
                    <b-col v-else-if="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].channel == 'Ambassadorship'">
                    </b-col>
                    <b-col v-else-if="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].channel == 'Content Rights'">
                    </b-col>
                    <b-col md="2" v-else>
                      <span class="label">Draft Date</span>
                      <datepicker
                        wrapper-class="form-control"
                        :monday-first="true"
                        format="dd-MM-yyyy"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].draft_date"
                        name="date"
                      ></datepicker>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Publish Date</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <datepicker
                        wrapper-class="form-control"
                        :monday-first="true"
                        format="dd-MM-yyyy"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].publish_date"
                        name="date"
                      ></datepicker>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Revenue</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <b-input
                        name="revenue"
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].revenue"
                        @change="getTotalRevenue(item.id)"
                      ></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <span class="label">Cost</span>
                      <validation-provider rules="required" v-slot="{ errors }">
                      <b-input
                        name="cost"
                        disabled
                        v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].cost"
                      ></b-input>
                      <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <br>
                      <b-form-group label-size="sm" label="Description" label-for="description">
                        <validation-provider rules="required" v-slot="{ errors }">
                        <b-form-textarea
                          id="description"
                          v-model="posts[getIndex(item.id)].posts[getPostIndex(item.id, items.post_id)].description"
                          placeholder="Description for the profile"
                          rows="5"
                        ></b-form-textarea>
                        <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <b-row v-if="item.name != 'Fee'">
                  <b-col cols="12" class="margin-top">
                    <b-button
                      @click="addPost(item.id)"
                      variant="outline-primary"
                      block>
                        Add post to {{ item.name }}
                      </b-button>
                    </b-col>
                </b-row>
                <b-row v-if="item.name != 'Fee'">
                  <b-col cols="12" class="margin-bottom">
                    <hr>
                  </b-col>
                  <b-col md="8">
                    <h5 class="margin-top">Specification for {{ item.name }}</h5>
                  </b-col>
                  <b-col md="2" v-if="item.name != 'Retainer'">
                    <span class="label">Total revenue</span>
                    <b-input v-if="item.name == 'Production' || item.name == 'Event'" name="total-revenue" v-model="posts[getIndex(item.id)].total_revenue"></b-input>
                    <b-input v-else name="total-revenue" disabled v-model="posts[getIndex(item.id)].total_revenue"></b-input>
                  </b-col>
                  <b-col md="2" offset-md="2" v-if="item.name == 'Retainer'">
                    <span class="label">Total revenue</span>
                    <b-input name="total-revenue" disabled v-model="posts[getIndex(item.id)].total_revenue"></b-input>
                  </b-col>
                  <b-col md="2" v-if="item.name != 'Retainer'">
                    <span class="label">Total cost</span>
                    <validation-provider rules="required" v-slot="{ errors }">
                    <b-input name="cost" v-model="posts[getIndex(item.id)].total_cost" @change="updateCost(item.id)"></b-input>
                    <span class="validation">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col md="6" class="margin-top">
                      <h6>
                        Revenue <span class="pull-right">{{
                        $global.showCurrency(country,
                        posts[getIndex(item.id)].total_revenue) }}</span>
                      </h6>
                      <hr>
                      <h6>
                        Cost <span class="pull-right">-{{
                        $global.showCurrency(country,
                        posts[getIndex(item.id)].total_cost) }}</span>
                      </h6>
                      <hr>
                      <h6>
                        <b>Profit</b>
                        <span class="pull-right">
                          <span class="text-right" v-if="Math.round((posts[getIndex(item.id)].total_revenue - posts[getIndex(item.id)].total_cost) / posts[getIndex(item.id)].total_revenue * 100)">{{ Math.round((posts[getIndex(item.id)].total_revenue - posts[getIndex(item.id)].total_cost) / posts[getIndex(item.id)].total_revenue * 100) }} %
                          </span>
                          <b>{{ $global.showCurrency(country,
                          posts[getIndex(item.id)].total_revenue -
                          posts[getIndex(item.id)].total_cost) }}</b>
                        </span>
                      </h6>
                    </b-col>
                    <b-col md="6" class="margin-top">
                      <span v-for="channel in options_channels">
                        <h6 v-if="channelProfit(item.id,channel) > 0">
                          {{ channel }} <span class="pull-right">{{
                          $global.showCurrency(country, channelProfit(item.id.channel))}} </span>
                          <hr>
                        </h6>
                      </span>
                    </b-col>
                </b-row>
              </div>
              <b-row v-if="posts.length != null">
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-2.accordion-3 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Overview -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-4 variant="outline-secondary">Overview</b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <h5 class="margin-top">Specification for {{ campaign_name }}</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="margin-top">
                  <hr>
                  <span v-for="item in profiles_picked">
                    <h6 class="margin-top" v-if="item.name != 'Fee'">
                      <span class="pull-left">
                        {{ item.name }}
                      </span>
                      <span class="text-right"> +{{ $global.showCurrency(country, posts[getIndex(item.id)].total_revenue)}}</span>
                      <span class="text-right"> -{{ $global.showCurrency(country, posts[getIndex(item.id)].total_cost)}}</span>
                      <span class="pull-left">
                        <i>Subtotal:</i>
                      </span>
                      <span class="text-right">
                        <i>{{ Math.round((posts[getIndex(item.id)].total_revenue - posts[getIndex(item.id)].total_cost) / posts[getIndex(item.id)].total_revenue * 100) }}%</i> /
                        <i>{{ $global.showCurrency(country, posts[getIndex(item.id)].total_revenue - posts[getIndex(item.id)].total_cost)}}</i>
                      </span>
                    </h6>
                    <h6 class="margin-top" v-if="item.name == 'Fee'">
                      <span class="pull-left">
                        {{ item.name }}
                      </span>
                      <span class="text-right"> {{ posts[getIndex(item.id)].revenue }}%</span>

                      <span class="pull-left">
                        <i>Subtotal:</i>
                      </span>
                      <span class="text-right">
                        <i>{{ getFee(item.id) }} SEK</i>
                      </span>
                    </h6>
                    <hr>
                  </span>
                  <h6 class="margin-top">
                    <span class="pull-left">
                      Total revenue:
                    </span>
                    <span class="text-right">
                      {{ $global.showCurrency(country,
                      getCampaignTotal('revenue'))}}
                    </span>
                  </h6>
                  <h6 class="margin-top">
                    <span class="pull-left">
                      Total costs:
                    </span>
                    <span class="text-right">
                      {{ $global.showCurrency(country, getCampaignTotal('cost'))}}
                    </span>
                  </h6>
                  <h6 class="margin-top">
                    <span class="pull-left">
                      <b>Total profit:</b>
                    </span>
                    <span class="text-right">
                      <b>{{ getCampaignTotal('percent') }}%</b> /
                      <b>{{ $global.showCurrency(country, getCampaignTotal('profit'))}}</b>
                    </span>
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                  <b-button v-b-toggle.accordion-4 variant="outline-primary" @click="showSubmit = true" class="pull-right margin-top">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-button class="btn-margin" type="submit" v-if="failed == true && loading == false" block disabled variant="outline-danger">Please fill out all fields to create a campaign</b-button>
      <b-button class="btn-margin" type="submit" v-if="loading == false && failed != true" block variant="outline-success">Create campaign</b-button>
      <b-button class="btn-margin" type="submit" v-if="loading == true" block variant="outline-success"><b-spinner small ></b-spinner> Loading</b-button>
    </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import $ from 'jquery'
import Multiselect from 'vue-multiselect'

import { ValidationProvider, extend } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

let brand_list = [];
let profile_list = [];
let employee_list = [];

export default {
  name: "CreateFixedCampaign",
  components: {
    Datepicker,
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      showSubmit: false,
      publicPath: process.env.BASE_URL,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Create Cube Campaign',
          href: '#',
          active: true
        },
      ],
      cube_percentage: '0%',
      profile_percentage: '0%',
      sales_person: '',
      project_manager: '',
      campaign_name: '',
      cover_image: null,
      micro_profiles_from_listview: '',
      brand_id: null,
      description: '',
      retainer_id: 0,
      production_id: 0,
      event_id: 0,
      skip_briefs: 1,
      options_skip_briefs: [
          {value: 1, text: "Yes"},
          {value: 0, text: "No"},
      ],
      fee_id: 0,
      profiles: [],
      loading: false,
      profiles_array: [],
      profiles_picked: [],
      channel_id: null,
      cost: 0,
      date: null,
      number_of_posts: { "value": 5, "text": "5" },
      options_posts: [
          {value: 1, text: "1"},
          {value: 2, text: "2"},
          {value: 3, text: "3"},
          {value: 4, text: "4"},
          {value: 5, text: "5"},
          {value: 6, text: "6"},
      ],
      revenue_cube: 0,
      revenue_profile: 0,
      start_date: null,
      end_date: null,
      price: '',
      file: null,
      options_project_manager: [],
      options_sales_person: [],
      options_brand: [
        { value: null, text: 'Please select an option' },
      ],
      options_channels: [
        'Ambassadorship',
        'Blogg',
        'Content Rights',
        'Instagram',
        'Instagram Stories',
        'Participation',
        'Podcast',
        'Snapchat',
        'TikTok',
        'Youtube'
      ],
      options_profiles: profile_list,
      posts: [

      ],
    }
  },
  computed: {
    ...mapState(['current_id', 'country']),
  },
  created () {
    this.getBrandList()
    this.getProfileList()
    this.getcubeEmployees()
  },
  methods: {
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    replaceByDefault(e){
      var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
      e.target.src = url
    },
    addPost(profile_id){
      this.posts[this.getIndex(profile_id)].number_of_posts = this.posts[this.getIndex(profile_id)].number_of_posts + 1

      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});

      var number = _.last(this.posts[indexProfile].posts)
      var number = number.post_id+1

      this.posts[indexProfile].posts.push({
        post_id: number,
        channel: null,
        draft_date: null,
        publish_date: null,
        cost: 0,
        revenue: 0,
        description: null,
        title: null,
        date: null,
        start_date: null,
        end_date: null,
      })
    },
    channelProfit(profile_id, channel){
      var value = 0
      var cost = 0
      var revenue = 0

      if(channel == 'Blogg'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Blogg') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Instagram'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Instagram') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Instagram Stories'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Instagram Stories') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Youtube'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Youtube') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'TikTok'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'TikTok') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Ambassadorship'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Ambassadorship') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Content Rights'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Content Rights') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Participation'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Participation') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Podcast'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Podcast') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      } else if(channel == 'Snapchat'){
        var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
        this.posts[indexProfile].posts.forEach(item => {
          if(item.channel == 'Snapchat') {
            cost = Number(cost) + Number(item.cost)
            revenue = Number(revenue) + Number(item.revenue)
          }
        })
        value = revenue - cost
      }

      return Math.round(value)
    },
    removePost(profile_id, post_id){
      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
      var indexPost = _.findIndex(this.posts[indexProfile].posts, {'post_id': post_id})

      this.posts[this.getIndex(profile_id)].number_of_posts = this.posts[this.getIndex(profile_id)].number_of_posts - 1

      this.posts[indexProfile].posts.splice(indexPost, 1)

      if(this.posts[this.getIndex(profile_id)].number_of_posts == 0){
        this.profiles_picked.splice(indexProfile, 1)
        this.posts.splice(indexProfile, 1)
      } else {
        this.getTotalRevenue(profile_id)
        this.updateCost(profile_id)
      }
    },
    getIndex(id){
      var index = _.findIndex(this.posts, {'profile_id': id});
      return index
    },
    getPostIndex(profile_id, post_id){
      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
      var indexPost = _.findIndex(this.posts[indexProfile].posts, {'post_id': post_id})
      return indexPost
    },
    getCampaignTotal(type){
      var totalRevenue = 0
      var totalCost = 0
      this.posts.forEach( item => {
        totalRevenue = Number(totalRevenue) + Number(item.total_revenue)
        totalCost = Number(totalCost) + Number(item.total_cost)
      })

      if(type == 'cost'){
        var total = Number(totalCost)
      } else if(type == 'revenue'){
        var total = Number(totalRevenue)
      } else if(type == 'profit'){
        var total = Number(totalRevenue) - Number(totalCost)
      } else if(type == 'percent'){
        var total = Number(totalRevenue - totalCost) / Number(totalRevenue) * 100
      }

      return total
    },
    getFee(profile_id) {
      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
      var fee = this.posts[indexProfile].revenue
      var totalCost = 0
      var totalRevenue = 0
      this.posts[indexProfile].posts.forEach( item => {
        totalRevenue = Number(totalRevenue) + Number(item.revenue)
        totalCost = Number(totalCost) + Number(item.revenue)
      })
      var totalProfit = Number(totalRevenue) - Number(totalCost)
      var value = Number(totalProfit) * Number(fee)
      return Number(value / 100)
    },
    getTotalRevenue(profile_id) {
      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});
      var totalRevenue = 0
      this.posts[indexProfile].posts.forEach( item => {
        totalRevenue = Number(totalRevenue) + Number(item.revenue)
      })
      this.posts[indexProfile].total_revenue = totalRevenue
      this.updateCost(profile_id)
    },
    updateCost(profile_id) {
      var indexProfile = _.findIndex(this.posts, {'profile_id': profile_id});

      this.posts[indexProfile].posts.forEach(item => {
        var totalCost = this.posts[indexProfile].total_cost
        var totalRevenue = this.posts[indexProfile].total_revenue
        var revenue = item.revenue

        var step1 = Number(revenue / totalRevenue)
        var newCost = Number(totalCost * step1)

        item.cost = Math.round(newCost)
      })
    },
    addProfile(profile) {
      this.posts.push({
        profile_id: profile.id,
        number_of_posts: 1,
        total_revenue: 0,
        total_cost: 0,
        profile_brief: null,
        brand_brief: null,
        posts: [{
          post_id: 1,
          channel: null,
          draft_date: null,
          publish_date: null,
          cost: 0,
          revenue: 0,
          description: null,
          title: null,
          date: null,
          start_date: null,
          end_date: null,
        }]
      })
    },
    addProduction() {
      var id = this.production_id
      var id = id + 1
      var newId = "production_"+id
      this.production_id = id

      this.profiles_picked.push({id: newId, name: 'Production'})

      this.posts.push({
        profile_id: newId,
        number_of_posts: 1,
        total_revenue: 0,
        total_cost: 0,
        posts: [{
          post_id: 1,
          cost: 0,
          revenue: 0,
          description: null,
          title: null,
          start_date: null,
          end_date: null,
        }]
      })
    },
    addEvent() {

      var id = this.event_id
      var id = id + 1
      var newId = "event_"+id
      this.event_id = id

      this.profiles_picked.push({id: newId, name: 'Event'})

      this.posts.push({
        profile_id: newId,
        number_of_posts: 1,
        total_revenue: 0,
        total_cost: 0,
        posts: [{
          post_id: 1,
          cost: 0,
          revenue: 0,
          description: null,
          title: null,
          start_date: null,
          end_date: null,
        }]
      })
    },
    addRetainer() {
      var id = this.retainer_id
      var id = id + 1
      var newId = "retainer_"+id
      this.retainer_id = id

      this.profiles_picked.push({id: newId, name: 'Retainer'})

      this.posts.push({
        profile_id: newId,
        number_of_posts: 1,
        total_revenue: 0,
        total_cost: 0,
        posts: [{
          post_id: 1,
          cost: 0,
          revenue: 0,
          description: null,
          title: null,
          start_date: null,
          end_date: null,
        }]
      })
    },
    addFee() {
      var id = this.fee_id
      var id = id + 1
      var newId = "fee_"+id
      this.fee_id = id

      this.profiles_picked.push({id: newId, name: 'Fee'})

      this.posts.push({
        profile_id: newId,
        number_of_posts: 1,
        total_revenue: 0,
        total_cost: 0,
        posts: [{
          post_id: 1,
          cost: 0,
          revenue: 0,
          description: null,
          title: null,
          start_date: null,
          end_date: null,
        }]
      })
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {
      if(response.data.success) {
        brand_list = []
        var brand_options_list = this.options_brand
        response.data.success.forEach(function (result, key) {
          brand_options_list.push({value: result.id, text: result.company_name})
        });

        this.options_brand = _.sortBy(this.options_brand, ['text']);

      }
    },
    getBrandListFailed() {
      this.error = 'no fetch sorry'
    },
    getcubeEmployees() {
      this.$http.bamse.get('/user/listcube')
        .then(response => this.getcubeEmployeesSuccess(response))
        .catch(() => this.getcubeEmployeesFailed())
    },
    getcubeEmployeesSuccess(response) {
      if(response.data.success) {
        employee_list = []
        response.data.success.forEach(function (result, key) {
          var name = result.name + " " + result.last_name
          employee_list.push({value: result.id, text: name})
        });

        this.options_sales_person = employee_list
        this.options_project_manager = employee_list

        this.options_sales_person = _.sortBy(this.options_sales_person, ['text']);
        this.options_project_manager = _.sortBy(this.options_project_manager, ['text']);


      }
    },
    getcubeEmployeesFailed() {
      this.error = 'no fetch sorry'
    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {
     if(response.data.success) {
        profile_list = []
        response.data.success.forEach(function (result, key) {
          profile_list.push({id: result.id, name: result.name, number_of_posts: 2})
        });

        this.options_profiles = profile_list

        this.options_profiles = _.sortBy(this.options_profiles, ['name']);

      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    createFixedCampaign() {

      this.loading = true

      // Loop through this.posts and change to the active currency

      this.$global.convertCurrencyAdd(this.country, this.revenue_cube_cpc)

      var temp_array = this.posts;

      temp_array.forEach((item, index) => {
        item.total_cost = ""+this.$global.convertCurrencyAdd(this.country,
        item.total_cost)+"";
        item.total_revenue = ""+this.$global.convertCurrencyAdd(this.country,
        item.total_revenue)+"";

        item.posts.forEach((item, index) => {
          item.cost = ""+this.$global.convertCurrencyAdd(this.country, item.cost)+"";
          item.revenue = ""+this.$global.convertCurrencyAdd(this.country, item.revenue)+""; 
        })

      })

      var post_array = [];
      post_array = JSON.stringify(temp_array)

      this.start_date = JSON.stringify(this.start_date)
      this.end_date = JSON.stringify(this.end_date)

      let formData = new FormData();

      formData.append('campaign_name', this.campaign_name);
      formData.append('brand_id', this.brand_id);
      formData.append('sales_person', this.sales_person);
      formData.append('project_manager', this.project_manager);
      formData.append('description', this.description);
      formData.append('skip_briefs', this.skip_briefs);
      formData.append('cover_image', this.cover_image);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      formData.append('activities', post_array);
      formData.append('created_by', this.current_id);
      this.posts.forEach(item => {
        formData.append('profile_brief_'+item.profile_id+'', item.profile_brief)
        formData.append('brand_brief_'+item.profile_id+'', item.brand_brief)
      })

      this.$http.bamse.post('campaigns/fixed/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.createFixedCampaignSuccess(response))
      .catch(error => this.createFixedCampaignFailed(error))
      
    },
    createFixedCampaignSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Fixed campaign created', 'success')

         setTimeout( () => this.$router.push({ path: '/list-fixedcampaigns?type=active' }), 1500);
      }
    },
    createFixedCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
    },
  },
};
</script>
