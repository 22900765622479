<template>
  <div>
        <b-row>
    <b-col cols="12" class="header">
      <h1 v-if="id == current_id">My settings</h1>
      <h1 v-else>Edit user</h1>
      <b-breadcrumb v-if="id == current_id" :items="breadcrumbs"></b-breadcrumb>
      <b-breadcrumb v-else :items="breadcrumbs_users"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-tabs active content-class="mt-3">
      <b-tab title="General">
        <ManageUser />
      </b-tab>
      <b-tab title="Connected profiles" v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <ConnectedProfilesForUser />
      </b-tab>
      <b-tab title="Other profiles"  v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <UnConnectedProfilesForUser />
      </b-tab>
      <b-tab title="Connected brands"  v-if="type === 1 && level === 1 || type === 1 && level === 2">
        <ConnectedBrandsForUser />
      </b-tab>
      <b-tab title="Other brands"  v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <UnConnectedBrandsForUser />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ManageUser from "@/components/cube/ManageUser.vue";
import ConnectedProfilesForUser from "@/components/cube/ConnectedProfilesForUser.vue";
import UnConnectedProfilesForUser from "@/components/cube/UnConnectedProfilesForUser.vue";
import ConnectedBrandsForUser from "@/components/cube/ConnectedBrandsForUser.vue";
import UnConnectedBrandsForUser from "@/components/cube/UnConnectedBrandsForUser.vue";

export default {
  name: "managecampaign",
  components: {
    ManageUser,
    ConnectedProfilesForUser,
    UnConnectedProfilesForUser,
    ConnectedBrandsForUser,
    UnConnectedBrandsForUser
  },
    computed: {
    ...mapState(['level', 'current_id', 'profile_assets', 'brand_assets', 'type']),
  },
  created () {

  },
  data: function () {
    return {
      id: this.$route.query.id,
      user_type_name: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings',
        },
        {
          text: 'My settings',
          href: '#',
          active: true
        }
      ],
      breadcrumbs_users: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings',
        },
        {
          text: 'Users',
          to: '/list-users?type=active',
        },
        {
          text: 'Edit user',
          href: '#',
          active: true
        }
      ],
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$http.bamse.get('user/details?user_id='+this.id)
        .then(response => this.getUserInfoSuccess(response))
        .catch(() => this.getUserInfoFailed())
    },
    getUserInfoSuccess(response) {
     if(response.data.success) {
       this.user_type_name = response.data.success[0].user_type_name
     }
    },
    getUserInfoFailed() {
      
    }
  },
};
</script>
