<template>
    <div class="finances">
          <b-row>
      <b-col cols="12" class="header">
        <h1>Withdrawals</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
      <b-tabs content-class="mt-3">
        <b-tab title="Pending Withdrawals" active>
          <div class="manage-campaign">
            <PendingWithdrawals />
          </div>
        </b-tab>
        <b-tab title="Approved Withdrawals">
          <ApprovedWithdrawals />
        </b-tab>
      </b-tabs>
    </div>
</template>

<script>
import PendingWithdrawals from "@/components/finances/PendingWithdrawals.vue";
import ApprovedWithdrawals from "@/components/finances/ApprovedWithdrawals.vue";

export default {
  name: "finances",
  components: {
    PendingWithdrawals,
    ApprovedWithdrawals
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances'
        },
        {
          text: 'Withdrawals',
          href: '#',
          active: true
        }
      ],
    }
  },
};
</script>