<template>
<div class="applications">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Applications</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-card>
    <div>
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" class="pivot-responsive">
          <b-table 
            striped
            hover
            responsive
            :items="items" 
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:cell(created_at)="row">
                {{ row.item.created_at }}
            </template>
            <template v-slot:cell(id)="row">
                <b-button 
                  @click="removeApply(row.item.id)" 
                  size="sm" 
                  variant="danger"
                >
                  Delete
                </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" v-if="noDataFound">
          <center><p>No data found.</p></center>
        </b-col>
      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
export default {
  name: "applications",
  components: {
    
  },
  created () {
    this.getApplications()
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Applications',
          href: '#',
          active: true
        }
      ],
      loading: false,
      filter: null,
      noDataFound: false,
      sortBy: 'created_at',
      sortDesc: true,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'company',
          label: 'Company',
          sortable: true
        },
        {
          key: 'links',
          label: 'Links',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Submitted',
          sortable: true
        },
        {
          key: 'id',
          label: 'Manage',
          sortable: true
        }
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }, 
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getApplications() {
      
      this.loading = true
      this.$http.bamse.get('user/apply/list')
      .then(response => this.getApplicationsSuccess(response))
        .catch(error => this.getApplicationsFailed(error))
    },
    getApplicationsSuccess(response) {
      
      this.noDataFound = false
      if(response.data.success) {
        
        this.items = response.data.success
        this.loading = false

      }

    },
    getApplicationsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    removeApply(id) {

      this.$http.bamse.post('user/apply/delete?id='+id)
      .then(response => this.removeApplySuccess(response, id))
      .catch(error => this.removeApplyFailed(error))

    },
    removeApplySuccess(response, id) {
      if(response.data.success) {
        
        console.log(response.data.success)
        let findIndex = _.findIndex(this.items, ['id', id])
        if(findIndex >= 0) {
          console.log(findIndex)
          this.items.splice(findIndex, 1)
        }

      }
    },
    removeApplyFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
  },
};
</script>
