<template>
  <div class="campaign-info fixed-campaign">
    <b-row>
      <b-col cols="12" class="header">
        <h1>{{ this.campaign_name }}</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-card
        overlay
        :img-src="getCampaignImg(this.id)"
        img-alt="Card Image"
        text-variant="white"
    >
    <b-modal id="reject-brief" hide-footer>
      <template v-slot:modal-title>
        Reject brief
      </template>
      <h6>Reason for rejection</h6>
      <b-form-textarea
        id="comment"
        v-model="comment"
        rows="6"
        max-rows="22"
      ></b-form-textarea>
      <b-button class="margin-top" block variant="outline-primary" @click="rejectBrief(profile_assets[0].profile_id, comment)">
        Reject brief
      </b-button>
    </b-modal>
      <b-row>
        <b-col cols="8" class="description margin-top">
          <h1>{{campaign_name}}</h1>
        </b-col>
        <b-col cols="4" class="fixed-campaign-overview">
          <img :src="getBrandImg(this.brand_id)" class="profile_pic">
        </b-col>
        <b-col cols="3" class="fixed-campaign-overview-details">
          <span class="label">Start</span>
          {{ this.start_date }}
        </b-col>
        <b-col cols="3" class="fixed-campaign-overview-details">
          <span class="label">End</span>
          {{ this.end_date }}
        </b-col>
        <b-col cols="3" class="fixed-campaign-overview-details">
          <span class="label">Revenue</span>
          {{ $global.convertCurrency(country, this.total_revenue)}}
        </b-col>
        <b-col cols="3" v-if="this.skipebriefs == 0" class="fixed-campaign-overview-details">
          <span class="label">Brief</span>
          <a :href="getProfileBrief(this.id)" target="_blank">
            Download
          </a>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="this.approved == 0">
      <b-modal v-model="modalShow" hide-footer>
        <template v-slot:modal-title>
          Please read through the brief
        </template>
        
           <p>You need to read and approve the following brief before continuing with this campaign.</p>
        
        <b-button variant="outline-primary" block @click="modalShow = false">Okay, let's go!</b-button>
      </b-modal>
      <b-col cols="12" class="pdf margin-top">
        <object :data="getProfileBrief(this.id)" type="application/pdf" width="100%" height="100%" hspace="0">
          This browser does not support PDFs.
        </object>
      </b-col>
      <b-col cols="6">
        <b-button class="btn-margin btn-margin-bottom" block type="submit" variant="outline-danger" @click="rejectBriefModal">Reject brief</b-button>
      </b-col>
      <b-col cols="6">
        <b-button class="btn-margin btn-margin-bottom" block type="submit" variant="outline-success" @click="approveBrief">Approve brief</b-button>
      </b-col>
    </b-row>
    <b-row v-else-if="this.approved == 2">
      <b-col cols="12">
        <h5 class="text-center">
        Brief rejected, waiting for new.
        </h5>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-modal id="upload-draft" hide-footer>
        <template v-slot:modal-title>
          Upload draft
        </template>
        <h6>Caption</h6>
        <b-form-textarea
          id="caption"
          v-model="caption"
          rows="6"
          max-rows="22"
        ></b-form-textarea>

        <h6 class="margin-top">Attachments</h6>
        <b-form-file
          v-model="file1"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file1 != null"
          v-model="file2"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file2 != null"
          v-model="file3"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file3 != null"
          v-model="file4"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file4 != null"
          v-model="file5"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file5 != null"
          v-model="file6"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file6 != null"
          v-model="file7"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file7 != null"
          v-model="file8"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file8 != null"
          v-model="file9"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file9 != null"
          v-model="file10"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file10 != null"
          v-model="file11"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file11 != null"
          v-model="file12"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file12 != null"
          v-model="file13"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file13 != null"
          v-model="file14"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file14 != null"
          v-model="file15"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file15 != null"
          v-model="file16"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file16 != null"
          v-model="file17"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file17 != null"
          v-model="file18"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file18 != null"
          v-model="file19"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <b-form-file
          v-if="file19 != null"
          v-model="file20"
          placeholder="Choose your file or drop it here..."
          drop-placeholder="Drop your file here..."
        ></b-form-file>

        <h6 class="margin-top">URL</h6>
        <b-form-input 
          v-model="url"
        >
        </b-form-input>
        
        <b-button class="margin-top" block variant="outline-success" @click="uploadDraft(null, null, null)">
          Submit draft
        </b-button>


      </b-modal>
      <b-col cols="12">
        <b-table 
          striped
          hover
          :items="activities" 
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
        <template v-slot:cell(draft_date)="row">
            <span v-if="row.item.draft_date != null">
              {{row.item.draft_date}}
            </span>
            <span v-else>
              No draft required
            </span>
          </template>
          <template v-slot:cell(status)="row">
            <span v-if="row.item.status == 0">
              <b-button size="sm" variant="outline-primary" @click="openDraftModal(row.item.id)">
                Upload draft
              </b-button>
            </span>
            <span v-else-if="row.item.status == 1">
              Draft in review
            </span>
            <span v-else-if="row.item.status == 2">
              Draft in review
            </span>
            <span v-else-if="row.item.status == 3">
              Ready for delivery
            </span>
            <span v-else-if="row.item.status == 4">
              Delivery approved
            </span>
            <span v-else-if="row.item.status == 5">
              <b-button size="sm" variant="outline-primary" @click="row.toggleDetails">
                Review draft
              </b-button>
            </span>
          </template>
          <template v-slot:row-details="row">
            <b-row>
              <b-col cols="6" class="margin-top">
                <h5>Caption</h5>
                <hr>
                <b-form-textarea
                  id="textarea"
                  v-model="row.item.caption"
                  placeholder="Enter caption..."
                  rows="6"
                ></b-form-textarea>
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5>URL</h5>
                <hr>
                <b-form-input 
                  v-model="row.item.url"
                >
                </b-form-input>
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5 class="margin-top">Attachments</h5>
                <hr class="margin-top">
                <span v-for="(item, index) in drafts" v-if="item.post_id == row.item.id" class="block">
                  <a :href="$global.spacesURL()+item.url" target="_blank">
                    <b-button class="draft-button margin-top" size="sm" variant="outline-primary">
                      <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'paperclip' }"/> {{ getAttachmentName(item.url) }}
                    </b-button>
                  </a>
                </span>
                <hr>
                <b-form-file
                  v-model="file1"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file1 != null"
                  v-model="file2"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file2 != null"
                  v-model="file3"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file3 != null"
                  v-model="file4"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file4 != null"
                  v-model="file5"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file5 != null"
                  v-model="file6"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file6 != null"
                  v-model="file7"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file7 != null"
                  v-model="file8"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file8 != null"
                  v-model="file9"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file9 != null"
                  v-model="file10"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file10 != null"
                  v-model="file11"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file11 != null"
                  v-model="file12"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file12 != null"
                  v-model="file13"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file13 != null"
                  v-model="file14"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file14 != null"
                  v-model="file15"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file15 != null"
                  v-model="file16"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file16 != null"
                  v-model="file17"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file17 != null"
                  v-model="file18"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file18 != null"
                  v-model="file19"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>

                <b-form-file
                  v-if="file19 != null"
                  v-model="file20"
                  placeholder="Choose your file or drop it here..."
                  drop-placeholder="Drop your file here..."
                ></b-form-file>
              </b-col>
              <b-col cols="6" class="margin-top">
                <h5 class="margin-top">Comment</h5>
                <hr class="margin-top">
                   {{ row.item.comment }}
                </span>
              </b-col>
            </b-row>
            <b-row class="draft">
              <b-col cols="12">
                <hr>
                <b-button class="margin-top" variant="outline-success" block @click="uploadDraft(row.item.id, row.item.url, row.item.caption)">
                  Submit new draft
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

import $ from 'jquery'

export default {
  name: "ProfileFixedCampaign",
  components: {
    Datepicker,
  },
  computed: {
    ...mapState(['profile_assets', 'current_id', 'country']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  data: function () {
    return {
      id: this.$route.query.id,
      campaign_name: null,
      description: null,
      start_date: null,
      end_date: null,
      brand_id: null,
      approved: null,
      post_id: null,
      drafts: [],
      total_cost_count: 0,
      general_cost_count: 0,
      skip_briefs: null,
      url: null,
      caption: null,
      comment: null,
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
      file6: null,
      file7: null,
      file8: null,
      file9: null,
      file10: null,
      file11: null,
      file12: null,
      file13: null,
      file14: null,
      file15: null,
      file16: null,
      file17: null,
      file18: null,
      file19: null,
      file20: null,
      total_revenue: 0,
      modalShow: false,
      activities: null,
      filter: null,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Fixed Campaigns',
          to: '/active-fixedcampaigns'
        },
        {
          text: 'Manage campaign',
          active: true
        }
      ],
      fields: [
        {
          key: 'activity',
          label: 'Activity',
          sortable: true
        },
        {
          key: 'draft_date',
          label: 'Draft Date',
          sortable: true
        },
        {
          key: 'publish_date',
          label: 'Publish Date',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        /*{
          key: 'description',
          Label: 'Description'
        },*/
      ],
    }
  },
  created () {
    this.getCampaign()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCampaignImg(id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/cover_image/campaign_' + id + '.png'
    },
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    getProfileBrief(id){
      return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/briefs/profile_' + this.profile_assets[0].profile_id + '.pdf'
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id+'&profile_id='+this.profile_assets[0].profile_id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {

        //Campaign details response in [0][0]
        this.campaign_name = response.data.success[0][0].campaign_name
        this.start_date = response.data.success[0][0].start_date
        this.end_date = response.data.success[0][0].end_date
        this.brand_id = response.data.success[0][0].brand_id
        this.approved = response.data.success[0][0].approved_by_profile
        this.skip_briefs = response.data.success[0][0].skip_briefs

        if(this.approved == 0) {
          this.modalShow = true
        }

        //First profile activities in [1]
        this.activities = response.data.success[1]

        this.activities.forEach(activity => {

          if(this.total_cost_count == 0){
            this.total_revenue = this.total_revenue + activity.cost
          }
           if(activity.status == 5){
            this.getDrafts(this.profile_assets[0].profile_id, activity.id)
          }
          
        });
        this.total_cost_count = 1
      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    approveBrief() {
        this.$http.bamse.post('campaigns/fixed/approvebrief?campaign_id='+this.id+'&profile_id='+this.profile_assets[0].profile_id)
        .then(response => this.approveBriefSuccess(response))
        .catch(() => this.approveBriefFailed())
    },
    approveBriefSuccess(response) {
      if(response.data.success) {
        this.approved = true
      }
    },
    approveBriefFailed() {
      this.error = 'no fetch sorry'
    },
    openDraftModal(post_id) {
      this.post_id = post_id

      // Empty draft
      this.caption = null
      this.file1 = null
      this.file2 = null
      this.file3 = null
      this.file4 = null
      this.file5 = null
      this.file6 = null
      this.file7 = null
      this.file8 = null
      this.file9 = null
      this.file10 = null
      this.file11 = null
      this.file12 = null
      this.file13 = null
      this.file14 = null
      this.file15 = null
      this.file16 = null
      this.file17 = null
      this.file18 = null
      this.file19 = null
      this.file20 = null
      this.url = null
      this.$bvModal.show('upload-draft')
    },
    uploadDraft(post_id, url, caption) {

      console.log(post_id)

      let formData = new FormData();

      if(post_id != null) {
        formData.append('campaign_id', this.id);
        formData.append('post_id', post_id)
        formData.append('profile_id', this.profile_assets[0].profile_id);
        formData.append('url', url);
        formData.append('caption', caption);
      } else {
        formData.append('campaign_id', this.id);
        formData.append('post_id', this.post_id)
        formData.append('profile_id', this.profile_assets[0].profile_id);
        formData.append('url', this.url);
        formData.append('caption', this.caption);
      }

      var files = 21;
      for(var i=1; i < files; i++){
        if(eval("this.file"+i) != null){
          formData.append('file'+i, eval("this.file"+i))
        }
      }

      this.$http.bamse.post('campaigns/fixed/uploaddraft', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response => this.uploadDraftSuccess(response))
       .catch(() => this.uploadDraftFailed())
    },
    uploadDraftSuccess(response) {
      if(response.data.success) {
        this.$bvModal.hide('upload-draft')
        this.getCampaign()
      }
    },
    uploadDraftFailed() {
      this.error = 'no fetch sorry'
    },
    getDrafts(profile_id, post_id) {
        this.$http.bamse.get('campaigns/fixed/getdraft?campaign_id='+this.id+'&profile_id='+profile_id+'&post_id='+post_id)
        .then(response => this.getDraftsSuccess(response, post_id))
        .catch(() => this.getDraftsFailed())
    },
    getDraftsSuccess(response, post_id) {
      if(response.data.success) {
        response.data.success.forEach((item) => {
          var url = item.replace("dev/", "")
          this.drafts.push({post_id: post_id, url: url})
        })
        
      }
    },
    getDraftsFailed() {
      this.error = 'no fetch sorry'
    },
    getAttachmentName(attachment) {
     return attachment.substring(attachment.lastIndexOf("/") + 1);
    },
    rejectBriefModal(){
      this.comment = ''
      this.$bvModal.show('reject-brief')
    },
    rejectBrief(profile_id, comment) {
      this.$http.bamse.post('campaigns/fixed/rejectbrief?campaign_id='+this.id+'&profile_id='+profile_id+'&comment='+comment)
      .then(response => this.rejectBriefSuccess(response, profile_id))
      .catch(() => this.rejectBriefFailed())
    },
    rejectBriefSuccess(response) {
      if(response.data.success) {
        this.$bvModal.hide('reject-brief')
        this.getCampaign()
      }
    },
    rejectBriefFailed() {
      this.error = 'no fetch sorry'
    },
  },
};
</script>
