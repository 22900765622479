<template>
  <b-card>
    <div slot="header">
        Suspended users
    </div>
    <div class="all-users">
      <div v-if="success">
          <Alerts header="Yay!" message="You did it!" type="success" />
        </div>
        <div v-if="error">
          <Alerts header="Oops!" message="Something went wrong" type="danger" />
        </div>
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table 
            striped
            hover 
            responsive 
            :items="items" 
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
          <template v-slot:cell(name)="row">
                <b-img 
                  class="table-img"
                  fluid 
                  :src="getProfileImg(row.item.id)" 
                  @error="replaceByDefault"
                ></b-img>
                {{ row.item.name }} {{ row.item.last_name }}
              </template>
            <template v-slot:cell(id)="row">
              <router-link :to="{ name: 'manageuser', query: { id: row.value }}">
                <b-button size="sm" variant="outline-primary">Edit User</b-button>
              </router-link>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import Alerts from "@/components/general/Alerts.vue";

export default {
  name: "activeusers",
  components: {
  },
  created () {
    this.listUsers()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'user_type_name',
            label: 'User Type',
            sortable: true,
          },
          {
            key: 'id',
            label: 'Edit'
          }
        ],
        items: []
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      getProfileImg(id){
        return this.$global.spacesURL()+'users/user_' + id + '/profile_image/user_' + id + '.png'
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
      listUsers() {
        this.$http.bamse.get('user/listsuspended')
          .then(response => this.listUsersInfoSuccess(response))
          .catch(() => this.listUsersInfoFailed())
      },
      listUsersInfoSuccess(response) {
      if(response.data.success) {
        console.log(response.data.success)

        this.items = response.data.success
        
      }
      },
      listUsersInfoFailed() {
        this.error = 'no fetch sorry'
      },
  },
};
</script>
