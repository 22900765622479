<template>
<div class="activefixedcampaigns">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Active Cube Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-row class="margin-bottom">
      <b-col cols="12" class="margin-bottom sortable">
        <b-button @click="toggleSortables('1')" id="sort-1" class="active-sort" variant="outline-primary">Brand</b-button>

        <b-button @click="toggleSortables('2')" id="sort-2" variant="outline-primary">Newest</b-button>

        <b-button @click="toggleSortables('3')" id="sort-3" variant="outline-primary">Start Date</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4" lg="4" md="6" v-for="item in orderedItems(items)" v-bind:key="item.id">
        <b-card
          overlay
          :img-src="getCampaignImg(item.id)"
          @error="replaceByDefault"
          text-variant="white"
        >
          <b-row>
            <b-col cols="12" class="text-center">
              <h3>{{ item.campaign_name }}</h3>
              <b-img
                class="campaign-img"
                :src="getBrandImg(item.brand_id)"
                fluid
              ></b-img>
              <h5>{{ item.company_name }}</h5>
            </b-col>
          </b-row>
          <b-row class="foot">
            <b-col cols="4">
              <span class="label">Approved</span>
              <span v-if="item.approved_by_profile == 0">No</span>
              <span v-else>Yes</span>
            </b-col>
            <b-col cols="4" class="text-center">
              <span class="label">Start Date</span>
              <span>{{item.start_date}}</span>
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="label_right">Revenue</span>
              {{ $global.convertCurrency(country, item.total_revenue_profile)}}
            </b-col>
            <b-col cols="12" class="margin-top">
              <router-link :to="{ name: 'profilefixedcampaign', query: { id: item.id }}">
                <b-button :id="item.id" block variant="outline-light">View campaign</b-button>
              </router-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'


  export default {
    name: "availablefixedcampaigns",
    components: {
    },
    computed: {
      ...mapState(['profile_assets', 'current_id', 'profile_id', 'country']),
    },
    data: function () {
      return {
        items: [],
        show: false,
        currentOrder: 'company_name',
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Cube Campaigns',
          active: true
        },
      ],
      }
    },
    created () {
      this.listCampaigns()
    },
    methods: {
      getCampaignImg(id){
        return this.$global.spacesURL()+'campaigns/fixed/campaign_' + id + '/cover_image/campaign_' + id + '.png'
      },
      getBrandImg(brand_id){
            return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
      },
      toggleSortables(id){
        if(id == 1) {
          this.currentOrder = 'company_name'
          $("#sort-1").addClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
        } else if (id == 2) {
          this.currentOrder = 'id'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").addClass('active-sort')
          $("#sort-3").removeClass('active-sort')
        } else if (id == 3) {
          this.currentOrder = 'start_date'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").addClass('active-sort')
        }
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"brands/brand_" + e.target.id + "/profile_picture/brand_" + e.target.id + ".png"

        e.target.src = url
      },
      orderedItems() {
        if(this.currentOrder == 'id') {
          return _.orderBy(this.items, this.currentOrder, 'desc')
        } else {
          return _.orderBy(this.items, this.currentOrder, 'asc')
        }


      },
      listCampaigns() {
        this.$http.bamse.post('campaigns/fixed/listallactive/profile/self', {
          profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
        })
          .then(response => this.listCampaignsSuccess(response))
          .catch(() => this.listCampaignsFailed())
      },
      listCampaignsSuccess(response) {
        if(response.data.success) {
          console.log(response.data.success)
          this.items = response.data.success
          this.items = _.uniqBy(this.items, 'id');
        }
      },
      listCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
    }
  };
</script>
