<template>
  <div>
    <b-modal v-model="modalShow" hide-footer hide-header size="lg" id="toc-modal" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <span v-if="step1">
        <div class="d-block text-left">
          <h2>Hi {{ name }},<br /> Welcome to Revrelations powered by Cube!</h2>
        </div>
        <div class="d-block text-left">
          <p>Please read through our terms and policies before you start using Revrelations platform!</p>
          <p>The terms and policies are written in English to accommodate international users. Please let us know if you need help to clarify any of the writing. Please email us at support@revrelations.com.</p>
        </div>
        <b-button variant="outline-primary" block @click="readTerms()">Read terms</b-button>
      </span>
      <span v-if="step2">
        <div class="d-block text-left">
          <h3>Terms and Conditions</h3>
          <p>WeAreCube AB (“Cube”) with company reg. no. 556925-4104, provide various Content (as defined in section 4.3 below) and marketing services (the “Services”) via www.revrelations.com (“the Site”) in order to facilitate for self-employed individuals and entities with websites, blogs, social media presence and other content platforms to earn commission by promoting products from certain style and fashion designers, manufacturers, third-party retailers, advertisers and other businesses associated with Cube (the “Retailers”).</p>
          <p>The terms and conditions set out below (the “Agreement”) governs your use of the Services provided via the Site and your obligations towards Cube when you, by means of the Site, are marketing and promoting the Retailers and the Retailer’s products via your website, social media presence or any other marketing channel you may use (collectively the “Marketing Channels”).</p>
          <p>In this Agreement, “you” means the legal entity, enterprise, individual or self-employed individual that has received an account to access Services and Content at the Site and, in connection with your first login, concludes this Agreement with Cube.</p>
          <p>If you are a legal entity, you shall make sure that any and all employees (if applicable) that are using the Site have taken note of this Agreement and the Privacy Policy.</p>
          <p>Please review this Agreement carefully.</p>
        </div>
        <div class="d-block text-left">
          <br />
          <strong>1. Eligibility, application and approval</strong>
        <br /><br />
        <strong>1.2, Eligibility to proceed</strong>
          <p>The Services provided via the Site are only available to legal entities, individuals and self-employed individuals aged 18 years or older or with permission from your legal guardian. If you are a self-employed individual, you have to ensure that you are 18 years or older in connection with accepting these terms. If you are a self-employed individual and younger than 18 years old, you have to ensure that you obtain permission from your legal guardian in connection with accepting these terms.</p>
          <p>By accepting this Agreement, you accept and authorize Cube to send you emails and other communication.</p>
        </div>
        <div class="d-block text-left">
          <br />
          <strong>2. Terms and termination</strong>
          <br /><br />
          <strong>2.1 Term</strong>
          <p>This Agreement shall be effective immediately upon your acceptance of this Agreement, and shall thereafter continue in full force until terminated by either party in accordance with this Agreement.</p>
          <strong>2.2, Termination</strong>
          <p>Cube may, at its sole discretion, terminate this agreement and thereby your use of the Services immediately, with or without notice, for whatever cause. Such cause could be, but is not limited to, that Cube in its sole discretion believes that you have violated or otherwise acted in conflict with the terms of this Agreement. Cube may also suspend your access to the Services and the Content at any time at its sole discretion.</p>
          <p>You may terminate this Agreement upon written notice to Cube (support@revrelations.com). In case of termination, section 15 shall apply.</p>
          <strong>3. Scope of agreement</strong>
          <p>Subject to the terms of this Agreement and approval of your application, Cube gives you: 1. a revocable, non-transferable and non-exclusive right to use the Services and the Content provided at the Site limited to use for the purposes of facilitating your marketing of the Retailer and the Retailer’s products; and 2. the opportunity to earn money through commission based on tracked conversions, that is, commission on sales or clicks that you generate on the Retailer’s website(s) via your Marketing Channels (“Conversions”).</p>
        </div>
        <div class="d-block text-left">
          <br />
          <strong>4. Use of the Services and content at the Site</strong>
          <br /><br />
        <strong>4.1, Allowed use</strong>
          <p>As Cube is an affiliate network for selected Influential individuals or platforms (“Exclusive Influencer”) only, you may only use the Services and the Content provided at the Site for own use via your Marketing Channels and only for the purpose of promoting the Retailers and the Retailer’s products. Hence, you are not allowed to copy, distribute, assign, sell or sublicense the Services or any Content provided at the Site or in any other way use the Services or any Content provided at the Site for any other purposes than those explicitly described in this Agreement. Any use of the Services or the Content provided at the Site in violation of the terms and condition of this Agreement may result in that you will be banned from the Site and, in severe cases, the Site may also take legal actions against you.</p>
          <strong>4.2 Content</strong>
          <p>Ownership and any and all other rights (e.g. intellectual property rights) to all software, information and other material including, but not limited to, images, videos, graphics, text, logotypes, articles, sounds, trademarks and other characteristics (the "Content") at the Site, vests solely by Cube and/or its Retailers.</p>
          <p>Under no circumstances do you acquire any ownership to any part of the Content, you only acquire a revocable, non-transferable and non-exclusive right to use the Services and the Content provided at the Site and only for the purposes of this Agreement. Thus, you are not allowed to use Cube’s and/or the Retailer’s name, logo or Content for other purposes than to generate traffic to the Retailer’s websites via your Marketing Channels.</p>
          <strong>5. Seeding</strong>
          <br /><br />
          <p>In this Agreement, “Seeding” means any and all material provided to you by Cube in order to facilitate and promote the marketing of the Retailers and their products. Seeding includes, but is not limited to, any and all clothes, shoes and accessories that Cube may provide you with.</p>
          <p>Any Seeding delivered to you by Cube are and remains Cube´s property and shall only be used for the purposes of this Agreement and be returned to Cube within three (6) months from delivery (at Cube’s expense), if not otherwise agreed.</p>

          <strong>6. Your rights and obligations as an Exclusive Influencer</strong>
          <br /><br />

          <strong>6.1, Your use of the Site</strong>
          <p>You act as an independent contractor and it is all up to you to what extent you decide to use the Services and the Content provided via the Site. Cube makes no demands whatsoever in terms of how much you shall promote and market the Retailers and the Retailer’s products by means of the Services and the Content provided via the Site.</p>

          <strong>6.2, Non-exclusivity</strong>
          <p>This Agreement does not constitute any form of exclusivity arrangement between you and Cube. However, if you engage in an assignment that Cube at its sole discretion believes could harm Cube, Cube reserves the right to terminate this Agreement with you.</p>

          <strong>6.3, Equipment</strong>
          <p>You act as an independent contractor, thus you acknowledge that you shall carry any and all costs which are, directly or indirectly, related to your marketing of the Retailers and their products. Consequently, it lies entirely on you to acquire and pay for e.g. office space and all equipment (such as computers, cameras etc.) that you may need in order to promote and market the Retailers and the Retailer’s products.</p>

          <strong>6.4, Unauthorized use</strong>
          <p>You are solely responsible for any and all activities that are conducted through your Cube account and it is your responsibility to keep your username and password confidential. You expressly acknowledge that you may not share, assign or otherwise transfer your account or login information to any other person or entity. In case you detect or suspect unauthorized use of your account, you shall notify Cube immediately by a written notice to the following email; (support@revrelations.com)</p>

          <strong>6.5, Marketing</strong>
          <p>It is your sole responsibility to make sure that your marketing and advertising of the Retailers and the Retailer's products via your Marketing Channels at all times comply with applicable laws and industry standards in each and every jurisdiction that you operate in. The foregoing especially includes, but is not limited to, that you shall comply with GDPR, the Swedish Marketing Act (Sw. Marknadsföringslagen) and/or equivalent legislation in other jurisdictions in which you operate.</p>

          <p>When marketing the Retailers and Retailer’s products you shall make sure that your marketing is consistent with good marketing practice. Among other things, you are obliged to design and present your marketing in such a way that it is clear to the recipient of the advertising that it is in fact a question of advertising.</p>

          <p>You shall inform your audience in your Marketing channels about the use of third party cookies, cross-device tracking and other tracking technologies.</p>

          <p>You shall not make use of inaccurate statements regarding Cube, the Site, the Retailers or the Retailer’s products which can affect the recipient’s ability to make a well-founded transaction decision or in any other manner use marketing methods which can be considered misleading or unfair.</p>

          <p>Cube may request that you delete marketing related to the Retailers and the Retailer’s products if Cube believes the marketing is not in line with applicable law and/or good marketing practice. If you do not delete such marketing upon Cube’s request, Cube may terminate this Agreement with you, and take legal actions.</p>

          <strong>6.6, Inappropriate material</strong>
          <p>As a selected Exclusive Influencer, you shall always act in a professional manner and with Cube’s best interest in mind. The foregoing includes, but is not limited to, that you shall not post any offensive, illegal or otherwise inappropriate material via your Marketing Channels. For the avoidance of doubt, inappropriate material includes, but is not limited to, any and all material that:</p>

          <p>1. is or may be considered to be discriminatory based on religion, nationality, gender, disability, age or sexual orientation;</p>
          <p>2. is or may be considered to be offensive, hateful, abusive or harassing;</p>
          <p>3. contains another person’s personal data without such person’s consent or is otherwise invasive of another person’s privacy;</p>
          <p>4. is or may be considered to be fraudulent, deceitful or misleading;</p>
          <p>5. infringes any third party’s rights (including, but not limited to, intellectual property rights);</p>
          <p>6. contains a virus or harmful software;</p>
          <p>7. violates or encourages the violation of any applicable local, national or international law.</p>
          <p>8. Cube considers you or the content of your website inappropriate in any way.</p>

          <p>If you post inappropriate material on your Marketing Channels or otherwise acts in a manner that may harm Cube, the Site, the Retailer and/or the Retailer’s reputation, it may result in that you will be banned from Cube and, in severe cases, Cube may also take legal actions against you.</p>

          <strong>6.7, Additional obligations</strong>
          <p>You are not allowed to perform security scan and/or penetration testing without the authorization in writing of a Cube representative, in any case the authorization given can be used only in the time range specified in the authorization itself, or if this is not specified it can be used only for a single continuative period of the maximum of seven (7) days from the date in which the authorization is provided. You will be reliable for any damage, loss or missed income from Cube.</p>

          <p>You shall not use any kind of scripting and/or automation to access to Cube and/or Cube’s Site or interface, this rule does not apply to Report API called using an authentication key in the URL and/or to other API services made expressly to be accessed through scripts and automation.</p>

          <strong>7. Warranties</strong>
          <br /><br />

          <strong>7.1, Compliance</strong>
          <p>You hereby warrant that any activities (including, but not limited to, your marketing of the Retailer and the Retailer’s products) performed in connection with this Agreement are undertaken in the strictest respect of any laws, regulations, directives etc. that apply in connection with your activities.</p>

          <strong>7.2, Enforceability</strong>
          <p>You hereby warrant that you have the authority to execute this agreement and that your obligations under this agreement are enforceable in accordance with their terms.</p>

          <strong>8. Commission</strong>
          <br /><br />

          <strong>8.1, Terms and rates</strong>
          <p>You are entitled to commission on Conversions (that is, sales or clicks you generate on the Websites where the conversion can be tracked to your marketing of the Retailer and the Retailer’s products in your Marketing Channels) in accordance with the terms and rates for commission that is, from time to time, stated and specified on the Site. An adjustment of the commission rates does not require approval by you.</p>

          <strong>8.2, Commission status</strong>
          <p>The commission status can either be Approved, Pending or Rejected, where Pending is the initial status of a new registered Conversion. The Pending period is dependent on the type of Conversion registered (find alternatives in section 8.3 and 8.4 below). The Retailer, of which the Conversion is registered to, may fail a Conversion due to a registered return, fraud, or other reason. A Conversion may further be rejected by Cube, if Cube in its sole discretion believes that you have violated or otherwise acted in conflict with the terms of this Agreement. The commission will be available for payment once the status, of the Conversion, is Approved.</p>

          <strong>8.3, Commission earned by generated Unique Click</strong>
          <p>A Unique Click includes the amount of individual users who clicked a tracked link, regardless of how many times they clicked the particular link within 24 hours. Commission earned on each generated Unique Click will get status Approved, and thus, become available for payment, thirty (30) days after the Unique Click was registered by the Site.</p>

          <p>Commission may further be rejected by Cube, if Cube in its sole discretion believes that you have generated "Artificial Traffic" - a collective term for invalid Clicks, which may originate (for example and without limitation) from: automatic openings and/or redirects, spiders, robots, adware and/or spyware, or script generators, placing links on other websites than informed, the automated redirection of visitors from mistyped or misspelled domain names, Clicks which are not generated by a browser, Clicks which are not preceded by an active act of a Visitor who wants to reach a certain website.</p>

          <strong>8.4, Commission earned by generated Sale</strong>
          <p>Commission earned on each generated Sale will get status Approved, and thus, available for payment, once and if the Sale has been approved by the Retailer. The approval time depends on the Retailer, but is not less than thirty (30) days.</p>

          <strong>8.5, Payment of commissions for legal entity</strong>
          <p>If your Approved commission exceeds 1000 SEK /100 EUR / 1000DKK / 1000 NOK, an invoice statement is automatically generated on the last business day of each month. You will be able to access the invoice statement in your account at the Site. To receive the payment you are required to send an invoice to invoice@wearecube.com. The invoice details have to align withthe invoice statement in order to be approved for payment.</p> 

          <p>If you are not a legal entity, we recommend you to use available financial services to issue an invoice.</p>

          <p>Payments are made given that you do not have any outstanding depth to Cube.</p>

          <strong>8.8, Taxes</strong>
          <p>You will be personally responsible for the reporting and payment of any taxes and social security charges due on the commissions received under this agreement since you act as an independent contractor. You will be personally responsible for reporting the commissions received to the relevant authorities in your country. Cube is not responsible for any punitive charges or delay interest incurred due to non-compliance with your country's reporting and payment requirements.</p>

          <strong>9. Relationship</strong>
          <p>You act as an independent contractor. Thus, nothing in this Agreement and no action taken by the parties pursuant to this Agreement shall constitute, or be deemed to constitute, an employer-employee relationship between you and Cube nor shall it constitute, or be deemed to constitute, a partnership, association, joint venture or other co-operative entity.</p>

          <strong>10. Privacy and cookies</strong>
          <p>Beside accepting this Agreement, you are obligated to take part of Cube’s Privacy Policy and Cookie Policy, compliance with current legislation, especially the General Data Protection Regulation (GDPR), before being able to access Cube’s Services. The Policies are further available for you to take part of at the Site at any time. By accepting this agreement you confirm that you also will act accordingly to the GDPR legislation, to secure your visitors/readers/followers personal integrity and that their personal data is treated in a correct manner. This responsibility includes, but is not limited to, informing and confirming consent from your Marketing Channel’s visitors/readers/followers about Cube’s (and other possible third-party solutions) third-party cookies stored when clicking at a, so called, tracking link.</p>

          <p>Cube may from time to time request to introduce cookies or other similar technologies on your websites, blogs, social media presence and other content platforms in which case you shall provide Cube with requested assistance. Cookies and other technologies may be used e.g. for tracking the efficiency of your generation of Conversions (and be used as basis for calculating your commission) and for other follow-up or development of Cube’s marketing. Upon Cube’s request you shall add cookie/privacy/or other notices relating to the use of cookies or other technologies on your websites, blogs, social media presence or other content platforms.</p>

          <strong>11. Confidential information</strong>
          <p>You shall not reveal any confidential information of Cube which you may take part of by reason of this Agreement nor use such confidential information otherwise than for the purpose of the provisions of this Agreement. You shall take all necessary precautions to prevent an unauthorized disclosure or use of such confidential information.</p>

          <br />
          <strong>12. Disclaimer</strong>
          <br /><br />
          <strong>12.1, Non-error free</strong>
          <p>Cube provides the Services on an “as is” basis. Thus, Cube does not warrant that the access to or use of the Site will be error free. Furthermore, Cube may at its sole discretion and at any time, without previous notice, suspend or discontinue the operation of the Site or remove, amend or add Content and/or Services.</p>

          <strong>12.2, No money guarantee</strong>
          <p>Cube makes no warranties as to your potential earnings by being an Exclusive Influencer and using the Services and the Site in general.</p>

          <strong>12.3, General disclaimer</strong>
          <p>Cube disclaims all warranties not specifically set forth in this Agreement, whether express or implied, including but not limited to warranties as to quality merchantability, operability, non-infringement or fitness for a particular purpose.</p>

          <strong>13. Limitation of liability</strong>
          <p>The Services available at the Site are provided to you on an “as is” and “as available” basis and your use of the Services is at your sole risk. Cube does not assume any liability whatsoever for any consequences arising directly or indirectly out of your use of the Services or the Content provided at the Site, unless otherwise stated herein.</p>

          <p>Cube shall under no circumstances be liable for any loss of sales, profits or revenue, loss of goodwill, loss of data or any other indirect or consequential loss or damage. Under any circumstances, Cube total liability under this Agreement shall not exceed EUR 1000.</p>

          <strong>14. Indemnity</strong>
          <p>You shall defend, indemnify and hold Cube harmless from any liability, expense, cost, loss or damage incurred by Cube, directly or indirectly as a result of your (including your employees and/or other person or entity acting on your behalf) breach of this Agreement.</p>

          <strong>15. Effects of termination</strong>
          <p>In case of termination, for whatever cause, your Cube account will be immediately shut down and the respective obligations of each party shall automatically terminate save that the rights and liabilities of the parties which have accrued prior to termination shall continue to subsist. Notwithstanding the foregoing, section 4.4, 7, 11, 12, 13, 14 and 22 shall survive the termination of the Agreement.</p>

          <strong>16. Entire agreement</strong>
          <p>This Agreement contains the whole agreement and understanding between the parties relating to the subject matter of this Agreement and supersedes any previous written or oral agreement between you and Cube.</p>

          <strong>17. Assignment</strong>
          <p>You may not assign or transfer any of your rights or obligations or in any other manner makeover to any third party the benefit of any of its rights or obligations under this Agreement.</p>

          <strong>18. Amendments</strong>
          <p>Cube may amend and modify the agreement and any other terms, such as the Privacy Policy, at any time and any and all such amendments and modification shall enter into force and be binding on you one month after they are posted at revrelations.com/terms and conditions. Thus, you should review all applicable terms regularly.</p>

          <strong>19. Severability</strong>
          <p>If any provision of this Agreement is held to be invalid or unenforceable by any competent court, authority or arbitral tribunal, the remainder of that provision and all other provisions will remain valid and enforceable to the fullest extent permitted by applicable law. Any provisions determined invalid or unenforceable will be substituted by a provision of the same spirit.</p>

          <strong>20. Waiver</strong>
          <p>Cube failure to require or enforce strict performance of any term or condition under this Agreement shall not be construed as a waiver of any such term or condition.</p>

          <strong>21. Notice</strong>
          <p>Any notices or communication sent to you by Cube will be sent to the email address you have registered at the Site. It is your responsibility to ensure that Cube has your current email address at all times. Any notices or communications sent by you to Cube pursuant to this Agreement must be sent to the email address specified on the Site or such other email- or post address as Cube may specify from time to time.</p>
          <strong>22. Jurisdiction and governing law</strong>
          <p>Any disputes shall primarily be settled by agreement after discussions between you and Cube. In the event the parties cannot reach an amicable solution, any dispute regarding the interpretation or application of this Agreement shall be governed by and construed in accordance with Swedish laws with the exclusion of its choice of law provisions and shall be exclusively settled by the courts of Sweden.</p>
          <br />
        </div>
        <b-button variant="outline-primary" block @click="getTocStatus">I agree to terms</b-button>
      </span>
    </b-modal>
  </div>
</template>

<script>
import { truncate } from 'fs';
import { mapState } from 'vuex'

export default {
  name: "TermsAndConditions",
  data: function () {
    return {
      modalShow: false,
      step1: true,
      step2: false,
    }
  },
  computed: {
    ...mapState(['name']),
  },
  created () {
    this.toggleModal()
    this.addLocalModalFalse()
  },
  methods: {
    toggleModal() {
      this.modalShow = !this.modalShow
      localStorage.tocRead = true
    },
    addLocalModalFalse() {
      localStorage.tocRead = false
    },
    readTerms() {
      this.step1 = !this.step1;
      this.step2 = !this.step2;
    },
    getTocStatus() {
      this.$http.bamse.get('user/toc/accept')
        .then(response => this.getTocStatusSuccess(response))
        .catch(() => this.getTocStatusFailed(response))
    },
    getTocStatusSuccess(response) {
      this.toggleModal()
    },
    getTocStatusFailed(response) {
      console.log(response)
    }
  },
};
</script>