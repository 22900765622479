<template>
  <div class="dashboard cube-dashboard">
      <b-row v-if="level != 3">
        <b-col cols="12" class="header">
          <h1>Payments</h1>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
	   <b-row>
      <b-col lg="4" v-if="level == 3">
        <b-card>
          <b-card-text>
            <div class="pull-left">
              <b-img
                fluid
                class="profile_pic"
                :src="this.$global.spacesURL()+'users/user_' + current_id + '/profile_image/user_' + current_id + '.png'"
                @error="replaceByDefault"
              ></b-img>
            </div>
            <div class="pull-left name">
              <h4>{{ name }}</h4>
            </div>
            <div class="clearfix"></div>
          </b-card-text>
          <router-link :to="{ name: 'manageuser', query: { id: this.current_id }}">
            <b-button variant="outline-primary">Manage Your Settings</b-button>
          </router-link>
        </b-card>
      </b-col>

      <b-col lg="4">
        <b-card title="Bank" >
          <hr>
          <b-card-text>
            Manage money
          </b-card-text>
          <router-link :to="{ name: 'moneylist'}">
            <b-button variant="outline-primary">Money list</b-button>
          </router-link>
          <router-link :to="{ name: 'adlinkoverview', query: { id: this.current_id }}">
            <b-button variant="outline-primary" class="margin-left">Adlinks overview</b-button>
          </router-link>
        </b-card>
      </b-col>

      <b-col lg="4">
        <b-card title="Withdrawals" >
          <hr>
          <b-card-text>
            Manage invoices for withdrawals
          </b-card-text>
          <router-link :to="{ name: 'withdrawals', query: { id: this.current_id }}">
            <b-button variant="outline-primary">Manage Withdrawals</b-button>
          </router-link>
        </b-card>
      </b-col>

      <b-col lg="4">
        <b-card title="Transactions" >
          <hr>
          <b-card-text>
            Manage transactions
          </b-card-text>
          <router-link :to="{ name: 'listtransactions'}">
            <b-button variant="outline-primary">Manage Transactions</b-button>
          </router-link>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "financedashboard",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Payments',
          active: true
        }
      ],
    }
  },
  created () {
  },
  methods: {

  }
};
</script>