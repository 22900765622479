<template>
  <b-card>
    <div slot="header">
        Profiles
    </div>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
            >
              <template v-slot:cell(type)="row">
                Profile
              </template>
              <template v-slot:cell(status)="row">
                {{row.item.status_text}}
              </template>
              <template v-slot:cell(action)="row">
                <div v-if="level === 1 || level === 2">
                  <b-modal v-model="modalShow" :id="row.item.campaign_name" title="View draft" hide-footer>

                    <b-img fluid :src="$global.spacesURL()+'campaigns/micro/campaign_' + id + '/drafts/profile_' + row.item.id + '_1.png'" @error="replaceByDefault"></b-img>

                    <b-img fluid :src="'https://bamse-in-space.fra1.digitaloceanspaces.com/campaigns/micro/campaign_' + id + '/drafts/profile_' + row.item.id + '_2.png'" @error="replaceByDefault"></b-img>

                    <b-img fluid :src="'https://bamse-in-space.fra1.digitaloceanspaces.com/campaigns/micro/campaign_' + id + '/drafts/profile_' + row.item.id + '_3.png'" @error="replaceByDefault"></b-img>

                    <b-img fluid :src="'https://bamse-in-space.fra1.digitaloceanspaces.com/campaigns/micro/campaign_' + id + '/drafts/profile_' + row.item.id + '_4.png'" @error="replaceByDefault"></b-img>

                    <b-img fluid :src="'https://bamse-in-space.fra1.digitaloceanspaces.com/campaigns/micro/campaign_' + id + '/drafts/profile_' + row.item.id + '_5.png'" @error="replaceByDefault"></b-img>

                    <b-button class="uploaddraftbtn" block v-if="row.item.status == 2" variant="outline-success" @click="approveDraft(row.item.id)">
                      Approve draft
                    </b-button>

                  </b-modal>

                  <b-button v-if="row.item.status == 4" :id="'done_'+row.item.id" variant="outline-success" @click="markAsDone(row.item.id)">
                    Campaign delivered
                  </b-button>

                  <b-button v-if="row.item.status == 3" :id="'waiting_'+row.item.id" disabled variant="primary">
                    Waiting...
                  </b-button>


                  <b-button v-if="row.item.status == 2" :id="'draft_'+row.item.id" variant="outline-primary" @click="modalShow = !modalShow">
                    View draft
                  </b-button>

                  <b-button :id="'thankyou-'+row.item.id" variant="success" disabled class="hide">
                    Thank you
                  </b-button>

                  <b-button :id="'waiting_'+row.item.id" disabled variant="primary" class="hide">
                    Waiting...
                  </b-button>
                </div>

              </template>
            </b-table>
          </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import { mapState } from 'vuex'
import $ from 'jquery'

export default {
  name: "listprofilesformicrocampaign",
  props:['view', 'buttonText', 'micro_profiles_from_listview'],
  components: {
  },
  computed: {
    ...mapState(['level', 'profile_assets', 'ext_profile_assets', 'current_id']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  created () {
    this.listActiveProfiles()
  },
  data() {
    return {
      id: this.$route.query.id,
      error: false,
      success: false,
      users: null,
      filter: null,
      modalShow: false,
      showButton: false,
      selected_profiles: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      items: [],
    }
  },
  methods: {
    replaceByDefault(e){
        var url = "http://indol.se/wp-content/uploads/2017/04/profile-placeholder.png"
        e.target.hidden = true
    },
    listActiveProfiles() {
      this.$http.bamse.get('campaigns/micro/details?campaign_id='+this.id)
        .then(response => this.listActiveProfilesInfoSuccess(response))
        .catch(() => this.listActiveProfilesInfoFailed())
    },
    listActiveProfilesInfoSuccess(response) {
      if(response.data.success) {
        this.items = response.data.success[0].applied_profiles
      }
    },
    listActiveProfilesInfoFailed() {
      this.error = 'no fetch sorry'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowSelected(items) {
      this.selected_profiles = items
      if (this.selected_profiles && this.selected_profiles.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    markAsDone(id) {
      this.$http.bamse.post('campaigns/micro/status/update/self', {
        campaign_id: this.id,
        profile_id: id,
        status: 5
      })
      .then(response => this.markAsDoneSuccess(response, id))
      .catch(() => this.markAsDoneFailed())
    },
    markAsDoneSuccess(response, id) {
      if(response.data.success) {
        $('#done_'+id).toggleClass("hide")
        $('#thankyou-'+id).toggleClass("hide")
        this.success = true
      }
    },
    markAsDoneFailed() {
      this.error = true
    },
    approveDraft(id) {
      this.$http.bamse.post('campaigns/micro/status/update/self', {
        campaign_id: this.id,
        profile_id: id,
        status: 3
      })
      .then(response => this.approveDraftSuccess(response, id))
      .catch(() => this.approveDraftFailed())
    },
    approveDraftSuccess(response, id) {
    if(response.data.success) {
        this.success = true
        $('#draft_'+id).toggleClass("hide")
        $('#waiting_'+id).toggleClass("hide")
        this.modalShow = !this.modalShow
      }
    },
    approveDraftFailed() {
      this.error = true
    },
  },
};
</script>