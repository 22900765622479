<template>
  <div>
   
    <b-row>
      <b-col cols="12" class="header">
        <h1>Create adlink campaign</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-form @submit.prevent="createAdlinkCampaign()">    
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="outline-secondary">Campaign information</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
                    <b-input name="campaign_name" v-model="campaign_name"></b-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Brand" label-for="brand_id">
                    <b-form-select name="brand_id" v-model="brand_id" :options="options_brand"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Network" label-for="network">
                    <b-form-select name="network" v-model="network" :options="options_network"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="network != null">
                  <b-form-group label-size="sm" label="Network URL" label-for="network_url">
                    <b-input name="network_url" v-model="network_url"></b-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Description" label-for="description">
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      placeholder="Describe the campaign"
                      rows="8"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <datepicker 
                      wrapper-class="form-control" 
                      :monday-first="true"
                       format="dd-MM-yyyy" 
                      v-model="start_date" 
                      name="start_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <datepicker 
                      wrapper-class="form-control" 
                      :monday-first="true"
                       format="dd-MM-yyyy" 
                      v-model="end_date" 
                      name="end_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
                  <b-form-file 
                    v-model="file"
                    placeholder="Choose a picture..."
                    drop-placeholder="Drop picture here..."
                    accept=".jpg, .png"
                    name="imageupload"
                  ></b-form-file>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cookie time" label-for="cookie_time">
                    <b-form-select name="cookie_time" v-model="cookie_time" :options="options_cookie_time"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Default campaign" label-for="default">
                    <b-form-select name="default" v-model="default_campaign" :options="options_default"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-1.accordion-2 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-2 variant="outline-secondary">Budget</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Type Cube" label-for="cube_revenue_type">
                    <b-form-select name="cube_revenue_type" v-model="cube_revenue_type" :options="options_cube_revenue_type"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Type Profile" label-for="profile_revenue_type">
                    <b-form-select name="profile_revenue_type" v-model="profile_revenue_type" :options="options_profile_revenue_type"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="cube_revenue_type == 'cpa_percentage'">
                  <b-form-group label-size="sm" label="Revenue Cube Percentage" label-for="revenue_cube">
                    <b-input-group :append="cube_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_cube" 
                        v-model="revenue_cube"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="cube_revenue_type == 'cpc'">
                  <b-form-group label-size="sm" label="Revenue Cube Per Click" label-for="revenue_cube_cpc">
                    <b-input-group :append="currency" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_cube_cpc" 
                        v-model="revenue_cube_cpc"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="profile_revenue_type == 'cpa_percentage'">
                  <b-form-group label-size="sm" label="Revenue Profile Percentage" label-for="revenue_profile">
                    <b-input-group :append="profile_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_profile" 
                        v-model="revenue_profile"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="profile_revenue_type == 'cpc'">
                  <b-form-group label-size="sm" label="Profile Cube Per Click" label-for="revenue_profile_cpc">
                    <b-input-group :append="currency" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_profile_cpc" 
                        v-model="revenue_profile_cpc"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-2.accordion-3 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-3 variant="outline-secondary">Profiles/Creators</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row class="listprofilesbig">

                <b-col cols="12" class="margin-btn">
                   <b-button 
                    v-if="showButton"
                    @click="addProfileToCampaign()" 
                    size="sm" 
                    variant="success"
                    class="card-header-btns"
                  >
                    Add to campaign
                  </b-button>

                  <b-button
                    v-if="showButton"
                    @click="removeProfileFromCampaign()" 
                    size="sm" 
                    variant="danger"
                    class="card-header-btns"
                  >
                    Remove from campaign
                  </b-button>
                </b-col>
                 
                  <b-col class="table_filter" cols="12">
                    <b-input-group>
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col cols="12">
                  <b-table 
                    striped
                    selectable
                    hover 
                    responsive
                    :items="items" 
                    :fields="fields"
                    :filter="filter"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                    select-mode="range"
                    selectedVariant="success"
                    ref="profiles"
                  >
                    <template v-slot:cell(type)="row">
                      Profile
                    </template>
                    <template v-slot:cell(activated)="row">
                        {{row.selected}}
                        <font-awesome-icon icon="badge-check" v-if="checkIfAdded(row.item.id)" />
                      </template>
                    </b-table>

                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-3 @click="showSubmit = true" variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-button class="btn-margin" type="submit" v-if="loading == false && showSubmit == true" block variant="outline-success">Create campaign</b-button>
      <b-button disabled class="btn-margin" variant="outline-success" block v-if="loading == true && showSubmit == true">
        <b-spinner small ></b-spinner> Loading
      </b-button>  
    </b-form>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import FileUpload from '@/components/general/FileUpload.vue'
import ListProfiles from "@/components/cube/ListProfiles.vue";
import $ from 'jquery'
import moment from 'moment'

let brand_list = [];
let profile_list = [];
let employee_list = [];

export default {
  name: "CreateAdlinkCampaign",
  components: {
    Datepicker,
    ListProfiles,
    FileUpload
  },
  data: function () {
    return {
      showSubmit: false,
      filter: null,
      showButton: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Create Adlink Campaign',
          href: '#',
          active: true
        },
      ],
      id_array: [],
      cube_percentage: '%',
      profile_percentage: '%',
      sales_person: '',
      loading: false,
      project_manager: '',
      default_campaign: 0,
      campaign_name: '',
      micro_profiles_from_listview: '',
      brand_id: {brand_id: null, tune_id: null},
      description: '',
      draft_required: 0,
      profiles: [],
      profiles_array: [],
      channels: [],
      channels_array: [],
      revenue_cube: 0,
      revenue_profile: 0,
      cookie_time: null,
      start_date: null,
      end_date: null,
      currency: null,
      payment_conditions: null,
      cube_revenue_type: 'cpa_percentage',
      profile_revenue_type: 'cpa_percentage',
      revenue_cube_cpc: 0,
      revenue_profile_cpc: 0,
      price: '',
      file: null,
      options_project_manager: [],
      options_sales_person: [],
      items: [],
      brand_id: null,
      options_brand: [],
      network: null,
      network_url: null,
      options_network: [
        { value: 'direct', text: 'Direct integration' },
        { value: 'adtraction', text: 'Adtraction'},
        { value: 'awin', text: 'Awin'},
        { value: 'tradedoubler', text: 'Tradedoubler'},
        { value: 'rakuten', text: 'Rakuten'},
      ],
      options_cookie_time: [
        { value: null, text: 'Please select an option' },
        { value: '168', text: 'One week'},
        { value: '336', text: 'Two weeks'},
        { value: '720', text: 'One month'},
      ],
      options_default: [
        { value: 0, text: 'No' },
        { value: 1, text: 'Yes'},
      ],
      options_cube_revenue_type: [
        { value: null, text: 'Please select an option' },
        { value: 'cpa_percentage', text: 'Revenue per Sale (RPS)'},
        { value: 'cpc', text: 'Revenue per Click (RPC)'}
      ],
      options_profile_revenue_type: [
        { value: null, text: 'Please select an option' },
        { value: 'cpa_percentage', text: 'Cost per Sale (CPS)'},
        { value: 'cpc', text: 'Cost per Click (CPC)'}
      ],
      options_profiles: profile_list,
      options_payment_conditions: [
        { value: null, text: 'Please select an option' },
        { value: 10, text: '10 days' },
        { value: 15, text: '15 days' },
        { value: 20, text: '20 days' },
        { value: 30, text: '30 days'}
      ],
      selected_profiles: [],
      activated_profiles: [],
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'activated',
          label: false,
          sortable: true
        }
      ],
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets', 'country']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  created () {
    this.getBrandList()
    this.getProfileList()
  },
  methods: {
    addProfileToCampaign() {

      for(var i = 1; i <= this.selected_profiles.length; i++){
        this.activated_profiles.push(this.selected_profiles[i-1].id);
      }

      var uniq = [...new Set(this.activated_profiles)];

      this.activated_profiles = uniq;

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles.length; i++){
        id_array.push({id: this.activated_profiles[i-1]});
      }

      this.profiles_array = id_array

      this.$refs.profiles.clearSelected()

    },
    removeProfileFromCampaign() {

      var active = this.activated_profiles;

      this.selected_profiles.forEach(function(element) {
        var index = active.indexOf(element.id);
        if (index > -1) {
          active.splice(index, 1);
        }
      });

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles.length; i++){
        id_array.push({id: this.activated_profiles[i-1]});
      }

      this.profiles_array = id_array

      this.$refs.profiles.clearSelected()
    },
    checkIfAdded(id) {
      var check = this.activated_profiles.includes(id);
      if(check == true){
        return true
      } else {
        return false
      }
    },
    rowSelected(items) {
      this.selected_profiles = items
      if (this.selected_profiles && this.selected_profiles.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {

      if(response.data.success) {
        brand_list = []
        var brand_options_list = this.options_brand
        
        response.data.success.forEach(function (result, key) {
          if(result.tune_id !== 0) {
            brand_options_list.push({value: {tune_id: result.tune_id, brand_id: result.id}, text: result.company_name})
          }
        });

        this.options_brand = _.orderBy(this.options_brand, ['text'],['asc']);

        this.options_brand.unshift({ value: null, text: 'Please select an option' })
      }
    },
    getBrandListFailed() {
      this.error = 'no fetch sorry'
    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {

     if(response.data.success) {

        this.items = response.data.success

        var i;

        var myProfiles = response.data.success;
        var myProfileTable = [];

       

        myProfiles.forEach(function (result, key) {
          if(result.tune_id !== 0) {
            myProfileTable.push(result)
          }
        });

        this.items = myProfileTable

      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    createAdlinkCampaign() {

      this.loading = true

      let formData = new FormData();

      let url_fix = '';

      if(this.network == null){
        url_fix = "http://tracker.revrelations.com/t/{aff_sub2}/?transaction_id={transaction_id}&advertiser_id={advertiser_id}&url_redirect={aff_sub}"
      } else {
        if(this.network == 'awin') {
          url_fix = this.network_url+'&clickref={transaction_id}&p={aff_sub}'
        } else if(this.network == 'rakuten') {
          url_fix = this.network_url+'&u1={transaction_id}&murl={aff_sub}'
        } else {
          url_fix = this.network_url+'&epi={transaction_id}&url={aff_sub}'
        }
      }      

      let date_format = moment(this.end_date).format('YYYY-MM-DD HH:MM:SS')

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Offer');
      formData.append('Method', 'create');
      formData.append('return_object', 1);
      formData.append('data[allow_multiple_conversions]', 1);
      formData.append('data[allow_website_links]', 1);
      formData.append('data[status]', 'active');
      formData.append('data[name]', this.campaign_name);
      formData.append('data[offer_url]', url_fix);
      formData.append('data[preview_url]', url_fix);
      formData.append('data[max_percent_payout]', this.revenue_cube);
      formData.append('data[percent_payout]', this.revenue_profile);
      formData.append('data[revenue_type]', this.cube_revenue_type);
      formData.append('data[payout_type]', this.profile_revenue_type);
      formData.append('data[protocol]', 'server');
      formData.append('data[expiration_date]', date_format);
      formData.append('data[currency]', 'SEK');
      formData.append('data[session_hours]', this.cookie_time);
      formData.append('data[advertiser_id]', this.brand_id.tune_id);
      formData.append('data[default_payout]',
      this.$global.convertCurrencyAdd(this.country, this.revenue_profile_cpc));
      formData.append('data[max_payout]', this.$global.convertCurrencyAdd(this.country, this.revenue_cube_cpc));
      formData.append('data[approve_conversions]', 1)

      this.$http.tune.post('', formData)
      .then(response => this.createAdlinkCampaignSuccess(response))
        .catch(error => this.createAdlinkCampaignFailed(error))
    },
    createAdlinkCampaignSuccess(response) {
      if(response.data.response.status == 1) {

        this.offer_id = response.data.response.data.Offer.id
        this.createAdlinkBamseCampaign()
       
      }
    },
    createAdlinkCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    createAdlinkBamseCampaign() {

      let formData = new FormData();

      var start_date_format = JSON.stringify(this.start_date)
      var end_date_format = JSON.stringify(this.end_date)

      var profiles = JSON.stringify(this.profiles_array)

      formData.append('campaign_name', this.campaign_name);
      formData.append('tune_id', this.offer_id);
      formData.append('profiles', profiles);
      formData.append('cube_payout_percent', this.revenue_cube);
      formData.append('profile_payout_percent', this.revenue_profile);
      formData.append('revenue_type', this.cube_revenue_type);
      formData.append('payout_type', this.profile_revenue_type);
      formData.append('start_date', start_date_format);
      formData.append('end_date', end_date_format);
      formData.append('currency', this.currency);
      formData.append('brand_id', this.brand_id.brand_id);
      formData.append('profile_cpc', this.$global.convertCurrencyAdd(this.country,
      this.revenue_profile_cpc));
      formData.append('cube_cpc', this.$global.convertCurrencyAdd(this.country,
      this.revenue_cube_cpc));
      formData.append('description', this.description);
      formData.append('network', this.network);
      formData.append('network_url', this.network_url);
      formData.append('created_by', this.current_id);
      formData.append('file', this.file);
      formData.append('cookie_time', this.cookie_time);
      formData.append('default', this.default_campaign);

      this.$http.bamse.post('campaigns/adlink/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.createAdlinkBamseCampaignSuccess(response))
      .catch(error => this.createAdlinkBamseCampaignFailed(error)) 
    },
    createAdlinkBamseCampaignSuccess(response) {
      if(response.data.success) {

        this.updateRefId()
        
      }
    },
    createAdlinkBamseCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    updateRefId() {

      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Offer');
      formData.append('Method', 'update');
      formData.append('id', this.offer_id);
      formData.append('data[ref_id]', this.offer_id);

        this.$http.tune.post('', formData)
        .then(response => this.updateRefIdSuccess(response))
          .catch(error => this.updateRefIdFailed(error))

      
    },
    updateRefIdFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    updateRefIdSuccess(response) {
      this.$global.makeToast(this, 'Success', 'Adlink campaign created', 'success')
        
      setTimeout( () => this.$router.push({ path: '/list-adlinkcampaigns?type=active' }), 1500);
    },
  },
};
</script>
