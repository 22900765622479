<template>
  <b-card>
    <div slot="header">
        Profiles
				<b-button 
          v-if="showButton"  
          @click="addProfileToCampaign()" 
          size="sm" 
          variant="success"
          class="card-header-btns"
        >
          Add to campaign
        </b-button>
    </div>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
				<b-col cols="12">
					<b-table 
						striped
						selectable
						hover 
            responsive
						:items="items" 
						:fields="fields"
						:filter="filter"
						@filtered="onFiltered"
						@row-selected="rowSelected"
						select-mode="range"
						selectedVariant="success"
						ref="microprofiles"
					>
            <template v-slot:cell(name)="row">
              <b-img 
                class="table-img"
                fluid 
                :src="'https://bamse-in-space.fra1.digitaloceanspaces.com/profiles/profile_' + row.item.id + '/profile_picture/profile_' + row.item.id + '.png'" 
                @error="replaceByDefault"
              ></b-img>
              {{ row.item.name }}
            </template>
					</b-table>
				</b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: "unconnectedprofiles",
  components: {
    
  },
  created () {
    this.listUnConnectedProfiles()
  },
  data() {
    return {
      id: this.$route.query.id,
      error: false,
      success: false,
      users: null,
      filter: null,
      showButton: false,
      refresh: false,
      fields: [
        {
          key: 'name',
          sortable: true
        }
      ],
      items: [],
      selected_profiles: []
    }
  },
  mounted: function () { 
    this.$root.$on('addProfileFromNotConnected', (newItem) => {
      this.items.push(newItem);
    })
  },
  computed: {
    ...mapState(['profile_assets', 'ext_profile_assets', 'current_id']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  methods: {
    replaceByDefault(e){
        var url = "https://bamse-in-space.fra1.digitaloceanspaces.com/placeholders/profile-placeholder.png"
        e.target.src = url
    },
    listUnConnectedProfiles() {
      this.$http.bamse.get('campaigns/micro/details/profiles/notconnected?campaign_id='+this.id)
        .then(response => this.listUnConnectedProfilesSuccess(response))
        .catch(() => this.listUnConnectedProfilesFailed())
    },
    listUnConnectedProfilesSuccess(response) {
      if(response.data.success) {
        this.items = response.data.success
      }
    },
    listUnConnectedProfilesFailed() {
      this.error = 'no fetch sorry'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowSelected(items) {
      this.selected_profiles = items
      if (this.selected_profiles && this.selected_profiles.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    addProfileToCampaign() {
      var selected_ids = []
      for(var i = 1; i <= this.selected_profiles.length; i++){
        selected_ids.push({ "id": this.selected_profiles[i-1].id });
      }
      this.$http.bamse.post('campaigns/micro/profile/connect', { 
          profiles: selected_ids, 
          campaign_id: parseInt(this.id)
        })
        .then(response => this.addProfileToCampaignSuccess(response))
        .catch(() => this.addProfileToCampaignFailed())
    },
    addProfileToCampaignSuccess(response) {
      if(response.data.success){
        for(var i = 1; i <= this.selected_profiles.length; i++){
          const index = this.items.findIndex(item => item.id === this.selected_profiles[i-1].id)
          if (~index){
            this.$root.$emit('addProfileFromConnected', this.selected_profiles[i-1]);
            this.items.splice(index, 1) //delete the post
          }
        }
        this.$global.makeToast(this, 'Success', response.data.success, 'success')
      }
    },
    addProfileToCampaignFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Something went wrong!', 'danger')
    }
  },
};
</script>