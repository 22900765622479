import axios from 'axios'

const API_URL = 'https://graph.facebook.com/v6.0/'

export default axios.create({
  baseURL: API_URL,
  /**
   * Do not set authorization header here, this is what caused the login problem
   * when changing users. This instance gets created once and only gets detroyed when page
   * refreshes.
   */
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },

  transformRequest: [(data, headers) => { delete headers.common.Authorization
    return data }]

})