<template>
  <div class="campaigns">
    <b-row>
        <b-col cols="12" class="header">
          <h1>Settings</h1>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
    <b-row>
      <b-col cols="4">
        <b-card title="My Settings">
          <hr>
          <router-link :to="{ name: 'manageuser', query: { id: this.current_id }}">
            <b-button variant="outline-primary">Edit your settings</b-button>
          </router-link>
        </b-card>
      </b-col>
      <b-col cols="4" v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <b-card title="Edit Users">
          <hr>
          <router-link :to="{ name: 'createuser'}">
            <b-button variant="outline-success">Create</b-button>
          </router-link>
          <router-link :to="{ name: 'listusers', query: { type: 'active' }}">
            <b-button variant="outline-primary">Active</b-button>
          </router-link>
          <router-link :to="{ name: 'listusers', query: { type: 'suspended' }}">
            <b-button variant="outline-primary">Suspended</b-button>
          </router-link>
        </b-card>
      </b-col>
      <b-col cols="4" v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <b-card title="Edit Assets">
          <hr>
          <router-link :to="{ name: 'createasset'}">
            <b-button variant="outline-success">Create</b-button>
          </router-link>
          <router-link :to="{ name: 'listassets', query: { type: 'profiles' }}">
            <b-button variant="outline-primary">Profiles</b-button>
          </router-link>
           <router-link :to="{ name: 'listassets', query: { type: 'brands' }}">
            <b-button variant="outline-primary">Brands</b-button>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" v-if="
        type === 1 && level === 1 || 
        type === 1 && level === 2"
      >
        <b-card title="Applications">
          <hr>
          <router-link :to="{ name: 'applications'}">
            <b-button variant="outline-primary">View applications</b-button>
          </router-link>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card title="Transactions">
          <hr>
          <router-link :to="{ name: 'addtransaction'}">
            <b-button variant="outline-primary">Add transaction</b-button>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: "settings",
  components: {
  },
  computed: {
    ...mapState(['level', 'current_id', 'type']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Settings',
          href: '#',
          active: true
        },
      ]
    }
  },
};
</script>
