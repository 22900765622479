<template>
<div class="performancereport revenue-status-report">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Revenue Status Report</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
  <b-card>
    <div class="all-users">
      <b-row v-if="showdates">
        <b-col lg="2" md="6">
          <b-form-group label-size="sm" label="Start date" label-for="start_date">
            <datepicker
              wrapper-class="form-control"
              :monday-first="true"
              format="yyyy-MM-dd"
              v-model="start_date"
              name="start_date"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="6">
          <b-form-group label-size="sm" label="End date" label-for="end_date">
            <datepicker
              wrapper-class="form-control"
              :monday-first="true"
              format="yyyy-MM-dd"
              v-model="end_date"
              name="end_date"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="12">
          <b-button @click="getStats()" class="active-sort getdatesbtn" variant="outline-primary">Submit</b-button>
          <b-button @click="showdates = false" class="active-sort getdatesbtn" variant="outline-primary">Cancel</b-button>
        </b-col>
        <b-col xl="5" lg="12" class="datefilter">
            <b-button @click="pickDates(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 90 days</b-button>

            <b-button @click="pickDates(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 30 days</b-button>

          <b-button @click="pickDates(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 7 days</b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="3" class="margin-bottom-sort">
          <span class="label">Current dates</span>
          <b-button @click="showdates = true" v-if="loading == false" class="active-sort datebtn" variant="outline-primary">{{ start_date}} - {{ end_date }}</b-button>
          <b-button class="active-sort datebtn" variant="outline-primary" v-else>
          <b-spinner small ></b-spinner> Loading
        </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="items"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(brand)="row">
              {{ row.item.brand }}
          </template>
          <template v-slot:cell(pending)="row">
            {{ $global.convertCurrency(country, row.item.pending)}}
          </template>
          <template v-slot:cell(approved)="row">
            {{ $global.convertCurrency(country, row.item.approved)}}
          </template>
          <template v-slot:cell(rejected)="row">
            {{ $global.convertCurrency(country, row.item.rejected)}}
          </template>
          <template v-slot:cell(payout)="row">
            {{ $global.convertCurrency(country, row.item.approved+row.item.pending)}}
          </template>

        </b-table>
        <b-col cols="12" v-if="noDataFound">
          <center><p>No data found.</p></center>
        </b-col>
      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';


export default {
  name: "revenuestatusreport",
  components: {
    Datepicker
  },
  computed: {
    ...mapState(['profile_id', 'profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created () {
    this.setDate()
    this.getStats()
  },
  data() {
    return {
      breadcrumbs: [
      {
        text: 'Reports',
        to: '/reports'
      },
      {
        text: 'Revenue Status Report',
        active: true
      },
      ],
      showdates: false,
      loading: false,
      error: false,
      success: false,
      users: null,
      filter: null,
      start_date: '',
      end_date: '',
      noDataFound: false,
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'brand',
          label: 'Brand',
          sortable: true
        },
        {
          key: 'pending',
          label: 'Pending',
          sortable: true
        },
        {
          key: 'approved',
          label: 'Approved',
          sortable: true
        },
        {
          key: 'rejected',
          label: 'Rejected',
          sortable: true
        },
        {
          key: 'payout',
          label: 'Payout',
          sortable: true
        }
      ],
      items: [],
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setDate(){
      this.end_date = moment().format('YYYY-MM-DD')
      this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
    },
    pickDates(id){
      if(id == 1){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.getStats()
      } else if(id == 2){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.getStats()
      } else if(id == 3){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(90, 'days').format('YYYY-MM-DD');
        this.getStats()

      }
    },
    getStats() {
      this.items = []
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      this.start_date = moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = moment(this.end_date).format('YYYY-MM-DD')

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Report');
      formData.append('Method', 'getConversions');

      formData.append('fields[]', 'Stat.status');
      formData.append('fields[]', 'Stat.payout');
      formData.append('fields[]', 'Advertiser.company');
      formData.append('fields[]', 'Advertiser.ref_id');
      formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.status][values][]', 'rejected');
      formData.append('filters[Stat.status][values][]', 'pending');
      formData.append('filters[Stat.status][values][]', 'approved');
      formData.append('limit', '0');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);
      formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
      formData.append('filters[Affiliate.ref_id][values]', this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].tune_id);
      formData.append('sort[Stat.date]', 'asc');

      this.$http.tune.post('', formData)
      .then(response => this.getStatsSuccess(response))
        .catch(error => this.getStatsFailed(error))
    },
    getStatsSuccess(response) {
      if(response.data.response.data.count == null) {
        this.noDataFound = true
      } else {
        this.noDataFound = false
      }
      if(response.data.response.status == 1) {

        var old_ref_id = 0;

        response.data.response.data.data.forEach((item) => {

          if(old_ref_id!=item.Advertiser.ref_id) {
            this.items.push({
              brand: item.Advertiser.company,
              ref_id: item.Advertiser.ref_id,
              pending: 0,
              approved: 0,
              rejected: 0,
              payout: 0
            })
            old_ref_id = item.Advertiser.ref_id
          }

          var keyExists = _.findIndex(this.items, ['ref_id', item.Advertiser.ref_id]);

          if(item.Stat.status == 'pending'){

            if(keyExists != -1) {
              var currentValue = Number(this.items[keyExists].pending)
              var newValue  = Number(item.Stat.payout)
              var updateValue = Number(currentValue) + Number(newValue)
              this.items[keyExists].pending = updateValue
            }

          }

          if(item.Stat.status == 'approved'){

            if(keyExists != -1) {
              var currentValue = Number(this.items[keyExists].approved)
              var newValue  = Number(item.Stat.payout)
              var updateValue = Number(currentValue) + Number(newValue)
              this.items[keyExists].approved = updateValue
            }

          }

          if(item.Stat.status == 'rejected'){

            if(keyExists != -1) {
              var currentValue = Number(this.items[keyExists].rejected)
              var newValue  = Number(item.Stat.payout)
              var updateValue = Number(currentValue) + Number(newValue)
              this.items[keyExists].rejected = updateValue
            }

          }

        })

        this.loading = false
      } else {
        this.$global.makeToast(this, 'Oh oh!', response.data.response.errorMessage, 'danger')
      }
    },
    getStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    }
  },
};
</script>
