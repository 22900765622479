<template>
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      :variant="type"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <h4 class="alert-heading">{{ header }}</h4>
      <p>{{ message }}</p>
      <p class="alert-count">Gone in {{ dismissCountDown }} seconds...</p>
    </b-alert>
</template>

<script>
export default {
  name: "Alerts",
  props:['header', 'message', 'type'],
  data: function () {
    return {
    dismissSecs: 5,
    dismissCountDown: 5,
    showDismissibleAlert: true,
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  }
};
</script>