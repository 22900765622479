import Vue from "vue";
import Vuex from "vuex";
import router from './router';
import axios from '@/backend/vue-axios/axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasUpdated: false,
    user: '',
    isLoggedIn: false,
    name: null,
    level: null,
    type: null,
    country: null,
    current_id: null,
    profile_id: null,
    brand_id: null,
    profile_assets: [],
    brand_assets: [],
    ext_profile_assets: [],
    ext_brand_assets: []

  },
  getters: {
    currentUser (state) {
      return state.user
    },
    hasUpdated: state => state.hasUpdated,
    isLoggedIn: state => state.isLoggedIn,
    profile_assets: state => state.profile_assets,
    name: state => state.name,
    brand_assets: state => state.brand_assets,
    type: state => state.type,
    country: state => state.country,
    level: state => state.level,
    current_id: state => state.current_id,
    profile_id: state => state.profile_id,
    brand_id: state => state.brand_id,
    ext_profile_assets: state => state.ext_profile_assets,
    ext_brand_assets: state => state.ext_brand_assets,
    getCurrentSession: function(state){
      let session = new Object();
      session.name = state.name;
      session.level = state.level;
      session.type = state.type;
      session.country = state.country;
      session.current_id = state.current_id;
      session.profile_id = state.profile_id;
      session.brand_id = state.brand_id;
      session.profile_assets = state.profile_assets;
      session.brand_assets = state.brand_assets;
      session.ext_profile_assets = state.ext_profile_assets;
      session.profile_assets = state.profile_assets;
      return session;
    }
  },
  mutations: {
    SETPROFILE: function(state, data){
      state.profile_id = data
    },
    SETBRAND: function(state, data){
      state.brand_id = data
    },
    LOGIN: function(state, data) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
      // Set default authorization header
      window.localStorage.token = data.token
      state.hasUpdated = true;
      state.isLoggedIn = true;
      state.user = JSON.parse(window.atob(data.token.split(".")[1]));
      state.name = data.name+' '+ data.last_name
      state.level = data.my_level
      state.type = data.my_type
      state.country = data.country
      state.brand_assets = data.brand_assets
      state.profile_assets = data.profile_assets
      state.current_id = data.user_id
      state.profile_id = null
      state.brand_id = null
      router.push('/dashboard');
    },
    LOGOUT: function(state) {
      state.user = null
      state.hasUpdated = true;
      state.isLoggedIn = false;
      state.user = null;
      state.name = null;
      state.level = null;
      state.type = null;
      state.country = null;
      state.brand_assets = [];
      state.profile_assets = [];
      state.current_id = null;
      state.profile_id = null;
      state.brand_id = null;
      state.ext_profile_assets = [];
      state.ext_brand_assets = [];

      router.push("/");
      if (window){
        delete window.localStorage.token;
        delete window.localStorage.name;
      }
    },

    /**
     * After page refresh
     */

    setUserSession: function(state, userInfoResponse){
      //userInfoResponse is a http response
      //we dont need to set default authorization header here as it is already being
      //set in App.vue (as it runs first)
      if (userInfoResponse.data.success){
        state.user = JSON.parse(window.atob(window.localStorage.token.split(".")[1]));
        state.isLoggedIn = true;
        state.hasUpdated = true;
        state.name = userInfoResponse.data.success[0].name + ' ' + userInfoResponse.data.success[0].last_name;
        state.level = userInfoResponse.data.success[0].user_level;
        state.type = userInfoResponse.data.success[0].user_type;
        state.country = userInfoResponse.data.success[0].country;
        state.brand_assets = userInfoResponse.data.success.brand_assets;
        state.profile_assets = userInfoResponse.data.success.profile_assets;
        state.current_id = userInfoResponse.data.success[0].id;
      }
    },
  }
});