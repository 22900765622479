<template>
  <div class="list-microcampaigns">
    <div v-if="list_type == 'active'">
      <b-row>
        <b-col cols="12" class="header">
          <span v-if="type == 3 && list_type == 'active'">
            <h1>Cube Campaigns</h1>
            <b-breadcrumb :items="breadcrumbsbrand"></b-breadcrumb>
          </span>
          <span v-else>
            <h1>Active Cube Campaigns</h1>
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </span>
        </b-col>
      </b-row>
    </div>
    <div v-if="type == 1 && list_type == 'active'">
      <ListActiveFixedCampaigns />
    </div>
    <div v-else-if="type == 3 && list_type == 'active'">
      <ListActiveFixedCampaignsBrand />
    </div>

    <div v-else-if="type == 1 && list_type == 'archived'">
      <b-row>
        <b-col cols="12" class="header">
          <h1>Archived Cube Campaigns</h1>
          <b-breadcrumb :items="breadcrumbsarchived"></b-breadcrumb>
        </b-col>
      </b-row>
      <ListArchivedFixedCampaigns />
    </div>
    <div v-else>
      Oh noes!
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import ListActiveFixedCampaigns from "@/components/campaigns/fixed/ListActiveFixedCampaigns.vue";
  import ListArchivedFixedCampaigns from "@/components/campaigns/fixed/ListArchivedFixedCampaigns.vue";
  import ListActiveFixedCampaignsBrand from "@/components/campaigns/fixed/brand/ListActiveFixedCampaigns.vue";


export default {
  name: "listfixedcampaigns",
  query: ['type'],
  computed: {
    ...mapState(['current_user_id', 'profile_assets', 'type']),
  },
  components: {
    ListActiveFixedCampaigns,
    ListArchivedFixedCampaigns,
    ListActiveFixedCampaignsBrand
  },
  data() {
    return {
      list_type: this.$route.query.type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Cube Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Active Cube Campaigns',
          href: '#',
          active: true
        },
      ],
      breadcrumbsbrand: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Cube Campaigns',
          href: '#',
          active: true
        },
      ],
      breadcrumbsarchived: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Cube Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Archived Cube Campaigns',
          href: '#',
          active: true
        },
      ]
    }
  },
};
</script>