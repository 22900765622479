<template>
  <div class="manageuser">
    <div v-if="type == 1">
      <ManageUserCube />
    </div>

    <div v-else-if="type == 2">
      <ManageUserProfile />
    </div>
    <div v-else>
      
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import ManageUserCube from "@/components/cube/ManageUserStart.vue";
  import ManageUserProfile from "@/components/profile/ManageUser.vue";

export default {
  name: "manageuser",
  components: {
    ManageUserCube,
    ManageUserProfile
  },
  computed: {
    ...mapState(['type', 'current_user_id']),
  },
  data() {
    return {
      list_type: this.$route.query.type,
    }
  },
};
</script>