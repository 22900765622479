<template>
  <div class="calendar">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Calendar</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    
    
    <vue-cal
      :events="events"
      :disableViews="disableViews"
      defaultView="week"
      :todayButton="true"
      :time="false"
      :events-on-month-view="true"
    >
      <template v-slot:event-renderer="{ event, view }">
        <router-link :to="{ name: 'managefixedcampaign', query: { id: event.campaign_id }}">
        <b-row>
          <b-col cols="12">
            <font-awesome-icon
              v-if="event.channel === 'Instagram' || event.channel === 'Instagram Stories'"
              :icon="['fab', 'instagram']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Youtube'"
              :icon="['fab', 'youtube']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'TikTok'"
              :icon="['fal', 'igloo']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Participation'"
              :icon="['fal', 'glass-cheers']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Podcast'"
              :icon="['fal', 'microphone']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Snapchat'"
              :icon="['fab', 'snapchat-ghost']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Blogg'"
              :icon="['fal', 'file-alt']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Event'"
              :icon="['fal', 'glass-cheers']"
              class="pull-right"
            />
            <font-awesome-icon
              v-if="event.channel === 'Production'"
              :icon="['fal', 'calculator']"
              class="pull-right"
            />
          </b-col>
          <b-col cols="12">
            <h5>{{ event.title }}</h5> 
          </b-col>
          <b-col cols="12">
            {{ event.profile_name }}
          </b-col>
          <b-col cols="12">
            <hr>
            <span class="type">{{ event.type }}</span>
          </b-col>
        </b-row>
        </router-link>
      </template>
      <template v-slot:no-event="{ event, view }">
        <p>Nothing planned</p>
      </template>
    </vue-cal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
  name: "brandcalendar",
  components: {
    VueCal
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Calendar',
          href: '#',
          active: true
        }
      ],
      events: [],
      disableViews: [ 'year', 'years' ]
    }
  },
  created () {
    this.listFixedCampaigns()
  },
  methods: {
    listFixedCampaigns() {
      this.$http.bamse.post('campaigns/fixed/brand/listalldates?brand_id='+this.brand_assets[0].brand_id)
        .then(response => this.listFixedCampaignsSuccess(response))
        .catch(() => this.listFixedCampaignsFailed())
    },
    listFixedCampaignsSuccess(response) {
      if(response.data.success) {

        response.data.success.events.forEach((item, index) => {
          this.events.push({
              
            title: item.campaign_name,
            start: item.end_date,
            end: item.end_date,
            allDay: true,
            class: 'vevent',
            channel: item.type,
            brand: item.company_name,
            type: item.type,
            campaign_id: item.campaign_id

          })

        })

        response.data.success.profile_activities.forEach((item, index) => {

          this.events.push({
              
            title: item.campaign_name,
            start: item.draft_date,
            end: item.draft_date,
            allDay: true,
            class: 'vdraft',
            channel: item.activity,
            brand: item.company_name,
            type: 'Draft',
            campaign_id: item.campaign_id,
            profile_name: item.profile_name

          })

          this.events.push({
              
            title: item.campaign_name,
            start: item.publish_date,
            end: item.publish_date,
            allDay: true,
            class: 'vpublish',
            channel: item.activity,
            brand: item.company_name,
            type: 'Publish',
            campaign_id: item.campaign_id,
            profile_name: item.profile_name

          })

        })

      }
    
    },
    listFixedCampaignsFailed() {
      this.error = 'no fetch sorry'
    }
  }
};
</script>