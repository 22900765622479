<template>
  <div class="campaign-info campaign-reports">
    <div v-if="this.statsarray == null">
      <h5>No report yet</h5>
    </div>
    
    <div v-if="this.statsarray != null">
      <b-row>
        <b-col cols="12">
          <b-button variant="outline-primary" size="sm" class="pull-right" @click="downloadPDF()">
            Download as PDF
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="this.statsarray != null" class="fixed-report" ref="capture">
      <b-row class="margin-top">
        <b-col cols="12" class="margin-top">
          <h2><font-awesome-icon :icon="{ prefix: 'far', iconName: 'chart-bar' }"/> {{campaign_name}} summary</h2>
        </b-col>
      </b-row>
      <b-row class="margin-top post-card">
        <b-col cols="12 text-center">
          <h4>Profiles</h4>
        </b-col>
        <b-row class="text-center profiles-report">
          <b-col cols="4" v-for="(item) in getProfileIds()">
            <b-img
              class="avatar-top"
              fluid
              :src="getProfileImg(item.profile_id)" 
              @error="replaceByDefault"
            ></b-img>
            {{item.profile_name}}
          </b-col>
        </b-row>
      </b-row>
      <b-row class="margin-top text-center post-card">
        <b-col cols="3">
          <span class="label">
            Posts
          </span>
          <h1>{{getNumber('posts', 'total')}}</h1>
        </b-col>
        <b-col cols="3">
          <span class="label">
            Impressions
          </span>
          <h1>{{getNumber('impressions', 'total').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
        </b-col>
        <b-col cols="3">
          <span class="label">
            Reach
          </span>
          <h1>{{getNumber('reach', 'total').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
        </b-col>
        <b-col cols="3">
          <span class="label">
            Engagements
          </span>
          <h1>{{getNumber('engagements', 'total').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
        </b-col>
        <b-col cols="3" class="margin-top">
          <span class="label">
            Profiles
          </span>
          <h3>{{getNumber('profiles', 'total').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
        </b-col>
        <b-col cols="3" class="margin-top">
          <span class="label">
            Avg. Impressions
          </span>
          <h3>{{getAverage(getNumber('impressions', 'total'), getNumber('posts', 'total'))}}</h3>
        </b-col>
        <b-col cols="3" class="margin-top">
          <span class="label">
            Avg. Reach
          </span>
          <h3>{{getAverage(getNumber('reach', 'total'), getNumber('posts', 'total'))}}</h3>
        </b-col>
        <b-col cols="3" class="margin-top">
          <span class="label">
            Avg. Engagements
          </span>
          <h3>{{getAverage(getNumber('engagements', 'total'), getNumber('posts', 'total'))}}</h3>
        </b-col>
      </b-row>

      <!-- Blog -->
      <div v-if="getNumber('posts', 'blog') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'file-alt' }"/> Blog posts Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="4">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'blog')}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Impressions
            </span>
            <h1>{{getNumber('impressions', 'blog').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Reach
            </span>
            <h1>{{getNumber('reach', 'blog').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'blog')}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Impressions Per Post
            </span>
            <h3>{{getAverage(getNumber('impressions', 'blog'), getNumber('posts', 'blog'))}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Reach Per Post
            </span>
            <h3>{{getAverage(getNumber('reach', 'blog'), getNumber('posts', 'blog'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'file-alt' }"/> Blog Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('blog')">
          <b-col cols="5" class="no-padding-left">
            <b-img
              :src="item.postimage" 
              fluid
              class="post-image-card"
            />
            <a target="_blank" :href="item.url">
              <b-button block variant="outline-primary">Read post</b-button>
            </a>
          </b-col>
          <b-col cols="7">
            <b-row class="post-card">
              <b-col cols="2">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="6">
                <span class="label">
                  Impressions
                </span>
                <h1>{{item.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="6">
                <span class="label">
                  Reach
                </span>
                <h1>{{item.reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="6" class="margin-top">
                <span class="label">
                  Profile Avg. Impressions
                </span>
                <h3>{{item.avg_impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
              <b-col cols="6" class="margin-top">
                <span class="label">
                  Profile Avg. Reach
                </span>
                <h3>{{item.avg_reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="6">
                <span class="label">
                  Total time spent
                </span>
                <h1>{{timeSpent(item.timespent / 60)}}</h1>
              </b-col>
              <b-col cols="6">
                <span class="label">
                  Average time spent
                </span>
                <h1>{{timeSpent(item.avg_timespent / 60)}} min</h1>
              </b-col>
              
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Instagram -->
      <div v-if="getNumber('posts', 'instagram') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/> Instagram Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="3">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'instagram')}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Impressions
            </span>
            <h1>{{getNumber('impressions', 'instagram').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Reach
            </span>
            <h1>{{getNumber('reach', 'instagram').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Engagements
            </span>
            <h1>{{getNumber('engagements', 'instagram').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'instagram')}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Impressions
            </span>
            <h3>{{getAverage(getNumber('impressions', 'instagram'), getNumber('posts', 'instagram'))}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Reach
            </span>
            <h3>{{getAverage(getNumber('reach', 'instagram'), getNumber('posts', 'instagram'))}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Engagements
            </span>
            <h3>{{getAverage(getNumber('engagements', 'instagram'), getNumber('posts', 'instagram'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/> Instagram Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('instagram')">
          <b-col cols="5" class="no-padding-left">
           <instagram-embed
              :url="item.url"
              :max-width=1000
              class="insta-frame"
            /> <!--
            <b-img
              :src="item.postimage" 
              fluid
              class="post-image-card"
            />
            <a target="_blank" :href="item.url">
              <b-button block variant="outline-primary">View post</b-button>
            </a> -->
          </b-col>
          <b-col cols="7">
            <b-row class="post-card">
              <b-col cols="2">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="4">
                <span class="label">
                  Impressions
                </span>
                <h1>{{item.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Reach
                </span>
                <h1>{{item.reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Engagements
                </span>
                <h1>{{item.engagement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Profile Avg. Impressions
                </span>
                <h3>{{item.avg_impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Profile Avg. Reach
                </span>
                <h3>{{item.avg_reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Profile Avg. Engagements
                </span>
                <h3>{{item.avg_engagement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="4">
                <span class="label">
                  Likes
                </span>
                <h1>{{item.likes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Comments
                </span>
                <h1>{{item.comments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Engagement rate
                </span>
                <h1>{{getEngagementRate(item.impressions, item.engagement)}} %</h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Instagram Stories -->
      <div v-if="getNumber('posts', 'instagramstories') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/> Instagram Stories Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="4">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'instagramstories')}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Impressions
            </span>
            <h1>{{getNumber('impressions', 'instagramstories').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Reach
            </span>
            <h1>{{getNumber('reach', 'instagramstories').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'instagramstories')}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Impressions Per Post
            </span>
            <h3>{{getAverage(getNumber('impressions', 'instagramstories'), getNumber('posts', 'instagramstories'))}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Reach Per Post
            </span>
            <h3>{{getAverage(getNumber('reach', 'instagramstories'), getNumber('posts', 'instagramstories'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/> Instagram Stories Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('instagramstories')">
          <b-col cols="5" class="no-padding-left">
            <b-img
              :src="item.postimage" 
              fluid
              class="post-image-card"
            />
            <a target="_blank" :href="item.url" v-if="isVideo(item.url)">
              <b-button block variant="outline-primary">View story</b-button>
            </a>
          </b-col>
          <b-col cols="7">
            <b-row class="post-card">
              <b-col cols="2">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="6">
                <span class="label">
                  Impressions
                </span>
                <h1>{{item.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="6">
                <span class="label">
                  Reach
                </span>
                <h1>{{item.reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="6" class="margin-top">
                <span class="label">
                  Profile Avg. Impressions
                </span>
                <h3>{{item.avg_impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
              <b-col cols="6" class="margin-top">
                <span class="label">
                  Profile Avg. Reach
                </span>
                <h3>{{item.avg_reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="3">
                <span class="label">
                  Exits
                </span>
                <h1>{{item.exits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Swipe Ups
                </span>
                <h1>{{item.swipeup.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Taps Back
                </span>
                <h1>{{item.tapsback.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Taps Forward
                </span>
                <h1>{{item.tapsforward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Youtube -->
      <div v-if="getNumber('posts', 'youtube') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/> Youtube Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="3">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'youtube')}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Impressions
            </span>
            <h1>{{getNumber('impressions', 'youtube').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Total Watch Time
            </span>
            <h1>{{timeSpent(getNumber('total_watchtime', 'youtube'))}}</h1>
          </b-col>
          <b-col cols="3">
            <span class="label">
              Engagements
            </span>
            <h1>{{getNumber('engagements', 'youtube').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'youtube')}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Impressions
            </span>
            <h3>{{getAverage(getNumber('impressions', 'youtube'), getNumber('posts', 'youtube'))}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Total Watch Time
            </span>
            <h3>{{timeSpent(getNumber('total_watchtime', 'youtube') / getNumber('posts', 'youtube'))}}</h3>
          </b-col>
          <b-col cols="3" class="margin-top">
            <span class="label">
              Avg. Engagements
            </span>
            <h3>{{getAverage(getNumber('engagements', 'youtube'), getNumber('posts', 'youtube'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/> Youtube Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('youtube')">
          <b-col cols="5" class="no-padding-left">
           <youtube 
            :video-id="getYoutubeID(item.url)"
            class="embed-responsive embed-responsive-16by9"
           ></youtube>
          </b-col>
          <b-col cols="7">
            <b-row class="post-card">
              <b-col cols="2">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="5">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="4">
                <span class="label">
                  Impressions
                </span>
                <h1>{{item.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Engagements
                </span>
                <h1>{{item.engagement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Total Watch Time
                </span>
                <h1>{{timeSpent(item.total_watchtime)}}</h1>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Profile Avg. Impressions
                </span>
                <h3>{{item.avg_impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Profile Avg. Engagements
                </span>
                <h3>{{item.avg_engagement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
              </b-col>
            </b-row>
            <b-row class="text-center post-card">
              <b-col cols="4">
                <span class="label">
                  Likes
                </span>
                <h1>{{item.likes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Comments
                </span>
                <h1>{{item.comments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Engagement rate
                </span>
                <h1>{{getEngagementRate(item.impressions, item.engagement)}} %</h1>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Percentage Viewed
                </span>
                <h1>{{item.avg_percentageviewed}}</h1>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Playlist Adds
                </span>
                <h1>{{item.playlist_adds}}</h1>
              </b-col>
              <b-col cols="4" class="margin-top">
                <span class="label">
                  Shares
                </span>
                <h1>{{item.shares}}</h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Podcast -->
      <div v-if="getNumber('posts', 'podcast') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'microphone' }"/> Podcast Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="4">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'podcast')}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Listens
            </span>
            <h1>{{getNumber('listens', 'podcast').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4">
            <span class="label">
              Unique Reach
            </span>
            <h1>{{getNumber('unique_reach', 'podcast').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'podcast')}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Listens
            </span>
            <h3>{{getAverage(getNumber('listens', 'podcast'), getNumber('posts', 'podcast'))}}</h3>
          </b-col>
          <b-col cols="4" class="margin-top">
            <span class="label">
              Avg. Unique Reach
            </span>
            <h3>{{getNumber('unique_reach', 'podcast') / getNumber('posts', 'podcast')}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'microphone' }"/> Podcast Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('podcast')">
          <b-col cols="12">
            <b-row class="post-card">
              <b-col cols="1">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
              <b-col cols="2">
                <span class="label">
                  Listens
                </span>
                <h4>{{item.listens.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h4>
              </b-col>
              <b-col cols="2">
                <span class="label">
                  Unique Reach
                </span>
                <h4>{{item.unique_reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Tiktok -->
      <div v-if="getNumber('posts', 'snapchat') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'igloo' }"/> TikTok Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="6">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'tiktok')}}</h1>
          </b-col>
          <b-col cols="6">
            <span class="label">
              Views
            </span>
            <h1>{{getNumber('views', 'tiktok').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="6" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'tiktok')}}</h3>
          </b-col>
          <b-col cols="6" class="margin-top">
            <span class="label">
              Avg. Views
            </span>
            <h3>{{getAverage(getNumber('views', 'tiktok'), getNumber('posts', 'tiktok'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'igloo' }"/> TikTok Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('tiktok')">
          <b-col cols="12">
            <b-row class="post-card">
              <b-col cols="1">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
              <b-col cols="2">
                <span class="label">
                  Views
                </span>
                <h4>{{item.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Snapchat -->
      <div v-if="getNumber('posts', 'snapchat') > 0">
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'snapchat-ghost' }"/> Snapchat Summary</h2>
          </b-col>
        </b-row>
        <b-row class="margin-top text-center post-card">
          <b-col cols="6">
            <span class="label">
              Posts
            </span>
            <h1>{{getNumber('posts', 'podcast')}}</h1>
          </b-col>
          <b-col cols="6">
            <span class="label">
              Views
            </span>
            <h1>{{getNumber('views', 'snapchat').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h1>
          </b-col>
          <b-col cols="6" class="margin-top">
            <span class="label">
              Profiles
            </span>
            <h3>{{getNumber('profiles', 'snapchat')}}</h3>
          </b-col>
          <b-col cols="6" class="margin-top">
            <span class="label">
              Avg. Views
            </span>
            <h3>{{getAverage(getNumber('views', 'snapchat'), getNumber('posts', 'snapchat'))}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 margin-top-report">
            <h2><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'snapchat-ghost' }"/> Snapchat Activities</h2>
          </b-col>
        </b-row>
      
        <b-row class="post-row" v-for="(item) in getChannelPosts('snapchat')">
          <b-col cols="12">
            <b-row class="post-card">
              <b-col cols="1">
                <b-img 
                  class="avatar"
                  fluid
                  :src="getProfileImg(item.profile_id)" 
                  @error="replaceByDefault"
                ></b-img>
              </b-col>
              <b-col cols="3">
                <span class="label">
                  Profile
                </span>
                <h4>{{item.profile_name}}</h4>
              </b-col>
              <b-col cols="4">
                <span class="label">
                  Published
                </span>
                <h4>{{item.date}}</h4>
              </b-col>
              <b-col cols="2">
                <span class="label">
                  Views
                </span>
                <h4>{{item.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      
     
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InstagramEmbed from 'vue-instagram-embed';
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import VueYouTubeEmbed from 'vue-youtube-embed'
import $ from 'jquery'
import jsPDF from 'jspdf'
import html2canvas from "html2canvas"


export default {
  name: "ReportsFixedCampaign",
  components: {
      InstagramEmbed,
      VueYouTubeEmbed
  },
  data: function () {
    return {
        stats: null,
        id: this.$route.query.id,
        statsarray: null,
        campaign_name: '',
      }
    },
  mounted() {
    
  },
  computed: {
  },
  created () {
    this.getStats()
    this.getCampaign()
  },
  methods: {
    deleteReport() {
        this.$http.bamse.post('campaigns/fixed/deletereport?campaign_id='+this.id)
        .then(response => this.deleteReportSuccess(response))
        .catch(() => this.deleteReportFailed())
    },
    deleteReportSuccess(response) {
      if(response.data.success) {

        this.statsarray = null
      }
    },
    deleteReportFailed() {
      this.error = 'no fetch sorry'
    },
    downloadPDF() {
      console.log("download")
    },
    getEngagementRate(impressions, engagement){
      var returnValue = Number(engagement / impressions)
      var returnValue = returnValue * 100
      var returnValue = returnValue.toFixed(2)

      return returnValue
    },
    isVideo(url){
      var returnValue = url.includes(".mp4")
      return returnValue
    },
    getYoutubeID(url){
      var n = url.lastIndexOf('/');
      var returnValue = url.substring(n + 1);
      return returnValue
    },
    getNumber(type, channel) {
      var returnValue = null
      if(type == 'posts' && channel == 'total') {
       returnValue = this.statsarray.length
      } else if(type == 'impressions' && channel == 'total') {
        var impressions = 0
        this.statsarray.forEach(item => {
          impressions = impressions + item.impressions
        });
        returnValue = impressions
      } else if(type == 'reach' && channel == 'total') {
        var reach = 0
        this.statsarray.forEach(item => {
          reach = Number(reach) + Number(item.reach)
        });
        returnValue = reach
      } else if(type == 'engagements' && channel == 'total') {
        var engagement = 0
        this.statsarray.forEach(item => {
          engagement = Number(engagement) + Number(item.engagement)
        });
        returnValue = engagement
      } else if(type == 'profiles' && channel == 'total') {
        var profiles = 0
        this.statsarray.forEach(item => {
          profiles = _.uniqBy(this.statsarray, 'profile_id');
        });
        returnValue = profiles.length
      } else if(type == 'posts') {
        var posts = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            posts = posts + 1
          }
        });
        returnValue = posts
      } else if(type == 'impressions') {
        var impressions = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            impressions = impressions + item.impressions
          }
        });
        returnValue = impressions
      } else if(type == 'reach') {
        var reach = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            reach = Number(reach) + Number(item.reach)
          }
        });
        returnValue = reach
      } else if(type == 'engagements') {
        var engagement = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            engagement = Number(engagement) + Number(item.engagement)
          }
        });
        returnValue = engagement
      } else if(type == 'total_watchtime') {
        var total_watchtime = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            total_watchtime = Number(total_watchtime) + Number(item.total_watchtime)
          }
        });
        returnValue = total_watchtime
      } else if(type == 'listens') {
        var listens = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            listens = Number(listens) + Number(item.listens)
          }
        });
        returnValue = listens
      } else if(type == 'unique_reach') {
        var unique_reach = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            unique_reach = Number(unique_reach) + Number(item.unique_reach)
          }
        });
        returnValue = unique_reach
      } else if(type == 'views') {
        var views = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            views = Number(views) + Number(item.views)
          }
        });
        returnValue = views
      } else if(type == 'profiles') {
        var profiles = 0
        this.statsarray.forEach(item => {
          if(item.type == channel){
            profiles = _.uniqBy(this.statsarray, 'profile_id');
          }
        });
        returnValue = profiles.length
      }
      return returnValue
    },
    getAverage(one, two){
      var returnValue = one / two
      var returnValue = Math.round(returnValue)
      return returnValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getChannelPosts(channel){
      var returnValue = _.filter(this.statsarray, { 'type': channel})
      return returnValue
    },
    getProfileIds(){
      var returnValue = _.uniqBy(this.statsarray, 'profile_id');
      return returnValue
    },
    timeSpent(seconds){
      var returnValue = moment.duration(seconds, 'seconds').format('hh:mm:ss')
      return returnValue
    },
    uploadStats() {

      let formData = new FormData();

      formData.append('stats', this.stats);
      formData.append('campaign_id', this.id);

      this.$http.bamse.post('campaigns/fixed/uploadstats', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.uploadStatsSuccess(response))
      .catch(error => this.uploadStatsFailed(error)) 
    },
    uploadStatsSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Stats uploaded!', 'success')
        this.getStats()
      }
    },
    uploadStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    getStats() {
        this.$http.bamse.get('campaigns/fixed/getstats?campaign_id='+this.id)
        .then(response => this.getStatsSuccess(response))
        .catch(() => this.getStatsFailed())
    },
    getStatsSuccess(response) {
      if(response.data.success) {

        if(response.data.success.length != 0){
          this.statsarray = response.data.success 
        }

        console.log(this.statsarray)
        
      }
    },
    getStatsFailed() {
      this.error = 'no fetch sorry'
    },
    getCampaign() {
        this.$http.bamse.get('campaigns/fixed/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {

        this.campaign_name = response.data.success[0][0].campaign_name
        //this.description = response.data.success[0][0].description
        //this.start_date = response.data.success[0][0].start_date
        //this.end_date = response.data.success[0][0].end_date
        //this.brand_id = response.data.success[0][0].brand_id

      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
  },
};
</script>