<template>
<div class="availablemicrocampaigns">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Archived Micro Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-row>

<b-col xl="4" lg="4" md="6" v-for="item in orderedItems(items)" v-if="item.status == 5" v-bind:key="item.id">
  <b-card
      class="card-archived-micro"
      overlay
      :img-src="getCampaignImg(item.id)"
      @error="replaceByDefault"
      text-variant="white"
  >

      <b-row>
          <b-col cols="12" class="text-center">
              <h3>{{ item.campaign_name }}</h3>
              <b-img
                  class="campaign-img"
                  :src="getBrandImg(item.brand_id)"
                  fluid
              ></b-img>
              <h5>{{ item.company_name }}</h5>

              <h1 class="payout-type" v-if="item.payout_type == 'cpc'">{{ item.profile_cpc }} {{ item.currency }}</h1>
              <h1 class="payout-type" v-if="item.payout_type == 'cpa_percentage'">{{ item.profile_payout_percent }}%</h1>
          </b-col>
      </b-row>
      <b-row class="foot mt-2">

            <b-col cols="4">
              <span class="label">Draft</span>
              <span v-if="item.draft_required == 1">Yes</span>
              <span v-else>No</span>
            </b-col>
            <b-col cols="4" class="text-center">
              <span class="label_center">End date</span>
              {{ item.end_date }}
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="label_right">Commission</span>
              {{ item.revenue_profile_per_post }} SEK
            </b-col>

            <div class="clearfix"></div>

             <b-col cols="12" class="text-center mt-2 mb-2">
              <span v-if="item.channels.includes(1)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/>
              </span>

              <span v-if="item.channels.includes(2)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook'}"/>
              </span>

              <span v-if="item.channels.includes(3)" class="icon micro-font-awesome">
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'home' }"/>
              </span>

              <span v-if="item.channels.includes(4)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/>
              </span>
            </b-col>

            <div class="clearfix"></div>

            <b-col cols="12" class="mt-2">
                <b-button variant="outline-light" disabled block>Thank you!</b-button>
            </b-col>

      </b-row>

  </b-card>
</b-col>

<!-- DEAN END -->

    </b-row>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'

  export default {
    name: "archivedmicrocampaigns",
    components: {
    },
    computed: {
      ...mapState(['profile_id', 'profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
    },
    data: function () {
      return {
        items: [],
        show: false,
        currentOrder: 'id',
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Archived Micro Campaigns',
          to: '#',
          active: true
        },
      ],
      }
    },
    created () {
      this.listMicroCampaigns()
    },
    methods: {
      getBrandImg(brand_id){
        return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
      },
      getCampaignImg(id){
        return this.$global.spacesURL()+'campaigns/micro/campaign_' + id + '/cover_image/campaign_' + id + '.png'
      },
      replaceByDefault(e){
        var url = "https://bamse-in-space.fra1.digitaloceanspaces.com/brands/brand_" + e.target.id + "/profile_picture/brand_" + e.target.id + ".png"

        e.target.src = url
      },
      orderedItems() {
        return _.orderBy(this.items, this.currentOrder, 'desc')
      },
      listMicroCampaigns() {
        this.$http.bamse.post('campaigns/micro/listallarchived/profile/self', {
          profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
        })
          .then(response => this.listMicroCampaignsSuccess(response))
          .catch(() => this.listMicroCampaignsFailed())
      },
      listMicroCampaignsSuccess(response) {
        if(response.data.success) {
          console.log(response.data.success)
          this.items = response.data.success
        }
      },
      listMicroCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
    }
  };
</script>