
<template>
  <div class="settings">

      <b-card header="Manage user">
        <b-form @submit.prevent="updateUserInfo()">
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="First name" label-for="firstname">
                <b-input :disabled="edit === false" name="firstname" v-model="name"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Last name" label-for="lastname">
                <b-input :disabled="edit === false" name="lastname" v-model="last_name" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="E-mail" label-for="email">
                <b-input :disabled="edit === false" name="email" v-model="email" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="user_type == 1">
              <b-form-group label-size="sm" label="User type" label-for="user-level">
                <b-form-select :disabled="edit === false" name="user-level" v-model="user_level" :options="level_list_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Phone" label-for="phone">
                <b-input :disabled="edit === false" name="phone" v-model="phone" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="User image" label-for="imageupload">
                <b-form-file
                  :disabled="edit === false"
                  v-model="file"
                  placeholder="Choose a picture..."
                  drop-placeholder="Drop picture here..."
                  accept=".jpg, .png"
                  name="imageupload"
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Address" label-for="street">
                <b-input :disabled="edit === false" v-model="address_street" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="City" label-for="town">
                <b-input :disabled="edit === false" name="town" v-model="address_town" ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label-size="sm" label="Zip Code" label-for="zip">
                <b-input :disabled="edit === false" name="zip" v-model="address_zip" ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-size="sm" label="Country" label-for="country">
                <b-form-select :disabled="edit === false" name="country" v-model="country" :options="country_options"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
         <b-button type="submit" class="btn btn-success pull-right" v-if="loading == false && edit == true">
            Save
          </b-button>
          <b-button disabled class="btn btn-success pull-right" v-else-if="loading == true && edit == true">
            <b-spinner small ></b-spinner> Loading
          </b-button>


          <b-button v-if="edit == true && loading == false && status == 'active'" class="pull-left" variant="outline-danger" v-on:click="suspendUser">Suspend</b-button>
          <b-button v-if="edit == true && loading == false && status == 'suspended'" class="pull-left" variant="outline-success" v-on:click="activateUser">Activate</b-button>
        </b-form>
        <b-button v-if="edit == true && loading == false" @click="this.doEdit" class="pull-right cancel-btn" variant="outline-danger">Cancel</b-button>
        <b-button v-if="edit === false" @click="this.doEdit" class="pull-right" variant="outline-primary">Edit</b-button>
      </b-card>

      <b-card header="Account settings">
        <b-row>
          <b-col md="6">
            <b-button v-if="reset == false" @click="resetPassword()" variant="outline-primary">
              Reset my password
            </b-button>
            <b-button v-else variant="outline-success" disabled>
              Check your inbox
            </b-button>
          </b-col>
          <b-col md="6">
             If you want to remove your account send an email to admin@revrelations.com
          </b-col>
        </b-row>
      </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex'
import ListProfiles from "@/components/cube/ListProfiles.vue";
import ListBrands from "@/components/cube/ListBrands.vue";

let level_list = [];

export default {
  name: "manageuser",
  components: {
    ListProfiles,
    ListBrands
  },
  computed: {
    ...mapState(['current_id', 'profile_assets', 'brand_assets', 'type', 'level']),
  },
  data: function () {
    return {
      reset: false,
      edit: false,
      error: false,
      success: false,
      id: this.$route.query.id,
      loading: false,
      name: '',
      last_name: '',
      email: '',
      user_type: '',
      user_type_name: '',
      user_level: '',
      phone: '',
      address_street: '',
      address_town: '',
      address_zip: '',
      country: '',
      status: '',
      file: null,
      selected: null,
      level_list_options: level_list,
      country_selected: null,
        country_options: [
        { value: null, text: 'Please select a country' },
        { value: "Afghanistan", text: "Afghanistan" },
        { value: "Åland Islands", text: "Åland Islands" },
        { value: "Albania", text: "Albania" },
        { value: "Algeria", text: "Algeria" },
        { value: "American Samoa", text: "American Samoa" },
        { value: "Andorra", text: "Andorra" },
        { value: "Angola", text: "Angola" },
        { value: "Anguilla", text: "Anguilla" },
        { value: "Antarctica", text: "Antarctica" },
        { value: "Antigua and Barbuda", text: "Antigua and Barbuda" },
        { value: "Argentina", text: "Argentina" },
        { value: "Armenia", text: "Armenia" },
        { value: "Aruba", text: "Aruba" },
        { value: "Australia", text: "Australia" },
        { value: "Austria", text: "Austria" },
        { value: "Azerbaijan", text: "Azerbaijan" },
        { value: "Bahamas", text: "Bahamas" },
        { value: "Bahrain", text: "Bahrain" },
        { value: "Bangladesh", text: "Bangladesh" },
        { value: "Barbados", text: "Barbados" },
        { value: "Belarus", text: "Belarus" },
        { value: "Belgium", text: "Belgium" },
        { value: "Belize", text: "Belize" },
        { value: "Benin", text: "Benin" },
        { value: "Bermuda", text: "Bermuda" },
        { value: "Bhutan", text: "Bhutan" },
        { value: "Bolivia", text: "Bolivia" },
        { value: "Bosnia and Herzegovina", text: "Bosnia and Herzegovina" },
        { value: "Botswana", text: "Botswana" },
        { value: "Bouvet Island", text: "Bouvet Island" },
        { value: "Brazil", text: "Brazil" },
        { value: "British Indian Ocean Territory", text: "British Indian Ocean Territory" },
        { value: "Brunei Darussalam", text: "Brunei Darussalam" },
        { value: "Bulgaria", text: "Bulgaria" },
        { value: "Burkina Faso", text: "Burkina Faso" },
        { value: "Burundi", text: "Burundi" },
        { value: "Cambodia", text: "Cambodia" },
        { value: "Cameroon", text: "Cameroon" },
        { value: "Canada", text: "Canada" },
        { value: "Cape Verde", text: "Cape Verde" },
        { value: "Cayman Islands", text: "Cayman Islands" },
        { value: "Central African Republic", text: "Central African Republic" },
        { value: "Chad", text: "Chad" },
        { value: "Chile", text: "Chile" },
        { value: "China", text: "China" },
        { value: "Christmas Island", text: "Christmas Island" },
        { value: "Cocos (Keeling) Islands", text: "Cocos (Keeling) Islands" },
        { value: "Colombia", text: "Colombia" },
        { value: "Comoros", text: "Comoros" },
        { value: "Congo", text: "Congo" },
        { value: "Congo, The Democratic Republic of The", text: "Congo, The Democratic Republic of The" },
        { value: "Cook Islands", text: "Cook Islands" },
        { value: "Costa Rica", text: "Costa Rica" },
        { value: "Cote D'ivoire", text: "Cote D'ivoire" },
        { value: "Croatia", text: "Croatia" },
        { value: "Cuba", text: "Cuba" },
        { value: "Cyprus", text: "Cyprus" },
        { value: "Czech Republic", text: "Czech Republic" },
        { value: "Denmark", text: "Denmark" },
        { value: "Djibouti", text: "Djibouti" },
        { value: "Dominica", text: "Dominica" },
        { value: "Dominican Republic", text: "Dominican Republic" },
        { value: "Ecuador", text: "Ecuador" },
        { value: "Egypt", text: "Egypt" },
        { value: "El Salvador", text: "El Salvador" },
        { value: "Equatorial Guinea", text: "Equatorial Guinea" },
        { value: "Eritrea", text: "Eritrea" },
        { value: "Estonia", text: "Estonia" },
        { value: "Ethiopia", text: "Ethiopia" },
        { value: "Falkland Islands (Malvinas)", text: "Falkland Islands (Malvinas)" },
        { value: "Faroe Islands", text: "Faroe Islands" },
        { value: "Fiji", text: "Fiji" },
        { value: "Finland", text: "Finland" },
        { value: "France", text: "France" },
        { value: "French Guiana", text: "French Guiana" },
        { value: "French Polynesia", text: "French Polynesia" },
        { value: "French Southern Territories", text: "French Southern Territories" },
        { value: "Gabon", text: "Gabon" },
        { value: "Gambia", text: "Gambia" },
        { value: "Georgia", text: "Georgia" },
        { value: "Germany", text: "Germany" },
        { value: "Ghana", text: "Ghana" },
        { value: "Gibraltar", text: "Gibraltar" },
        { value: "Greece", text: "Greece" },
        { value: "Greenland", text: "Greenland" },
        { value: "Grenada", text: "Grenada" },
        { value: "Guadeloupe", text: "Guadeloupe" },
        { value: "Guam", text: "Guam" },
        { value: "Guatemala", text: "Guatemala" },
        { value: "Guernsey", text: "Guernsey" },
        { value: "Guinea", text: "Guinea" },
        { value: "Guinea-bissau", text: "Guinea-bissau" },
        { value: "Guyana", text: "Guyana" },
        { value: "Haiti", text: "Haiti" },
        { value: "Heard Island and Mcdonald Islands", text: "Heard Island and Mcdonald Islands" },
        { value: "Holy See (Vatican City State)", text: "Holy See (Vatican City State)" },
        { value: "Honduras", text: "Honduras" },
        { value: "Hong Kong", text: "Hong Kong" },
        { value: "Hungary", text: "Hungary" },
        { value: "Iceland", text: "Iceland" },
        { value: "India", text: "India" },
        { value: "Indonesia", text: "Indonesia" },
        { value: "Iran, Islamic Republic of", text: "Iran, Islamic Republic of" },
        { value: "Iraq", text: "Iraq" },
        { value: "Ireland", text: "Ireland" },
        { value: "Isle of Man", text: "Isle of Man" },
        { value: "Israel", text: "Israel" },
        { value: "Italy", text: "Italy" },
        { value: "Jamaica", text: "Jamaica" },
        { value: "Japan", text: "Japan" },
        { value: "Jersey", text: "Jersey" },
        { value: "Jordan", text: "Jordan" },
        { value: "Kazakhstan", text: "Kazakhstan" },
        { value: "Kenya", text: "Kenya" },
        { value: "Kiribati", text: "Kiribati" },
        { value: "Korea, Democratic People's Republic of", text: "Korea, Democratic People's Republic of" },
        { value: "Korea, Republic of", text: "Korea, Republic of" },
        { value: "Kuwait", text: "Kuwait" },
        { value: "Kyrgyzstan", text: "Kyrgyzstan" },
        { value: "Lao People's Democratic Republic", text: "Lao People's Democratic Republic" },
        { value: "Latvia", text: "Latvia" },
        { value: "Lebanon", text: "Lebanon" },
        { value: "Lesotho", text: "Lesotho" },
        { value: "Liberia", text: "Liberia" },
        { value: "Libyan Arab Jamahiriya", text: "Libyan Arab Jamahiriya" },
        { value: "Liechtenstein", text: "Liechtenstein" },
        { value: "Lithuania", text: "Lithuania" },
        { value: "Luxembourg", text: "Luxembourg" },
        { value: "Macao", text: "Macao" },
        { value: "Macedonia, The Former Yugoslav Republic of", text: "Macedonia, The Former Yugoslav Republic of" },
        { value: "Madagascar", text: "Madagascar" },
        { value: "Malawi", text: "Malawi" },
        { value: "Malaysia", text: "Malaysia" },
        { value: "Maldives", text: "Maldives" },
        { value: "Mali", text: "Mali" },
        { value: "Malta", text: "Malta" },
        { value: "Marshall Islands", text: "Marshall Islands" },
        { value: "Martinique", text: "Martinique" },
        { value: "Mauritania", text: "Mauritania" },
        { value: "Mauritius", text: "Mauritius" },
        { value: "Mayotte", text: "Mayotte" },
        { value: "Mexico", text: "Mexico" },
        { value: "Micronesia, Federated States of", text: "Micronesia, Federated States of" },
        { value: "Moldova, Republic of", text: "Moldova, Republic of" },
        { value: "Monaco", text: "Monaco" },
        { value: "Mongolia", text: "Mongolia" },
        { value: "Montenegro", text: "Montenegro" },
        { value: "Montserrat", text: "Montserrat" },
        { value: "Morocco", text: "Morocco" },
        { value: "Mozambique", text: "Mozambique" },
        { value: "Myanmar", text: "Myanmar" },
        { value: "Namibia", text: "Namibia" },
        { value: "Nauru", text: "Nauru" },
        { value: "Nepal", text: "Nepal" },
        { value: "Netherlands", text: "Netherlands" },
        { value: "Netherlands Antilles", text: "Netherlands Antilles" },
        { value: "New Caledonia", text: "New Caledonia" },
        { value: "New Zealand", text: "New Zealand" },
        { value: "Nicaragua", text: "Nicaragua" },
        { value: "Niger", text: "Niger" },
        { value: "Nigeria", text: "Nigeria" },
        { value: "Niue", text: "Niue" },
        { value: "Norfolk Island", text: "Norfolk Island" },
        { value: "Northern Mariana Islands", text: "Northern Mariana Islands" },
        { value: "Norway", text: "Norway" },
        { value: "Oman", text: "Oman" },
        { value: "Pakistan", text: "Pakistan" },
        { value: "Palau", text: "Palau" },
        { value: "Palestinian Territory, Occupied", text: "Palestinian Territory, Occupied" },
        { value: "Panama", text: "Panama" },
        { value: "Papua New Guinea", text: "Papua New Guinea" },
        { value: "Paraguay", text: "Paraguay" },
        { value: "Peru", text: "Peru" },
        { value: "Philippines", text: "Philippines" },
        { value: "Pitcairn", text: "Pitcairn" },
        { value: "Poland", text: "Poland" },
        { value: "Portugal", text: "Portugal" },
        { value: "Puerto Rico", text: "Puerto Rico" },
        { value: "Qatar", text: "Qatar" },
        { value: "Reunion", text: "Reunion" },
        { value: "Romania", text: "Romania" },
        { value: "Russian Federation", text: "Russian Federation" },
        { value: "Rwanda", text: "Rwanda" },
        { value: "Saint Helena", text: "Saint Helena" },
        { value: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis" },
        { value: "Saint Lucia", text: "Saint Lucia" },
        { value: "Saint Pierre and Miquelon", text: "Saint Pierre and Miquelon" },
        { value: "Saint Vincent and The Grenadines", text: "Saint Vincent and The Grenadines" },
        { value: "Samoa", text: "Samoa" },
        { value: "San Marino", text: "San Marino" },
        { value: "Sao Tome and Principe", text: "Sao Tome and Principe" },
        { value: "Saudi Arabia", text: "Saudi Arabia" },
        { value: "Senegal", text: "Senegal" },
        { value: "Serbia", text: "Serbia" },
        { value: "Seychelles", text: "Seychelles" },
        { value: "Sierra Leone", text: "Sierra Leone" },
        { value: "Singapore", text: "Singapore" },
        { value: "Slovakia", text: "Slovakia" },
        { value: "Slovenia", text: "Slovenia" },
        { value: "Solomon Islands", text: "Solomon Islands" },
        { value: "Somalia", text: "Somalia" },
        { value: "South Africa", text: "South Africa" },
        { value: "South Georgia and The South Sandwich Islands", text: "South Georgia and The South Sandwich Islands" },
        { value: "Spain", text: "Spain" },
        { value: "Sri Lanka", text: "Sri Lanka" },
        { value: "Sudan", text: "Sudan" },
        { value: "Suriname", text: "Suriname" },
        { value: "Svalbard and Jan Mayen", text: "Svalbard and Jan Mayen" },
        { value: "Swaziland", text: "Swaziland" },
        { value: "Sweden", text: "Sweden" },
        { value: "Switzerland", text: "Switzerland" },
        { value: "Syrian Arab Republic", text: "Syrian Arab Republic" },
        { value: "Taiwan, Province of China", text: "Taiwan, Province of China" },
        { value: "Tajikistan", text: "Tajikistan" },
        { value: "Tanzania, United Republic of", text: "Tanzania, United Republic of" },
        { value: "Thailand", text: "Thailand" },
        { value: "Timor-leste", text: "Timor-leste" },
        { value: "Togo", text: "Togo" },
        { value: "Tokelau", text: "Tokelau" },
        { value: "Tonga", text: "Tonga" },
        { value: "Trinidad and Tobago", text: "Trinidad and Tobago" },
        { value: "Tunisia", text: "Tunisia" },
        { value: "Turkey", text: "Turkey" },
        { value: "Turkmenistan", text: "Turkmenistan" },
        { value: "Turks and Caicos Islands", text: "Turks and Caicos Islands" },
        { value: "Tuvalu", text: "Tuvalu" },
        { value: "Uganda", text: "Uganda" },
        { value: "Ukraine", text: "Ukraine" },
        { value: "United Arab Emirates", text: "United Arab Emirates" },
        { value: "United Kingdom", text: "United Kingdom" },
        { value: "United States", text: "United States" },
        { value: "United States Minor Outlying Islands", text: "United States Minor Outlying Islands" },
        { value: "Uruguay", text: "Uruguay" },
        { value: "Uzbekistan", text: "Uzbekistan" },
        { value: "Vanuatu", text: "Vanuatu" },
        { value: "Venezuela", text: "Venezuela" },
        { value: "Viet Nam", text: "Viet Nam" },
        { value: "Virgin Islands, British", text: "Virgin Islands, British" },
        { value: "Virgin Islands, U.S.", text: "Virgin Islands, U.S." },
        { value: "Wallis and Futuna", text: "Wallis and Futuna" },
        { value: "Western Sahara", text: "Western Sahara" },
        { value: "Yemen", text: "Yemen" },
        { value: "Zambia", text: "Zambia" },
        { value: "Zimbabwe", text: "Zimbabwe" }
        ],

    }
  },
  created () {
    this.getUserInfo()
    this.getLevelList()
  },
  methods: {
    checkTypeOfString(s) {
      if(s == '' || s == null || s == 'null'){
        return ''
      } else {
        return s
      }
    },
    getUserInfo() {
      if(this.level === 1 || this.level === 2) {
        this.$http.bamse.get('user/details?user_id='+this.id)
          .then(response => this.getUserInfoSuccess(response))
          .catch(() => this.getUserInfoFailed())
      } else {
        this.$http.bamse.get('user/details?user_id='+this.current_id)
          .then(response => this.getUserInfoSuccess(response))
          .catch(() => this.getUserInfoFailed())
      }
    },
    getUserInfoSuccess(response) {
     if(response.data.success) {
       this.name = this.checkTypeOfString(response.data.success[0].name)
       this.last_name = this.checkTypeOfString(response.data.success[0].last_name)
       this.email = this.checkTypeOfString(response.data.success[0].email)
       this.user_type = this.checkTypeOfString(response.data.success[0].user_type)
       this.user_type_name = this.checkTypeOfString(response.data.success[0].user_type_name)
       this.phone = this.checkTypeOfString(response.data.success[0].phone)
       this.address_street = this.checkTypeOfString(response.data.success[0].address_street)
       this.address_town = this.checkTypeOfString(response.data.success[0].address_town)
       this.address_zip = this.checkTypeOfString(response.data.success[0].address_zip)
       this.country = this.checkTypeOfString(response.data.success[0].country)
       this.status = this.checkTypeOfString(response.data.success[0].status)
       this.user_level = this.checkTypeOfString(response.data.success[0].user_level)
       this.$store.state.ext_profile_assets = this.checkTypeOfString(response.data.success.profile_assets)
       this.$store.state.ext_brand_assets = response.data.success.brand_assets
     }
    },
    getUserInfoFailed() {
      this.error = 'no fetch sorry'
    },
    updateUserInfo() {

      this.loading = true

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('last_name', this.last_name);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('user_type', this.user_type);
      formData.append('address_street', this.address_street);
      formData.append('address_town', this.address_town);
      formData.append('address_zip', this.address_zip);
      formData.append('country', this.country);
      formData.append('user_level', this.user_level);
      formData.append('file', this.file);

      this.$http.bamse.post('user/edit?user_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateUserInfoSuccess(response))
        .catch(() => this.updateUserInfoFailed())
    },
    updateUserInfoSuccess(response) {
      if(response.data.success) {
        localStorage.name = this.name+' '+this.last_name
        this.$global.makeToast(this, 'Success', 'User updated', 'success')

        setTimeout( () => this.$router.push({ path: 'settings'}), 1500);
      }
    },
    updateUserInfoFailed() {
      this.$global.makeToast(this, 'Oh oh!', 'Something went wrong!', 'danger')
      this.loading = false
      this.edit = false
    },
    doEdit() {
      this.edit = !this.edit
      this.success = false
      this.error = false
    },
    suspendUser() {
      this.$http.bamse.post('user/suspend?user_id='+this.id, {
        user_id: this.id,
      })
        .then(response => this.suspendUserSuccess(response))
        .catch(() => this.suspendUserFailed())

    },
    suspendUserSuccess(response) {
      if(response.data.success) {
        this.edit = false
        this.success = true
        this.$global.makeToast(this, 'Success', 'User suspended', 'success')
      }
    },
    suspendUserFailed() {
      this.error = true
    },
    activateUser() {
      this.$http.bamse.post('user/activate?user_id='+this.id, {
        user_id: this.id,
      })
        .then(response => this.activateUserSuccess(response))
        .catch(() => this.activateUserFailed())

    },
    activateUserSuccess(response) {
      if(response.data.success) {
        this.edit = false
        this.success = true
        this.$global.makeToast(this, 'Success', 'User activated', 'success')
      }
    },
    activateUserFailed() {
      this.error = true
    },
    getLevelList() {
      this.$http.bamse.get('user/listlevels')
        .then(response => this.getLevelListSuccess(response))
        .catch(() => this.getLevelListFailed())
    },
    getLevelListSuccess(response) {

      if(response.data.success) {
        level_list = []
        response.data.success.forEach(function (result, key) {
          level_list.push({value: result.id, text: result.user_level_name})
        });

        this.level_list_options = level_list
      }
    },
    getLevelListFailed() {
      this.error = 'no fetch sorry'
    },
    resetPassword() {
      this.$http.bamse.post('password/create', { email: this.email })
        .then(response => this.resetPasswordSuccessful(response))
        .catch(response => this.resetPasswordFailed(response))
    },
    resetPasswordSuccessful(response) {
      if (response.data) {
        this.$global.makeToast(this, 'Check your inbox', response.data.message)
        this.reset = true
      }
    },
    resetPasswordFailed(response) {
      this.$global.makeToast(this, 'Oh oh!', 'Couldnt find that email address in our system. Maybe you use another one?', 'danger')
      this.loading = false
    },
  }

};

</script>