<template>
  <div>
    <b-form @submit.prevent="createMicroCampaign()">
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="outline-secondary">Campaign information</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
                    <b-input name="campaign_name" v-model="campaign_name"></b-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Brand" label-for="brand_id">
                    <b-form-select name="brand_id" v-model="brand_id" :options="options_brand"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Sales person" label-for="sales_person">
                    <b-form-select name="sales_person" v-model="sales_person" :options="options_sales_person"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Project Manager" label-for="project_manager">
                    <b-form-select name="project_manager" v-model="project_manager" :options="options_project_manager"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Description" label-for="description">
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      placeholder="Describe the campaign"
                      rows="8"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="start_date"
                      name="start_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                       format="dd-MM-yyyy"
                      v-model="end_date"
                      name="end_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
                  <b-form-file
                    v-model="file"
                    placeholder="Choose a picture..."
                    drop-placeholder="Drop picture here..."
                    accept=".jpg, .png"
                    name="imageupload"
                  ></b-form-file>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="draft_req_form">
                  <b-form-checkbox
                    disabled
                    id="draft_required"
                    v-model="draft_required"
                    name="draft_required"
                    value="1"
                    unchecked-value="0"
                  >
                    Is a draft required?
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Channels" label-for="channels">
                    <div @click="addChannel(1)" class="channel-disabled" v-bind:class="{ channelactive: instagram }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/>
                    </div>
                    <div @click="addChannel(2)" class="channel-disabled" v-bind:class="{ channelactive: facebook }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/>
                    </div>
                    <div @click="addChannel(3)" class="channel-disabled" v-bind:class="{ channelactive: blog }">
                      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'home' }"/>
                    </div>
                    <div @click="addChannel(4)" class="channel-disabled" v-bind:class="{ channelactive: youtube }">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.accordion-1.accordion-2 variant="outline-primary" class="pull-right">Next</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-2 variant="outline-secondary">Budget</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Campaign Budget" label-for="price">
                    <b-input
                      type="number"
                      :state="price"
                      name="price"
                      v-model="price"
                      @input="getCubePercentage"
                    ></b-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Cube" label-for="revenue_cube">
                    <b-input-group :append="cube_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        type="number"
                        name="revenue_cube"
                        v-model="revenue_cube"
                        @input="getCubePercentage"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Profile" label-for="revenue_profile">
                    <b-input-group :append="profile_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        type="number"
                        name="revenue_profile"
                        v-model="revenue_profile"
                        @input="getProfilePercentage"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Price per post" label-for="price_per_post">
                    <b-input name="price_per_post" v-model="price_per_post"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-col cols="12" class="update-column">
        <b-button type="submit" class="pull-right btn-margin" variant="outline-success">
          Update campaign
        </b-button>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

import $ from 'jquery'

import ListProfiles from "@/components/cube/ListProfiles.vue";

let brand_list = [];
let profile_list = [];
let employee_list = [];

export default {
  name: "ManageMicroCampaign",
  components: {
    Datepicker,
    ListProfiles
  },
  data: function () {
    return {
      id: this.$route.query.id,
      cube_percentage: '0%',
      profile_percentage: '0%',
      sales_person: '',
      project_manager: '',
      campaign_name: '',
      micro_profiles_from_listview: '',
      brand_id: null,
      description: '',
      draft_required: 0,
      profiles: [],
      profiles_array: [],
      channels: [],
      channels_array: [],
      revenue_cube: 0,
      revenue_profile: 0,
      price_per_post: 0,
      start_date: null,
      end_date: null,
      file: '',
      payment_conditions: null,
      price: 0,
      options_project_manager: [],
      options_sales_person: [],
      options_brand: [
        { value: null, text: 'Please select an option' },
      ],
      options_profiles: profile_list,
      options_channels: [
        { value: 1, text: 'Instagram' },
        { value: 2, text: 'Facebook' },
        { value: 3, text: 'Blog' },
        { value: 4, text: 'Youtube'}
      ],
      channels_array: [],
      instagram: false,
      facebook: false,
      blog: false,
      youtube: false,
      options_payment_conditions: [
        { value: null, text: 'Please select an option' },
        { value: 10, text: '10 days' },
        { value: 15, text: '15 days' },
        { value: 20, text: '20 days' },
        { value: 30, text: '30 days'}
      ],
      selected_profiles_micro: [],
    }
  },
  computed: {
  },
  created () {
    this.getBrandList()
    this.getMicroCampaign()
    this.getcubeEmployees()
  },
  methods: {
    addChannel(id) {
      if(id == 1) {
        this.instagram = !this.instagram
      }
      if(id == 2) {
        this.facebook = !this.facebook
      }
      if(id == 3) {
        this.blog = !this.blog
      }
      if(id == 4) {
        this.youtube = !this.youtube
      }
      this.channels_array = _.uniqBy(this.channels_array, 'value')
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {

      if(response.data.success) {
        brand_list = []
        var brand_options_list = this.options_brand
        response.data.success.forEach(function (result, key) {
          brand_options_list.push({value: result.id, text: result.company_name})
        });
      }
    },
    getBrandListFailed() {
      this.error = 'no fetch sorry'
    },
    getcubeEmployees() {
      this.$http.bamse.get('/user/listcube')
        .then(response => this.getcubeEmployeesSuccess(response))
        .catch(() => this.getcubeEmployeesFailed())
    },
    getcubeEmployeesSuccess(response) {

      if(response.data.success) {
        console.log(response.data.success)
        employee_list = []
        response.data.success.forEach(function (result, key) {
          var name = result.name + " " + result.last_name
          employee_list.push({value: result.id, text: name})
        });

        this.options_sales_person = employee_list
        this.options_project_manager = employee_list
      }
    },
    getcubeEmployeesFailed() {
      this.error = 'no fetch sorry'
    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {

      if(response.data.success) {
        profile_list = []
        response.data.success.forEach(function (result, key) {
          profile_list.push({value: result.id, text: result.name})
        });

        this.options_profiles = profile_list
      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    getMicroCampaign() {
        this.$http.bamse.get('campaigns/micro/details?campaign_id='+this.id)
        .then(response => this.getMicroCampaignSuccess(response))
        .catch(() => this.getMicroCampaignFailed())
    },
    getMicroCampaignSuccess(response) {
      if(response.data.success) {
        var channels_array = JSON.parse("[" + response.data.success[0].channels + "]");
        this.campaign_name = response.data.success[0].campaign_name,
        this.micro_profiles_from_listview = response.data.success[0].micro_profiles_from_listview,
        this.brand_id = response.data.success[0].brand_id,
        this.project_manager = response.data.success[0].project_manager,
        this.channels_array = response.data.success[0].channels,
        this.draft_required = response.data.success[0].draft_required,
        this.sales_person = response.data.success[0].sales_person,
        this.description = response.data.success[0].description,
        this.price_per_post = response.data.success[0].revenue_profile_per_post,
        this.get_payment_array = response.data.success[0].payments,
        this.price = response.data.success[0].campaign_budget,
        this.revenue_cube = response.data.success[0].revenue_cube,
        this.revenue_profile = response.data.success[0].revenue_profiles,
        this.start_date = response.data.success[0].start_date,
        this.end_date = response.data.success[0].end_date,
        this.payment_conditions = response.data.success[0].payment_conditions
        if(this.channels_array.includes(1)){
          this.instagram = true
        }
        if(this.channels_array.includes(2)){
          this.facebook = true
        }
        if(this.channels_array.includes(3)){
          this.blog = true
        }
        if(this.channels_array.includes(4)){
          this.youtube = true
        }

      }
    },
    getMicroCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    createMicroCampaign() {

      let formData = new FormData();

      this.channels_array = []

      if(this.instagram == true) {
        this.channels_array.push({value: 1, text: "Instagram"});
      }

      if(this.facebook == true) {
        this.channels_array.push({value: 2, text: "Facebook"});
      }

      if(this.blog == true) {
        this.channels_array.push({value: 3, text: "Blog"});
      }

      if(this.youtube == true) {
        this.channels_array.push({value: 4, text: "Youtube"});
      }


      this.channels_array = _.uniqBy(this.channels_array, 'value')

      this.channels_array = JSON.stringify(this.channels_array)

      this.start_date = JSON.stringify(this.start_date)
      this.end_date = JSON.stringify(this.end_date)

      formData.append('campaign_name', this.campaign_name);
      formData.append('brand_id', this.brand_id);
      formData.append('project_manager', this.project_manager);
      formData.append('draft_required', this.draft_required);
      formData.append('sales_person', this.sales_person);
      formData.append('description', this.description);
      formData.append('price_per_post', this.price_per_post);
      formData.append('channels', this.channels_array);
      formData.append('campaign_budget', this.price);
      formData.append('revenue_cube', this.revenue_cube);
      formData.append('revenue_profiles', this.revenue_profile);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
//    formData.append('file', this.file);

      this.$http.bamse.post('campaigns/micro/edit?campaign_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.createMicroCampaignSuccess(response))
      .catch(error => this.createMicroCampaignFailed(error))
    },
    createMicroCampaignSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Micro campaign updated', 'success')
      }
    },
    createMicroCampaignFailed() {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
    getCubePercentage() {
      this.cube_percentage = ""+Math.round(this.revenue_cube / this.price * 100)+"%"
    },
    getProfilePercentage() {
      this.profile_percentage = ""+Math.round(this.revenue_profile / this.price * 100)+"%"
    }
  },
};
</script>