<template>
  <div class="campaign-info campaign-reports">
    <b-row>
      <b-col cols="12" class="header">
        <h1>General adlink report</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="campaign-reports-box">
          <div>
            <b-form>
              <b-row v-if="showdates">
                <b-col lg="2">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="start_date"
                      name="start_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="end_date"
                      name="end_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-button @click="updateGraph()" class="pull-left active-sort getdatesbtn" variant="outline-primary">Submit</b-button>
                   <b-button @click="showdates = false" class="pull-left active-sort getdatesbtn" variant="outline-primary">Cancel</b-button>
                </b-col>
                <b-col xl="5" class="datefilter">
                   <b-button @click="pickDates(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 90 days</b-button>

                   <b-button @click="pickDates(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 30 days</b-button>

                  <b-button @click="pickDates(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 7 days</b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col lg="3" class="margin-bottom-sort">
                  <span class="label">Current dates</span>
                  <b-button @click="showdates = true" v-if="loading == false" class="active-sort datebtn" variant="outline-primary">{{ start_date}} - {{ end_date }}</b-button>
                  <b-button class="active-sort datebtn" variant="outline-primary" v-else>
                    <b-spinner small ></b-spinner> Loading
                  </b-button>
                </b-col>
                <b-col lg="3">
                  <span class="label">Brands</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick brands"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="brand_array"
                    :options="brand_options"
                    label="text"
                    track-by="text"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} brands selected</span></template>
                  </multiselect>
                </b-col>
                <b-col lg="3">
                  <span class="label">Profiles</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick profiles"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="profiles_array"
                    :options="profiles_options"
                    label="text"
                    track-by="text"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} profiles selected</span></template>
                  </multiselect>
                </b-col>
                <b-col lg="3">
                  <span class="label">Campaigns</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick campaigns"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="campaign_array"
                    :options="campaign_options"
                    label="text"
                    track-by="text"
                  >
                  <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} campaigns selected</span></template>
                  </multiselect>
                </b-col>
                <b-col cols="12" class="sortfilter">
                   <b-button @click="pickFilter(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 1</b-button>

                   <b-button @click="pickFilter(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 2</b-button>

                  <b-button @click="pickFilter(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 3</b-button>

                  <b-button @click="updateGraph()" class="pull-right active-sort getdatesbtn updategraph" variant="outline-primary">Update</b-button>
                </b-col>
              </b-row>
            </b-form>

            <hr class="reporthr">
              
            <b-row class="margin-bottom">
              <b-col cols="12" class="margin-bottom sortable">
                <b-button @click="filterChart('all')" id="show-all" class="active-sort" variant="outline-primary">Show all</b-button>
                <b-button @click="filterChart('hideall')" id="hide-all" class="hide-all" variant="outline-primary">Hide all</b-button>
                <b-button :id="item.id" @click="filterChart(item.label)" v-for="item in chartData['line'].datasets" variant="outline-primary" class="active-sort" :style="'background-color:'+item.backgroundColor">{{ item.label }}</b-button>
              </b-col>
            </b-row>
          </div>
          <line-chart :updated="updated" :options="options" :chart-data="chartData['line']"></line-chart>
        </b-card>
        <b-card v-if="profiles.length && statsByProfileBars.length">
          <bar-chart :updated="updated" :options="options" :chart-data="chartData['bar']"></bar-chart>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'

import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'

import Vue from 'vue'
import { log } from 'util'
import { stringify } from 'querystring'

export default {
  name: "AdlinkGeneralReport",
  components: {
      LineChart,
      Datepicker,
      Multiselect,
      BarChart
  },
  data: function () {
    return {
      chartData: {},
      barChartTempData: {},
      items: [],
      updated: 0,
      current_tune_id: null,
      rejected: [],
      showall:true,
      showdates: false,
      campaign_name: null,
      loading: true,
      start_date: null,
      end_date: null,
      responseArrayConversions: [],
      brand_array: [],
      brands: [],
      brand_options: [],
      profiles_array: [],
      profiles: [],
      profiles_options: [],
      campaign_array: [],
      campaigns: [],
      statsByDate: [],
      statsByCampaign: [],
      statsByBrand: [],
      statsByCountry: [],
      statsByProfile: [],
      statsByProfileBars: [],
      campaign_options: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Reports',
          to: '/reports'
        },
        {
          text: 'General Adlink report',
          href: '#',
          active: true
        },
      ],
      options: null
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['country', 'type', 'profile_id', 'profile_assets', 'brand_assets']),
  },
  created () {
    this.checkUser()
    this.getCurrencyName()
    this.setDate()
    this.getBrandList()
    this.getProfileList()
    this.getCampaignList()
    this.getCampaignStats()
  },
  methods: {
    sortButtons() {
      this.chartData['line'].datasets.forEach((item) => {
          var id = item.id
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = false
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)
        })
    },
    checkUser() {
      if(this.$store.state.type != 1) {
        if(this.$store.state.profile_id != null) {
          var profile = this.$store.state.profile_id;  
          var index = _.findIndex(this.profile_assets, { 'profile_id': profile });
          this.current_tune_id = this.profile_assets[index].tune_id;
          this.profiles = [this.current_tune_id];
        }

        if(this.$store.state.brand_id != null) {
          var brand = this.$store.state.brand_id;  
          var index = _.findIndex(this.brand_assets, { 'brand_id': brand });
          this.current_tune_id = this.brand_assets[index].tune_id;
          this.brands = [this.current_tune_id];
          console.log(this.brands);
        }
      }
    },
    getCurrencyName() {
      var currencyName = this.$global.getCurrencyName(this.country)
      this.options = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
          }],
          xAxes: [ {
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label;
              var id = data.datasets[tooltipItem.datasetIndex].id;
              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              if(id == 4 || id == 5 || id == 6 || id == 7 || id == 8 || id == 10 || id == 13){
                label += " "+currencyName;
              } else if ( id == 9 || id == 11){
                label += '%';
              }
              return label;
            }
          }
        }
      }
    },
    getCampaignStats() {

      this.statsByDate = [];
      this.statsByCampaign = [];
      this.statsByBrand = [];
      this.statsByCountry = [];
      this.statsByProfile = [];
      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false

      let formData = new FormData();

      formData.append('Target', 'Report');
      formData.append('Method', 'getConversions');
      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');

      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);

      formData.append('fields[]', 'Offer.name');
      formData.append('fields[]', 'Offer.ref_id');
      

      formData.append('fields[]', 'Stat.date');
      formData.append('fields[]', 'Stat.status');
      formData.append('fields[]', 'Stat.net_sale_amount');
      formData.append('fields[]', 'Stat.sale_amount');
      formData.append('fields[]', 'Stat.payout');
      formData.append('fields[]', 'Stat.revenue');
      formData.append('totals', 1);
      formData.append('limit', '0');
      formData.append('sort[Stat.date]=', 'ASC');

      formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.status][values][]', 'rejected');
      formData.append('filters[Stat.status][values][]', 'pending');
      formData.append('filters[Stat.status][values][]', 'approved');

      if(this.brands.length > 0) {
        formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
        this.brands.forEach((item) => {
          formData.append('filters[Advertiser.ref_id][values][]', item);
        })
      }

      if(this.profiles.length > 0) {
        formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
        this.profiles.forEach((item) => {
          formData.append('filters[Affiliate.ref_id][values][]', item);
        })
      }

      if(this.campaigns.length > 0) {
        formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
        this.campaigns.forEach((item) => {
          formData.append('filters[Offer.ref_id][values][]', item);
        })
      }

       

      this.$http.tune.post('', formData)
      .then(response => this.getCampaignStatsSuccess(response))
      .catch(error => this.getCampaignStatsFailed(error))
    },
    getCampaignStatsSuccess(response) {

      if(response.data.response.status == 1) {

        this.statsByDate = []
        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = []
        var conversions = []
        var revenue = []
        var payout = []
        var profit = []
        var sale_amount = []
        var net_sale_amount = []
        var aov = []
        var cos = []
        var roi = []
        var margin = []
        var returns = []

        //Count the number of days between start_date and end_date
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);

        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //Loop through number of day (+1 to count today) and add new date.
        var i;
        var date_to_add = this.start_date;
        for (i = 0; i < Difference_In_Days+1; i++) {
            //Fill array with correct dates. And fill info arrays with same amount of posts with value 0
            dates.push(date_to_add)
            conversions.push(0)
            revenue.push(0)
            payout.push(0)
            profit.push(0)
            sale_amount.push(0)
            net_sale_amount.push(0)
            margin.push(0)
            aov.push(0)
            cos.push(0)
            roi.push(0)
            returns.push(0)
            date_to_add = moment(date_to_add).add(1, 'days').format('YYYY-MM-DD');
        }

        //IF TYPE: DATE
        

          responseArray.forEach((item) => {
            //Find index from date array corresponding to item date
            var dateIndex = dates.findIndex(dates => dates === item.Stat.date);

            //Add conversions
            var newConversionValue = conversions[dateIndex]+1;
            conversions[dateIndex] = newConversionValue
            
            //Add revenue
            var newRevenueValue = revenue[dateIndex]+parseInt(this.$global.convertCurrency(this.country, item.Stat.revenue));
            revenue[dateIndex] = newRevenueValue

            //Add payout
            var newPayoutValue = payout[dateIndex]+parseInt(this.$global.convertCurrency(this.country, item.Stat.payout));
            payout[dateIndex] = newPayoutValue

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = sale_amount[dateIndex]+parseInt(this.$global.convertCurrency(this.country, tuneSaleAmountNew));
            sale_amount[dateIndex] = newSaleAmountValue

            //Add profit
            var profitValue = parseInt(this.$global.convertCurrency(this.country, item.Stat.revenue))-parseInt(this.$global.convertCurrency(this.country, item.Stat.payout));
            var newProfitValue = profit[dateIndex]+profitValue;
            profit[dateIndex] = newProfitValue

            //Add returns
            if(item.Stat.status=="rejected") {
              var newReturnsValue = returns[dateIndex]+1;
              returns[dateIndex] = newReturnsValue
            }

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = net_sale_amount[dateIndex]+parseInt(this.$global.convertCurrency(this.country, tuneNetSaleAmountNew));

            net_sale_amount[dateIndex] = newNetSaleAmountValue
            

          });

          //Loop through dates and do new calculations based on results in previous loop
          dates.forEach((item, index) => {

            //Add AOV
            if(conversions[index]==0) {
              aov[index] = 0;
            } else {
              var aovValue = sale_amount[index]/conversions[index];
              var newAovValue = aov[index]+parseFloat(this.$global.convertCurrency(this.country, aovValue));
              aov[index] = newAovValue.toFixed(2)
            }

            if(this.type == 1) {
              //Add COS
              if(revenue[index]==0) {
                cos[index] = 0;
              } else {
                var cosValue = revenue[index]/sale_amount[index];
                var newCosValue = cos[index]+cosValue;
                cos[index] = newCosValue.toFixed(2)
              }

              //Add ROI
              if(revenue[index]==0) {
                roi[index] = 0;
              } else {
                var roiValue = sale_amount[index]/revenue[index];
                var newRoiValue = roi[index]+parseFloat(this.$global.convertCurrency(this.country, roiValue));
                roi[index] = newRoiValue.toFixed(2)
              }
            
              //Add margin
              var marginValueSEK = profit[index]/revenue[index];
              var marginValue = marginValueSEK*100;
              var newMarginValue = margin[index]+marginValue;
              margin[index] = newMarginValue.toFixed(2)
            }

            //V2.0
            this.statsByDate.push({
              date: item,
              conversions: conversions[index],
              revenue: revenue[index],
              payout: payout[index],
              profit: profit[index],
              sale_amount: sale_amount[index],
              net_sale_amount: net_sale_amount[index],
              aov: aov[index],
              cos: cos[index],
              roi: roi[index],
              margin: margin[index],
              returns: returns[index],
            })

          });


          var newLabels = []
          var newData = []

          newLabels = dates

          newData.push({
              id: 3,
              label: 'Conversions',
              data: conversions,
              fill: false,
              hidden: false,
              borderColor: [
                  '#DF744A',
              ],
              backgroundColor: 'rgba(223, 116, 74, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 4,
              label: 'Revenue',
              data: revenue,
              fill: false,
              hidden: false,
              borderColor: [
                  '#DCB239',
              ],
              backgroundColor: 'rgba(220, 178, 57, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 5,
              label: 'Payout',
              data: payout,
              fill: false,
              hidden: false,
              borderColor: [
                  '#E24E42',
              ],
              backgroundColor: 'rgba(226, 78, 66, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 12,
              label: 'Returns',
              data: returns,
              fill: false,
              hidden: false,
              borderColor: [
                  '#636B46',
              ],
              backgroundColor: 'rgba(99, 107, 70, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 6,
              label: 'Profit',
              data: profit,
              fill: false,
              hidden: false,
              borderColor: [
                  '#EB6E80',
              ],
              backgroundColor: 'rgba(235, 110, 128, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 7,
              label: 'Order value',
              data: sale_amount,
              fill: false,
              hidden: false,
              borderColor: [
                  '#008F95',
              ],
              backgroundColor: 'rgba(0, 143, 149, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 13,
              label: 'Net Order Value',
              data: net_sale_amount,
              fill: false,
              hidden: false,
              borderColor: [
                  '#fe4a49',
              ],
              backgroundColor: 'rgba(254, 74, 73, 0.3)',
              borderWidth: 1
          })

          newData.push({
              id: 8,
              label: 'AOV',
              data: aov,
              fill: false,
              hidden: false,
              borderColor: [
                  '#94618E',
              ],
              backgroundColor: 'rgba(148, 97, 142, 0.3)',
              borderWidth: 1
          })

          if(this.type == 1) {
            newData.push({
                id: 9,
                label: 'COS%',
                data: cos,
                fill: false,
                hidden: false,
                borderColor: [
                    '#4484CE',
                ],
                backgroundColor: 'rgba(68, 132, 206, 0.3)',
                borderWidth: 1
            })

            newData.push({
                id: 10,
                label: 'ROI',
                data: roi,
                fill: false,
                hidden: false,
                borderColor: [
                    '#6B7A8F',
                ],
                backgroundColor: 'rgba(107, 122, 143, 0.3)',
                borderWidth: 1
            })
          
            newData.push({
                id: 11,
                label: 'Margin',
                data: margin,
                fill: false,
                hidden: false,
                borderColor: [
                    '#66AB8C',
                ],
                backgroundColor: 'rgba(102, 171, 140, 0.3)',
                borderWidth: 1
            })
          }
          this.chartData['line'] = {
          //Data to be represented on x-axis
            labels: newLabels,
            datasets: newData
          }


        this.getClickStats(dates)

      }
    },
    getCampaignStatsFailed(error) {
      console.log(this, 'Oh oh!', error, 'danger')
    },
    getCampaignProfileStats() {
      this.statsByDate = [];
      this.statsByCampaign = [];
      this.statsByBrand = [];
      this.statsByCountry = [];
      this.statsByProfileBars = [];
      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);
      formData.append('fields[]', 'Offer.name');
      formData.append('fields[]', 'Offer.ref_id');
      formData.append('limit', '0');

      formData.append('Target', 'Report');
      formData.append('Method', 'getConversions');
      formData.append('fields[]', 'Stat.date');
      formData.append('fields[]', 'Stat.status');
      formData.append('fields[]', 'Stat.net_sale_amount');
      formData.append('fields[]', 'Stat.sale_amount');
      formData.append('fields[]', 'Stat.payout');
      formData.append('fields[]', 'Stat.revenue');
      formData.append('fields[]', 'Stat.country_code');
      formData.append('fields[]', 'Advertiser.ref_id');
      formData.append('fields[]', 'Advertiser.company');
      formData.append('fields[]', 'Affiliate.ref_id');
      formData.append('fields[]', 'Affiliate.company');
      formData.append('totals', 1);
      formData.append('sort[Stat.date]=', 'ASC');

      formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.status][values][]', 'rejected');
      formData.append('filters[Stat.status][values][]', 'pending');
      formData.append('filters[Stat.status][values][]', 'approved');

      if(this.brands.length > 0) {
        formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
        this.brands.forEach((item) => {
          formData.append('filters[Advertiser.ref_id][values][]', item);
        })
      }

      if(this.campaigns.length > 0) {
        formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
        this.campaigns.forEach((item) => {
          formData.append('filters[Offer.ref_id][values][]', item);
        })
      }

      this.$http.tune.post('', formData)
      .then(response => this.getCampaignStatsProfileSuccess(response))
      .catch(error => this.getCampaignStatsProfileFailed(error))
    },
    getCampaignStatsProfileSuccess(response) {

      if(response.data.response.status == 1) {
        //this.filterChart('all')
        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = []
        var conversions = []
        var revenue = []
        var payout = []
        var profit = []
        var sale_amount = []
        var net_sale_amount = []
        var aov = []
        var cos = []
        var roi = []
        var margin = []
        var returns = []

        //Count the number of days between start_date and end_date
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //Loop through number of day (+1 to count today) and add new date.
        var i;
        var date_to_add = this.start_date;
        for (i = 0; i < Difference_In_Days+1; i++) {
            //Fill array with correct dates. And fill info arrays with same amount of posts with value 0
            dates.push(date_to_add)
            conversions.push(0)
            revenue.push(0)
            payout.push(0)
            profit.push(0)
            sale_amount.push(0)
            net_sale_amount.push(0)
            margin.push(0)
            aov.push(0)
            cos.push(0)
            roi.push(0)
            returns.push(0)
            date_to_add = moment(date_to_add).add(1, 'days').format('YYYY-MM-DD');
        }

          var old_ref_id = 0;

          responseArray.forEach((item) => {
            var keyExists = this.profiles.includes(parseInt(item.Affiliate.ref_id));
            if(keyExists==true) {

              if(old_ref_id!=item.Affiliate.ref_id) {
                  this.statsByProfileBars[item.Affiliate.ref_id] = {
                    profile: item.Affiliate.company,
                    ref_id: item.Affiliate.ref_id,
                    clicks: 0,
                    unique_clicks: 0,
                    conversions: 0,
                    revenue: 0,
                    payout: 0,
                    profit: 0,
                    sale_amount: 0,
                    aov: 0,
                    cos: 0,
                    roi: 0,
                    cr: 0,
                    margin: 0,
                    returns: 0,
                    net_sale_amount: 0
                  }
                  old_ref_id = item.Affiliate.ref_id

              }


            //Add conversions
            this.statsByProfileBars[item.Affiliate.ref_id].conversions = this.statsByProfileBars[item.Affiliate.ref_id].conversions+1

            //Add revenue
            var newRevenueValue = this.statsByProfileBars[item.Affiliate.ref_id].revenue+parseInt(this.$global.convertCurrency(this.country, item.Stat.revenue));
            this.statsByProfileBars[item.Affiliate.ref_id].revenue = newRevenueValue

            //Add payout
            var newPayoutValue = this.statsByProfileBars[item.Affiliate.ref_id].payout+parseInt(this.$global.convertCurrency(this.country, item.Stat.payout));
            this.statsByProfileBars[item.Affiliate.ref_id].payout = newPayoutValue

            //Add returns
            if(item.Stat.status == 'rejected') {
              this.statsByProfileBars[item.Affiliate.ref_id].returns = this.statsByProfileBars[item.Affiliate.ref_id].returns+1
            }

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = this.statsByProfileBars[item.Affiliate.ref_id].sale_amount+parseInt(this.$global.convertCurrency(this.country, tuneSaleAmountNew));
            this.statsByProfileBars[item.Affiliate.ref_id].sale_amount = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = this.statsByProfileBars[item.Affiliate.ref_id].net_sale_amount+parseInt(this.$global.convertCurrency(this.country, tuneNetSaleAmountNew));

            this.statsByProfileBars[item.Affiliate.ref_id].net_sale_amount = newNetSaleAmountValue
            
            //Add profit
            var profitValue = parseInt(this.$global.convertCurrency(this.country, item.Stat.revenue))-parseInt(this.$global.convertCurrency(this.country, item.Stat.payout));
            var newProfitValue = this.statsByProfileBars[item.Affiliate.ref_id].profit+profitValue;
            this.statsByProfileBars[item.Affiliate.ref_id].profit = newProfitValue

            }

          })

          //Loop through dates and do new calculations based on results in previous loop
          this.statsByProfileBars.forEach((item, index) => {

            //Add AOV
            if(this.statsByProfileBars[index].conversions==0) {
              this.statsByProfileBars[index].aov = 0;
            } else {
              var aovValue = this.statsByProfileBars[index].sale_amount/this.statsByProfileBars[index].conversions;
              this.statsByProfileBars[index].aov = aovValue.toFixed(2)
            }

            //Add COS
            if(this.statsByProfileBars[index].revenue==0) {
              this.statsByProfileBars[index].cos = 0;
            } else {
              var cosValue = this.statsByProfileBars[index].revenue/this.statsByProfileBars[index].sale_amount;
              var newCosValue = this.statsByProfileBars[index].cos+cosValue;
              this.statsByProfileBars[index].cos = newCosValue.toFixed(2)
            }

            //Add ROI
            if(this.statsByProfileBars[index].revenue==0) {
              this.statsByProfileBars[index].roi = 0;
            } else {
              var roiValue = this.statsByProfileBars[index].sale_amount/this.statsByProfileBars[index].revenue;
              var newRoiValue = this.statsByProfileBars[index].roi+roiValue;
              this.statsByProfileBars[index].roi = newRoiValue.toFixed(2)
            }

            //Add margin
            var marginValueSEK = this.statsByProfileBars[index].profit/this.statsByProfileBars[index].revenue;
            var marginValue = marginValueSEK*100;
            var newMarginValue = this.statsByProfileBars[index].margin+marginValue;
            this.statsByProfileBars[index].margin = newMarginValue.toFixed(2)

          });

          //If profiles are chosen
            if(this.profiles.length>0) {

              //Arrays for barChart
              var conversionsBarChart = []
              var revenueBarChart = []
              var payoutBarChart = []
              var profitBarChart = []
              var sale_amountBarChart = []
              var net_sale_amountBarChart = []
              var aovBarChart = []
              var cosBarChart = []
              var roiBarChart = []
              var marginBarChart = []
              var returnsBarChart = []

              var newBarData = []
              var newBarLabels = []

              this.statsByProfileBars.forEach((item) => {
                newBarLabels.push(item.profile)
              })

              //Push data in bar chart
              this.statsByProfileBars.forEach((item) => {
                conversionsBarChart.push(item.conversions)
                revenueBarChart.push(item.revenue)
                payoutBarChart.push(item.payout)
                profitBarChart.push(item.profit)
                sale_amountBarChart.push(item.sale_amount)
                net_sale_amountBarChart.push(item.net_sale_amount)
                marginBarChart.push(item.margin)
                aovBarChart.push(item.aov)
                cosBarChart.push(item.cos)
                roiBarChart.push(item.roi)
                returnsBarChart.push(item.returns)
              })

              newBarData.push({
                  id: 3,
                  label: 'Conversions',
                  data: conversionsBarChart,
                  fill: false,
                  hidden: false,
                  borderColor: [
                      '#DF744A',
                  ],
                  backgroundColor: 'rgba(223, 116, 74, 0.3)',
                  borderWidth: 1
              })

              newBarData.push({
                    id: 4,
                    label: 'Revenue',
                    data: revenueBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#DCB239',
                    ],
                    backgroundColor: 'rgba(220, 178, 57, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 5,
                    label: 'Payout',
                    data: payoutBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#E24E42',
                    ],
                    backgroundColor: 'rgba(226, 78, 66, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 12,
                    label: 'Returns',
                    data: returnsBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#636B46',
                    ],
                    backgroundColor: 'rgba(99, 107, 70, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 6,
                    label: 'Profit',
                    data: profitBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#EB6E80',
                    ],
                    backgroundColor: 'rgba(235, 110, 128, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 7,
                    label: 'Order value',
                    data: sale_amountBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#008F95',
                    ],
                    backgroundColor: 'rgba(0, 143, 149, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 13,
                    label: 'Net Order Value',
                    data: net_sale_amountBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#fe4a49',
                    ],
                    backgroundColor: 'rgba(254, 74, 73, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 8,
                    label: 'AOV',
                    data: aovBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#94618E',
                    ],
                    backgroundColor: 'rgba(148, 97, 142, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 9,
                    label: 'COS%',
                    data: cosBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#4484CE',
                    ],
                    backgroundColor: 'rgba(68, 132, 206, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 10,
                    label: 'ROI',
                    data: roiBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#6B7A8F',
                    ],
                    backgroundColor: 'rgba(107, 122, 143, 0.3)',
                    borderWidth: 1
                })

                newBarData.push({
                    id: 11,
                    label: 'Margin',
                    data: marginBarChart,
                    fill: false,
                    hidden: false,
                    borderColor: [
                        '#66AB8C',
                    ],
                    backgroundColor: 'rgba(102, 171, 140, 0.3)',
                    borderWidth: 1
                })

              this.chartData['bar'] = {
                //Data to be represented on x-axis
                labels: newBarLabels,
                datasets: newBarData
              }

            }

          this.getClickBarsStats(dates)
      }
    },
    getCampaignStatsProfileFailed(error) {
      console.log('Oh oh!')
    },
    getClickStats(getDates) {

      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);

      if(this.brands.length > 0) {
        formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
        this.brands.forEach((item) => {
          formData.append('filters[Advertiser.ref_id][values][]', item);
        })
      }

      if(this.profiles.length > 0) {
        formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
        this.profiles.forEach((item) => {
          formData.append('filters[Affiliate.ref_id][values][]', item);
        })
      }

      if(this.campaigns.length > 0) {
        formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
        this.campaigns.forEach((item) => {
          formData.append('filters[Offer.ref_id][values][]', item);
        })
      }

 

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Stat.date');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Stat.date]=', 'ASC');


      


      this.$http.tune.post('', formData)
      .then(response => this.getClickStatsSuccess(response, getDates))
      .catch(error => this.getClickStatsFailed(error))
    },
    getClickStatsSuccess(response, getDates) {

      if(response.data.response.status == 1) {

        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = getDates
        var clicks = []
        var unique_clicks = []

        //Count the number of days between start_date and end_date
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //Loop through number of day (+1 to count today) and add new date.
        var i;
        var date_to_add = this.start_date;
        for (i = 0; i < Difference_In_Days+1; i++) {
            //Fill array with correct dates. And fill info arrays with same amount of posts with value 0
            clicks.push(0)
            unique_clicks.push(0)
        }

          responseArray.forEach((item) => {
            //Find index from date array corresponding to item date
            var dateIndex = dates.findIndex(dates => dates === item.Stat.date);

            //Add clicks and unique clicks
            clicks[dateIndex] = item.Stat.clicks
            unique_clicks[dateIndex] = item.Stat.unique_clicks

          });

          dates.forEach((item, index) => {
            //V2.0
            this.statsByDate[index].clicks = clicks[index]
            this.statsByDate[index].unique_clicks = unique_clicks[index]

          });

          
          var newLabels = []
          var newData = []

          newLabels = dates

         // console.log(this.chartData['line'].datasets)
          if(this.chartData['line'].datasets.length<=11) {
            this.chartData['line'].datasets.push({
                id: 1,
                label: 'Clicks',
                data: clicks,
                fill: false,
                hidden: false,
                borderColor: [
                    '#DF744A',
                ],
                backgroundColor: 'rgba(223, 116, 74, 0.3)',
                borderWidth: 1
            })

            this.chartData['line'].datasets.push({
                id: 2,
                label: 'Unique clicks',
                data: unique_clicks,
                fill: false,
                hidden: false,
                borderColor: [
                    '#DCB239',
                ],
                backgroundColor: 'rgba(220, 178, 57, 0.3)',
                borderWidth: 1
            })
          }

          this.updated = this.updated+1
          this.loading=false

        
        this.filterChart('all')
    }
    },
    getClickStatsFailed(error) {
      console.log('Oh oh!')
    },
    getClickBarsStats(getDates) {

      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Affiliate.company');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Affiliate.ref_id]=', 'ASC');



      this.$http.tune.post('', formData)
      .then(response => this.getClickStatsBarsSuccess(response, getDates))
      .catch(error => this.getClickStatsBarsFailed(error))
    },
    getClickStatsBarsSuccess(response, getDates) {

      if(response.data.response.status == 1) {

        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = getDates
        var clicks = []
        var unique_clicks = []

          responseArray.forEach((item) => {
            var keyExists = _.findIndex(this.statsByProfileBars, ['ref_id', item.Affiliate.ref_id]);
            if(keyExists!=-1) {
              this.statsByProfileBars[item.Affiliate.ref_id].clicks = item.Stat.clicks
              this.statsByProfileBars[item.Affiliate.ref_id].unique_clicks = item.Stat.unique_clicks
              clicks.push(item.Stat.clicks)
              unique_clicks.push(item.Stat.unique_clicks)
            }

          })

          this.chartData['bar'].datasets.push({
                  id: 1,
                  label: 'Clicks',
                  data: clicks,
                  fill: false,
                  hidden: false,
                  borderColor: [
                      '#DF744A',
                  ],
                  backgroundColor: 'rgba(223, 116, 74, 0.3)',
                  borderWidth: 1
              })

              this.chartData['bar'].datasets.push({
                  id: 2,
                  label: 'Unique clicks',
                  data: unique_clicks,
                  fill: false,
                  hidden: false,
                  borderColor: [
                      '#DCB239',
                  ],
                  backgroundColor: 'rgba(220, 178, 57, 0.3)',
                  borderWidth: 1
              })


          this.updated=this.updated+1

      }
    },
    getClickStatsBarsFailed(error) {
      console.log(this, 'Oh oh!', error, 'danger')
    },
    updateGraph() {



      this.profiles = []
      this.brands = []
      this.campaigns = []

      if(this.brand_array.length > 0) {
        this.brand_array.forEach((item) => {
          this.brands.push(item.value.tune_id)
        })
      } else {
        this.brands = []
        this.checkUser();
      }

      if(this.profiles_array.length > 0) {
        this.profiles_array.forEach((item) => {
          this.profiles.push(item.value.tune_id)
        })
      } else {
        this.profiles = []
        this.checkUser();
      }

      if(this.campaign_array.length > 0) {
        this.campaign_array.forEach((item) => {
          this.campaigns.push(item.value.tune_id)
        })
      } else {
        this.campaigns = []
        this.checkUser();
      }

      if(this.profiles_array.length > 0) {
        this.getCampaignProfileStats()
      }

      this.getCampaignStats()
      this.updated=this.updated+1
      this.filterChart('all')

    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        //.catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.profiles_options.push({value: {tune_id: result.tune_id}, text: result.name})
          }
        });

        this.profiles_options = _.orderBy(this.profiles_options, ['text'],['asc']);

      }
    },
    getProfileListFailed() {
      //this.error = 'no fetch sorry'
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.brand_options.push({value: {tune_id: result.tune_id, brand_id: result.id}, text: result.company_name})
          }
        });

        this.brand_options = _.orderBy(this.brand_options, ['text'],['asc']);

      }
    },
    getCampaignList() {
      // byt till ny endpoint campaigns/adlink/listall när den är skapad
      this.$http.bamse.get('campaigns/adlink/listall')
        .then(response => this.getCampaignListSuccess(response))
        .catch(() => this.getCampaignListFailed())
    },
    getCampaignListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.campaign_options.push({value: {tune_id: result.campaign_tune_id, brand_id: result.id}, text: result.campaign_name+' ('+result.brand_name+')'})
          }
        });

        this.campaign_options = _.orderBy(this.campaign_options, ['text'],['asc']);

      }
    },
    getCampaignListFailed() {
      //this.error = 'no fetch sorry'
    },
    pickFilter(id){

      this.chartData['line'].datasets.forEach((item) => {
          var id = item.id
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = false
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = false
            $("#"+this.chartData['bar'].datasets[index].id).addClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color:'+this.chartData['bar'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['bar'].datasets[index].backgroundColor)
          }
      })

      if(id == 1){


        var filterList = [4,5,6,7,8,9,10,11,12,13]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }

        });

        this.updated = this.updated+1

      } else if(id == 2){

        var filterList = [1,2,3,4,5,6,7,11,12,13]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }
        });

        this.updated = this.updated+1

      } else if(id == 3){

        var filterList = [1,2,3,8,9,10,12]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }

        });

        this.updated = this.updated+1

      }
    },
    pickDates(id){


      if(id == 1){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      } else if(id == 2){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      } else if(id == 3){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(90, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      }

      this.filterChart('all')
    },
    setDate(){
      this.end_date = moment().format('YYYY-MM-DD')
      this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
    },
    filterChart(label) {

      if(label == "all"){

          var count = 0

          this.chartData['line'].datasets.forEach((dataset) => {

            count = count+1
            dataset.hidden = false
            $("#"+count).addClass('active-sort')
            $("#"+dataset.id).attr('style', 'background-color:'+dataset.backgroundColor+'; border: 1px solid '+dataset.backgroundColor)

          });

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets.forEach((dataset) => {
              count = count+1
              dataset.hidden = false
              $("#"+count).addClass('active-sort')
              $("#"+dataset.id).attr('style', 'background-color:'+dataset.backgroundColor+'; border: 1px solid '+dataset.backgroundColor)

            });
          }
          this.updated = this.updated+1
      } else if (label == "hideall") {

  
          this.chartData['line'].datasets.forEach((dataset) => {

            count = count+1
            dataset.hidden = true
            $(".sortable .active-sort").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
            $(".sortable .active-sort").removeClass('active-sort')

          });

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets.forEach((dataset) => {
              count = count+1
              dataset.hidden = true
              $(".sortable .active-sort").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
              $(".sortable .active-sort").removeClass('active-sort')

            });
          }
       
        this.updated = this.updated+1

      } else {
        var index = _.findIndex(this.chartData['line'].datasets, {label: label});
        this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
        if(this.statsByProfileBars.length>0) {
          this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
        }
        this.updated = this.updated+1

        if(this.chartData['line'].datasets[index].hidden == true){
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          if(this.statsByProfileBars.length>0) {
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }
        } else {
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)
          if(this.statsByProfileBars.length>0) {
            $("#"+this.chartData['bar'].datasets[index].id).addClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color:'+this.chartData['bar'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['bar'].datasets[index].backgroundColor)
          }
        }
      }

      var number = this.chartData['line'].datasets.length
      var count = 0
      this.chartData['line'].datasets.forEach(function(dataset) {
        if(dataset.hidden == true){
          $("#show-all").removeClass('active-sort')
          $("#show-all").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
        } else {
          count = count+1
        }
      });
      if(this.statsByProfileBars.length>0) {
        this.chartData['bar'].datasets.forEach(function(dataset) {
          if(dataset.hidden == true){
            $("#show-all").removeClass('active-sort')
            $("#show-all").attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
          } else {
            count = count+1
          }
        });
      }

      if(count == number){

        $("#show-all").addClass('active-sort')
        $("#show-all").attr('style', 'background-color: #000; border: 1px solid #000; color: #fff;')
      }

    },
  },
};
</script>
