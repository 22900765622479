<template>
  <div class="all-users">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Money List for {{ profile_name }}</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="campaign-info-box">
          <div>
            <h4 class="header-h">Available</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
            </span>
          </div>
          <div class="clearfix"></div>
          <hr>
          
          {{this.$global.convertCurrency(country, total)}}
          <h6>Cube: {{this.$global.convertCurrency(country, total_cube)}}</h6>
          <h6>Micro: {{this.$global.convertCurrency(country, total_micro)}}</h6>
          <h6>Adlinks: {{this.$global.convertCurrency(country, total_adlinks)}}</h6>
          <h6>Transactions: {{this.$global.convertCurrency(country, total_general)}}</h6>

        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="campaign-info-box">
          <div>
            <h4 class="header-h">Next Payout</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'hand-holding-usd' }"/>
            </span>
          </div>
          <div class="clearfix"></div>
          <hr>

          {{this.$global.convertCurrency(country, approved)}}
          <h6>Cube: {{this.$global.convertCurrency(country, approved_cube)}}</h6>
          <h6>Micro: {{this.$global.convertCurrency(country, approved_micro)}}</h6>
          <h6>Adlinks: {{this.$global.convertCurrency(country, approved_adlinks)}}</h6>
          <h6>Transactions: {{this.$global.convertCurrency(country, approved_general)}}</h6>

        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-row v-if="total_cube != 0">
        <b-col cols="12" class="moneylisth4">
          <h4>Cube Campaigns</h4>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="cube_transactions"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(profile_name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}
          </template>
          <template v-slot:cell(approved)="row">
            {{$global.convertCurrency(country, row.item.total - row.item.approved)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addToInvoice(row.item.campaign_id, 1)"
            >
              Add to invoice
            </b-button>
          </template>
        </b-table>
      </b-row>
      <b-row v-if="total_micro != 0">
        <b-col cols="12" class="moneylisth4">
          <h4>Micro Campaigns</h4>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="micro_transactions"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(profile_name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}


          </template>
          <template v-slot:cell(approved)="row">
              {{$global.convertCurrency(country, row.item.total - row.item.approved)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addToInvoice(row.item.campaign_id, 3)"
            >
              Add to invoice
            </b-button>
          </template>
        </b-table>
      </b-row>
      <b-row v-if="total_general != 0">
        <b-col cols="12" class="moneylisth4">
          <h4>Transactions</h4>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="general_transactions"
          :fields="fields_transactions"
          :filter="filter"
          @filtered="onFiltered"
        >

          <template v-slot:cell(approved)="row">
            {{$global.convertCurrency(country, row.item.total - row.item.approved)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addToInvoice(row.item.campaign_id, 5)"
            >
              Add to invoice
            </b-button>
          </template>
        </b-table>
      </b-row>
      <b-row v-if="total_adlinks != 0">
        <b-col cols="12" class="moneylisth4">
          <h4>Adlinks Campaigns</h4>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="adlinks_transactions"
          :fields="fields_adlinks"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(profile_name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}
          </template>
          <template v-slot:cell(total)="row">
            {{$global.convertCurrency(country, row.item.total - approved_adlinks)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addToInvoice(row.item.campaign_id, 2)"
            >
              Add to invoice
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addToInvoice(row.item.campaign_id, 4)"
            >
              Remove from invoice
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import $ from 'jquery'

import { mapState } from 'vuex'
export default {
  name: "detailedmoneylist",
  components: {

  },
  created () {
    this.getDetailedMoneyList()
  },
  data() {
      return {
        id: this.$route.query.id,
        error: false,
        success: false,
        users: null,
        filter: null,
        total: 0,
        approved: 0,
        total_cube: 0,
        approved_cube: 0,
        total_micro: 0,
        approved_micro: 0,
        profile_name: '',
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'campaign_name',
            sortable: true
          },
          {
            key: 'brand',
            sortable: true
          },
          {
            key: 'end_date',
            sortable: true
          },
          {
            label: 'Available',
            key: 'approved',
            sortable: true
          },
          {
            label: 'Approve',
            key: 'id'
          },
        ],
        fields_transactions: [
          {
            label: 'Transaction type',
            key: 'campaign_id',
            sortable: true
          },
          {
            label: 'Available',
            key: 'approved',
            sortable: true
          },
          {
            label: 'Approve',
            key: 'id'
          },
        ],
        fields_adlinks: [
          {
            label: 'Total',
            key: 'total',
            sortable: true
          },
          {
            label: 'Manage',
            key: 'id'
          },
        ],
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances',
        },
        {
          text: 'Money list',
          to: '/moneylist',
        },
        {
          text: 'Detailed Money list',
          href: '#',
          active: true
        }
      ]
      }
    },
    mounted:{
    },
    computed: {
      ...mapState(['country']),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      getProfileImg(id){
        return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      },
      getDetailedMoneyList() {
        this.$http.bamse.get('finance/getdetailedmoneylist?profile_id='+this.id)
          .then(response => this.getDetailedMoneyListSuccess(response))
          .catch(() => this.getDetailedMoneyListFailed())
      },
      getDetailedMoneyListSuccess(response) {
        if(response.data.success) {

          this.total_cube = 0
          this.approved_cube = 0
          this.total_micro = 0
          this.approved_micro = 0
          this.total_adlinks = 0
          this.approved_adlinks = 0
          this.total_general = 0
          this.approved_general = 0

          this.cube_transactions = response.data.success.cube_transactions
          this.micro_transactions = response.data.success.micro_transactions
          this.adlinks_transactions = response.data.success.adlinks_transactions
          this.general_transactions = response.data.success.general_transactions

          this.profile_name = response.data.success.profile_name
          this.total = response.data.success.total
          this.approved = response.data.success.approved

          this.cube_transactions.forEach(item => {
            this.total_cube = this.total_cube + Number(item.total)
            this.approved_cube = this.approved_cube + Number(item.approved)
          })

          this.micro_transactions.forEach(item => {
            this.total_micro = this.total_micro + Number(item.total)
            this.approved_micro = this.approved_micro + Number(item.approved)
          })

          this.adlinks_transactions.forEach(item => {
            this.total_adlinks = this.total_adlinks + Number(item.total)
            this.approved_adlinks = this.approved_adlinks + Number(item.approved)
          })

          this.general_transactions.forEach(item => {
            this.total_general = this.total_general + Number(item.total)
            this.approved_general = this.approved_general + Number(item.approved)
          })

        }
      },
      getDetailedMoneyListFailed() {
        this.error = 'no fetch sorry'
      },
      addToInvoice(campaign_id, campaign_type) {
        this.$http.bamse.post('finance/addtoinvoice?profile_id='+this.id+'&campaign_type='+campaign_type+'&campaign_id='+campaign_id)
        .then(response => this.addToInvoiceSuccess(response))
        .catch(() => this.addToInvoiceFailed())
      },
      addToInvoiceSuccess(response) {
        if(response.data.success) {
          this.getDetailedMoneyList()
        }
      },
      addToInvoiceFailed() {
        this.error = 'no fetch sorry'
      },
    },
};
</script>
