<template>
  <div id="app">
    <Navigation />
    <b-container fluid v-if="this.$route.path == '/' || this.$route.path == '/resetpassword'">
      <router-view />
    </b-container>
    <b-container v-else>
      <router-view />
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Navigation from "./views/general/Navigation.vue";
import Footer from "./views/general/Footer.vue";

import { mapMutations } from 'vuex';

export default {
  components: {
    Navigation,
    Footer
  },
  created () {
    this.checkCurrentLogin();
  },
  updated () {
    this.checkCurrentLogin();
  },
  methods: {
    ...mapMutations({
      'setUserSession': 'setUserSession',
      'logoutStore': 'LOGOUT'
    }),
    checkCurrentLogin: async function() {
      //get user info

      /**
       * Check if token exists in localStorage, if it exists we need
       * to set the default authorization header to this token or else
       * we wont be authorized and will receive a 401.
       */

      if (window && window.localStorage.token){
        this.$http.bamse.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.token;
      }

      let userInfo;
      try {
        userInfo = await this.$http.bamse.get('user/details/self');
        this.setUserSession(userInfo);
      }
      catch(err){
        //Well, if an error occurs it means user is not logged in or
        //atleast not logged in correctly therefore to remove users session
        //completely we should log user out.
        if (this.$route.name != 'login' && this.$route.name != 'resetpassword'){
          this.$router.push('/');
          this.logoutStore();
        }
      }
    }
  }
}
</script>
