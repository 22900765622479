<template>
  <div class="campaign-info campaign-reports">
    <b-card-group deck>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Clicks</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'mouse-pointer' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ this.clicks }}
        </b-card>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Conversions</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'shopping-cart' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ this.conversions }}
        </b-card>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Conversion rate</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'shopping-cart' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ this.conversion_rate }}
        </b-card>
    </b-card-group>
    <b-card-group deck>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Revenue</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ $global.convertCurrency(country, this.revenue)}}
        </b-card>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Payout</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ $global.convertCurrency(country, this.payout)}}
        </b-card>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Profit</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            {{ $global.convertCurrency(country, this.profit)}}
        </b-card>
    </b-card-group>
    <b-card-group>
        <b-card class="campaign-info-box">
            <div>
                <h4 class="header-h">Report</h4>
                <span class="header-icon">
                <font-awesome-icon :icon="{ prefix: 'far', iconName: 'chart-bar' }"/>
                </span>
            </div>
            <div class="clearfix"></div>
            <hr>
            <router-link :to="{ name: 'adlinkgeneralreport', query: { campaign: this.tune_id }}">
                <b-button block variant="outline-primary" class="btn-margin">Go to full report</b-button>
            </router-link>
        </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Chart from 'chart.js';


import $ from 'jquery'

export default {
  name: "ReportsAdlinkCampaign",
  components: {
      Chart
  },
  data: function () {
    return {
      id: this.$route.query.id,
      currency: '',
      number_of_profiles: '',
      tune_id: '',
      clicks: '',
      count: null,
      showgraph: true,
      conversions: '',
      conversion_rate: null,
      profit: 0,
      payout: 0,
      revenue: 0,
      loaded: false,
      }
    },
  mounted() {
    
  },
  computed: {
    ...mapState(['country']),
  },
  created () {
    this.getCampaign()
  },
  methods: {
    getCampaign() {
        this.$http.bamse.get('campaigns/adlink/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {
        this.currency = response.data.success[0].currency
        this.tune_id = response.data.success[0].tune_id
        this.getCampaignStats()
      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    getCampaignStats() {

      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Report');
      formData.append('Method', 'getStats');
      formData.append('fields[]', 'Stat.revenue');
      formData.append('fields[]', 'Stat.profit');
      formData.append('fields[]', 'Stat.payout');
      formData.append('fields[]', 'Stat.conversions');
      formData.append('fields[]', 'Stat.clicks');
      formData.append('fields[]', 'Stat.affiliate_id');
      formData.append('filters[Stat.offer_id][conditional]', 'EQUAL_TO');
      formData.append('filters[Stat.offer_id][values]', this.tune_id);
      formData.append('totals', 1);

      this.$http.tune.post('', formData)
      .then(response => this.getCampaignStatsSuccess(response))
      .catch(error => this.getCampaignStatsFailed(error))
    },
    getCampaignStatsSuccess(response) {
      if(response.data.response.status == 1) {
          
            this.count = response.data.response.data.count

            if(this.count == '' || this.count == null) {

                this.profit = "N/A"
                this.payout = "N/A"
                this.revenue = "N/A"
                this.clicks = "N/A"
                this.conversions = "N/A"
                this.conversion_rate = "N/A"
                this.currency = ""
                
            } else {
                
                this.clicks = response.data.response.data.totals.Stat.clicks
                this.conversions = response.data.response.data.totals.Stat.conversions
                this.conversion_rate = Number(this.conversions/this.clicks*100).toFixed(2)+'%'
                this.profit = Number(response.data.response.data.totals.Stat.profit).toFixed()
                this.payout = Number(response.data.response.data.totals.Stat.payout).toFixed()
                this.revenue = Number(response.data.response.data.totals.Stat.revenue).toFixed()
            }
      }
    },
    getCampaignStatsFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
  },
};
</script>
