<template>
  <div>
    <b-form @submit.prevent="updateAdlinkCampaign()">    
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="outline-secondary">Campaign information</b-button>
          </b-card-header>
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Campaign name" label-for="campaign_name">
                    <b-input name="campaign_name" v-model="campaign_name"></b-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Description" label-for="description">
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      placeholder="Describe the campaign"
                      rows="8"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <datepicker 
                      wrapper-class="form-control" 
                      :monday-first="true"
                       format="dd-MM-yyyy" 
                      v-model="start_date" 
                      name="start_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <datepicker 
                      wrapper-class="form-control" 
                      :monday-first="true"
                       format="dd-MM-yyyy" 
                      v-model="end_date" 
                      name="end_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cover image" label-for="imageupload">
                  <b-form-file 
                    v-model="file"
                    placeholder="Choose a picture..."
                    drop-placeholder="Drop picture here..."
                    accept=".jpg, .png"
                    name="imageupload"
                  ></b-form-file>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Cookie time" label-for="cookie_time">
                    <b-form-select name="cookie_time" v-model="cookie_time" :options="options_cookie_time"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Default campaign" label-for="default">
                    <b-form-select name="default" v-model="default_campaign" :options="options_default"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Network" label-for="network">
                    <b-form-select name="network" disabled v-model="network" :options="options_network"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="network != null">
                  <b-form-group label-size="sm" label="Network URL" label-for="network_url">
                    <b-input name="network_url" v-model="network_url"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label-size="sm" label="Currency" label-for="currency">
                    <b-form-select name="currency" v-model="currency" :options="options_currency"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Type Cube" label-for="cube_revenue_type">
                    <b-form-select name="cube_revenue_type" v-model="cube_revenue_type" :options="options_cube_revenue_type"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-size="sm" label="Revenue Type Profile" label-for="profile_revenue_type">
                    <b-form-select name="profile_revenue_type" v-model="profile_revenue_type" :options="options_profile_revenue_type"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="cube_revenue_type == 'cpa_percentage'">
                  <b-form-group label-size="sm" label="Revenue Cube Percentage" label-for="revenue_cube">
                    <b-input-group :append="cube_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_cube" 
                        v-model="revenue_cube"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="cube_revenue_type == 'cpc'">
                  <b-form-group label-size="sm" label="Revenue Cube Per Click" label-for="revenue_cube_cpc">
                    <b-input-group :append="currency" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_cube_cpc" 
                        v-model="revenue_cube_cpc"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="profile_revenue_type == 'cpa_percentage'">
                  <b-form-group label-size="sm" label="Revenue Profile Percentage" label-for="revenue_profile">
                    <b-input-group :append="profile_percentage" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_profile" 
                        v-model="revenue_profile"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="profile_revenue_type == 'cpc'">
                  <b-form-group label-size="sm" label="Profile Cube Per Click" label-for="revenue_profile_cpc">
                    <b-input-group :append="currency" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input 
                        type="number" 
                        name="revenue_profile_cpc" 
                        v-model="revenue_profile_cpc"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button class="btn-margin pull-right" type="submit" variant="outline-success" v-if="loading == false">Update campaign</b-button>
                  <b-button disabled class="btn-margin pull-right" variant="outline-success" v-else>
                    <b-spinner small ></b-spinner> Loading
                  </b-button>  
                </b-col>
              </b-row>
            </b-card-body>
        </b-card>

            
      </div>
        
    </b-form>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'
import FileUpload from '@/components/general/FileUpload.vue'
import ListProfiles from "@/components/cube/ListProfiles.vue";
import $ from 'jquery'
import moment from 'moment'

let brand_list = [];
let profile_list = [];
let employee_list = [];

export default {
  name: "CreateAdlinkCampaign",
  components: {
    Datepicker,
    ListProfiles,
    FileUpload
  },
  data: function () {
    return {
      showSubmit: false,
      filter: null,
      showButton: false,
      id: this.$route.query.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Create Adlink Campaign',
          href: '#',
          active: true
        },
      ],
      id_array: [],
      loading: false,
      default_campaign: 0,
      cube_percentage: '%',
      profile_percentage: '%',
      sales_person: '',
      project_manager: '',
      campaign_name: '',
      micro_profiles_from_listview: '',
      brand_id: {brand_id: null, tune_id: null},
      description: '',
      draft_required: 0,
      profiles: [],
      profiles_array: [],
      channels: [],
      channels_array: [],
      revenue_cube: 0,
      revenue_profile: 0,
      cookie_time: null,
      start_date: null,
      end_date: null,
      currency: null,
      payment_conditions: null,
      cube_revenue_type: 'cpa_percentage',
      profile_revenue_type: 'cpa_percentage',
      revenue_cube_cpc: 0,
      revenue_profile_cpc: 0,
      price: '',
      file: null,
      options_project_manager: [],
      options_sales_person: [],
      items: [],
      network: null,
      network_url: null,
      options_network: [
        { value: null, text: 'Direct integration' },
        { value: 'adtraction', text: 'Adtraction'},
        { value: 'awin', text: 'Awin'},
        { value: 'tradedoubler', text: 'Tradedoubler'},
        { value: 'rakuten', text: 'Rakuten'},
      ],
      options_brand: [
        { value: null, text: 'Please select an option' },
      ],
      options_currency: [
        { value: null, text: 'Please select an option' },
        { value: 'SEK', text: 'Swedish Krona'},
        { value: 'DKK', text: 'Danish Krona'}
      ],
      options_cookie_time: [
        { value: null, text: 'Please select an option' },
        { value: '168', text: 'One week'},
        { value: '336', text: 'Two weeks'},
        { value: '720', text: 'One month'},
      ],
      options_cube_revenue_type: [
        { value: null, text: 'Please select an option' },
        { value: 'cpa_percentage', text: 'Revenue per Sale (RPS)'},
        { value: 'cpc', text: 'Revenue per Click (RPC)'}
      ],
      options_default: [
        { value: 0, text: 'No' },
        { value: 1, text: 'Yes'},
      ],
      options_profile_revenue_type: [
        { value: null, text: 'Please select an option' },
        { value: 'cpa_percentage', text: 'Cost per Sale (CPS)'},
        { value: 'cpc', text: 'Cost per Click (CPC)'}
      ],
      options_profiles: profile_list,
      options_payment_conditions: [
        { value: null, text: 'Please select an option' },
        { value: 10, text: '10 days' },
        { value: 15, text: '15 days' },
        { value: 20, text: '20 days' },
        { value: 30, text: '30 days'}
      ],
      selected_profiles: [],
      activated_profiles: [],
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'activated',
          label: false,
          sortable: true
        }
      ],
    }
  },
  computed: {
    ...mapState(['current_id', 'profile_assets'])
  },
  created () {
    this.getCampaign()
  },
  methods: {
    getCampaign() {
        this.$http.bamse.get('campaigns/adlink/details?campaign_id='+this.id)
        .then(response => this.getCampaignSuccess(response))
        .catch(() => this.getCampaignFailed())
    },
    getCampaignSuccess(response) {
      if(response.data.success) {
        this.campaign_name = response.data.success[0].campaign_name
        this.description = response.data.success[0].description
        this.start_date = response.data.success[0].start_date
        this.end_date = response.data.success[0].end_date
        this.currency = response.data.success[0].currency
        this.type = response.data.success[0].payout_type
        this.tune_id = response.data.success[0].tune_id
        this.cube_revenue_type = response.data.success[0].revenue_type
        this.profile_revenue_type = response.data.success[0].payout_type
        this.revenue_cube = response.data.success[0].cube_payout_percent
        this.revenue_profile = response.data.success[0].profile_payout_percent
        this.revenue_cube_cpc = response.data.success[0].cube_cpc
        this.revenue_profile_cpc = response.data.success[0].profile_cpc
        this.cookie_time = response.data.success[0].cookie_time
        this.default_campaign = response.data.success[0].default
        this.network = response.data.success[0].network
        this.network_url = response.data.success[0].network_url

      }
    },
    getCampaignFailed() {
      this.error = 'no fetch sorry'
    },
    updateAdlinkCampaign() {

      this.loading = true

      let formData = new FormData();
      let url_fix = '';
      if(this.network == null){
        url_fix = "http://tracker.revrelations.com/t/{aff_sub2}/?transaction_id={transaction_id}&advertiser_id={advertiser_id}&url_redirect={aff_sub}"
      } else {
        url_fix = this.network_url+'&epi={transaction_id}&url={aff_sub}'
      }

      let date_format = moment(String(this.end_date)).format('YYYY-MM-DD HH:MM:SS')

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('Target', 'Offer');
      formData.append('Method', 'update');
      formData.append('id', this.tune_id);
      formData.append('return_object', 1);
      formData.append('data[name]', this.campaign_name);
      formData.append('data[offer_url]', url_fix);
      formData.append('data[preview_url]', url_fix);
      formData.append('data[max_percent_payout]', this.revenue_cube);
      formData.append('data[percent_payout]', this.revenue_profile);
      formData.append('data[revenue_type]', this.cube_revenue_type);
      formData.append('data[payout_type]', this.profile_revenue_type);
      formData.append('data[expiration_date]', date_format);
      formData.append('data[currency]', this.currency);
      formData.append('data[session_hours]', this.cookie_time);
      formData.append('data[default_payout]', this.revenue_profile_cpc);
      formData.append('data[max_payout]', this.revenue_cube_cpc);

      this.$http.tune.post('', formData)
      .then(response => this.updateAdlinkCampaignSuccess(response))
        .catch(error => this.updateAdlinkCampaignFailed(error))
    },
    updateAdlinkCampaignSuccess(response) {
      if(response.data.response.status == 1) {

        var tune_id = response.data.response.data.Offer.id

        this.updateAdlinkBamseCampaign(tune_id)
       
      }
    },
    updateAdlinkCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
    },
    updateAdlinkBamseCampaign(tune_id) {

      let formData = new FormData();

      var start_date_format = JSON.stringify(this.start_date)
      var end_date_format = JSON.stringify(this.end_date)

      var profiles = JSON.stringify(this.profiles_array)

      formData.append('campaign_name', this.campaign_name);
      formData.append('tune_id', tune_id);
      formData.append('profiles', profiles);
      formData.append('cube_payout_percent', this.revenue_cube);
      formData.append('profile_payout_percent', this.revenue_profile);
      formData.append('revenue_type', this.cube_revenue_type);
      formData.append('payout_type', this.profile_revenue_type);
      formData.append('start_date', start_date_format);
      formData.append('end_date', end_date_format);
      formData.append('currency', this.currency);
      formData.append('brand_id', this.brand_id.brand_id);
      formData.append('profile_cpc', this.revenue_profile_cpc);
      formData.append('cube_cpc', this.revenue_cube_cpc);
      formData.append('network_url', this.network_url);
      formData.append('description', this.description);
      formData.append('created_by', this.current_id);
      formData.append('file', this.file);
      formData.append('cookie_time', this.cookie_time);
      formData.append('default', this.default_campaign);

      this.$http.bamse.post('campaigns/adlink/edit?campaign_id='+this.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => this.updateAdlinkBamseCampaignSuccess(response))
      .catch(error => this.updateAdlinkBamseCampaignFailed(error)) 
    },
    updateAdlinkBamseCampaignSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Adlink campaign updated', 'success')
        setTimeout( () => this.$router.push({ path: '/list-adlinkcampaigns?type=active' }), 1500);
      }
    },
    updateAdlinkBamseCampaignFailed(error) {
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
      this.loading = false
    },
  },
};
</script>