<template>
    <div class="finances">
          <b-row>
      <b-col cols="12" class="header">
        <h1>Transactions</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
      <b-tabs content-class="mt-3">
        <b-tab title="Pending Transactions" active>
          <div class="manage-campaign">
            <PendingTransactions />
          </div>
        </b-tab>
        <b-tab title="Approved Transactions">
          <ApprovedTransactions />
        </b-tab>
      </b-tabs>
    </div>
</template>

<script>
import PendingTransactions from "@/components/cube/finances/transactions/PendingTransactions.vue";
import ApprovedTransactions from "@/components/cube/finances/transactions/ApprovedTransactions.vue";

export default {
  name: "finances",
  components: {
    PendingTransactions,
    ApprovedTransactions
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances'
        },
        {
          text: 'Transactions',
          href: '#',
          active: true
        }
      ],
    }
  },
};
</script>