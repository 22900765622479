<template>

    <div v-if="type == 1 && level == 1 || type == 1 && level == 3">
      <FinanceDashboard />
    </div>

    <div v-else-if="type == 2">
      <UserBank />
    </div>

    <div v-else>
      
    </div>

</template>

<script>

import { mapState } from 'vuex'
import FinanceDashboard from "@/components/finances/FinanceDashboard.vue";
import UserBank from "@/views/users/UserBank.vue"

export default {
  name: "finances",
  components: {
    FinanceDashboard,
    UserBank
  },
  computed: {
    ...mapState(['type', 'level', 'current_user_id']),
  },
  data: function () {
    return {
    }
  },
};
</script>
