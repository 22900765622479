<template>

    <!-- CUBE: Super admin -->
    <div v-if="type == 1 && level == 1">
      <CubeDashboard />
    </div>

    <!-- CUBE: Finance -->
    <div v-else-if="type == 1 && level == 3">
      <FinanceDashboard />
    </div>

    <!-- CUBE: Sales -->
    <div v-else-if="type == 1 && level == 4">
      <SalesDashboard />
    </div>

    <!-- Profile -->
    <div v-else-if="type == 2">
      <ProfileDashboard v-if="this.$store.state.profile_id != null" />
      <ProfileDashboardMultiple v-else />
      <TermsAndConditions v-if="tocRead === 'false'" />
    </div>

    <!-- Brand -->
    <div v-else-if="type == 3">
      <BrandDashboard v-if="this.$store.state.brand_id != null" />
      <BrandDashboardMultiple v-else />
    </div>

    <div v-else>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

</template>

<script>

import { mapState, mapMutations } from 'vuex';
import CubeDashboard from "@/components/cube/CubeDashboard.vue";
import BrandDashboard from "@/components/brand/BrandDashboard.vue";
import BrandDashboardMultiple from "@/components/brand/BrandDashboardMultiple.vue";
import ProfileDashboard from "@/components/profile/ProfileDashboard.vue";
import ProfileDashboardMultiple from "@/components/profile/ProfileDashboardMultiple.vue";
import FinanceDashboard from "@/components/finances/FinanceDashboard.vue";
import SalesDashboard from "@/components/cube/SalesDashboard.vue";
import TermsAndConditions from "@/components/general/TermsAndConditions.vue";

export default {
  name: "dashboard",
  components: {
    CubeDashboard,
    BrandDashboard,
    BrandDashboardMultiple,
    ProfileDashboard,
    FinanceDashboard,
    SalesDashboard,
    TermsAndConditions,
    ProfileDashboardMultiple
  },
  data: function () {
    return {
      tocRead: Boolean
    }
  },
  computed: {
    ...mapState(['type', 'level', 'current_user_id', 'profile_assets', 'brand_assets']),
  },
  created () {
    this.setProfileID()
    this.setBrandID()
    this.updateLocalTocRead()
  },
  updated () {
    this.setProfileID()
    this.setBrandID()
  },
  methods: {
    ...mapMutations({
      'setProfile': 'SETPROFILE',
      'setBrand': 'SETBRAND'
    }),
    setProfileID(){
      if(this.profile_assets.length == 1) {
        this.setProfile(this.profile_assets[0].profile_id)
      }
      if(this.type == 1){
        this.setProfile(this.profile_assets[0].profile_id)
      }
    },
    setBrandID(){
      if(this.brand_assets.length == 1) {
        this.setBrand(this.brand_assets[0].brand_id)
      }
    },
    updateLocalTocRead() {
      if(localStorage.tocRead) {
        this.tocRead = localStorage.tocRead
      } else {
        localStorage.tocRead = false
        this.$router.go(this.$router.currentRoute)
      }

    }
  },
};
</script>
