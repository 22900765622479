<template>
  <div class="all-users">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Money List</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="campaign-info-box">
          <div>
            <h4 class="header-h">Available</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'far', iconName: 'money-bill-alt' }"/>
            </span>
          </div>
          <div class="clearfix"></div>
          <hr>
          {{this.$global.convertCurrency(country, total)}}
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="campaign-info-box">
          <div>
            <h4 class="header-h">Next Payout</h4>
            <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'hand-holding-usd' }"/>
            </span>
          </div>
          <div class="clearfix"></div>
          <hr>
          {{this.$global.convertCurrency(country, approved)}}
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table
          striped
          hover
          responsive
          :items="items"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(profile_name)="row">
            <b-img
              class="table-img"
              fluid
              :src="getProfileImg(row.item.profile_id)"
              @error="replaceByDefault"
            ></b-img>
            {{ row.item.profile_name }}
          </template>
          <template v-slot:cell(total)="row">
            {{ $global.convertCurrency(country, row.item.total)}}
          </template>
          <template v-slot:cell(approved)="row">
            {{ $global.convertCurrency(country, row.item.approved)}}
          </template>
          <template v-slot:cell(manage)="row">
            <router-link :to="{ name: 'detailedmoneylist', query: { id: row.item.profile_id }}">
            <b-button variant="outline-primary" size="sm">Manage</b-button>
          </router-link>
          </template>
          <template v-slot:cell(id)="row">
            <b-button
              :id="row.item.profile_id"
              size="sm"
              variant="outline-primary"
              @click="createInvoice(row.item.profile_id)"
            >
              Make withdrawal
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import $ from 'jquery'

import { mapState } from 'vuex'
export default {
  name: "moneylist",
  components: {

  },
  created () {
    this.getMoneyList()
  },
  data() {
      return {
        error: false,
        success: false,
        users: null,
        filter: null,
        total: 0,
        approved: 0,
        // Note `isActive` is left out and will not appear in the rendered table
        fields: [
          {
            key: 'profile_name',
            sortable: true
          },
          {
            key: 'total',
            sortable: true,
            label: 'Available'
          },
          {
            key: 'approved',
            sortable: true,
            label: 'Next Payout'
          },
          {
            label: 'Manage',
            key: 'manage'
          },
          {
            key: 'id',
            label: 'Pay'
          }
        ],
        items: [],
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Finances',
          to: '/finances',
        },
        {
          text: 'Money list',
          href: '#',
          active: true
        }
      ]
      }
    },
    mounted:{
    },
    computed: {
     ...mapState(['country']),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    methods: {
      getProfileImg(id){
        return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      },
      getMoneyList() {
        this.$http.bamse.get('finance/getmoneylist')
          .then(response => this.getMoneyListSuccess(response))
          .catch(() => this.getMoneyListFailed())
      },
      getMoneyListSuccess(response) {
        if(response.data.success) {
          //this.items = response.data.success

          response.data.success.forEach(item => {
            if(item.total != 0){
              this.items.push(item)
            } else if (item.approved != 0) {
              this.items.push(item)
            }
          });
          
          this.items.forEach(item => {
            this.approved = this.approved + Number(item.approved)
            this.total = this.total + Number(item.total)
          });

        }
      },
      getMoneyListFailed() {
        this.error = 'no fetch sorry'
      },
      createInvoice(profile_id) {
        this.$http.bamse.post('finance/createinvoice?profile_id='+profile_id)
        .then(response => this.createInvoiceSuccess(response))
        .catch(() => this.createInvoiceFailed())
      },
      createInvoiceSuccess(response) {
        if(response.data.success) {
          this.$global.makeToast(this, 'Success', 'Invoice created!', 'success')
          this.total = 0;
          this.approved = 0;
          this.items = [];
          this.getMoneyList();
         //setTimeout( () => this.$router.push({ path: '/finances' }), 1500);
        }
      },
      createInvoiceFailed() {
        this.error = 'no fetch sorry'
      },
    },
};
</script>
