<template>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-8">
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </div>
            <div class="col-md-2">
                <button v-on:click="submitFile()">Submit</button>
            </div>

        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                file: '',
            }
        },
        methods: {
            submitFile(){
                let formData = new FormData();
                formData.append('file', this.file);
        
                this.$http.bamse.post( '/upload',
                    formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        ).then(function(){
                            console.log('SUCCESS!!');
                        })
                        .catch(function(){
                            console.log('FAILURE!!');
                        });
            },
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
                console.log(this.file)
            }


        }
    }
</script>