import Vue from 'vue';
import VueAxios from 'vue-axios';

import axios from './axios';
import tune from './tune';
import fb from './fb';

Vue.use(VueAxios, {bamse: axios, tune: tune, fb: fb});

export default {

}
