<template>
  <div class="create-user addtransaction">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Add transaction</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-card header="Add new transaction">
      <b-form-group>
        <b-form @submit.prevent="addTransaction()">
          <b-row>
            <b-col lg="2">
              <span class="label">Transaction Date</span>
              <datepicker 
                wrapper-class="form-control" 
                :monday-first="true" 
                format="dd-MM-yyyy" 
                v-model="transaction_date" 
                name="date"
              ></datepicker>
            </b-col>
            <b-col lg="5">
              <span class="label">Profile</span>
              <multiselect 
                :showLabels="false"
                class="profile-select"
                placeholder="Pick profile for this transaction" 
                :clearOnSelect="false"
                :close-on-select="true"
                v-model="profile" 
                :options="options_profiles"
                label="name"
                >
              </multiselect>
            </b-col>
            <b-col lg="5">
              <span class="label">Activity</span>
              <multiselect 
                :showLabels="false" 
                placeholder="Pick activity" 
                :clearOnSelect="false" 
                v-model="channel"
                :options="options_channels"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="margin-top">
            <b-col lg="2">
            </b-col>
            <b-col lg="5">
              <span class="label">Revenue</span>
              <b-input 
                name="revenue" 
                v-model="revenue"
              ></b-input>
            </b-col>
            <b-col lg="5">
              <span class="label">Cost</span>
              <b-input 
                name="cost" 
                v-model="cost"
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="margin-top">
            <b-col cols="12">
              <b-button type="submit" class="btn btn-success pull-right" v-if="loading == false">
                Create transaction
              </b-button>
              <b-button disabled class="btn btn-success pull-right" v-else-if="loading == true">
                <b-spinner small ></b-spinner> Loading
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex'

let profile_list = [];

export default {
  name: "addtransaction",
  components: {
    Multiselect,
    Datepicker
  },
  computed: {
    ...mapState(['current_id', 'profile_assets', 'country']),
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          href: '/dashboard'
        },
        {
          text: 'Settings',
          href: '/settings'
        },
        {
          text: 'Create User',
          href: '#',
          active: true
        },
      ],
      options_channels: ['Youtube Adsense', 'Podcast', 'Blog premium ads', 'Fixed fee'],
      loading: false,
      options_profiles: profile_list,
      profile: null,
      channel: null,
      transaction_date: null,
      revenue: null,
      cost: null,

    }
  },
  created () {
    this.getProfileList()
  },
  methods: {
   getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        .catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {
     if(response.data.success) {
        profile_list = []
        response.data.success.forEach(function (result, key) {
          profile_list.push({id: result.id, name: result.name, number_of_posts: 2})
        });
        this.options_profiles = profile_list
      }
    },
    getProfileListFailed() {
      this.error = 'no fetch sorry'
    },
    addTransaction() {

      if (this.profile == null || this.channel == null || this.transaction_date == null || this.revenue == null || this.cost == null ){
        this.$global.makeToast(this, 'Oh oh!', 'Required information missing.', 'danger')
      } else {

        this.loading = true
        this.transaction_date = JSON.stringify(this.transaction_date)
        
        let formData = new FormData();

        formData.append('transaction_date', this.transaction_date);
        formData.append('activity', this.channel);
        formData.append('profile_id', this.profile.id);
        formData.append('profile_revenue',
        this.$global.convertCurrencyAdd(this.country, this.cost));
        formData.append('cube_revenue',
        this.$global.convertCurrencyAdd(this.country, this.revenue));
  

        this.$http.bamse.post('finance/addtransaction', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => this.addTransactionSuccess(response))
        .catch(error => this.addTransactionFailed(error))
      }
    },
    addTransactionSuccess(response) {
      if(response.data.success) {
        this.$global.makeToast(this, 'Success', 'Transaction added!', 'success')
        
        setTimeout( () => this.$router.go(), 1500);
      }
    },
    addTransactionFailed(error) {
      loading = false
      this.$global.makeToast(this, 'Oh oh!', error, 'danger')
    },
  }
}
</script>
