<template>
<div class="availablemicrocampaigns">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Available Micro Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-row class="margin-bottom">
      <b-col cols="12" class="margin-bottom sortable">
          <b-button @click="toggleSortables('1')" id="sort-1" class="active-sort" variant="outline-primary">Newest</b-button>

          <b-button @click="toggleSortables('2')" id="sort-2" variant="outline-primary">Ending soon</b-button>

          <b-button @click="toggleSortables('3')" id="sort-3" variant="outline-primary">Revenue</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4" lg="4" md="6" v-for="item in orderedItems(items)" v-if="item.end_date > today && item.status == null" v-bind:key="item.id">
        <b-modal :id="'modal_'+item.id" title="View brief" hide-footer>
          <p>
            {{ item.description }}
          </p>
          <b-button :id="item.id" variant="outline-success" block @click="applyForCampaigns(item.id, item.my_profile_id, item.draft_required, item.status)">Join campaign</b-button>
          <router-link :to="{ name: 'activemicrocampaigns' }">
            <b-button :id="'gotocampaign-'+item.id" block variant="outline-primary" class="hide">Go to your active campaigns</b-button>
          </router-link>
        </b-modal>

        <b-card
            class="card-available-micro"
            overlay
            :img-src="getCampaignImg(item.id)"
            @error="replaceByDefault"
            text-variant="white"
        >

        <b-row>
            <b-col cols="12" class="text-center">
                <h3>{{ item.campaign_name }}</h3>
                <b-img
                    class="campaign-img"
                    :src="getBrandImg(item.brand_id)"
                    fluid
                ></b-img>
                <h5>{{ item.company_name }}</h5>

                <h1 class="payout-type" v-if="item.payout_type == 'cpc'">{{ item.profile_cpc }} {{ item.currency }}</h1>
                <h1 class="payout-type" v-if="item.payout_type == 'cpa_percentage'">{{ item.profile_payout_percent }}%</h1>


            </b-col>
        </b-row>
        <b-row class="foot mt-2">


            <b-col cols="4">
              <span class="label">Draft</span>
              <span v-if="item.draft_required == 1">Yes</span>
              <span v-else>No</span>
            </b-col>
            <b-col cols="4" class="text-center">
              <span class="label_center">End date</span>
              {{ item.end_date }}
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="label_right">Commission</span>
              {{ item.revenue_profile_per_post }} SEK
            </b-col>

            <div class="clearfix"></div>

             <b-col cols="12" class="text-center mt-2 mb-2">
              <span v-if="item.channels.includes(1)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/>
              </span>

              <span v-if="item.channels.includes(2)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook'}"/>
              </span>

              <span v-if="item.channels.includes(3)" class="icon micro-font-awesome">
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'home' }"/>
              </span>

              <span v-if="item.channels.includes(4)" class="icon">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"/>
              </span>
            </b-col>

            <div class="clearfix"></div>


            <b-col cols="12" class="mt-1">
                <b-button :id="'viewbrief_'+item.id" variant="outline-light" block @click="$bvModal.show('modal_'+item.id+'')">View brief</b-button>
            </b-col>
        </b-row>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import moment from 'moment'

  export default {
    name: "availablemicrocampaigns",
    components: {
    },
    computed: {
      ...mapState(['profile_id', 'profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id', 'country']),
    },
    data: function () {
      return {
        items: [],
        show: false,
        currentOrder: 'id',
        instagram: false,
        facebook: false,
        blog: false,
        youtube: false,
        today: null,
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Available Micro Campaigns',
          active: true
        },
      ],
      }
    },
    created () {
      this.listMicroCampaigns()
    },
    methods: {
      getBrandImg(brand_id){
        return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
      },
      getCampaignImg(id){
        return this.$global.spacesURL()+'campaigns/micro/campaign_' + id + '/cover_image/campaign_' + id + '.png'
      },
      toggleSortables(id){
        if(id == 1) {
          this.currentOrder = 'id'
          $("#sort-1").addClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
        } else if (id == 2) {
          this.currentOrder = 'end_date'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").addClass('active-sort')
          $("#sort-3").removeClass('active-sort')
        } else if (id == 3) {
          this.currentOrder = 'revenue_profile_per_post'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").addClass('active-sort')
        }
      },
      replaceByDefault(e){
        var url = "https://bamse-in-space.fra1.digitaloceanspaces.com/brands/brand_" + e.target.id + "/profile_picture/brand_" + e.target.id + ".png"

        e.target.src = url
      },
      orderedItems() {
        return _.orderBy(this.items, this.currentOrder, 'desc')
      },
      listMicroCampaigns() {

        this.today = moment().format('YYYY-MM-DD')

        this.$http.bamse.post('campaigns/micro/listallactive/profile/self', {
          profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
        })
          .then(response => this.listMicroCampaignsSuccess(response))
          .catch(() => this.listMicroCampaignsFailed())
      },
      listMicroCampaignsSuccess(response) {
        if(response.data.success) {
          this.items = response.data.success
        }
      },
      listMicroCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
      applyForCampaigns(campaign_id, profile_id, draft_required, campaign_status) {

        this.$http.bamse.post('campaigns/micro/status/update/self', {
          campaign_id: campaign_id,
          profile_id: profile_id,
          status: 1
        })
        .then(response => this.applyForCampaignsSuccess(response, campaign_id))
        .catch(() => this.applyForCampaignsFailed())

      },
      applyForCampaignsSuccess(response, campaign_id) {
      if(response.data.success) {
          this.success = true
          $('#'+campaign_id).toggleClass("hide")
          $('#viewbrief_'+campaign_id).toggleClass("hide")
          $('#gotocampaign-'+campaign_id).toggleClass("hide")
        }
      },
      applyForCampaignsFailed() {
        this.error = true
      },
    }
  };
</script>