<template>
  <div class="404">
      <h1>404 - Page not found</h1>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {
  }
};
</script>
